/* eslint-disable react/prop-types */
import React from 'react';

import Appointment from './Appointment';

import './AppointmentTooltip.scss';

export default function AppointmentTooltipComponent({
  itemData,
  schedulerRef,
  reasonsOptions,
  setTaskRescheduling,
  removeAppointmentByIndex,
}) {
  return (
    <Appointment
      itemData={{ data: itemData }}
      className="appointment-tooltip-task"
      screen="appointmentTooltip"
      reasonsOptions={reasonsOptions}
      schedulerRef={schedulerRef}
      setTaskRescheduling={setTaskRescheduling}
      removeAppointmentByIndex={removeAppointmentByIndex}
    />
  );
}
