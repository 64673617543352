import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, message, Tooltip } from 'antd';

import { FileExcelOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import ModalUploadExcel from '../../../Components/Instructor/ModalUploadExcel';
import TableAntd from '../../../Components/TableAntd/TableAntd';
import { api } from '../../../Services/axiosService';

export default function TransportList() {
  const [requiredDocuments, setRequiredDocuments] = useState();
  const [isModalOpen, setIsModalOpen] = useState();
  const [canHandle, setCanHandle] = useState(false);

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome/Razão Social',
        ...getColumnSearchProps('name', 'Nome/Razão Social'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'commercialName',
        title: 'Nome Fantasia',
        ...getColumnSearchProps('commercialName', 'Nome Fantasia'),
        sorter: (a, b) => a.commercialName.localeCompare(b.commercialName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'city',
        title: 'Cidade',
        ...getColumnSearchProps('city', 'Cidade'),
        sorter: (a, b) => a.city.localeCompare(b.city),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'state',
        title: 'Estado',
        ...getColumnSearchProps('state', 'Estado'),
        sorter: (a, b) => a.state.localeCompare(b.state),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  const fetchSettings = async () => {
    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => Utils.decryptSettings(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });

    const reqDocs =
      res.requiredDocumentsTransport?.split(',')?.map((item) => parseInt(item, 10)) ?? [];
    setRequiredDocuments(reqDocs);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const renderExtraToolbar = () => {
    if (!canHandle) return null;

    return (
      <div style={{ marginLeft: '10px' }}>
        <Tooltip title="Importar Registro">
          <Button
            onClick={openModal}
            shape="circle"
            icon={<FileExcelOutlined />}
            style={{
              width: '100%',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          />
        </Tooltip>
      </div>
    );
  };

  useLayoutEffect(() => {
    const vehicleResource = JSON.parse(
      localStorage.getItem('conecta__permissions')
    )?.resources.find(({ name }) => name === 'Transport');

    setCanHandle(vehicleResource.canHandle);
  }, []);

  useEffect(() => {
    fetchSettings();
  }, []);

  if (requiredDocuments) {
    return (
      <>
        <TableAntd
          columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
          routePermissions="Transport"
          requestName="Transport?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Transporte"
          route="/Transporte"
          toggleStatusRoute="/Company"
          cdPage="id"
          btnNew
          requiredDocuments={requiredDocuments}
          extraToolbar={renderExtraToolbar()}
        />
        <ModalUploadExcel setIsOpen={setIsModalOpen} isOpen={isModalOpen} screenType="transport" />
      </>
    );
  }
}
