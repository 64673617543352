/* eslint-disable react/prop-types */
import React from 'react';

export default function ResourceCellRender({
  itemData: {
    data: { slotType },
  },
}) {
  let columnName = slotType;

  if (slotType === 'Service') {
    columnName = 'Serviço';
  }

  return (
    <div id="sourceCell">
      <span>{columnName}</span>
    </div>
  );
}
