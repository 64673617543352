/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Popconfirm, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Inputmask from 'inputmask';
import { useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';
import { uploadProfilePic } from '../../Services/firebaseService';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = async (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('Permitido somente JPG ou PNG!');
    return false;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('A imagem precisa ser menor que 2MB!');
    return false;
  }

  return true;
};

let passwordEdit = '';
let profilePicEdit = '';

export default function Profile() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState();
  const [profilePic, setProfilePic] = useState();
  const [loading, setLoading] = useState(false);
  const [screenModified, setScreenModified] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [resourcePermissions, setResourcePermissions] = useState();
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const userData = JSON.parse(localStorage.getItem('conecta__userData'));

  // tras o perfil do usuário logado
  const getUserEdit = async () => {
    await api
      .get(`/User?id=${userData.id}`)
      .then((res) => {
        const { data } = res;
        passwordEdit = data.password;
        profilePicEdit = data?.profilePic;
        delete data.password;
        form.setFieldsValue(data);
        setImageUrl(data?.profilePic);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });
  };

  // faz o put(edição) do perfil de usuário
  const submit = async (values) => {
    setLoading(true);
    const data = values;
    if (profilePic) {
      data.profilePic = await uploadProfilePic(profilePic?.file);
    } else {
      data.profilePic = profilePicEdit;
    }
    if (data.password === '') {
      data.password = passwordEdit;
      data.confirmPassword = passwordEdit;
    } else {
      data.changedPassword = true;
    }

    await api
      .put('/User/UpdateProfile', data)
      .then(() => {
        userData.name = data.name;
        userData.phone1 = data.phone1;
        userData.phone2 = data.phone2;
        userData.profilePic = data.profilePic;
        localStorage.setItem('conecta__userData', JSON.stringify(userData));
        message.success('Dados atualizados!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setScreenModified(false);
      })
      .catch((error) => {
        setLoading(false);
        Utils.logError(error);
        message.error('Erro ao editar!');
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const updatePasswordCriteria = (value) => {
    const criteria = {
      minLength: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*]/.test(value),
    };

    setPasswordCriteria(criteria);
  };

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };

  useEffect(() => {
    getUserEdit();

    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone2'));
    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone1'));
  }, []);

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields, canView, canHandle }) => {
        setResourcePermissions({ canView, canHandle });

        if (name === 'Profile') {
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  return (
    <Form
      form={form}
      disabled={loading}
      name="Profile"
      onFinish={submit}
      layout="vertical"
      autoComplete="off"
      onChange={() => setScreenModified(true)}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={loading}
          // disabled={!resourcePermissions?.canHandle}
        >
          Salvar
        </Button>
      </div>
      <Row gutter={[24]}>
        {fieldsPermissions?.Photo.access !== 0 && (
          <Col span={8}>
            <Form.Item
              style={{
                textAlign: 'center',
              }}
              rules={[
                {
                  required: fieldsPermissions?.Photo.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <ImgCrop grid rotate>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={(e) => setProfilePic(e)}
                  onChange={handleChange}
                  disabled={fieldsPermissions?.Photo.access !== 2}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Foto perfil"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
        )}

        <Col span={8}>
          <Form.Item label="id" name="id" className="input-hidden">
            <Input />
          </Form.Item>

          {fieldsPermissions?.Name.access !== 0 && (
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: fieldsPermissions?.Name.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input disabled={fieldsPermissions?.Name.access !== 2} />
            </Form.Item>
          )}

          {fieldsPermissions?.Password.access !== 0 && (
            <Form.Item
              label="Repita a senha"
              name="confirmPassword"
              rules={[
                {
                  validator: (rule, value) => {
                    if (form.getFieldValue('password') !== '' && value !== '') {
                      if (form.getFieldValue('password') !== value) {
                        return Promise.reject(new Error(`As senhas precisam ser iguais`));
                      }
                    }
                    return Promise.resolve();
                  },
                },
                {
                  required: fieldsPermissions?.Password.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input.Password disabled={fieldsPermissions?.Password.access !== 2} />
            </Form.Item>
          )}

          {fieldsPermissions?.Phone.access !== 0 && (
            <Form.Item
              label="Celular"
              name="phone2"
              rules={[
                {
                  required: fieldsPermissions?.Phone.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                placeholder="(00) 0000-0000"
                id="phone2"
                disabled={fieldsPermissions?.Phone.access !== 2}
              />
            </Form.Item>
          )}
        </Col>

        <Col span={8}>
          {fieldsPermissions?.Password.access !== 0 && (
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: fieldsPermissions?.Password.isRequired,
                  message: 'Campo obrigatório!',
                },
                // Sua lógica de validação aqui
              ]}
            >
              <Input.Password
                onChange={(e) => {
                  updatePasswordCriteria(e.target.value);
                  if (!passwordTouched && e.target.value) {
                    setPasswordTouched(true);
                  } else if (passwordTouched && !e.target.value) {
                    setPasswordTouched(false);
                  }
                }}
                disabled={fieldsPermissions?.Password.access !== 2}
              />
            </Form.Item>
          )}
          {passwordTouched && (
            <div>
              <p style={{ color: passwordCriteria.minLength ? 'green' : 'red' }}>
                No mínimo 8 caracteres.
              </p>
              <p
                style={{
                  color: passwordCriteria.uppercase && passwordCriteria.lowercase ? 'green' : 'red',
                }}
              >
                Ao menos uma letra maiúscula e uma minúscula.
              </p>
              <p style={{ color: passwordCriteria.number ? 'green' : 'red' }}>
                Ao menos um número.
              </p>
              <p style={{ color: passwordCriteria.specialChar ? 'green' : 'red' }}>
                Ao menos um caractere especial.
              </p>
            </div>
          )}

          {fieldsPermissions?.Cellphone.access !== 0 && (
            <Form.Item
              label="Telefone"
              name="phone1"
              rules={[
                {
                  required: fieldsPermissions?.Cellphone.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                placeholder="(00) 0000-0000"
                id="phone1"
                disabled={fieldsPermissions?.Cellphone.access !== 2}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
}
