/* eslint-disable no-await-in-loop */
/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Tabs,
} from 'antd';
import Upload from 'antd/lib/upload/Upload';
import Inputmask from 'inputmask';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import CompanyFunctions from '../../../Assets/Scripts/CompanyFunctions';
import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';
import DriverRegisterDrawerComponent from '../../Driver/Register/DriverRegisterDrawer';
import VehicleRegisterDrawerComponent from '../../Vehicle/Register/VehicleRegisterDrawer';
import ListDriverItemComponent from '../Components/ListDriverItem';
import ListVehicleItemComponent from '../Components/ListVehicleItem';

const { Search } = Input;

let canHandleTransport = false;

function TransportRegisterPage() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [hiddenVehicles, setHiddenVehicles] = useState([]);
  const [hiddenDrivers, setHiddenDrivers] = useState([]);
  const [screenModified, setScreenModified] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState();
  const [docIdsVehicle, setDocIdsVehicle] = useState();
  const [docIdsDriver, setDocIdsDriver] = useState();
  const [isVehicleDrawerOpen, setIsVehicleDrawerOpen] = useState(false);
  const [isDriverDrawerOpen, setIsDriverDrawerOpen] = useState(false);

  const fetchDocumentTypes = async () => {
    const documentTypes = await api
      .get('/DocumentType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os tipos de documento!');
      });

    const mappedDocumentTypes = documentTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setDocumentTypeOptions(mappedDocumentTypes);
    return mappedDocumentTypes;
  };

  const fetchVehicles = async (docIdsP) => {
    const vehicles = await api
      .get('/Vehicle')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os veículos!');
      });

    const filteredVehicles = CompanyFunctions.registersWithValidDocs(vehicles, docIdsP);

    const mappedVehicles = filteredVehicles?.map((item) => ({
      ...item,
      value: item.id,
      label: `${item.licensePlate} - ${item.type.name} (${item.capacity} pessoas)`,
    }));

    setVehicleOptions(mappedVehicles);
    return mappedVehicles;
  };

  const fetchDrivers = async (docIdsP) => {
    try {
      const filter = `/User?filters[0].Field=Company.CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Motorista`;
      const driversUsers = await api.get(filter).then((res) => res.data);

      const chunkSize = 30;
      let drivers = [];

      for (let i = 0; i < driversUsers?.length; i += chunkSize) {
        const chunkUsers = driversUsers?.slice(i, i + chunkSize);
        const usersCompanyIds = chunkUsers.map(({ company }) => company.id).toString();
        const driverFilter = `/Driver?filters[0].Field=Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${usersCompanyIds}`;

        const chunkDriverOptions = await api.get(driverFilter).then((res) => res.data);
        drivers = [...drivers, ...chunkDriverOptions];
      }

      const filteredDrivers = CompanyFunctions.registersWithValidDocs(drivers, docIdsP);

      const mappedDrivers = filteredDrivers?.map((item) => {
        const driverUser = driversUsers.find(({ company }) => company.id === item.id);

        return {
          ...item,
          ...driverUser,
          value: driverUser.id,
          label: item.name,
        };
      });

      setDriverOptions(mappedDrivers);
      return mappedDrivers;
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Motoristas!');
    }
    return [];
  };

  const fetchTransportById = async (editId, docIdsVehicleP, docIdsDriverP) => {
    setLoading(true);

    const vehicles = await fetchVehicles(docIdsVehicleP);
    const drivers = await fetchDrivers(docIdsDriverP);

    const response = await api
      .get(`/Transport?id=${editId}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados da transportadora!');
      });

    response.documents = response?.documents?.map(
      ({ type: { id: type }, number, validity, downloadUrls, verified }) => ({
        type,
        number,
        validity: moment(validity),
        downloadUrls,
        verified,
      })
    );

    if (response.vehicleList?.length > 0) {
      setHiddenVehicles(response.vehicleList.map(({ id }) => id) ?? []);
      const vehiclesIds = response.vehicleList.map(({ id }) => id) ?? [];
      response.vehicleList = vehicles.filter(({ id }) => vehiclesIds.includes(id));
    }

    if (response.driverList?.length > 0) {
      setHiddenDrivers(response.driverList.map(({ id }) => id) ?? []);
      const driversIds = response.driverList.map(({ id }) => id) ?? [];
      response.driverList = drivers.filter(({ id }) => driversIds.includes(id));
    }

    form.setFieldsValue(response);
    setLoading(false);
  };

  const submit = async (values) => {
    setLoading(true);
    const data = { ...values };

    await CompanyFunctions.saveDocuments(data, documentTypeOptions, 'transport');

    const httpMethod = editId ? 'put' : 'post';
    await api[httpMethod]('/Transport', data)
      .then(() => {
        message.success(`Dados ${editId ? 'atualizados' : 'salvos'}!`);
        form.resetFields();

        if (editId) {
          fetchTransportById(editId, docIdsVehicle, docIdsDriver);
        }

        setLoading(false);
        setScreenModified(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Erro ao enviar os dados da transportadora!');
        setLoading(false);
      });
  };

  const removeItemsFromList = (state, setState, id, fieldName) => {
    setScreenModified(true);
    const indexState = state.indexOf(id);

    if (indexState !== -1) {
      state.splice(indexState, 1);
      setState([...state]);
    }

    const formList = form.getFieldValue(fieldName) ?? [];
    const formListIds = formList.map(({ id }) => id);
    const indexList = formListIds.indexOf(id);

    if (indexList !== -1) {
      formList.splice(indexList, 1);
      form.setFieldValue(fieldName, [...formList]);
    }
  };

  const fetchSettings = async () => {
    const docTypes = await fetchDocumentTypes();

    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => Utils.decryptSettings(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });

    const tranportDocsIds =
      res.requiredDocumentsTransport?.split(',')?.map((item) => parseInt(item, 10)) ?? [];
    const vehicleDocsIds =
      res.requiredDocumentsVehicle?.split(',')?.map((item) => parseInt(item, 10)) ?? [];
    const driverDocsIds =
      res.requiredDocumentsDriver?.split(',')?.map((item) => parseInt(item, 10)) ?? [];

    const reqDocs = docTypes
      ?.filter(({ value }) => tranportDocsIds.includes(value))
      ?.map(({ label }) => label);

    if (editId) {
      fetchTransportById(editId, vehicleDocsIds, driverDocsIds);
    } else {
      fetchVehicles(vehicleDocsIds);
      fetchDrivers(driverDocsIds);
    }

    setRequiredDocuments(reqDocs);
    setDocIdsVehicle(vehicleDocsIds);
    setDocIdsDriver(driverDocsIds);
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields, canHandle }) => {
        if (name === 'Transport') {
          canHandleTransport = canHandle;
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  useEffect(() => {
    fetchSettings();

    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone2'));
    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone1'));
    Inputmask({ mask: '99999-999' }).mask(document.getElementById('cep'));
    Inputmask({ mask: '99.999.999/9999-99' }).mask(document.getElementById('cnpj'));
  }, []);

  return (
    <>
      <Form
        form={form}
        disabled={loading}
        name="transportForm"
        layout="vertical"
        onFinish={submit}
        autoComplete="off"
        onChange={() => setScreenModified(true)}
      >
        <div className="form-toolbar">
          {screenModified && (
            <Popconfirm
              title="Deseja mesmo voltar?"
              onConfirm={() => navigate(-1)}
              okText="Sim"
              cancelText="Não"
            >
              <Button>Voltar</Button>
            </Popconfirm>
          )}
          {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}

          <Button loading={loading} block type="primary" htmlType="submit ">
            Salvar
          </Button>
        </div>

        <Tabs>
          <Tabs.TabPane tab="Dados Gerais" key="generalData">
            {/* Campos invisiveis */}
            <Form.Item className="input-hidden" label="Id" name="id">
              <Input />
            </Form.Item>

            <Row gutter={[24]}>
              {fieldsPermissions?.Cnpj.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="CNPJ"
                    name="cnpj"
                    rules={[
                      {
                        required: fieldsPermissions?.Cnpj.isRequired,
                        validator: (rule, value) => {
                          const isValid = Utils.validateCNPJ(value);
                          if (!isValid && fieldsPermissions?.Cnpj.isRequired) {
                            return Promise.reject(new Error(`CNPJ Inválido`));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Search
                      id="cnpj"
                      placeholder="00.000.000/0000-00"
                      disabled={fieldsPermissions?.Cnpj.access !== 2}
                      onSearch={(val) => CompanyFunctions.fetchCnpj(val, form)}
                    />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Name.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                      {
                        required: fieldsPermissions?.Name.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.Name.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.CommercialName.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Nome Fantasia"
                    name="commercialName"
                    rules={[
                      {
                        required: fieldsPermissions?.CommercialName.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.CommercialName.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Street.access !== 0 && (
                <Col span={7}>
                  <Form.Item
                    label="Rua"
                    name="street"
                    rules={[
                      {
                        required: fieldsPermissions?.Street.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.Street.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.AddressNumber.access !== 0 && (
                <Col span={3}>
                  <Form.Item
                    label="Número"
                    name="addressNumber"
                    rules={[
                      {
                        required: fieldsPermissions?.AddressNumber.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <InputNumber disabled={fieldsPermissions?.AddressNumber.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.District.access !== 0 && (
                <Col span={7}>
                  <Form.Item
                    label="Bairro"
                    name="district"
                    rules={[
                      {
                        required: fieldsPermissions?.District.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.District.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Cep.access !== 0 && (
                <Col span={7}>
                  <Form.Item
                    label="CEP"
                    name="cep"
                    rules={[
                      {
                        required: fieldsPermissions?.Cep.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input
                      id="cep"
                      placeholder="00000-000"
                      disabled={fieldsPermissions?.Cep.access !== 2}
                    />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Complement.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Complemento"
                    name="complement"
                    rules={[
                      {
                        required: fieldsPermissions?.Complement.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.Complement.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.City.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Cidade"
                    name="city"
                    rules={[
                      {
                        required: fieldsPermissions?.City.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.City.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.State.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Estado"
                    name="state"
                    rules={[
                      {
                        required: fieldsPermissions?.State.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input disabled={fieldsPermissions?.State.access !== 2} />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Email.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: fieldsPermissions?.Email.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="exemplo@email.com"
                      disabled={fieldsPermissions?.Email.access !== 2}
                    />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Phone1.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Telefone 1"
                    name="phone1"
                    rules={[
                      {
                        required: fieldsPermissions?.Phone1.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input
                      id="phone1"
                      placeholder="(00) 0000-0000"
                      disabled={fieldsPermissions?.Phone1.access !== 2}
                    />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.Phone2.access !== 0 && (
                <Col span={8}>
                  <Form.Item
                    label="Telefone 2"
                    name="phone2"
                    rules={[
                      {
                        required: fieldsPermissions?.Phone2.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <Input
                      id="phone2"
                      placeholder="(00) 0000-0000"
                      disabled={fieldsPermissions?.Phone2.access !== 2}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Veículos e Motoristas" key="vehiclesAndDrivers" forceRender>
            <Row gutter={[24]}>
              {fieldsPermissions?.VehicleList.access !== 0 && (
                <Col span={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 12,
                    }}
                  >
                    <Form.Item
                      label="Veículos"
                      name="vehicleAdd"
                      rules={[
                        {
                          required: fieldsPermissions?.VehicleList.isRequired,
                          message: 'Campo obrigatório!',
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder="Selecione"
                        optionFilterProp="label"
                        showSearch
                        options={vehicleOptions.filter(({ id }) => !hiddenVehicles.includes(id))}
                        dropdownStyle={{ borderRadius: 16 }}
                        maxTagCount="responsive"
                        disabled={fieldsPermissions?.VehicleList.access !== 2}
                      />
                    </Form.Item>
                    <Button
                      block
                      type="primary"
                      style={{ width: '25%' }}
                      onClick={() => {
                        const selectedItemsIds = form.getFieldValue('vehicleAdd');

                        if (selectedItemsIds?.length > 0) {
                          const selectedItems = vehicleOptions?.filter(({ value }) =>
                            selectedItemsIds?.includes(value)
                          );

                          setHiddenVehicles([...hiddenVehicles, ...selectedItemsIds]);

                          const currentItems = form.getFieldValue('vehicleList') ?? [];
                          form.setFieldValue('vehicleList', [...currentItems, ...selectedItems]);
                          form.setFieldValue('vehicleAdd', []);
                          setTriggerRender(!triggerRender);
                          setScreenModified(true);
                        }
                      }}
                    >
                      Vincular
                    </Button>
                    <Button
                      block
                      type="primary"
                      style={{ width: '25%' }}
                      onClick={() => setIsVehicleDrawerOpen(true)}
                      icon={<PlusOutlined />}
                    >
                      Novo
                    </Button>
                  </div>

                  <Form.Item
                    name="vehicleList"
                    rules={[
                      {
                        required: fieldsPermissions?.VehicleList.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <List
                      bordered
                      rowKey="id"
                      style={{ borderRadius: 28 }}
                      dataSource={form.getFieldValue('vehicleList') ?? []}
                      renderItem={(item) => (
                        <List.Item key={item.id} style={{ padding: 12 }}>
                          <ListVehicleItemComponent
                            data={item}
                            deleteFunc={() => {
                              removeItemsFromList(
                                hiddenVehicles,
                                setHiddenVehicles,
                                item.id,
                                'vehicleList'
                              );
                            }}
                          />
                        </List.Item>
                      )}
                    />
                  </Form.Item>
                </Col>
              )}

              {fieldsPermissions?.DriverList.access !== 0 && (
                <Col span={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 12,
                    }}
                  >
                    <Form.Item
                      label="Motoristas"
                      name="driverAdd"
                      rules={[
                        {
                          required: fieldsPermissions?.DriverList.isRequired,
                          message: 'Campo obrigatório!',
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder="Selecione"
                        optionFilterProp="label"
                        showSearch
                        options={driverOptions.filter(({ id }) => !hiddenDrivers.includes(id))}
                        dropdownStyle={{ borderRadius: 16 }}
                        maxTagCount="responsive"
                        disabled={fieldsPermissions?.DriverList.access !== 2}
                      />
                    </Form.Item>
                    <Button
                      block
                      type="primary"
                      style={{ width: '25%' }}
                      onClick={() => {
                        const selectedItemsIds = form.getFieldValue('driverAdd');

                        if (selectedItemsIds?.length > 0) {
                          const selectedItems = driverOptions?.filter(({ value }) =>
                            selectedItemsIds?.includes(value)
                          );

                          setHiddenDrivers([...hiddenDrivers, ...selectedItemsIds]);

                          const currentItems = form.getFieldValue('driverList') ?? [];
                          form.setFieldValue('driverList', [...currentItems, ...selectedItems]);
                          form.setFieldValue('driverAdd', []);
                          setTriggerRender(!triggerRender);
                          setScreenModified(true);
                        }
                      }}
                    >
                      Vincular
                    </Button>
                    <Button
                      block
                      type="primary"
                      style={{ width: '25%' }}
                      onClick={() => setIsDriverDrawerOpen(true)}
                      icon={<PlusOutlined />}
                    >
                      Novo
                    </Button>
                  </div>

                  <Form.Item
                    name="driverList"
                    rules={[
                      {
                        required: fieldsPermissions?.DriverList.isRequired,
                        message: 'Campo obrigatório!',
                      },
                    ]}
                  >
                    <List
                      bordered
                      rowKey="id"
                      style={{ borderRadius: 28 }}
                      dataSource={form.getFieldValue('driverList') ?? []}
                      renderItem={(item) => (
                        <List.Item key={item.id} style={{ padding: 12 }}>
                          <ListDriverItemComponent
                            data={item}
                            deleteFunc={() => {
                              removeItemsFromList(
                                hiddenDrivers,
                                setHiddenDrivers,
                                item.id,
                                'driverList'
                              );
                            }}
                          />
                        </List.Item>
                      )}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Documentos" key="documentsData" forceRender>
            <Form.List name="documents">
              {(fields, { add, remove }) => (
                <>
                  {requiredDocuments?.length > 0 && (
                    <div>
                      <h3>Documentos Obrigatórios</h3>
                      <p style={{ margin: 0 }}>{requiredDocuments?.join(' / ')}</p>
                    </div>
                  )}

                  <Button
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                      width: 200,
                    }}
                    type="dashed"
                    onClick={() => {
                      add();
                      setScreenModified(true);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Documento
                  </Button>
                  <Row gutter={[24]}>
                    {fields.map(({ key, name, ...restField }) => (
                      <Col key={key} span={8}>
                        <Card
                          extra={
                            <Popconfirm
                              title="Deseja mesmo excluir?"
                              onConfirm={() => {
                                if (form.getFieldValue('documents')[name]?.downloadUrls) {
                                  CompanyFunctions.deleteDocumentFromStorage(
                                    form,
                                    'transport',
                                    name
                                  );
                                  remove(name);
                                  submit(form.getFieldsValue());
                                  setScreenModified(false);
                                } else {
                                  remove(name);
                                  setScreenModified(true);
                                }
                              }}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <CloseOutlined />
                            </Popconfirm>
                          }
                          style={{
                            backgroundColor: '#ffffff00',
                            borderColor: '#b7b7b7',
                            borderRadius: 16,
                            marginBottom: 10,
                          }}
                        >
                          {canHandleTransport && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'verified']}
                                valuePropName="checked"
                                initialValue={true}
                              >
                                <Checkbox>Verificado</Checkbox>
                              </Form.Item>
                            </Col>
                          )}
                          {fieldsPermissions?.DocumentType.access !== 0 && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'type']}
                                label="Tipo de documento"
                                rules={[
                                  {
                                    required: fieldsPermissions?.DocumentType.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Select
                                  allowClear
                                  placeholder="Selecione"
                                  optionFilterProp="label"
                                  showSearch
                                  options={documentTypeOptions}
                                  dropdownStyle={{ borderRadius: 16 }}
                                  disabled={fieldsPermissions?.DocumentType.access !== 2}
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {fieldsPermissions?.DocumentNumber.access !== 0 && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'number']}
                                label="Nº documento"
                                rules={[
                                  {
                                    required: fieldsPermissions?.DocumentNumber.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Input disabled={fieldsPermissions?.DocumentNumber.access !== 2} />
                              </Form.Item>
                            </Col>
                          )}
                          {fieldsPermissions?.DocumentValidity.access !== 0 && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'validity']}
                                label="Validade"
                                rules={[
                                  {
                                    required: fieldsPermissions?.DocumentValidity.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  style={{
                                    borderRadius: 16,
                                  }}
                                  disabled={fieldsPermissions?.DocumentValidity.access !== 2}
                                />
                              </Form.Item>
                            </Col>
                          )}

                          {fieldsPermissions?.UploadFiles.access !== 0 &&
                            form.getFieldValue('documents')[name]?.downloadUrls && (
                              <Col span={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'downloadUrls']}
                                  label
                                  style={{
                                    textAlign: 'center',
                                  }}
                                  rules={[
                                    {
                                      required: fieldsPermissions?.UploadFiles.isRequired,
                                      message: 'Campo obrigatório!',
                                    },
                                  ]}
                                >
                                  <List
                                    size="small"
                                    header={<div>Arquivos</div>}
                                    bordered
                                    dataSource={form.getFieldValue('documents')[name]?.downloadUrls}
                                    renderItem={({ label, url }) => (
                                      <List.Item>
                                        <a
                                          href={url}
                                          target="blank"
                                          style={{ wordBreak: 'break-word' }}
                                        >
                                          {label}
                                        </a>
                                      </List.Item>
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            )}

                          {fieldsPermissions?.UploadFiles.access !== 0 &&
                            !form.getFieldValue('documents')[name]?.downloadUrls && (
                              <Col span={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'uploadFiles']}
                                  label
                                  style={{
                                    textAlign: 'center',
                                  }}
                                  rules={[
                                    {
                                      required: fieldsPermissions?.UploadFiles.isRequired,
                                      message: 'Campo obrigatório!',
                                    },
                                  ]}
                                >
                                  <Upload
                                    multiple
                                    beforeUpload={() => false}
                                    disabled={fieldsPermissions?.UploadFiles.access !== 2}
                                  >
                                    <Button icon={<UploadOutlined />}>Upload de arquivos</Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            )}
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Form.List>
          </Tabs.TabPane>
        </Tabs>
      </Form>

      {docIdsVehicle && (
        <VehicleRegisterDrawerComponent
          isOpen={isVehicleDrawerOpen}
          setIsOpen={setIsVehicleDrawerOpen}
          vehicleOptions={vehicleOptions}
          setVehicleOptions={setVehicleOptions}
          docIds={docIdsVehicle}
        />
      )}

      {docIdsDriver && (
        <DriverRegisterDrawerComponent
          isOpen={isDriverDrawerOpen}
          setIsOpen={setIsDriverDrawerOpen}
          driverOptions={driverOptions}
          setDriverOptions={setDriverOptions}
          docIds={docIdsDriver}
        />
      )}
    </>
  );
}

const TransportRegister = memo(TransportRegisterPage);
export default TransportRegister;
