import { message } from 'antd';
import axios from 'axios';

import Utils from '../../../Assets/Scripts/Utils';

class TrainingCenterFunctions {
  static async fetchBitrixAddresses(pBitrixUrl, pSetBitrixAddresses) {
    try {
      const addressOptions = [];
      const response = await axios.get(`${pBitrixUrl}crm.deal.fields`);

      if (response && response?.data) {
        Object.values(response.data?.result?.UF_CRM_1637610715263.items).forEach((address) => {
          addressOptions.push({
            label: address.VALUE,
            value: address.ID,
          });
        });

        const concatOptions = addressOptions.flat();
        pSetBitrixAddresses(concatOptions);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao buscar os endereços do Bitrix!');
    }
  }
}

export default TrainingCenterFunctions;
