/* eslint-disable react/prop-types */
import React from 'react';

import './OverviewResourceCell.scss';

export default function OverviewResourceCellRender({
  itemData: {
    data: { text, ctTotalClasses, ctTotalStudents },
  },
}) {
  return (
    <div className="overview-resource-cell">
      <span className="resource-cell-ct-name">{text}</span>
      <div className="resource-cell-info">
        <span>T: {ctTotalClasses}</span>
        <span>A: {ctTotalStudents}</span>
      </div>
    </div>
  );
}
