/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from 'antd';
import { FaRegBuilding } from 'react-icons/fa';

import {
  CalendarOutlined,
  DeleteOutlined,
  InfoOutlined,
  ShareAltOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

export default function Appointment({
  itemData: {
    data: { appointmentData },
  },
  className,
  schedulerRef,
  screen,
  setTaskRescheduling,
  removeAppointmentByIndex,
}) {
  const isTooltip = screen === 'appointmentTooltip';

  if (appointmentData.allDay) {
    return (
      <div key="cell" className="booked-day-cell">
        Dia Reservado
      </div>
    );
  }

  const type = appointmentData.scheduleType;

  let stage = 999;
  const backgroundColor = type ? appointmentData[type].stage.color : appointmentData.stage.color;
  if (type) {
    stage = appointmentData[type].stage.id;
  }

  let textColor = '#121212';

  switch (type ? appointmentData[type].stage.id : appointmentData.stage.id) {
    case 12: // Reservado
      textColor = '#fff';
      break;
    case 14: // Aguardando Documentação
      textColor = '#fff';
      break;
    default:
      textColor = '#121212';
      break;
  }

  const mainInstructorStatusColor = () => {
    if (
      appointmentData?.mainInstructor?.stage?.id === 1 ||
      appointmentData?.mainInstructor?.stage?.id === 3
    ) {
      return appointmentData?.mainInstructor.stage.color;
    }
    return '#b1b1b1';
  };

  const instructorTagTitle = () => {
    switch (appointmentData?.mainInstructor?.stage?.id) {
      case 1:
        return `${appointmentData?.mainInstructor.userName}: Aguardando aceite`;
      case 3:
        return `${appointmentData?.mainInstructor.userName}: Aceito`;
      default:
        return 'Sem Instrutor vinculado';
    }
  };

  const handleDelete = () => {
    schedulerRef?.current?.instance?.deleteAppointment(appointmentData);
  };

  return (
    <div
      id="appointment-task"
      className={className ?? ''}
      style={{
        color: stage === 1 ? 'white' : '',
      }}
    >
      <div className="top-bar" />
      <h5 className="class-id" style={{ color: '#121212' }}>
        {appointmentData.cardTitle}
      </h5>
      <div className="body" style={{ color: '#121212' }}>
        <p className="card-description">{appointmentData.text}</p>

        {!type && appointmentData.quoteOrDealUpdated && (
          <Tooltip placement="top" title="Informações Atualizadas">
            <div className="updated-tag">
              <InfoOutlined />
            </div>
          </Tooltip>
        )}

        <div className="tags-1">
          <Tooltip placement="bottom" title="Alunos">
            <div className="students-tag">
              <TeamOutlined />
              <p className="card-text">{appointmentData.qtyStudentsPerClass}</p>
            </div>
          </Tooltip>

          {!type && (
            <Tooltip placement="bottom" title={instructorTagTitle}>
              <div className="instructor-tag">
                <UserOutlined style={{ color: mainInstructorStatusColor() }} />
              </div>
            </Tooltip>
          )}

          {!type && (
            <Tooltip placement="bottom" title={appointmentData.quoteName}>
              <div className="client-tag">
                <FaRegBuilding />
              </div>
            </Tooltip>
          )}
        </div>

        <div className="tags-2">
          <Tooltip placement="bottom" title="Estágio">
            <div className="tag stage-tag" style={{ backgroundColor, color: textColor }}>
              <p className="card-text">
                {type ? appointmentData[type].stage.name : appointmentData.stage.name}
              </p>
            </div>
          </Tooltip>

          {(appointmentData.sharedTraining || appointmentData.acceptSharedTraining) && (
            <Tooltip placement="bottom" title="Treinamento Compartilhado">
              <div className="tag shared-training-tag">
                <ShareAltOutlined />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={className ?? ''}>
          {isTooltip && (
            <div className="wrapper-action">
              <Tooltip title="Deletar" placement="right">
                <DeleteOutlined className="delete-icon" onClick={handleDelete} />
              </Tooltip>
              <Tooltip title="Reagendar" placement="right">
                <CalendarOutlined
                  className="reschedule-icon"
                  onClick={() => {
                    removeAppointmentByIndex(appointmentData.index);
                    setTaskRescheduling([
                      {
                        id: appointmentData.dealId,
                        name: appointmentData.quoteTitle,
                        trainingsIndicator: '',
                        tasks: [appointmentData],
                      },
                    ]);
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
