/* eslint-disable no-param-reassign */
import { message } from 'antd';
import axios from 'axios';

import { deleteFileDocuments, uploadFiles } from '../../Services/firebaseService';

import Utils from './Utils';

export default class CompanyFunctions {
  static async fetchCnpj(cnpj, form) {
    const isValid = Utils.validateCNPJ(cnpj);
    if (isValid) {
      const cleanCnpj = cnpj.replace(/[^\d]+/g, '');
      const response = await axios
        .get(`https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cleanCnpj}`)
        .then((res) => res.data)
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo ocorreu errado ao consultar o CNPJ!');
        });

      const data = {
        name: response['RAZAO SOCIAL'],
        commercialName: response['NOME FANTASIA'],
        street: response.LOGRADOURO,
        addressNumber: response.NUMERO,
        district: response.BAIRRO,
        cep: response.CEP,
        complement: response.COMPLEMENTO,
        city: response.MUNICIPIO,
        state: response.UF,
        email: response.EMAIL,
        phone1: response.DDD + response.TELEFONE,
      };

      form.setFieldsValue({ ...form.getFieldsValue(), ...data });
    }
  }

  static async deleteDocumentFromStorage(form, storageName, docIndex) {
    const companyId = form.getFieldValue('id');
    const document = form.getFieldValue('documents')[docIndex];
    const promises = [];

    document?.downloadUrls?.forEach((item) => {
      promises.push(deleteFileDocuments(item.name, storageName, companyId));
    });
    await Promise.all(promises);
  }

  static async saveDocuments(data, documentTypeOptions, storageName) {
    const { documents } = data;

    if (documents) {
      const [firstDoc] = documents;
      if (firstDoc?.type) {
        const docUploadPromises = [];

        for (let i = 0; i < documents.length; i += 1) {
          const urls = [];
          const fileList = documents[i]?.uploadFiles?.fileList;

          const [documentType] = documentTypeOptions.filter(
            (type) => type.value === data.documents[i].type
          );

          data.documents[i].type = {
            id: documentType?.value,
            name: documentType?.label,
          };

          if (fileList?.length > 0) {
            for (let j = 0; j < fileList.length; j += 1) {
              const docName = `${data.id}__${Utils.generateUUID()}`;
              const promise = uploadFiles(fileList[j].originFileObj, storageName, data.id, docName);
              promise.then((res) => {
                const docLabel = `${documents[i]?.type?.name}_${documents[i]?.number}_${j + 1}`;

                urls.push({
                  label: docLabel,
                  name: docName,
                  url: res,
                });

                if (urls.length === fileList.length) {
                  data.documents[i].downloadUrls = urls;
                }
              });
              docUploadPromises.push(promise);
            }
          }
        }

        await Promise.all(docUploadPromises);
      } else {
        data.documents = null;
      }
    }
  }

  static registersWithValidDocs(registerList, validDocsList) {
    return registerList?.filter(({ documents }) => {
      const docIds = documents?.map(({ type: { id } }) => id);
      return validDocsList?.every((id) => docIds?.includes(id));
    });
  }
}
