/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Tabs,
  Upload,
} from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import CompanyFunctions from '../../../Assets/Scripts/CompanyFunctions';
import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

let canHandleVehicle = false;

function VehicleRegisterPage() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [screenModified, setScreenModified] = useState(false);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [documentTypeOptions, setDocumentTypeOptions] = useState();
  const [requiredDocuments, setRequiredDocuments] = useState();

  const getVehicleById = async (editId) => {
    setLoading(true);

    const response = await api
      .get(`/Vehicle?id=${editId}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados do veículo!');
      });

    response.documents = response?.documents?.map(
      ({ type: { id: type }, number, validity, downloadUrls, verified }) => ({
        type,
        number,
        validity: moment(validity),
        downloadUrls,
        verified,
      })
    );

    form.setFieldsValue(response);
    form.setFieldValue('type', response.type.id);
    setLoading(false);
  };

  const submit = async (values) => {
    setLoading(true);
    const data = { ...values };

    const vehicleType = vehicleTypeOptions?.find(({ value }) => value === data.type);
    data.type = {
      id: vehicleType.value,
      name: vehicleType.label,
    };

    await CompanyFunctions.saveDocuments(data, documentTypeOptions, 'vehicle');

    const httpMethod = editId ? 'put' : 'post';
    await api[httpMethod]('/Vehicle', data)
      .then(() => {
        message.success(`Dados ${editId ? 'atualizados' : 'salvos'}!`);
        form.resetFields();

        if (editId) {
          getVehicleById(editId);
        }

        setLoading(false);
        setScreenModified(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Erro ao enviar os dados do veículo!');
        setLoading(false);
      });
  };

  const getVehicleTypes = async () => {
    const vehicleTypes = await api
      .get('/VehicleType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os tipos de veículo!');
      });

    const mappedVehicleTypes = vehicleTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setVehicleTypeOptions(mappedVehicleTypes);
  };

  const getDocumentTypes = async () => {
    const documentTypes = await api
      .get('/DocumentType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os tipos de documento!');
      });

    const mappedDocumentTypes = documentTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setDocumentTypeOptions(mappedDocumentTypes);
    return mappedDocumentTypes;
  };

  const fetchSettings = async () => {
    const docTypes = await getDocumentTypes();

    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => Utils.decryptSettings(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });

    const reqDocsIds =
      res.requiredDocumentsVehicle?.split(',')?.map((item) => parseInt(item, 10)) ?? [];

    const reqDocs = docTypes
      ?.filter(({ value }) => reqDocsIds.includes(value))
      ?.map(({ label }) => label);

    setRequiredDocuments(reqDocs);
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields, canHandle }) => {
        if (name === 'Vehicle') {
          canHandleVehicle = canHandle;
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  useEffect(() => {
    fetchSettings();
    getVehicleTypes();

    if (editId) {
      getVehicleById(editId);
    }
  }, []);

  return (
    <Form
      form={form}
      disabled={loading}
      name="vehicleForm"
      layout="vertical"
      onFinish={submit}
      autoComplete="off"
      onChange={() => setScreenModified(true)}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}

        <Button loading={loading} block type="primary" htmlType="submit ">
          Salvar
        </Button>
      </div>

      <Tabs>
        <Tabs.TabPane tab="Dados Gerais" key="generalData">
          <Row gutter={[24]}>
            {/* Campos invisiveis */}
            <Form.Item className="input-hidden" label="Id" name="id">
              <Input />
            </Form.Item>

            {fieldsPermissions?.Type.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Tipo"
                  name="type"
                  rules={[
                    {
                      required: fieldsPermissions?.Type.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    options={vehicleTypeOptions}
                    dropdownStyle={{ borderRadius: 16 }}
                    disabled={fieldsPermissions?.Type.access !== 2}
                  />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Model.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Modelo"
                  name="model"
                  rules={[
                    {
                      required: fieldsPermissions?.Model.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input disabled={fieldsPermissions?.Model.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Year.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Ano"
                  name="year"
                  rules={[
                    {
                      required: fieldsPermissions?.Year.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <InputNumber disabled={fieldsPermissions?.Year.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.LicensePlate.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Placa"
                  name="licensePlate"
                  rules={[
                    {
                      required: fieldsPermissions?.LicensePlate.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input disabled={fieldsPermissions?.LicensePlate.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Color.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Cor"
                  name="color"
                  rules={[
                    {
                      required: fieldsPermissions?.Color.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input disabled={fieldsPermissions?.Color.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Capacity.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Capacidade"
                  name="capacity"
                  rules={[
                    {
                      required: fieldsPermissions?.Capacity.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <InputNumber disabled={fieldsPermissions?.Capacity.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Chassis.access !== 0 && (
              <Col span={6}>
                <Form.Item
                  label="Chassi"
                  name="chassis"
                  rules={[
                    {
                      required: fieldsPermissions?.Chassis.isRequired,
                      message: 'Campo obrigatório!',
                    },
                    { min: 17, max: 17, message: 'O Número do Chassi deve ter 17 caracteres!' },
                  ]}
                >
                  <Input maxLength={17} disabled={fieldsPermissions?.Chassis.access !== 2} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Documentos" key="documentsData" forceRender>
          <Form.List name="documents">
            {(fields, { add, remove }) => (
              <>
                {requiredDocuments?.length > 0 && (
                  <div>
                    <h3>Documentos Obrigatórios</h3>
                    <p style={{ margin: 0 }}>{requiredDocuments?.join(' / ')}</p>
                  </div>
                )}

                <Button
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                    width: 200,
                  }}
                  type="dashed"
                  onClick={() => {
                    add();
                    setScreenModified(true);
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Adicionar Documento
                </Button>
                <Row gutter={[24]}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Col key={key} span={8}>
                      <Card
                        extra={
                          <Popconfirm
                            title="Deseja mesmo excluir?"
                            onConfirm={() => {
                              if (form.getFieldValue('documents')[name]?.downloadUrls) {
                                CompanyFunctions.deleteDocumentFromStorage(form, 'driver', name);
                                remove(name);
                                submit(form.getFieldsValue());
                                setScreenModified(false);
                              } else {
                                remove(name);
                                setScreenModified(true);
                              }
                            }}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <CloseOutlined />
                          </Popconfirm>
                        }
                        style={{
                          backgroundColor: '#ffffff00',
                          borderColor: '#b7b7b7',
                          borderRadius: 16,
                          marginBottom: 10,
                        }}
                      >
                        {canHandleVehicle && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'verified']}
                              valuePropName="checked"
                              initialValue={true}
                            >
                              <Checkbox>Verificado</Checkbox>
                            </Form.Item>
                          </Col>
                        )}
                        {fieldsPermissions?.DocumentType.access !== 0 && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              label="Tipo de documento"
                              rules={[
                                {
                                  required: fieldsPermissions?.DocumentType.isRequired,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Selecione"
                                optionFilterProp="label"
                                showSearch
                                options={documentTypeOptions}
                                dropdownStyle={{ borderRadius: 16 }}
                                disabled={fieldsPermissions?.DocumentType.access !== 2}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {fieldsPermissions?.DocumentNumber.access !== 0 && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'number']}
                              label="Nº documento"
                              rules={[
                                {
                                  required: fieldsPermissions?.DocumentNumber.isRequired,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Input disabled={fieldsPermissions?.DocumentNumber.access !== 2} />
                            </Form.Item>
                          </Col>
                        )}
                        {fieldsPermissions?.DocumentValidity.access !== 0 && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'validity']}
                              label="Validade"
                              rules={[
                                {
                                  required: fieldsPermissions?.DocumentValidity.isRequired,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <DatePicker
                                format="DD-MM-YYYY"
                                style={{
                                  borderRadius: 16,
                                }}
                                disabled={fieldsPermissions?.DocumentValidity.access !== 2}
                              />
                            </Form.Item>
                          </Col>
                        )}

                        {fieldsPermissions?.UploadFiles.access !== 0 &&
                          form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'downloadUrls']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                                rules={[
                                  {
                                    required: fieldsPermissions?.UploadFiles.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <List
                                  size="small"
                                  header={<div>Arquivos</div>}
                                  bordered
                                  dataSource={form.getFieldValue('documents')[name]?.downloadUrls}
                                  renderItem={({ label, url }) => (
                                    <List.Item>
                                      <a
                                        href={url}
                                        target="blank"
                                        style={{ wordBreak: 'break-word' }}
                                      >
                                        {label}
                                      </a>
                                    </List.Item>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          )}

                        {fieldsPermissions?.UploadFiles.access !== 0 &&
                          !form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'uploadFiles']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                                rules={[
                                  {
                                    required: fieldsPermissions?.UploadFiles.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Upload
                                  multiple
                                  beforeUpload={() => false}
                                  disabled={fieldsPermissions?.UploadFiles.access !== 2}
                                >
                                  <Button icon={<UploadOutlined />}>Upload de arquivos</Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          )}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </Form.List>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
}

const VehicleRegister = memo(VehicleRegisterPage);
export default VehicleRegister;
