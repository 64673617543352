/* eslint-disable react/prop-types */
import React from 'react';

import DataCell from './DataCell';

export default function DataCellMonth({ itemData, workDays, blockedDates, appointments }) {
  const day = itemData.startDate.getDate();

  return (
    <DataCell
      itemData={itemData}
      workDays={workDays}
      blockedDates={blockedDates}
      appointments={appointments}
    >
      {day}
    </DataCell>
  );
}
