import React, { useEffect, useState } from 'react';
import { Button, message, Popconfirm, Tooltip } from 'antd';

import { CheckCircleOutlined, CloseCircleOutlined, MailOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import TableAntd from '../../../Components/TableAntd/TableAntd';
import { api } from '../../../Services/axiosService';

export default function UserList() {
  const [optionsRoles, setOptionsRoles] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [clearSelection, setClearSelection] = useState([]);

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'login',
        title: 'Login',
        width: '15%',
        ...getColumnSearchProps('login', 'Login'),
        sorter: (a, b) => a.login.localeCompare(b.login),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'email',
        title: 'E-mail',
        width: '25%',
        ...getColumnSearchProps('email', 'E-mail'),
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Cargo',
        dataIndex: ['role', 'name'],
        key: 'role',
        filters: optionsRoles,
        filterSearch: true,
        onFilter: (value, record) => record.role.id === value,
        sorter: (a, b) => a.role.name.localeCompare(b.role.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'cpf',
        title: 'CPF',
        ...getColumnSearchProps('cpf', 'cpf'),
      },
      {
        title: 'Primeiro Acesso',
        width: '100px',
        dataIndex: '',
        align: 'center',
        fixed: 'right',
        filters: [
          { text: 'Realizado', value: false },
          { text: 'Pendente', value: true },
        ],
        onFilter: (value, record) => record.firstAccess === value,
        render: (_, record) => {
          if (record.firstAccess) {
            return (
              <Tooltip title="Pendente" placement="left">
                <CloseCircleOutlined style={{ color: '#fc5d20', fontSize: 20 }} />
              </Tooltip>
            );
          }
          return (
            <Tooltip title="Realizado" placement="left">
              <CheckCircleOutlined style={{ color: '#3bb856', fontSize: 20 }} />
            </Tooltip>
          );
        },
      },
    ];
    return columns;
  };

  const fetchRoles = async () => {
    await api
      .get('/Role')
      .then((res) => {
        const mappedRoles = res.data.map((item) => ({
          ...item,
          text: item.name,
          value: item.id,
        }));

        setOptionsRoles(mappedRoles);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar buscar os Cargos!');
      });
  };

  const resendNewAccessEmail = async () => {
    await api
      .post(`/User/ResendNewAccessEmail?userIdList=${selectedRowKeys.toString()}`)
      .then(() => message.success('E-mails de acesso enviados!'))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar reenviar os e-mails de acesso!');
      });
    setClearSelection([]);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <TableAntd
      selection={setSelectedRowKeys}
      clearSelection={clearSelection}
      selectionType="checkbox"
      columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
      routePermissions="User"
      requestName="User"
      route="/Usuario"
      cdPage="id"
      btnNew
      extraToolbar={
        <Popconfirm
          title='Deseja realmente "Reenviar os e-mails de acesso" para os usuários selecionados?'
          onConfirm={resendNewAccessEmail}
          okText="Sim"
          cancelText="Não"
          disabled={selectedRowKeys.length === 0}
        >
          <Tooltip title="Reenviar os e-mails de acesso">
            <Button
              style={{ borderRadius: '16px', alignItems: 'center', marginLeft: 8 }}
              icon={<MailOutlined />}
              disabled={selectedRowKeys.length === 0}
            />
          </Tooltip>
        </Popconfirm>
      }
    />
  );
}
