/* eslint-disable react/prop-types */
import React from 'react';
import { Tag } from 'antd';

export default function TagComponent({ type, text, link }) {
  if (type === 'text') {
    return <Tag color="default">{text}</Tag>;
  }

  if (type === 'link') {
    return (
      <Tag color="default">
        {' '}
        <a href={link} target="_blank" rel="noreferrer">
          {text}
        </a>
      </Tag>
    );
  }

  return <Tag color="default">TAG NÃO CONFIGURADA CORRETAMENTE</Tag>;
}
