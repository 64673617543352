/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from 'antd';

import { DeleteOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import './ListItem.scss';

export default function ListDriverItemComponent({ data, deleteFunc }) {
  return (
    <div className="transport-list-item-wrapper">
      <Tooltip title="Nome" placement="right">
        <h3>{data.name}</h3>
      </Tooltip>

      <Tooltip title="E-mail" placement="right">
        <p>
          <MailOutlined style={{ marginRight: 5 }} /> {data.email}
        </p>
      </Tooltip>

      <Tooltip title="Telefone" placement="right">
        <p>
          <PhoneOutlined style={{ marginRight: 5 }} />
          {data.phone1}
        </p>
      </Tooltip>

      <Tooltip title="Remover" placement="left" className="transport-list-item-delete-btn">
        <DeleteOutlined onClick={deleteFunc} />
      </Tooltip>
    </div>
  );
}
