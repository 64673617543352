/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, List, message, Row, Select } from 'antd';
import { Popup, Scheduler } from 'devextreme-react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SearchOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

import DataCellMonth from './DataCellMonth';

import '../../BlockedDaysModal/ScheduleModal.scss';

let scheduleDate = null;

const notifyTraining = () => {
  message.warning('Este dia possui Treinamento!');
};

const notifyBlockDay = () => {
  message.warning('Este dia está Bloqueado!');
};

const notifyShouldSearch = () => {
  message.warning('Realize a Pesquisa!');
};
export default function InstructorBookingPopup({
  instructorBookingPopupRef,
  getCurrentDate,
  instructors,
  open,
}) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [startViewDate, setStartViewDate] = useState();
  const [endViewDate, setEndViewDate] = useState();
  const [data, setData] = useState({ instructorBookingList: [] });
  const [shouldSearch, setShouldSearch] = useState(true);

  const fetchInstructorsBooked = async () => {
    try {
      setIsLoading(true);
      const { instructorUserId } = form.getFieldsValue();
      if (!instructorUserId) {
        return;
      }

      const startDateFilter = moment(new Date(moment(startViewDate)).setHours(0, 0, 0, 1)).format(
        'YYYY-MM-DDTHH:mm:ssZ'
      );
      const endDateFilter = moment(new Date(moment(endViewDate)).setHours(23, 59, 59, 999)).format(
        'YYYY-MM-DDTHH:mm:ssZ'
      );
      const instructorBookings = await api
        .get(
          `/InstructorBooking?filters[0].Field=Date&filters[0].Condition=DATE.>=&filters[0].Value=${startDateFilter}&filters[1].Field=Date&filters[1].Condition=DATE.<=&filters[1].Value=${endDateFilter}&filters[2].Field=InstructorUserId&filters[2].Condition=NUMBER.EQUAL&filters[2].Value=${instructorUserId}`
        )
        .then((res) => res?.data);

      const instructorFilter = instructors?.find(
        (instructor) => instructor.id === instructorUserId
      );

      const availabilityModel = {
        Start: startDateFilter,
        Finish: endDateFilter,
        OperatorIdList: [
          {
            UserId: instructorFilter.id,
            CompanyId: instructorFilter.company.id,
          },
        ],
      };

      const instructorAvailability = await api
        .post('/Task/InstructorsAvailabilityWithinDateRange', availabilityModel)
        .then((res) => res?.data);
      // Montar lista combinada
      const combinedList = [];

      // Iterar sobre a lista de instructorAvailability, se existir
      if (instructorAvailability) {
        instructorAvailability.forEach(({ hasTrainings, isBlocked, date }) => {
          let text = '';
          if (hasTrainings) {
            text = 'Treinamento';
            combinedList.push({
              text,
              startDate: moment(date).startOf('day').add(12, 'hours').toISOString(),
              endDate: moment(date).startOf('day').add(12, 'hours').toISOString(),
            });
          } else if (isBlocked) {
            text = 'Dia Bloqueado';
            combinedList.push({
              text,
              startDate: moment(date).startOf('day').add(12, 'hours').toISOString(),
              endDate: moment(date).startOf('day').add(12, 'hours').toISOString(),
            });
          }
        });
      }
      // Iterar sobre a lista de instructorBookings, se existir
      if (instructorBookings) {
        const instructorBookingMap = instructorBookings.map((booking) => ({
          id: booking.id,
          text: 'Reserva',
          startDate: moment(booking.date).startOf('day').add(12, 'hours').toISOString(),
          endDate: moment(booking.date).startOf('day').add(12, 'hours').toISOString(),
        }));

        combinedList.push(...instructorBookingMap);
      }

      // Definir a lista combinada
      setDataSource(combinedList);
      setShouldSearch(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Instrutores Reservados!');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const submitInstructorBooking = async (type) => {
    data.instructorBookingList = data.instructorBookingList.map((item) => ({
      ...item,
      instructorUserId: form.getFieldsValue().instructorUserId,
      date: moment(item.date).startOf('day').add(12, 'hours').toISOString(),
    }));
    data.actionType = type;
    setData({ ...data });

    setIsLoading(true);
    await api
      .post('/InstructorBooking/AddRemoveDays', data)
      .then(() => {
        setData({ instructorBookingList: [] });
        fetchInstructorsBooked();
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao bloquear os dias!');
        setIsLoading(false);
      });
  };

  const onContentReady = async (e) => {
    const { component } = e;
    const date = moment(component.option('currentDate')).format('YYYY-MM-DD');

    const currentDateChanged = date !== scheduleDate;
    scheduleDate = date;

    if (currentDateChanged) {
      setStartViewDate(component.getStartViewDate());
      setEndViewDate(component.getEndViewDate());
      setShouldSearch(true);

      component.option('onCellClick', null);
    }
  };

  const onCellClick = ({ cellElement, cellData: { startDate, endDate } }) => {
    if (isLoading) {
      return;
    }
    if (shouldSearch === true) {
      notifyShouldSearch();
      return;
    }
    const foundAppointments = dataSource.filter(
      ({ startDate: sourceStart, endDate: sourceEnd }) =>
        (new Date(sourceStart) >= new Date(startDate) &&
          new Date(sourceStart) <= new Date(endDate)) ||
        (new Date(sourceEnd) >= new Date(startDate) && new Date(sourceEnd) <= new Date(endDate))
    );

    if (foundAppointments?.length > 0) {
      const [{ text }] = foundAppointments;
      switch (text) {
        case 'Treinamento':
          notifyTraining();
          return;
        case 'Dia Bloqueado':
          notifyBlockDay();
          return;
        default:
          break;
      }
    }

    const date = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');

    const existsIndex = data.instructorBookingList.findIndex((booking) => booking.date === date);

    if (existsIndex !== -1) {
      cellElement.style.background = '#fff';
      cellElement.style.boxShadow = 'none';
      const newData = [...data.instructorBookingList];
      newData.splice(existsIndex, 1);
      setData({ ...data, instructorBookingList: newData });
    } else {
      cellElement.style.background = '#05005B26';
      cellElement.style.boxShadow = 'inset 0 0 0 1px #05005B';

      const instructorBooking = {
        date,
      };
      const instructorBookingId = cellElement.firstChild?.dataset.id;
      if (instructorBookingId) {
        instructorBooking.id = instructorBookingId;
      }

      const newData = [...data.instructorBookingList, instructorBooking];
      setData({ ...data, instructorBookingList: newData });
    }
  };

  const renderDataCell = (itemData) => (
    <DataCellMonth
      itemData={itemData}
      instructorBookingList={data.instructorBookingList}
      appointmentList={dataSource}
    />
  );

  const appointmentRender = ({
    data: {
      appointmentData: { text },
    },
  }) => {
    switch (text) {
      case 'Reserva':
        return <div className="appointment-booking booking" />;
      case 'Treinamento':
        return <div className="appointment-booking training" />;
      case 'Dia Bloqueado':
        return <div className="appointment-booking blocked" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    form.setFieldValue('date', moment(getCurrentDate()));
  }, [getCurrentDate]);

  return (
    <Popup
      ref={instructorBookingPopupRef}
      className="schedule-disable-dates"
      title="Reserva de Instrutores"
      hideOnOutsideClick={false}
      onHidden={() => {
        setShouldSearch(true);
        setDataSource([]);
        setData({ instructorBookingList: [] });
        form.resetFields(['instructorUserId']);
      }}
      width="80vw"
      copyRootClassesToWrapper
    >
      <Form
        form={form}
        disabled={isLoading}
        name="instructorBookingPopup"
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={[24]}>
          <Col span={16}>
            <Col span={24}>
              <Form.Item
                className="schedule-modal"
                open={open}
                width="70%"
                style={{ top: 10, padding: 0 }}
                confirmLoading={isLoading}
                closable={false}
                maskClosable={false}
              >
                <Scheduler
                  disabled={isLoading}
                  id="schedule-disable-dates"
                  views={[]}
                  dataSource={dataSource}
                  defaultCurrentView="month"
                  defaultCurrentDate={new Date()}
                  showAllDayPanel={false}
                  firstDayOfWeek={1}
                  width="100%"
                  height="33vw"
                  crossScrollingEnabled
                  editing={false}
                  dataCellRender={renderDataCell}
                  onCellClick={onCellClick}
                  appointmentComponent={appointmentRender}
                  onContentReady={onContentReady}
                />
              </Form.Item>
            </Col>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={22}>
                <Form.Item
                  label="Instrutor"
                  name="instructorUserId"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                  style={{ width: '99%', marginRight: '1%' }}
                >
                  <Select
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
                    options={(instructors || [])
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((instructor) => ({
                        label: instructor.name,
                        value: instructor.id,
                      }))}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label=" ">
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={fetchInstructorsBooked}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  block
                  loading={isLoading}
                  type="primary"
                  style={{ marginRight: '8px', background: '#5cb85c', borderColor: '#5cb85c' }}
                  onClick={() => submitInstructorBooking(0)}
                  disabled={data.instructorBookingList.length === 0}
                >
                  Reservar Datas
                </Button>
                <Button
                  block
                  loading={isLoading}
                  type="primary"
                  style={{ background: '#d9534f', borderColor: '#d9534f' }}
                  onClick={() => submitInstructorBooking(1)}
                  disabled={data.instructorBookingList.length === 0}
                >
                  Remover Reservas
                </Button>
              </Col>
            </Row>
            <Divider orientation="left" />

            <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
                borderRadius: 16,
              }}
            >
              <InfiniteScroll
                dataLength={data.instructorBookingList.length}
                scrollableTarget="scrollableDiv"
              >
                <List
                  size="small"
                  header={<div>Datas Selecionadas</div>}
                  className="instructor-booking-selected-date-list"
                  bordered
                  dataSource={data.instructorBookingList}
                  renderItem={({ date }) => (
                    <>
                      <List.Item>{moment(date).format('DD/MM/YYYY')}</List.Item>
                      <Divider orientation="left" />
                    </>
                  )}
                />
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
}
