/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { message } from 'antd';
import moment from 'moment';

import Utils from '../../../Assets/Scripts/Utils';
import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../../Services/axiosService';
import { deleteFileDocuments, uploadFiles } from '../../../Services/firebaseService';
import SchedulingFunctions from '../../ScheduleConecta/API/SchedulingFunctions';

const mountProductName = (product) => {
  const customProductList = ScheduleUtils.getCustomProductList();
  const isCustomProduct = customProductList.includes(product?.id);

  if (isCustomProduct) {
    return product.complement ? product.complement : product.name;
  }

  return product.name;
};

export const fetchInstructors = async () => {
  try {
    const filter = `/Instructor?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Instrutor`;
    const companyInstructors = await api.get(filter).then((res) => res.data);

    const chunkSize = 30;
    let instructors = [];

    for (let i = 0; i < companyInstructors?.length; i += chunkSize) {
      const chunkInstructors = companyInstructors?.slice(i, i + chunkSize);
      const instructorIds = chunkInstructors.map(({ id }) => id).toString();
      const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${instructorIds}`;

      const chunkInstructorOptions = await api.get(userFilter).then((res) => res.data);
      const mappedInstructors = chunkInstructorOptions.map((item) => {
        const instructorData = companyInstructors.find(({ id }) => id === item.company.id);

        return { ...instructorData, ...item };
      });
      instructors = [...instructors, ...mappedInstructors];
    }

    return instructors.map((instructor) => {
      const trainingGroupsList = instructor?.trainingKind?.map(({ id }) => id);

      return {
        ...instructor,
        label: instructor.name,
        value: instructor.id,
        trainingGroupsList,
      };
    });
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Instrutores!');
    return [];
  }
};

export const fetchDrivers = async () => {
  try {
    const filter = `/Driver?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Motorista`;
    const companyDrivers = await api.get(filter);
    const drivers = companyDrivers.data;

    const chunkSize = 30;
    let allDrivers = [];

    for (let i = 0; i < drivers.length; i += chunkSize) {
      const chunkDrivers = drivers.slice(i, i + chunkSize);
      const driverIds = chunkDrivers.map(({ id }) => id).toString();
      const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${driverIds}`;

      const chunkDriverOptions = await api.get(userFilter);
      allDrivers = [...allDrivers, ...chunkDriverOptions.data];
    }

    return allDrivers.map((driver) => ({
      ...driver,
      label: driver.name,
      value: driver.id,
    }));
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Motoristas!');
    return [];
  }
};

export const fetchTransportCompanies = async () => {
  try {
    const response = await api.get(
      '/Transport?filters[0].Field=CompanyType&filters[0].Condition=IN&filters[0].Value=Transporte'
    );
    return response.data.map((tc) => ({ ...tc, value: tc.id, label: tc.commercialName }));
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar as Empresas de Transporte!');
    return [];
  }
};

export const fetchVehicles = async () => {
  try {
    const response = await api.get('/Vehicle');
    const vehicles = response.data.map((vehicle) => ({
      ...vehicle,
      value: vehicle.id,
      label: `${vehicle.licensePlate} - ${vehicle.type.name} (${vehicle.capacity} pessoas)`,
    }));
    return vehicles;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Veículos!');
    return [];
  }
};

export const fetchStages = async () => {
  try {
    const response = await api.get(`/Stage`);
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data.map((stage) => ({
        ...stage,
        label: stage.name,
        value: stage.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Estágios!');
    return [];
  }
};

export const fetchSettings = async () => {
  try {
    const response = await api.get(`/Settings?id=1`);
    return Utils.decryptSettings(response.data);
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar as Configurações!');
    return [];
  }
};

export const fetchTrainingCenters = async () => {
  try {
    const response = await api.get(
      `/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento`
    );
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data
        .filter(
          (tc) =>
            tc.ctQtySlots > 0 ||
            tc.inCompanyQtySlots > 0 ||
            tc.eadQtySlots > 0 ||
            tc.serviceQtySlots > 0
        )
        .sort((a, b) => b.ctQtyslots - a.ctQtyslots)
        .map((tc) => ({ ...tc, value: tc.id, label: tc.commercialName })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Centros de Treinamento!');
    return [];
  }
};

export const fetchReasons = async () => {
  try {
    const response = await api.get(
      `/DenyTaskReason?filters[0].Field=Type&filters[0].Condition=IN&filters[0].Value=Scheduling,Logistics,NoBilling`
    );
    return response.data.map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
      type: item.type,
    }));
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Motivos!');
    return [];
  }
};

export const fetchTaskColumnsSettings = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;
    const response = await api.get(
      `/TaskColumnsSettings?filters[0].Field=UserId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${userId}`
    );
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar as configurações das colunas!');
    return [];
  }
};

export const addTaskColumnsSettings = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    const response = await api.post('/TaskColumnsSettings', submitData);
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar salvar as configurações das colunas!');
    return [];
  }
};

export const updateTaskColumnsSettings = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;

    const response = await api.put('/TaskColumnsSettings', submitData);
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar as configurações das colunas!');
    return [];
  }
};

export const fetchTrainings = async () => {
  try {
    const response = await api.get('/Trainings');
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data.map((trainings) => ({ label: trainings.name, value: trainings.id })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao buscar os Grupos de Treinamento!');
    return [];
  }
};

export const fetchTasks = async (filters = null) => {
  try {
    const url = '/Task/TaskList';
    const response = await api.post(url, filters);
    const tasks = response.data;

    const notSelectableStages = [
      1, // 1 = Aguardando
      5, // 5 = Concluido
      6, // 6 = Reagendar
      11, // 11 = Distrato
    ];

    const mappedTasks = tasks
      .map((task, index) => ({
        ...task,
        tablePageIndex: index,
        key: task.id,
        name: mountProductName(task.product),
        selectable: !notSelectableStages.includes(task.stage?.id),
        hideCheckbox: false,
      }))
      .sort((a, b) => a.key - b.key);

    return mappedTasks;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Treinamentos!');
    return [];
  }
};

export const deleteFileFirebase = async (uploadDeleteEvent, fileName, task) => {
  await deleteFileDocuments(fileName, 'trainings', task.id);

  await TaskCommentFunctions.addTaskCommentUploadFile(task, uploadDeleteEvent, 'SchedulingList');
};

export const updateTask = async (newTaskData, oldTaskData, driverOptions, vehicleOptions) => {
  try {
    // Verifica Remoção de Lista de Presença Inicial
    if (newTaskData.initialAttendanceDocList?.length > 0) {
      for (let index = 0; index < newTaskData.initialAttendanceDocList.length; index += 1) {
        const file = newTaskData.initialAttendanceDocList[index];

        if (file.shouldDelete) {
          await deleteFileFirebase(file.deleteEvent, file.name, newTaskData);
        }
      }

      newTaskData.initialAttendanceDocList = newTaskData.initialAttendanceDocList.filter(
        ({ shouldDelete }) => !shouldDelete
      );
    }

    // Verifica Remoção de Lista de Presença Final
    if (newTaskData.finalAttendanceDocList?.length > 0) {
      for (let index = 0; index < newTaskData.finalAttendanceDocList.length; index += 1) {
        const file = newTaskData.finalAttendanceDocList[index];

        if (file.shouldDelete) {
          await deleteFileFirebase(file.deleteEvent, file.name, newTaskData);
        }
      }

      newTaskData.finalAttendanceDocList = newTaskData.finalAttendanceDocList.filter(
        ({ shouldDelete }) => !shouldDelete
      );
    }

    // Verifica Remoção de Formulário Instrutor
    if (newTaskData.instructorForm?.length > 0) {
      for (let index = 0; index < newTaskData.instructorForm.length; index += 1) {
        const file = newTaskData.instructorForm[index];

        if (file.shouldDelete) {
          await deleteFileFirebase(file.deleteEvent, file.name, newTaskData);
        }
      }

      newTaskData.instructorForm = newTaskData.instructorForm.filter(
        ({ shouldDelete }) => !shouldDelete
      );
    }

    // Verifica Remoção de NF Transporte
    if (newTaskData.nfTransportation?.length > 0) {
      for (let index = 0; index < newTaskData.nfTransportation.length; index += 1) {
        const file = newTaskData.nfTransportation[index];

        if (file.shouldDelete) {
          await deleteFileFirebase(file.deleteEvent, file.name, newTaskData);
        }
      }

      newTaskData.nfTransportation = newTaskData.nfTransportation.filter(
        ({ shouldDelete }) => !shouldDelete
      );
    }

    // Verifica Remoção de NF Treinamento
    if (newTaskData.nfTraining?.length > 0) {
      for (let index = 0; index < newTaskData.nfTraining.length; index += 1) {
        const file = newTaskData.nfTraining[index];

        if (file.shouldDelete) {
          await deleteFileFirebase(file.deleteEvent, file.name, newTaskData);
        }
      }

      newTaskData.nfTraining = newTaskData.nfTraining.filter(({ shouldDelete }) => !shouldDelete);
    }

    await api.put('/Task/UpdateTaskScheduling', newTaskData);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000); // 3 Segundos
    });

    // Gera Atividade conforme mudança de valores dos campos
    await TaskCommentFunctions.addTaskCommentActivities(newTaskData, oldTaskData, 'SchedulingList');
    await TaskCommentFunctions.addTaskCommentHistory(newTaskData, oldTaskData, 'SchedulingList');
    await TaskCommentFunctions.addTaskCommentDriver(newTaskData, oldTaskData, driverOptions);
    await TaskCommentFunctions.addTaskCommentVehicle(newTaskData, oldTaskData, vehicleOptions);

    // Enviar Notificação para os Operadores (Instrutor, Instrutor Auxiliar, Motorista)
    await SchedulingFunctions.sendNotificationToOperators(newTaskData, oldTaskData);
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao atualizar os Treinamentos!');
  }
};

export const uploadFilesToFirebase = async (fileList, fieldName, taskId) => {
  try {
    const uploadedFiles = [];
    for (const file of fileList) {
      const fileExtension = file.name.split('.').pop();
      const fileName = `${fieldName}_${Utils.generateUUID()}.${fileExtension}`;
      const res = await uploadFiles(file.originFileObj, 'trainings', taskId, fileName);

      uploadedFiles.push({
        label: file.name,
        name: fileName,
        url: res,
      });
    }

    return uploadedFiles;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar enviar os arquivos para o Firebase!');
    return [];
  }
};

export const downloadTaskListReport = async (data) => {
  try {
    const fileName = `Relatorio_Treinamentos_${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;

    await fetch(`${process.env.REACT_APP_HOST}/ExportData/ExportTaskList`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
        'Content-Type': 'application/json; charset=UTF-8',
        'Cache-Control': 'max-age=31536000',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      body: JSON.stringify(data),
      responseType: 'blob',
      withCredentials: true,
    })
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw Error('Oops. Algo deu errado ao tentar baixar o relatório!');
        }

        return res.blob();
      })
      .then((res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        link.click();
        message.success('Download do relatório realizado com sucesso!');
      });
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar baixar o relatório!');
  }
};

export const fetchTaskListTableFilter = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;
    const filterType = 'taskListTable';

    const response = await api.get(
      `/ScreenFilter?filters[0].Field=UserId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${userId}&filters[1].Field=Type&filters[1].Condition=EQUAL&filters[1].Value=${filterType}`
    );

    if (response.data?.length > 0) {
      const [filter] = response.data;
      filter.filters = JSON.parse(filter.filters);

      if (filter.filters.period) {
        const periodStart = filter.filters?.period[0];
        const periodEnd = filter.filters?.period[1];
        filter.filters.period = [moment(periodStart), moment(periodEnd)];
      }

      return filter;
    }

    return null;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os filtros!');
    return [];
  }
};

export const addTaskListTableFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'taskListTable';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.post('/ScreenFilter', submitData);
    message.success('Filtros salvos com sucesso!');

    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar salvar os filtros!');
    return [];
  }
};

export const updateTaskListTableFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'taskListTable';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.put('/ScreenFilter', submitData);
    message.success('Filtros salvos com sucesso!');

    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar os filtros!');
    return [];
  }
};
