/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';

import { ScheduleOutlined, StopOutlined, UserOutlined } from '@ant-design/icons';

// Custom Select Component
function CustomInstructorSelect({
  taskData,
  options,
  fieldsPermissions,
  value,
  onChange,
  onClear,
  type,
  isTaskReadOnly = false,
  isEditing = true,
}) {
  const typeCap = `${type?.charAt(0)?.toUpperCase()}${type?.slice(1)}`;

  const [instructorIcon, setInstructorIcon] = useState(<UserOutlined />);
  const [selectedValue, setSelectedValue] = useState(value);

  const sortOptions = (optionsArray) => {
    if (!Array.isArray(optionsArray)) {
      return [];
    }

    return optionsArray.sort((a, b) =>
      a.label.replace(/\s+/g, '').localeCompare(b.label.replace(/\s+/g, ''))
    );
  };

  const InstructorOption = ({
    label,
    value,
    hasTrainings,
    isBlocked,
    disabled,
    trainingGroupsList,
  }) => {
    const dontTrain = trainingGroupsList?.some((val) => val === taskData.group.id);

    const optionStyle = () => {
      if (!dontTrain) return { backgroundColor: '#FFEDD8' };
      if (isBlocked) return { backgroundColor: '#ffe6e6' };
      if (hasTrainings) return { backgroundColor: '#FFFBE6' };
      return {};
    };

    const trainingTooltip = () => (
      <Tooltip
        title="Já possui treinamentos neste dia"
        getPopupContainer={(trigger) => trigger.parentNode}
        placement="right"
      >
        {hasTrainings && <ScheduleOutlined style={{ color: 'orange', marginRight: 8 }} />}
      </Tooltip>
    );

    const blockedTooltip = () => (
      <Tooltip
        title="Dia bloqueado"
        getPopupContainer={(trigger) => trigger.parentNode}
        placement="right"
      >
        {isBlocked && <StopOutlined style={{ color: 'red', marginRight: 8 }} />}
      </Tooltip>
    );

    const dontTrainTooltip = () => (
      <Tooltip
        title="Não ministra este tipo de treinamento"
        getPopupContainer={(trigger) => trigger.parentNode}
        placement="right"
      >
        {!dontTrain && <StopOutlined style={{ color: 'orange', marginRight: 8 }} />}
      </Tooltip>
    );

    return {
      searchVal: label,
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!dontTrain && dontTrainTooltip()}
          {isBlocked && blockedTooltip()}
          {hasTrainings && trainingTooltip()}
          {label}
        </div>
      ),
      value,
      style: optionStyle(),
      disabled: disabled || !dontTrain,
    };
  };

  const determineInstructorIcon = (instructor) => {
    if (instructor?.stage?.id === 1) {
      return (
        <Tooltip placement="right" title="Aguardando aceite">
          <UserOutlined style={{ color: instructor?.stage.color }} />
        </Tooltip>
      );
    }

    if (instructor?.stage?.id === 3) {
      return (
        <Tooltip placement="right" title="Aceito">
          <UserOutlined style={{ color: instructor?.stage.color }} />
        </Tooltip>
      );
    }

    return <UserOutlined />;
  };

  const handleSelectChange = (newValue) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const handleClear = () => {
    setSelectedValue(undefined);
    if (onClear) onClear();
  };

  useEffect(() => {
    setSelectedValue(value);
    const instructorIcon = determineInstructorIcon(taskData?.[type]);
    setInstructorIcon(instructorIcon);
  }, [value, taskData, type]);

  return (
    <Select
      className="appointment-form-input"
      placeholder="o campo está vazio"
      optionFilterProp="searchVal"
      showSearch
      options={sortOptions(options)?.map(InstructorOption)}
      disabled={fieldsPermissions[typeCap]?.access !== 2 || isTaskReadOnly || !isEditing}
      dropdownStyle={{ zIndex: 99999, borderRadius: 16 }}
      style={{ width: '100%' }}
      suffixIcon={instructorIcon}
      allowClear
      value={selectedValue}
      onChange={handleSelectChange}
      onClear={handleClear}
    />
  );
}

export default CustomInstructorSelect;
