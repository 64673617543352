/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { Popup } from 'devextreme-react';
import moment from 'moment';

import { CheckCircleOutlined, ExportOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';

import ScheduleUtils from './ScheduleUtils';

import './OverviewPopup.scss';
import './ResumePopup.scss';

export default function ResumePopup({
  resumePopupRef,
  resumeList,
  getCurrentDate,
  schedulerRef,
  currentScreen,
  fetchResumeOverview,
  ctName,
}) {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const [appointmentList, setAppointmentList] = useState(resumeList || []);
  const [loading, setLoading] = useState(false);

  const customProductList = ScheduleUtils.getCustomProductList();

  const mainTableColumns = [
    {
      title: 'Qtd. Alunos',
      dataIndex: 'qtyStudents',
      width: '10%',
    },
    {
      title: 'Qtd. Cards',
      dataIndex: 'qtyCts',
      width: '10%',
    },
    {
      title: 'Modalidade',
      dataIndex: 'type',
      width: '17%',
    },
  ];

  const subTableColumns = [
    {
      title: 'Empresa',
      dataIndex: 'company',
    },
    {
      title: 'Produto',
      dataIndex: 'productName',
    },
    {
      title: 'Status',
      dataIndex: 'stageName',
      width: '8%',
      render: (value, object) => <span style={{ color: object.stageColor }}>{value}</span>,
    },
    {
      title: 'Qtd. Alunos',
      dataIndex: 'students',
      width: '5%',
    },
    {
      title: 'Modalidade',
      dataIndex: 'type',
      width: '8%',
    },
    {
      title: 'Compartilhado',
      dataIndex: 'isShared',
      width: '5%',
      render: (value) =>
        value ? <CheckCircleOutlined style={{ color: 'green', fontSize: 20 }} /> : '',
    },
  ];

  if (currentScreen === 'scheduleOverView') {
    subTableColumns.unshift({
      title: 'Data',
      dataIndex: 'startDate',
      render: (date) => moment(date).format('DD/MM/YYYY'),
    });
    mainTableColumns.unshift({
      title: 'Centro de Treinamento',
      dataIndex: 'trainingCenter',
      width: '70%',
    });
  }

  if (!currentScreen) {
    mainTableColumns.unshift({
      title: 'ID Treinamento',
      dataIndex: 'slotClassId',
    });
    subTableColumns.push({
      width: '5%',
      render: (object, record) =>
        !record.isHeader ? (
          <Tooltip title="Abrir treinamento">
            <ExportOutlined
              style={{ fontSize: 20, cursor: 'pointer' }}
              onClick={() => {
                schedulerRef.current.instance.showAppointmentPopup(object);
              }}
            />
          </Tooltip>
        ) : (
          ''
        ),
    });
  } else {
    subTableColumns.unshift({
      title: 'ID Treinamento',
      dataIndex: 'slotClassId',
    });
  }

  const renderSummary = () => {
    const grupBySlotClass = Utils.groupBy(appointmentList, 'slotClassId');
    return (
      <Table.Summary fixed>
        <Table.Summary.Row className="overview-summary">
          <Table.Summary.Cell colSpan={2} align="right">
            <div className="resume-summary-wrapper">
              <span>
                Alunos:{' '}
                {appointmentList
                  .filter(({ product }) => !customProductList.includes(product.id))
                  .reduce((sum, item) => sum + item.qtyStudentsPerClass, 0)}
              </span>
              <span> Turmas: {Object.keys(grupBySlotClass).length}</span>
            </div>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const prepareDataForTable = (data) => {
    const groupedBySlotClassId = Utils.groupBy(data, 'slotClassId');
    const newData = [];

    Object.keys(groupedBySlotClassId).forEach((slotClassId) => {
      if (slotClassId !== 'null') {
        // Calcula a quantidade de alunos para o slotClassId atual
        const qtyCts = groupedBySlotClassId[slotClassId].length;

        // Para cada slotClassId, soma a quantidade de alunos
        const studentsInGroup = groupedBySlotClassId[slotClassId];
        const qtyStudents = studentsInGroup
          .filter(({ product }) => !customProductList.includes(product.id))
          .reduce((sum, item) => sum + item.qtyStudentsPerClass, 0);
        const modality = studentsInGroup[0]?.type;
        const trainingCenter = studentsInGroup[0]?.trainingCenter || '';

        newData.push({
          key: `header-${slotClassId}`,
          slotClassId,
          qtyCts,
          trainingCenter,
          qtyStudents,
          type: modality,
          isHeader: true,
        });
      }
    });
    return newData;
  };

  const expandableConfig = {
    //  permite que o estado da expansão de cada linha seja controlado de forma dinâmica.
    expandedRowRender: (record) => {
      if (record.isHeader) {
        const relatedRecords = appointmentList.filter(
          (item) => item.slotClassId === record.slotClassId
        );
        return (
          <Table
            columns={subTableColumns}
            dataSource={relatedRecords}
            pagination={false}
            showHeader={true}
          />
        );
      }
      return null;
    },
    rowExpandable: (record) => record.isHeader,
  };

  const toggleExpand = () => {
    if (isAllExpanded) {
      setExpandedRowKeys([]);
    } else {
      const allRowKeys = appointmentList
        .map((item) => `header-${item.slotClassId}`)
        .filter((value, index, self) => self.indexOf(value) === index);
      setExpandedRowKeys(allRowKeys);
    }
    setIsAllExpanded(!isAllExpanded);
  };

  // Mudança sugerida começa aqui
  function ExpandCollapseButton() {
    return (
      <Button onClick={toggleExpand} style={{ marginTop: 5, marginBottom: 5 }}>
        {isAllExpanded ? 'Minimizar Todos' : 'Expandir Todas'}
      </Button>
    );
  }

  useEffect(() => {
    const allRowKeys = appointmentList
      .map((item) => `header-${item.slotClassId}`)
      .filter((value, index, self) => self.indexOf(value) === index);
    setExpandedRowKeys(allRowKeys); // Isso garante que todas as linhas estejam expandidas inicialmente
  }, [appointmentList]);

  const genereteTitle = () => {
    if (getCurrentDate) {
      return `Resumo (${moment(getCurrentDate()).format('DD/MM/YYYY')}) - ${ctName}`;
    }

    if (schedulerRef?.current?.instance) {
      const currentStartDate = schedulerRef.current.instance.getStartViewDate();
      const currentEndDate = schedulerRef.current.instance.getEndViewDate();

      return `Resumo - (${moment(currentStartDate).format('DD/MM/YYYY')} até ${moment(
        currentEndDate
      ).format('DD/MM/YYYY')})`;
    }
    return 'Resumo';
  };

  useEffect(() => {
    setAppointmentList(resumeList || []);
  }, [resumeList]);

  return (
    <Popup
      ref={resumePopupRef}
      title={genereteTitle()}
      className="overview-popup"
      hideOnOutsideClick={false}
      dragEnabled={false}
      dragOutsideBoundary={false}
      dragAndResizeArea={false}
      height="95vh"
      width="95vw"
      copyRootClassesToWrapper
      onShowing={async () => {
        if (currentScreen === 'scheduleOverView') {
          setLoading(true);
          const { resumeList } = await fetchResumeOverview();
          setAppointmentList(resumeList);
          setLoading(false);
        }
      }}
    >
      <ExpandCollapseButton />
      <Table
        className="resume-popup-table"
        columns={mainTableColumns}
        dataSource={prepareDataForTable(appointmentList)}
        pagination={false}
        summary={renderSummary}
        loading={loading}
        expandable={{
          ...expandableConfig,
          expandedRowKeys, // Já está sendo usado conforme seu último exemplo
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />
    </Popup>
  );
}
