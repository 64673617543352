/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';

import { SendOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

function EmailConfirmPopup({ onClose, currentOpenTask, type }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchConfirmationEmailList = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/Task/GetConfirmationEmailList?taskId=${currentOpenTask.id}`);
      form.setFieldsValue(response.data);
    } catch (error) {
      message.error('Oops. Algo deu errado ao buscar a Lista de E-mails!');
      Utils.logError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfirmationEmailList();
  }, []);

  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email.trim());
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      let allEmails = [
        values.emailDealContact,
        values.emailDealResponsible,
        values.emailActionUser,
        values.otherEmails?.split(';').map((email) => email.trim()),
      ].flat();

      // Adiciona o email de transporte apenas se o tipo for 2 (Transporte)
      if (type === 2) {
        allEmails.push(values.emailTransportCompany);
      }

      allEmails = allEmails.filter((email) => email); // Remove qualquer string vazia resultante

      if (allEmails.length === 0) {
        message.info('Por favor, adicione pelo menos um e-mail válido.');
        return;
      }

      const uniqueEmails = new Set(allEmails);
      if (uniqueEmails.size !== allEmails.length) {
        message.info('Existem emails duplicados!');
        return;
      }

      if (!Array.from(uniqueEmails).every(isEmailValid)) {
        message.info('Um ou mais emails fornecidos são inválidos!');
        return;
      }

      const emailList = Array.from(uniqueEmails).toString();
      await api.post(
        `/Task/SendConfirmationEmail?taskId=${currentOpenTask.id}&emailList=${emailList}&type=${type}&clientAddress=${values.clientAddress}&logisticContact1=${values.logisticContact1}&logisticContact2=${values.logisticContact2}&logisticContact3=${values.logisticContact3}`
      );

      message.success('E-mail enviado!');
      form.resetFields();
      onClose();
    } catch (error) {
      message.error('Oops. Algo deu errado ao tentar enviar o e-mail!');
      Utils.logError(error);
    } finally {
      setLoading(false);
    }
  };

  let title = '';
  switch (type) {
    case 1:
      title = 'Treinamento';
      break;
    case 2:
      title = 'Transporte';
      break;
    case 3:
      title = 'Instrutor';
      break;
    case 4:
      title = 'Motorista';
      break;
    default:
      break;
  }

  return (
    <Modal
      open={true}
      title={`Enviar e-mail de Confirmação de ${title}`}
      onCancel={onClose}
      footer={false}
    >
      <Form
        form={form}
        disabled={loading}
        onFinish={handleSave}
        name="emailConfirmPopupForm"
        layout="vertical"
        autoComplete="off"
        initialValues={{
          clientAddress: `${currentOpenTask.streetLogistic ?? ''}, ${
            currentOpenTask.numberLogistic ?? ''
          } - ${currentOpenTask.districtLogistic ?? ''}, ${currentOpenTask.cityLogistic ?? ''} - ${
            currentOpenTask.stateLogistic ?? ''
          }, ${currentOpenTask.postalCodeLogistic ?? ''} (${
            currentOpenTask.complementLogistic ?? ''
          })`,
          logisticContact1: 'Gestor do setor: Adhan Fidencio - Telefone: (11) 98304-6827',
          logisticContact2: 'Setor de logística: Amanda Alves - Telefone: (11) 91760-9423',
          logisticContact3: 'Setor de transporte: Kevin Oliveira - Telefone: (11) 91925-2546',
        }}
      >
        <Form.Item label="E-mail Contato" name="emailDealContact">
          <Input placeholder="exemplo@email.com" />
        </Form.Item>

        <Form.Item label="E-mail Responsável" name="emailDealResponsible">
          <Input placeholder="exemplo@email.com" />
        </Form.Item>

        <Form.Item label="E-mail Usuário Conectado" name="emailActionUser">
          <Input placeholder="exemplo@email.com" />
        </Form.Item>

        {type === 2 && ( // 2 = Transporte
          <Form.Item label="E-mail Empresa Transporte" name="emailTransportCompany">
            <Input placeholder="exemplo@email.com" />
          </Form.Item>
        )}

        <Form.Item
          label="Outros E-mails"
          name="otherEmails"
          rules={[
            {
              validator: Utils.validateEmails,
            },
          ]}
        >
          <Input placeholder="exemplo@email.com; exemplo2@email.com; exemplo3@email.com" />
        </Form.Item>

        <Form.Item
          label="Endereço Cliente"
          name="clientAddress"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input placeholder="Rua, Número - Bairro, Cidade - Estado, CEP (Complemento)" />
        </Form.Item>

        <Form.Item
          label="Gestor do Setor"
          name="logisticContact1"
          rules={[
            {
              required: type !== 2,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input placeholder="Gestor do setor: Nome Sobrenome - Telefone: (00) 00000-0000" />
        </Form.Item>

        <Form.Item
          label="Setor de Logística"
          name="logisticContact2"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input placeholder="Gestor do setor: Nome Sobrenome - Telefone: (00) 00000-0000" />
        </Form.Item>

        <Form.Item
          label="Setor de Transporte"
          name="logisticContact3"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input placeholder="Gestor do setor: Nome Sobrenome - Telefone: (00) 00000-0000" />
        </Form.Item>

        {/* Botões do formulário */}
        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
                disabled={
                  loading || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                style={{ width: '48%' }}
              >
                Enviar
              </Button>
              <Button onClick={onClose} style={{ width: '48%' }}>
                Cancelar
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EmailConfirmPopup;
