/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Card, Timeline } from 'antd';
import moment from 'moment';

import {
  ExportOutlined,
  FileFilled,
  InfoOutlined,
  MailFilled,
  MessageFilled,
  TagFilled,
  UserOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';

import './TaskComment.scss';

export default function TaskComment({ comments }) {
  const formattedComments = comments?.map((item) => {
    let prettyDate = '';

    const currentDate = moment().startOf('day');
    const createDate = moment(item.createDate).startOf('day');

    const daysDiff = currentDate.diff(createDate, 'days');

    if (daysDiff === 0) {
      prettyDate = `Hoje`;
    } else if (daysDiff === 1) {
      prettyDate = `Ontem`;
    } else if (daysDiff < 7) {
      prettyDate = Utils.getWeekDayLong(item.createDate);
    } else {
      prettyDate = `${new Date(item.createDate).getDate()} ${Utils.getMonthLong(item.createDate)}`;
    }

    return {
      ...item,
      prettyDate,
    };
  });

  const commentsGroupedByDate = Utils.groupBy(formattedComments, 'prettyDate');

  const renderTimeLineDot = (item) => {
    const sourceType = item.sourceType.toLowerCase();

    const commonStyle = {
      fontSize: '18px',
    };

    // Estágio
    if (sourceType.includes('statuschanged')) {
      return <TagFilled style={commonStyle} />;
    }

    // E-mail
    if (sourceType.includes('email')) {
      return <MailFilled style={commonStyle} />;
    }

    // Ocorrência
    if (sourceType.includes('occurrence')) {
      return <MessageFilled style={commonStyle} />;
    }

    // Arquivo
    if (sourceType.includes('upload')) {
      return <FileFilled style={commonStyle} />;
    }

    // Padrão
    return <InfoOutlined style={commonStyle} />;
  };

  if (comments) {
    return (
      <Timeline id="task-activities" reverse>
        {Object.keys(commentsGroupedByDate).map((prettyDate) => (
          <React.Fragment key={prettyDate}>
            <div className="task-activity-date-wrapper">
              <p className="task-activity-date-tag">{prettyDate}</p>
            </div>
            {commentsGroupedByDate[prettyDate]
              .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
              .map((item) => (
                <Timeline.Item key={item.id} dot={renderTimeLineDot(item)}>
                  <Card>
                    {/* Header */}
                    <div className="task-activity-header">
                      <p className="task-activity-title-wrapper">
                        <span>{item.title}</span> {moment(item.createDate).format('HH:mm')}
                      </p>

                      <span>
                        {item.urlRedirect && (
                          <a
                            href={item.urlRedirect}
                            target="_blank"
                            rel="noreferrer"
                            title="Ir para Cadastro Instrutor"
                            style={{ marginRight: 5 }}
                          >
                            <ExportOutlined />
                          </a>
                        )}
                        <Avatar
                          style={{ width: '22px', height: '22px' }}
                          src={item.userAvatar}
                          icon={!item.userAvatar && <UserOutlined />}
                        />
                      </span>
                    </div>

                    {/* Body */}
                    {item.userName && <p className="task-comment-username">{item.userName}</p>}
                    {item.reasonId && !item.location && (
                      <>
                        <p>{item.reason}</p>
                        {item.percentageToBeCharged && (
                          <p>Percentual a ser cobrado: {item.percentageToBeCharged}%</p>
                        )}
                        {item.description && (
                          <p dangerouslySetInnerHTML={{ __html: `${item.description}` }} />
                        )}
                      </>
                    )}

                    {!item.reasonId && item.location && (
                      <>
                        <p>Local: {item.location.name}</p>
                        <p>Geolocalização: {item.location.geopoint}</p>
                      </>
                    )}

                    {!item.reasonId && !item.location && (
                      <>
                        {item.description && (
                          <p dangerouslySetInnerHTML={{ __html: `${item.description}` }} />
                        )}
                      </>
                    )}
                  </Card>
                </Timeline.Item>
              ))}
          </React.Fragment>
        ))}
      </Timeline>
    );
  }
  return '';
}
