/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';

import StageTagComponent from '../Components/StageTagComponent';
import TagComponent from '../Components/TagComponent';

const renderCurrency = (value) => {
  let valToFormat = value;

  if (!value || value === '0' || value === '') {
    valToFormat = '0';
  }

  const [cleanValue] = valToFormat.toString().split('|');

  const number = Number(cleanValue);
  if (Number.isNaN(number)) {
    return 0;
  }

  // Formata o número como moeda
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
};

const dateSorter = (key) => (a, b) => new Date(a[key]) - new Date(b[key]);

const renderTagTextList = (textList) => (
  <div>
    {textList?.map((text) => (
      <TagComponent type="text" text={text} />
    ))}
  </div>
);

const renderTagLinkList = (linkList) => (
  <div>
    {linkList?.map(({ text, link }) => (
      <TagComponent type="link" text={text} link={link} />
    ))}
  </div>
);

const TaskListColumns = ({
  editingIds,
  fieldsPermissions,
  columnsSettings,
  returnAllColumns,
  instructorOptions,
  driverOptions,
  vehicleOptions,
  reasonOptions,
  transportCompanyOptions,
  trainingCenterOptions,
  formTaskList,
  filterByPermissions,
}) => {
  let defaultColumns = [
    // Geral
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 100,
      group: 'general',
    },
    {
      title: 'Id Negócio',
      dataIndex: 'dealId',
      sorter: (a, b) => a.dealId - b.dealId,
      width: 120,
      group: 'general',
    },
    {
      title: 'Id Serviço',
      dataIndex: 'serviceId',
      sorter: (a, b) => a.serviceId - b.serviceId,
      width: 120,
      group: 'general',
    },
    {
      title: 'Id Turma',
      dataIndex: 'slotClassId',
      sorter: (a, b) => a.slotClassId?.localeCompare(b.slotClassId),
      width: 250,
      group: 'general',
    },
    {
      title: 'Título',
      dataIndex: 'cardTitle',
      width: 150,
      group: 'general',
    },
    {
      title: 'Produto',
      dataIndex: 'product',
      width: 300,
      render: (item) => item?.name || '-',
      group: 'general',
    },
    {
      title: 'Estágio',
      dataIndex: 'stage',
      width: 200,
      render: (item) => <StageTagComponent stage={item} />,
      group: 'general',
    },
    {
      title: 'Data',
      dataIndex: 'startDate',
      sorter: dateSorter('startDate'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      group: 'general',
    },
    {
      title: 'Hora Início',
      dataIndex: 'startHour',
      render: (text) => (text ? moment.utc(text).local().format('HH:mm:ss') : '-'),
      width: 200,
      editable: fieldsPermissions?.StartHour?.access === 2,
      inputType: 'time',
      group: 'general',
    },
    {
      title: 'Hora Fim',
      dataIndex: 'endHour',
      render: (text) => (text ? moment.utc(text).local().format('HH:mm:ss') : '-'),
      width: 200,
      editable: fieldsPermissions?.EndHour?.access === 2,
      inputType: 'time',
      group: 'general',
    },
    {
      title: 'Recurso',
      dataIndex: 'resource',
      sorter: (a, b) => a.resource?.localeCompare(b.resource),
      render: (text) => text || '-',
      width: 150,
      group: 'general',
    },
    {
      title: 'Instrutor',
      dataIndex: 'mainInstructor',
      sorter: (a, b) => a.mainInstructor?.userName?.localeCompare(b.mainInstructor?.userName),
      render: (item) => item?.userName || '-',
      width: 300,
      editable: fieldsPermissions?.MainInstructor?.access === 2,
      inputType: 'mainInstructor',
      group: 'general',
    },
    {
      title: 'Instrutor Auxiliar',
      dataIndex: 'assistantInstructor',
      sorter: (a, b) =>
        a.assistantInstructor?.userName?.localeCompare(b.assistantInstructor?.userName),
      render: (item) => item?.userName || '-',
      width: 300,
      editable: fieldsPermissions?.AssistantInstructor?.access === 2,
      inputType: 'assistantInstructor',
      group: 'general',
    },
    {
      title: 'Valor Instrutor',
      dataIndex: 'instructorValue',
      sorter: (a, b) => a.instructorValue - b.instructorValue,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.InstructorValue?.access === 2,
      inputType: 'currency',
      group: 'general',
    },
    {
      title: 'Valor Instrutor Auxiliar',
      dataIndex: 'assistantInstructorValue',
      sorter: (a, b) => a.assistantInstructorValue - b.assistantInstructorValue,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.AssistantInstructorValue?.access === 2,
      inputType: 'currency',
      group: 'general',
    },
    {
      title: 'Transporte Oficial',
      dataIndex: 'trainingTransportation',
      sorter: (a, b) => a.trainingTransportation?.localeCompare(b.trainingTransportation),
      width: 150,
      group: 'general',
    },
    {
      title: 'Responsável Negócio',
      dataIndex: 'quoteResponsible',
      sorter: (a, b) => a.quoteResponsible?.localeCompare(b.quoteResponsible),
      width: 200,
      group: 'general',
    },
    {
      title: 'Valor Oportunidade',
      dataIndex: 'dealOportunity',
      sorter: (a, b) => a.dealOportunity?.localeCompare(b.dealOportunity),
      render: renderCurrency,
      width: 200,
      group: 'general',
    },

    // Cliente
    {
      title: 'Nome Cliente',
      dataIndex: 'dealClient',
      sorter: (a, b) => a.dealClient?.localeCompare(b.dealClient),
      width: 250,
      group: 'client',
    },
    {
      title: 'Nome Fantasia Cliente',
      dataIndex: 'dealClientCommercialName',
      sorter: (a, b) => a.dealClientCommercialName?.localeCompare(b.dealClientCommercialName),
      width: 250,
      group: 'client',
    },
    {
      title: 'CNPJ Cliente',
      dataIndex: 'dealClientCNPJ',
      sorter: (a, b) => a.dealClientCNPJ?.localeCompare(b.dealClientCNPJ),
      width: 250,
      group: 'client',
    },
    {
      title: 'Cliente Prioritário',
      dataIndex: 'dealPrioritaryClient',
      sorter: (a, b) => a.dealPrioritaryClient?.localeCompare(b.dealPrioritaryClient),
      width: 250,
      group: 'client',
    },

    {
      title: 'Empresa Contratada',
      dataIndex: 'dealContractedCompany',
      sorter: (a, b) => a.dealContractedCompany?.localeCompare(b.dealContractedCompany),
      width: 250,
      group: 'general',
    },
    {
      title: 'Endereço Centro de Treinamento',
      dataIndex: 'dealTrainingCenterAddress',
      sorter: (a, b) => a.dealTrainingCenterAddress?.localeCompare(b.dealTrainingCenterAddress),
      width: 250,
      group: 'general',
    },
    {
      title: 'Observação Específica',
      dataIndex: 'dealClientObservation',
      sorter: (a, b) => a.dealClientObservation?.localeCompare(b.dealClientObservation),
      render: (text) => text || '-',
      width: 300,
      group: 'general',
    },
    {
      title: 'Grupo Treinamento',
      dataIndex: 'group',
      sorter: (a, b) => a.instructorValue - b.instructorValue,
      render: (item) => item?.name || '-',
      width: 150,
      group: 'general',
    },
    {
      title: 'Quantidade Turmas',
      dataIndex: 'qtyClass',
      sorter: (a, b) => a.qtyClass - b.qtyClass,
      width: 200,
      group: 'general',
    },
    {
      title: 'Alunos p/ Turma',
      dataIndex: 'qtyStudentsPerClass',
      sorter: (a, b) => a.qtyStudentsPerClass - b.qtyStudentsPerClass,
      width: 200,
      group: 'general',
    },
    {
      title: 'Alunos Treinados',
      dataIndex: 'qtyStudentsTrained',
      sorter: (a, b) => a.qtyStudentsTrained - b.qtyStudentsTrained,
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.QtyStudentsTrained?.access === 2,
      inputType: 'number',
      group: 'general',
    },
    {
      title: 'Diferença Alunos Confirmados/Treinados',
      dataIndex: 'qtyStudentsDiff',
      sorter: (a, b) => a.qtyStudentsDiff - b.qtyStudentsDiff,
      render: (text) => text || '-',
      width: 200,
    },
    {
      title: 'Dias Práticos p/ Turma',
      dataIndex: 'practicalDaysPerClass',
      sorter: (a, b) => a.practicalDaysPerClass - b.practicalDaysPerClass,
      width: 200,
      group: 'general',
    },
    {
      title: 'Dias Teóricos p/ Turma',
      dataIndex: 'theoreticalDaysPerClass',
      sorter: (a, b) => a.theoreticalDaysPerClass - b.theoreticalDaysPerClass,
      width: 200,
      group: 'general',
    },
    {
      title: 'Dias In Company p/ Turma',
      dataIndex: 'inCompanyDaysPerClass',
      sorter: (a, b) => a.inCompanyDaysPerClass - b.inCompanyDaysPerClass,
      width: 200,
      group: 'general',
    },
    {
      title: 'Dias EAD p/ Turma',
      dataIndex: 'eadDays',
      sorter: (a, b) => a.eadDays - b.eadDays,
      width: 200,
      group: 'general',
    },
    {
      title: 'Total Dias de Treinamento',
      dataIndex: 'totalTrainingDays',
      sorter: (a, b) => a.totalTrainingDays - b.totalTrainingDays,
      width: 200,
      group: 'general',
    },
    {
      title: 'Centro de Treinamento',
      dataIndex: 'trainingCenter',
      sorter: (a, b) => a.trainingCenter?.localeCompare(b.trainingCenter),
      render: (text) => text || '-',
      width: 200,
      group: 'general',
    },
    {
      title: 'Data Agendamento',
      dataIndex: 'dateScheduling',
      sorter: dateSorter('dateScheduling'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      group: 'general',
    },
    {
      title: 'Data Integração',
      dataIndex: 'createDate',
      sorter: dateSorter('createDate'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      group: 'general',
    },
    {
      title: 'Lista de Presença Inicial',
      dataIndex: 'initialAttendanceDocList',
      render: (value) =>
        value
          ? renderTagLinkList(value?.map(({ label, url }) => ({ text: label, link: url })))
          : '-',
      width: 300,
      editable: fieldsPermissions?.InitialAttendanceDocList?.access === 2,
      inputType: 'fileUpload',
      attachDeleteEvent: 'InitialAttendanceListDelete',
      group: 'general',
    },
    {
      title: 'Lista de Presença Final',
      dataIndex: 'finalAttendanceDocList',
      render: (value) =>
        value
          ? renderTagLinkList(value?.map(({ label, url }) => ({ text: label, link: url })))
          : '-',
      width: 300,
      editable: fieldsPermissions?.FinalAttendanceDocList?.access === 2,
      inputType: 'fileUpload',
      attachDeleteEvent: 'FinalAttendanceListDelete',
      group: 'general',
    },
    {
      title: 'Formulário Instrutor',
      dataIndex: 'instructorForm',
      render: (value) =>
        value
          ? renderTagLinkList(value?.map(({ label, url }) => ({ text: label, link: url })))
          : '-',
      width: 300,
      editable: fieldsPermissions?.InstructorForm?.access === 2,
      inputType: 'fileUpload',
      attachDeleteEvent: 'InstructorFormDelete',
      group: 'general',
    },
    {
      title: 'Pedido de Compra',
      dataIndex: 'downloadDealBuyOrder',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Abrir
        </a>
      ),
      width: 200,
      group: 'general',
    },
    {
      title: 'Termo de Aceite',
      dataIndex: 'downloadAcceptTerm1',
      render: (text) => {
        if (text && text !== '') {
          return (
            <a href={text} target="_blank" rel="noopener noreferrer" className="custom-a">
              Abrir
            </a>
          );
        }
        return <p style={{ color: 'orange' }}>Pendente</p>;
      },
      width: 200,
      group: 'general',
    },
    {
      title: 'Termo de Aceite Assinado',
      dataIndex: 'downloadAcceptTerm2',
      width: 200,
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Abrir
        </a>
      ),
      group: 'general',
    },
    {
      title: 'Data Recebimento do Termo Assinado',
      dataIndex: 'dateUploadAcceptTerm2',
      sorter: dateSorter('dateUploadAcceptTerm2'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      group: 'general',
    },
    {
      title: 'Lista de Presença Verificada',
      dataIndex: 'attendanceListChecked',
      render: (text) => (text ? 'Sim' : 'Não'),
      width: 200,
      editable: fieldsPermissions?.AttendanceListChecked?.access === 2,
      inputType: 'checkbox',
      group: 'general',
    },
    {
      title: 'Documentação Enviada',
      dataIndex: 'hasDocumentation',
      render: (text) => (text ? 'Sim' : 'Não'),
      width: 200,
      editable: fieldsPermissions?.HasDocumentation?.access === 2,
      inputType: 'checkbox',
      group: 'general',
    },
    {
      title: 'Aceita Encaixe',
      dataIndex: 'acceptSharedTraining',
      render: (text) => (text ? 'Sim' : 'Não'),
      width: 150,
      editable: fieldsPermissions?.AcceptSharedTraining?.access === 2,
      inputType: 'checkbox',
      group: 'general',
    },
    {
      title: 'Observação Negócio',
      dataIndex: 'dealNotes',
      sorter: (a, b) => a.dealNotes?.localeCompare(b.dealNotes),
      render: (text) => text || '-',
      width: 500,
      group: 'general',
    },
    {
      title: 'Observação Comercial',
      dataIndex: 'commercialNotes',
      sorter: (a, b) => a.commercialNotes?.localeCompare(b.commercialNotes),
      render: (text) => text || '-',
      width: 500,
      editable: fieldsPermissions?.CommercialNotes?.access === 2,
      inputType: 'textarea',
      group: 'general',
    },
    {
      title: 'Observação Logística',
      dataIndex: 'logisticsNotes',
      sorter: (a, b) => a.logisticsNotes?.localeCompare(b.logisticsNotes),
      render: (text) => text || '-',
      width: 500,
      editable: fieldsPermissions?.LogisticsNotes?.access === 2,
      inputType: 'textarea',
      group: 'general',
    },
    {
      title: 'Observação CT',
      dataIndex: 'ctNotes',
      sorter: (a, b) => a.ctNotes?.localeCompare(b.ctNotes),
      render: (text) => text || '-',
      width: 500,
      editable: fieldsPermissions?.CtNotes?.access === 2,
      inputType: 'textarea',
      group: 'general',
    },
    {
      title: 'Observação Instrutor',
      dataIndex: 'instructorNotes',
      sorter: (a, b) => a.instructorNotes?.localeCompare(b.instructorNotes),
      render: (text) => text || '-',
      width: 500,
      editable: fieldsPermissions?.InstructorNotes?.access === 2,
      inputType: 'textarea',
      group: 'general',
    },

    // Logistica
    {
      title: 'Quando Avançado 24 horas Hibrido (Endereço Teoria)',
      dataIndex: 'advance24h',
      sorter: (a, b) => a.advance24h?.localeCompare(b.advance24h),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.Advance24h?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Endereço de Parada de Transporte',
      dataIndex: 'transportStopAddress',
      sorter: (a, b) => a.transportStopAddress?.localeCompare(b.transportStopAddress),
      render: (text) => text || '-',
      width: 200,
      // editable: fieldsPermissions?.TransportStopAddress?.access === 2,
      // inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Ponto de Referência',
      dataIndex: 'referencePoint',
      sorter: (a, b) => a.referencePoint?.localeCompare(b.referencePoint),
      render: (text) => text || '-',
      width: 200,
      group: 'logistic',
    },
    {
      title: 'Nome Responsável',
      dataIndex: 'responsiblePersonNameLogistic',
      sorter: (a, b) =>
        a.responsiblePersonNameLogistic?.localeCompare(b.responsiblePersonNameLogistic),
      render: (text) => text || '-',
      width: 300,
      editable: fieldsPermissions?.ResponsiblePersonNameLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Telefone Responsável',
      dataIndex: 'responsiblePersonPhoneLogistic',
      sorter: (a, b) =>
        a.responsiblePersonPhoneLogistic?.localeCompare(b.responsiblePersonPhoneLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.ResponsiblePersonPhoneLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'E-mail Responsável',
      dataIndex: 'responsiblePersonEmailLogistic',
      sorter: (a, b) =>
        a.responsiblePersonEmailLogistic?.localeCompare(b.responsiblePersonEmailLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.ResponsiblePersonEmailLogistic?.access === 2,
      inputType: 'email',
      group: 'logistic',
    },
    {
      title: 'CEP',
      dataIndex: 'postalCodeLogistic',
      sorter: (a, b) => a.postalCodeLogistic?.localeCompare(b.postalCodeLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.PostalCodeLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Rua',
      dataIndex: 'streetLogistic',
      sorter: (a, b) => a.streetLogistic?.localeCompare(b.streetLogistic),
      render: (text) => text || '-',
      width: 300,
      editable: fieldsPermissions?.StreetLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Número',
      dataIndex: 'numberLogistic',
      sorter: (a, b) => a.numberLogistic?.localeCompare(b.numberLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.NumberLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Bairro',
      dataIndex: 'districtLogistic',
      sorter: (a, b) => a.districtLogistic?.localeCompare(b.districtLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.DistrictLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Cidade',
      dataIndex: 'cityLogistic',
      sorter: (a, b) => a.cityLogistic?.localeCompare(b.cityLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.CityLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Estado',
      dataIndex: 'stateLogistic',
      sorter: (a, b) => a.stateLogistic?.localeCompare(b.stateLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.StateLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Complemento',
      dataIndex: 'complementLogistic',
      sorter: (a, b) => a.complementLogistic?.localeCompare(b.complementLogistic),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.ComplementLogistic?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'Reembolso',
      dataIndex: 'refund',
      sorter: (a, b) => a.refund - b.refund,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.Refund?.access === 2,
      inputType: 'currency',
      group: 'logistic',
    },
    {
      title: 'Valor Transporte',
      dataIndex: 'transportValue',
      sorter: (a, b) => a.transportValue - b.transportValue,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.TransportValue?.access === 2,
      inputType: 'currency',
      group: 'logistic',
    },
    {
      title: 'Valor Pago Transporte',
      dataIndex: 'amountPaidTransportation',
      sorter: (a, b) => a.amountPaidTransportation - b.amountPaidTransportation,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.AmountPaidTransportation?.access === 2,
      inputType: 'currency',
      group: 'logistic',
    },
    {
      title: 'Data de Confirmação',
      dataIndex: 'confirmationDate',
      sorter: dateSorter('confirmationDate'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      group: 'logistic',
    },
    {
      title: 'Confirmado por',
      dataIndex: 'confirmedBy',
      sorter: (a, b) => a.confirmedBy - b.confirmedBy,
      render: (text) => text || '-',
      width: 200,
      group: 'logistic',
    },
    {
      title: 'Nome Responsável Confirmação',
      dataIndex: 'nameResponsibleConfirmation',
      sorter: (a, b) => a.nameResponsibleConfirmation?.localeCompare(b.nameResponsibleConfirmation),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.NameResponsibleConfirmation?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'E-mail Responsável Confirmação',
      dataIndex: 'emailResponsibleConfirmation',
      sorter: (a, b) =>
        a.emailResponsibleConfirmation?.localeCompare(b.emailResponsibleConfirmation),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.EmailResponsibleConfirmation?.access === 2,
      inputType: 'email',
      group: 'logistic',
    },
    {
      title: 'Telefone Responsável Confirmação',
      dataIndex: 'phoneResponsibleConfirmation',
      sorter: (a, b) =>
        a.phoneResponsibleConfirmation?.localeCompare(b.phoneResponsibleConfirmation),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.PhoneResponsibleConfirmation?.access === 2,
      inputType: 'text',
      group: 'logistic',
    },
    {
      title: 'NF Transporte',
      dataIndex: 'nfTransportation',
      sorter: (a, b) => a.nfTransportation?.localeCompare(b.nfTransportation),
      render: (value) =>
        value
          ? renderTagLinkList(value?.map(({ label, url }) => ({ text: label, link: url })))
          : '-',
      width: 300,
      editable: fieldsPermissions?.NfTransportation?.access === 2,
      inputType: 'fileUpload',
      attachDeleteEvent: 'NFTransportationDelete',
      group: 'logistic',
    },

    // Transporte
    {
      title: 'Empresa Transporte',
      dataIndex: 'transportCompany',
      sorter: (a, b) => a.transportCompany?.name?.localeCompare(b.transportCompany?.name),
      render: (item) => item?.name || '-',
      width: 300,
      editable: fieldsPermissions?.TransportCompany?.access === 2,
      inputType: 'transportCompany',
      group: 'general',
    },
    {
      title: 'Veículos',
      dataIndex: 'vehicleList',
      render: (value) =>
        value
          ? renderTagTextList(
              value?.map(
                ({ licensePlate, type, capacity }) =>
                  `${licensePlate} - ${type.name} - ${capacity} pessoas`
              )
            )
          : '-',
      width: 300,
      editable: fieldsPermissions?.VehicleList?.access === 2,
      inputType: 'vehicleList',
      group: 'general',
    },
    {
      title: 'Motoristas',
      dataIndex: 'driverList',
      render: (value) => (value ? renderTagTextList(value?.map((item) => item.userName)) : '-'),
      width: 300,
      editable: fieldsPermissions?.DriverList?.access === 2,
      inputType: 'driverList',
      group: 'general',
    },
    {
      title: 'Horário de Embarque',
      dataIndex: 'boardingHour',
      render: (text) => (text ? moment.utc(text).local().format('HH:mm:ss') : '-'),
      width: 200,
      editable: fieldsPermissions?.BoardingHour?.access === 2,
      inputType: 'time',
      group: 'general',
    },

    // Financeiro
    {
      title: 'Valor de Treinamento',
      dataIndex: 'trainingValue',
      sorter: (a, b) => a.trainingValue - b.trainingValue,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.TrainingValue?.access === 2,
      inputType: 'currency',
      group: 'financial',
    },
    {
      title: 'Data de Previsão de Faturamento ',
      dataIndex: 'billingDeadlineDate',
      sorter: dateSorter('billingDeadlineDate'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      group: 'financial',
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'dealPaymentForm',
      sorter: (a, b) => a.dealPaymentForm?.localeCompare(b.dealPaymentForm),
      render: (text) => text || '-',
      width: 200,
      group: 'financial',
    },
    {
      title: 'Forma de Pagamento Negociada',
      dataIndex: 'dealPaymentFormNegotiated',
      sorter: (a, b) => a.dealPaymentFormNegotiated?.localeCompare(b.dealPaymentFormNegotiated),
      render: (text) => text || '-',
      width: 200,
      group: 'financial',
    },
    {
      title: 'Condição de Pagamento Negociada',
      dataIndex: 'dealPaymentConditionNegotiated',
      sorter: (a, b) =>
        a.dealPaymentConditionNegotiated?.localeCompare(b.dealPaymentConditionNegotiated),
      render: (text) => text || '-',
      width: 200,
      group: 'financial',
    },
    {
      title: 'Retenção Tributária',
      dataIndex: 'dealTributaryRetention',
      sorter: (a, b) => a.dealTributaryRetention?.localeCompare(b.dealTributaryRetention),
      render: (text) => text || '-',
      width: 200,
      group: 'financial',
    },
    {
      title: 'Razão de não Faturamento',
      dataIndex: 'reasonNoBilling',
      sorter: (a, b) => a.reasonNoBilling?.name?.localeCompare(b.reasonNoBilling?.name),
      render: (item) => item?.name || '-',
      width: 300,
      editable: fieldsPermissions?.ReasonNoBilling?.access === 2,
      inputType: 'reasonNoBilling',
      group: 'financial',
    },
    {
      title: 'NF Treinamento',
      dataIndex: 'nfTraining',
      render: (value) =>
        value
          ? renderTagLinkList(value?.map(({ label, url }) => ({ text: label, link: url })))
          : '-',
      width: 300,
      editable: fieldsPermissions?.NfTraining?.access === 2,
      inputType: 'fileUpload',
      attachDeleteEvent: 'NFTrainingDelete',
      group: 'financial',
    },
    {
      title: 'Número Nota Fiscal',
      dataIndex: 'invoiceNumber',
      sorter: (a, b) => a.invoiceNumber?.localeCompare(b.invoiceNumber),
      render: (text) => text || '-',
      width: 200,
      editable: fieldsPermissions?.InvoiceNumber?.access === 2,
      inputType: 'text',
      group: 'financial',
    },
    {
      title: 'Data de Emissão da Nota Fiscal',
      dataIndex: 'invoiceEmissionDate',
      sorter: dateSorter('invoiceEmissionDate'),
      render: (text) => (text ? moment.utc(text).format('DD/MM/YYYY') : '-'),
      width: 200,
      editable: fieldsPermissions?.InvoiceEmissionDate?.access === 2,
      inputType: 'date',
      group: 'financial',
    },
    {
      title: 'Emissor Nota Fiscal',
      dataIndex: 'invoiceIssuer',
      sorter: (a, b) => a.invoiceIssuer?.name?.localeCompare(b.invoiceIssuer?.name),
      render: (item) => item?.name || '-',
      width: 300,
      editable: fieldsPermissions?.InvoiceIssuer?.access === 2,
      inputType: 'invoiceIssuer',
      group: 'financial',
    },
    {
      title: 'Valor Nota Fiscal Total',
      dataIndex: 'totalInvoiceValue',
      sorter: (a, b) => a.totalInvoiceValue - b.totalInvoiceValue,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.TotalInvoiceValue?.access === 2,
      inputType: 'currency',
      group: 'financial',
    },
    {
      title: 'Valor Nota Fiscal Parcial',
      dataIndex: 'partialInvoiceValue',
      sorter: (a, b) => a.partialInvoiceValue - b.partialInvoiceValue,
      render: renderCurrency,
      width: 200,
      editable: fieldsPermissions?.PartialInvoiceValue?.access === 2,
      inputType: 'currency',
      group: 'financial',
    },

    // Certificado
    {
      title: 'Não Gera Certificado',
      dataIndex: 'notHaveCertificate',
      render: (text) => (text ? 'Sim' : 'Não'),
      width: 200,
      editable: fieldsPermissions?.NotHaveCertificate?.access === 2,
      inputType: 'checkbox',
      group: 'general',
    },
  ];

  if (!returnAllColumns) {
    let columns = [];

    if (columnsSettings.length > 0) {
      const [firstItem] = columnsSettings;
      columns = firstItem.columns;
    } else {
      columns =
        'dealId,cardTitle,product,stage,startDate,startHour,endHour,group,quoteResponsible,dealOportunity,dealClient'; // Colunas Padrão
    }

    const columnsToFilter = columns.split(',');
    defaultColumns = defaultColumns.filter(({ dataIndex }) => columnsToFilter.includes(dataIndex));
  }

  if (filterByPermissions) {
    defaultColumns = defaultColumns.filter(({ dataIndex }) => {
      const dataIndexCap = dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1);

      return fieldsPermissions[dataIndexCap].access >= 1;
    });
  }

  return defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
        inputType: col.inputType,
        attachDeleteEvent: col.attachDeleteEvent,
        dataIndex: col.dataIndex,
        editable: col.editable,
        editing: editingIds?.some((id) => id === record.id),
        fieldsPermissions,
        formTaskList,
        instructorOptions,
        driverOptions,
        vehicleOptions,
        reasonOptions,
        transportCompanyOptions,
        trainingCenterOptions,
      }),
    };
  });
};
export default TaskListColumns;
