/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  AlertOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './TableAntd.scss';

export default function TableAntd({
  columnsTable,
  routePermissions,
  requestName,
  cdPage,
  route,
  notEdit,
  notDelete,
  notInactivate,
  scroll,
  btnNew,
  menuItems,
  defaultPageSize,
  isNotAction,
  selection,
  clearSelection,
  summary,
  selectionType,
  toggleStatusRoute,
  requiredDocuments,
  extraToolbar,
}) {
  const [searchedColumn, setSearchedColumn] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [isModalDeleteOpen, openModalDelete] = useState(false);
  const [isModalStatusOpen, openModalStatus] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('conecta__permissions'));
  const [key, setKey] = useState();
  const [loadingTableComponent, setLoadingTableComponent] = useState();
  const [tableData, setTableData] = useState();

  const navigate = useNavigate();

  function permissionsFilter() {
    const [permissionsFiltered] = permissions.resources.filter(
      ({ name }) => name === routePermissions
    );
    return permissionsFiltered;
  }

  const getData = async () => {
    setLoadingTableComponent(true);
    await api
      .get(`/${requestName}`)
      .then((res) => {
        setTableData(
          res.data?.map((item) => ({
            ...item,
            key: item[cdPage],
            selectable: item.status !== 'Inativo',
          }))
        );
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar buscar os registros!');
        setTableData([]);
      });
    setLoadingTableComponent(false);
  };

  // Busca os registros na primeira vez
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [clearSelection]);

  const handleDelete = async () => {
    setLoadingTableComponent(true);
    setTimeout(async () => {
      await api
        .delete(`/${requestName?.split('?')[0] ?? ''}?id=${key}`)
        .then(() => {
          getData();
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Erro ao excluir registro!');
        });
      setLoadingTableComponent(false);
    }, 1000);
  };

  const toggleStatus = async () => {
    setLoadingTableComponent(true);
    setTimeout(async () => {
      await api
        .put(`${toggleStatusRoute ?? routePermissions}/UpdateStatus?id=${key}`)
        .then(() => {
          getData();
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Erro ao tentar Ativar/Inativar registro!');
        });
      setLoadingTableComponent(false);
    }, 1000);
  };

  const getColumnSearchProps = (dataIndex, title, isDate) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {isDate ? (
          <DatePicker
            onChange={(date, dateString) => setSelectedKeys([dateString])}
            format="DD/MM/YYYY"
            style={{ marginBottom: 8, display: 'block' }}
            placeholder={title}
          />
        ) : (
          <Input
            id="inputSearch"
            placeholder={title}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            // eslint-disable-next-line no-use-before-define
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        )}
        <Space>
          <Button
            type="primary"
            // eslint-disable-next-line no-use-before-define
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#05005B',
              borderColor: '#05005B',
              borderRadius: '16px',
            }}
          >
            Pesquisar
          </Button>
          <Button
            // eslint-disable-next-line no-use-before-define
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90, borderRadius: '16px' }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#05005B' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && !isDate) {
        setTimeout(() => document.getElementById('inputSearch').value, 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? <p>{text}</p> : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const menu = (keyItem, record) => (
    <Menu>
      {permissionsFilter().canHandle && !notDelete && record.status !== 'Inativo' && (
        <Menu.Item
          key="0"
          data-bs-toggle="modalDelete"
          data-bs-target="#modalDelete"
          onClick={() => openModalDelete(true)}
          style={notEdit && { borderRadius: '16px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
              }}
              data-bs-toggle="modalDelete"
              data-bs-target="#modalDelete"
              onClick={() => setKey(keyItem)}
            >
              Excluir
            </button>
          </div>
        </Menu.Item>
      )}
      {permissionsFilter().canHandle && !notInactivate && (
        <Menu.Item
          key="1"
          data-bs-toggle="modalStatus"
          data-bs-target="#modalStatus"
          onClick={() => openModalStatus(true)}
          style={notEdit && { borderRadius: '16px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
              }}
              data-bs-toggle="modal"
              data-bs-target="#modalStatus"
              onClick={() => setKey(keyItem)}
            >
              Ativar/Inativar
            </button>
          </div>
        </Menu.Item>
      )}
      {permissionsFilter().canHandle && !notEdit && record.status !== 'Inativo' && (
        <Menu.Item
          onClick={() => {
            navigate(`${route}/Editar/${keyItem}`);
          }}
          key="2"
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
              }}
            >
              Editar
            </button>
          </div>
        </Menu.Item>
      )}
      {menuItems ? menuItems(keyItem, record) : null}
    </Menu>
  );

  const columns = () => {
    if (!isNotAction) {
      const colReturn = [
        {
          title: 'Código',
          dataIndex: cdPage,
          width: '110px',
          ...getColumnSearchProps(cdPage, 'Código'),
          sorter: (a, b) => a[cdPage] - b[cdPage],
          sortDirections: ['descend', 'ascend'],
          render: (text, record) =>
            permissionsFilter().canHandle && !notEdit && record.status !== 'Inativo' ? (
              <span
                role="presentation"
                style={{ color: '#05005B', cursor: 'pointer', fontWeight: '500' }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${route}/Editar/${record[cdPage]}`);
                }}
              >
                {text}
              </span>
            ) : (
              <span>{text}</span>
            ),
        },
        ...columnsTable(getColumnSearchProps),
      ];

      if (requiredDocuments) {
        colReturn.push({
          title: 'Documentos',
          width: '100px',
          dataIndex: '',
          align: 'center',
          fixed: 'right',
          render: (_, record) => {
            if (requiredDocuments?.length === 0) {
              return (
                <Tooltip title="Possui todos documentos obrigatórios" placement="left">
                  <CheckCircleOutlined style={{ color: '#3bb856', fontSize: 20 }} />
                </Tooltip>
              );
            }

            if (record.documents?.length > 0) {
              const validDocs = record.documents
                .filter(({ verified, validity }) => {
                  if (!verified) return false;

                  const validityDate = new Date(validity);
                  const year2000 = new Date('2000-01-01');
                  const today = new Date();

                  if (validityDate > year2000) {
                    return validityDate > today;
                  }

                  // Documentos sem data de Validade
                  return true;
                })
                .map(({ type }) => type.id);

              // Verifica se todos os documentos validos estão na lista
              const allRequiredDocsValid = requiredDocuments.every((requiredDocId) =>
                validDocs.includes(requiredDocId)
              );

              if (allRequiredDocsValid && requiredDocuments.length > 0) {
                return (
                  <Tooltip title="Possui todos documentos obrigatórios" placement="left">
                    <CheckCircleOutlined style={{ color: '#3bb856', fontSize: 20 }} />
                  </Tooltip>
                );
              }
            }

            return (
              <Tooltip title="Documentos obrigatórios faltando" placement="left">
                <AlertOutlined style={{ color: '#fc5d20', fontSize: 20 }} />
              </Tooltip>
            );
          },
        });
      }

      colReturn.push({
        title: 'Ações',
        width: '75px',
        dataIndex: '',
        align: 'center',
        fixed: 'right',
        render: (_, record) => (
          <Dropdown
            style={{ cursor: 'pointer' }}
            overlay={menu(record[cdPage], record)}
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="ant-dropdown-link">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                ...
              </span>
            </div>
          </Dropdown>
        ),
      });

      return colReturn;
    }
    return [...columnsTable(getColumnSearchProps)];
  };

  // eslint-disable-next-line no-param-reassign
  const columnsTable2 = columns().map((col) => {
    if (!col.editable || !permissionsFilter().canHandle) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const headerTable = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        {permissionsFilter().canHandle && btnNew ? (
          <Button
            className="button-new"
            onClick={() => {
              navigate(`${route}/Cadastrar`);
            }}
          >
            Novo
          </Button>
        ) : null}
        <Tooltip title="Recarregar">
          <Button
            style={{ borderRadius: '16px', alignItems: 'center' }}
            icon={<ReloadOutlined />}
            onClick={() => getData()}
          />
        </Tooltip>
        {extraToolbar}
      </div>
      <p className="allRegisters-table">Total de Registros: {tableData ? tableData.length : 0}</p>
    </div>
  );

  // eslint-disable-next-line no-shadow
  const onSelectedRowKeysChangeClient = async (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    selection(selectedRowKeys);
  };

  const rowSelection = () => {
    if (selection !== undefined) {
      return {
        type: selectionType ?? 'radio',
        selectedRowKeys,
        onChange: onSelectedRowKeysChangeClient,
        getCheckboxProps: (record) => ({
          disabled: record.selectable === false,
        }),
      };
    }
    return null;
  };

  return (
    <>
      {headerTable()}
      <Table
        columns={columnsTable2}
        loading={{
          spinning: loadingTableComponent,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        }}
        dataSource={tableData}
        sticky
        size="middle"
        bordered
        tableLayout="auto"
        id={cdPage}
        rowSelection={rowSelection()}
        scroll={scroll}
        pagination={{
          position: ['bottomCenter'],
          size: 'default',
          defaultPageSize: defaultPageSize || 10,
          pageSizeOptions: [10, 20, 30, 50, 100],
          showSizeChanger: tableData?.length > 9,
        }}
        rowClassName={({ status }) => (status === 'Inativo' ? 'table__inactive-row' : '')}
        summary={() => summary}
      />

      {/* Modal Excluir */}
      <Modal
        title="Atenção"
        open={isModalDeleteOpen}
        onOk={() => {
          openModalDelete(false);
          handleDelete();
        }}
        onCancel={() => openModalDelete(false)}
        okText="Excluir"
        closable={false}
      >
        <h4>Deseja realmente excluir este registro?</h4>
      </Modal>

      {/* Modal Status */}
      <Modal
        title="Atenção"
        open={isModalStatusOpen}
        onOk={() => {
          openModalStatus(false);
          toggleStatus();
        }}
        onCancel={() => openModalStatus(false)}
        okText="Ativar/Inativar"
        closable={false}
      >
        <h4>Deseja realmente Ativar/Inativar este registro?</h4>
      </Modal>
    </>
  );
}
