/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button, Dropdown, Menu, Space } from 'antd';

import { CalendarOutlined, DownOutlined } from '@ant-design/icons';

import BlockedDaysModal from '../BlockedDaysModal/BlockedDaysModal';

export default function CustomDropdown({
  type,
  sourceId,
  updateBlockedDays,
  workDays,
  breakTime,
  blockedDates,
  dataSource,
  visible,
}) {
  const [openModal, setOpenModal] = useState(false);

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <a onClick={() => setOpenModal(true)}>Bloquear dias</a>,
          icon: <CalendarOutlined />,
        },
      ]}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Space
          style={{
            cursor: 'pointer',
            paddingBottom: 10,
            color: '#05005B',
            display: !visible ? ' ' : 'none',
          }}
        >
          <Button type="default">
            Ações
            <DownOutlined />
          </Button>
        </Space>
      </Dropdown>
      <BlockedDaysModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        type={type}
        sourceId={sourceId}
        updateBlockedDays={updateBlockedDays}
        workDays={workDays}
        breakTime={breakTime}
        blockedDates={blockedDates}
        dataSource={dataSource}
      />
    </>
  );
}
