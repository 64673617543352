/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './ForgotPassword.scss';

function ForgotPasswordPage({ mobile }) {
  const [loading, setLoading] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [email, setEmail] = useState('');

  const submit = async (values) => {
    setLoading(true);
    setEmail(values.email);
    await api
      .put(`/User/ForgotPassword?Email=${values.email}`)
      .then(() => {
        setShowSuccessScreen(true);
        message.success('Mensagem enviada!');
        window.location.href = '/Login';
      })
      .catch((error) => {
        setLoading(false);
        message.warning('Não encontramos nenhuma conta com este email.');
        Utils.logError(error);
      });
  };

  return (
    <Row className={`wrapper-login ${mobile ? 'wrapper-login-mobile' : ''}`}>
      <Col className="left-panel" span={mobile ? 30 : 11}>
        <Row justify="center" align="middle" className="wrapper-logo">
          <Col span={12} />
        </Row>
      </Col>
      <Col className="right-panel" span={mobile ? 30 : 13}>
        {showSuccessScreen ? (
          <div className="wrapper-form">
            <div className={`screen-success ${mobile ? 'screen-success-mobile' : ''}`}>
              <h1>Email enviado</h1>
              <p>Enviamos um email com instruções de como</p>
              <p>
                redefinir sua senha para <strong>{email}</strong>. Se
              </p>
              <p>você não encontrar o email na sua caixa de entrada,</p>
              <p>verifique a lixeira ou a pasta de spam.</p>
            </div>
          </div>
        ) : (
          <Row justify="center" align="middle" className="wrapper-form">
            <Form
              name="login"
              disabled={loading}
              layout="vertical"
              autoComplete="off"
              onFinish={submit}
              style={mobile ? { width: '80%' } : {}}
            >
              <h2>Recuperar Senha</h2>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button loading={loading} block type="primary" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default ForgotPasswordPage;
