import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './Login.scss';

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const authenticateCredentials = async (values) => {
    setLoading(true);
    // Requisição
    await api
      .post('/Authentication', values)
      .then((res) => {
        if (res.status === 200) {
          const date = new Date();
          date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 dias
          date.toUTCString();

          // Cria os cookies
          if (values.remember) {
            document.cookie = `conecta__refreshToken=${res.data.refreshToken}; expires=${date}; path=/; SameSite=none; secure;`;
          }

          // Cria a "session"
          localStorage.setItem('conecta__userData', JSON.stringify(res.data.userData));
          localStorage.setItem('conecta__permissions', JSON.stringify(res.data.role));
          localStorage.setItem('conecta__token', res.data.token);

          if (localStorage.getItem('conecta__permissions') !== '') {
            window.location.href = '/';
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Usuário não encontrado!');
        Utils.logError(error);
      });
  };

  return (
    <Row className="wrapper-login">
      <Col className="left-panel" span={11} />
      <Col className="right-panel" span={13}>
        <Row justify="center" align="middle" className="wrapper-form">
          <Form
            disabled={loading}
            name="login"
            layout="vertical"
            onFinish={authenticateCredentials}
            autoComplete="off"
          >
            <h2>Login</h2>
            <Form.Item
              label="Login"
              name="login"
              rules={[
                {
                  required: true,
                  message: 'Preencha com seu login!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Preencha com sua senha!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="forgotPassword" style={{ float: 'right' }}>
              <Button
                type="link"
                style={{ border: 'none', boxShadow: 'none' }}
                onClick={() => navigate('/RecuperarSenha')}
              >
                Esqueceu a senha?
              </Button>
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Lembre de mim</Checkbox>
            </Form.Item>

            <Form.Item style={{ marginBottom: 8 }}>
              <Button loading={loading} block type="primary" htmlType="submit">
                Entrar
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: 'center' }}>
              <Button
                type="link"
                style={{ border: 'none', boxShadow: 'none' }}
                onClick={() => navigate('/Instrutor')}
              >
                Pré-Cadastro Instrutor
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
}

export default Login;
