/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';

import { DeleteOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons';

const stagesNoConfirmation = [
  3, // 3 = Confirmado
  4, // 4 = Negado
  5, // 5 = Concluido
  9, // 9 = Aguardando Conclusão
  14, // 14 = Aguardando Documentação
];

const stagesNoDeny = [
  3, // 3 = Confirmado
  4, // 4 = Negado
  5, // 5 = Concluido
  9, // 9 = Aguardando Conclusão
  13, // 13 = Reservado Sem Confirmação
  14, // 14 = Aguardando Documentação
];

const stagesNoContactClient = [
  3, // 3 = Confirmado
  4, // 4 = Negado
  5, // 5 = Concluido
  9, // 9 = Aguardando Conclusão
  13, // 13 = Reservado Sem Confirmação
  14, // 14 = Aguardando Documentação
];

const stagesNoDelete = [
  5, // 5 = Concluido
  9, // 9 = Aguardando Conclusão
  14, // 14 = Aguardando Documentação
];

const stagesNoEdit = [
  5, // 5 = Concluido
];

export default function ActionButtonsComponent({
  selectedRowKeys,
  taskList,
  editingIds,
  fieldsPermissions,
  handleActionClick,
  setIsModalDeletingVisible,
  setIsModalAttachFilesVisible,
  setEditingIds,
  handleSubmit,
}) {
  // Monta lista com Treinamentos Selecionados
  const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));

  // Regras para Exibir/Esconder os botões
  const showConfirmationBtn = selectedTaskList.every(
    ({ stage }) => !stagesNoConfirmation.includes(stage.id)
  );
  const showDenyBtn = selectedTaskList.every(({ stage }) => !stagesNoDeny.includes(stage.id));
  const showNoContactClientBtn = selectedTaskList.every(
    ({ stage }) => !stagesNoContactClient.includes(stage.id)
  );
  const showDeleteBtn = selectedTaskList.every(({ stage }) => !stagesNoDelete.includes(stage.id));
  const showEditBtn = selectedTaskList.every(({ stage }) => !stagesNoEdit.includes(stage.id));

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      {fieldsPermissions?.ConfirmBtn?.access >= 1 && (
        <Button
          style={{
            backgroundColor: '#5cb85c', // Verde
            color: 'white',
            border: 'none',
            opacity:
              selectedRowKeys.length === 0 || editingIds.length > 0 || !showConfirmationBtn
                ? 0.3
                : 1,
          }}
          onClick={() => {
            if (selectedRowKeys.length > 0 && editingIds.length === 0 && showConfirmationBtn) {
              handleActionClick('confirm');
            }
          }}
        >
          Confirmar
        </Button>
      )}

      {/* Negar */}
      {fieldsPermissions?.DenyBtn?.access >= 1 && (
        <Button
          style={{
            backgroundColor: '#d9534f', // Vermelho
            color: 'white',
            border: 'none',
            opacity:
              selectedRowKeys.length === 0 || editingIds.length > 0 || !showDenyBtn ? 0.3 : 1,
          }}
          onClick={() => {
            if (selectedRowKeys.length > 0 && editingIds.length === 0 && showDenyBtn) {
              handleActionClick('deny');
            }
          }}
        >
          Negar
        </Button>
      )}

      {/* Sem contato com cliente */}
      {fieldsPermissions?.NoClientContactBtn?.access >= 1 && (
        <Button
          style={{
            backgroundColor: '#fff',
            color: '#333',
            border: 'none',
            opacity:
              selectedRowKeys.length === 0 || editingIds.length > 0 || !showNoContactClientBtn
                ? 0.3
                : 1,
          }}
          onClick={() => {
            if (selectedRowKeys.length > 0 && editingIds.length === 0 && showNoContactClientBtn) {
              handleActionClick('noClientContact');
            }
          }}
        >
          Sem contato com cliente
        </Button>
      )}

      {/* Anexar Arquivos */}
      {fieldsPermissions?.RemoveBtn?.access >= 1 && (
        <Button
          style={{
            color: '#121212',
            border: 'none',
            boxShadow: 'none',
            opacity:
              selectedRowKeys.length === 0 || editingIds.length > 0 || !showEditBtn ? 0.3 : 1,
          }}
          onClick={() => {
            if (selectedRowKeys.length > 0 && editingIds.length === 0 && showEditBtn) {
              setIsModalAttachFilesVisible(true);
            }
          }}
          type="link"
          icon={<FileAddOutlined />}
        >
          Anexar Arquivos
        </Button>
      )}

      {/* Remover */}
      {fieldsPermissions?.RemoveBtn?.access >= 1 && (
        <Button
          style={{
            color: '#121212',
            border: 'none',
            boxShadow: 'none',
            opacity:
              selectedRowKeys.length === 0 || editingIds.length > 0 || !showDeleteBtn ? 0.3 : 1,
          }}
          onClick={() => {
            if (selectedRowKeys.length > 0 && editingIds.length === 0 && showDeleteBtn) {
              setIsModalDeletingVisible(true);
            }
          }}
          type="link"
          icon={<DeleteOutlined />}
        >
          Remover
        </Button>
      )}

      {/* Editar */}
      {fieldsPermissions?.EditBtn?.access >= 1 && (
        <Button
          style={{
            color: '#121212',
            border: 'none',
            boxShadow: 'none',
            opacity:
              selectedRowKeys.length === 0 || editingIds.length > 0 || !showEditBtn ? 0.3 : 1,
          }}
          onClick={() => {
            if (selectedRowKeys.length > 0 && editingIds.length === 0 && showEditBtn) {
              setEditingIds(selectedRowKeys);
            }
          }}
          type="link"
          icon={<EditOutlined />}
        >
          Editar
        </Button>
      )}

      {/* Salvar */}
      {editingIds.length > 0 && (
        <Button
          style={{
            backgroundColor: '#5cb85c', // Verde
            color: 'white',
            border: 'none',
          }}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      )}

      {/* Cancelar */}
      {editingIds.length > 0 && (
        <Button
          style={{ backgroundColor: '#fff', color: '#333', border: 'none' }}
          onClick={() => setEditingIds([])}
        >
          Cancelar
        </Button>
      )}
    </div>
  );
}
