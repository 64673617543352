/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';

import ScheduleUtils from './ScheduleUtils';

export default function OverviewCell({
  itemData: {
    data: { appointmentData },
  },
  blockedDaysByCT,
  workDaysByCT,
  trainingCenters,
}) {
  const values = appointmentData.text.split('/');
  const tasks = parseInt(values[0], 10);
  const slots = parseInt(values[1], 10);

  const [{ workDays }] = workDaysByCT[appointmentData.trainingCenterId];
  const blockedDates =
    Object.keys(blockedDaysByCT).length > 0
      ? blockedDaysByCT[appointmentData.trainingCenterId]
      : null;

  const isWorkDay = ScheduleUtils.isDisableDate(new Date(appointmentData.startDate), workDays);
  const isBlockedDay = blockedDates
    ? ScheduleUtils.isBlockedDay(new Date(appointmentData.startDate), blockedDates)
    : false;
  if (isWorkDay || isBlockedDay) {
    appointmentData.isBlocked = true;
    return <div className="overview-cell blocked" />;
  }
  appointmentData.isBlocked = false;

  const {
    ctQtyColumns,
    ctQtySlots,
    inCompanyQtyColumns,
    inCompanyQtySlots,
    eadQtyColumns,
    eadQtySlots,
    serviceQtyColumns,
    serviceQtySlots,
  } = trainingCenters?.find(({ id }) => id === appointmentData.trainingCenterId) ?? {};

  let color = '#fff';

  const hasCtSlots = ctQtyColumns && ctQtySlots;
  const hasInCompanySlots = inCompanyQtyColumns && inCompanyQtySlots;
  const hasEadSlots = eadQtyColumns && eadQtySlots;
  const hasServiceSlots = serviceQtyColumns && serviceQtySlots;

  if (hasCtSlots || hasInCompanySlots || hasEadSlots || hasServiceSlots) {
    appointmentData.hasSlot = true;

    if (tasks === 0) {
      color = '#3BB537';
    } else if (tasks === slots) {
      color = '#FC5D20';
    } else if (tasks > 0 && tasks < slots) {
      color = '#FABC2C';
    }
  } else {
    appointmentData.hasSlot = false;
    return <div className="overview-cell no-slot-configured" />;
  }

  return (
    <div className="overview-cell" style={{ backgroundColor: color }}>
      {appointmentData.text}
    </div>
  );
}
