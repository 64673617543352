/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

function TaskDrawerFooterComponent({
  fieldsPermissions,
  task,
  isLoading,
  handleActionUpdate,
  setIsEditing,
  form,
  render,
  setIsConfirmationModalOpen,
  setConfirmationModalConfirmAction,
}) {
  const [shouldDisableConfirmButton, setShouldDisableConfirmButton] = useState(false);
  const [shouldDisableNoContactButton, setShouldDisableNoContactButton] = useState(false);

  const stagesNoConfirmation = [
    3, // Confirmado
    4, // Negado
    5, // Concluído
    9, // Aguardando Conclusão
    14, // Aguardando Documentação
  ];

  const stagesNoDeny = [
    3, // Confirmado
    4, // Negado
    5, // Concluído
    9, // Aguardando Conclusão
    13, // Reservado Sem Confirmação
    14, // Aguardando Documentação
  ];

  const stagesNoContactClient = [
    3, // Confirmado
    4, // Negado
    5, // Concluído
    9, // Aguardando Conclusão
    13, // Reservado Sem Confirmação
    14, // Aguardando Documentação
  ];

  const fieldsToConfirm = [
    'mainInstructor', // Instrutor
    'instructorValue', // Valor Instrutor
    'startHour', // Horário de Início
    'responsiblePersonNameLogistic', // Nome Responsável
    'responsiblePersonPhoneLogistic', // Telefone Responsável
    'responsiblePersonEmailLogistic', // E-mail Responsável
    'nameResponsibleConfirmation', // Nome Responsável Confirmação
    'emailResponsibleConfirmation', // E-mail Responsável Confirmação
    'phoneResponsibleConfirmation', // Telefone Responsável Confirmação
    'transportCompany', // Empresa de Transporte
    'vehicleList', // Veículos
    'driverList', // Motoristas
    'transportValue', // Valor Transporte
    'boardingHour', // Horário de Embarque
    'postalCodeLogistic', // CEP
    'streetLogistic', // Rua
    'numberLogistic', // Número
    'districtLogistic', // Bairro
    'cityLogistic', // Cidade
    'stateLogistic', // Estado
  ];

  const shouldShowConfirmButton =
    !stagesNoConfirmation.includes(task.stage.id) &&
    fieldsPermissions?.LogisticConfirm?.access >= 1;

  const shouldShowNoContactButton =
    !stagesNoContactClient.includes(task.stage.id) &&
    fieldsPermissions?.LogisticNoClientContact?.access >= 1;

  const shouldShowDenyButton =
    !stagesNoDeny.includes(task.stage.id) && fieldsPermissions?.LogisticDeny?.access >= 1;

  const areAllFieldsFilled = (fields) => {
    const taskData = form.getFieldsValue();

    return fields.every((field) => {
      const value = taskData[field];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== undefined && value !== null && value !== '';
    });
  };

  const updateButtonStates = () => {
    const allFieldsFilled = areAllFieldsFilled(fieldsToConfirm);

    setShouldDisableConfirmButton(!allFieldsFilled);
    setShouldDisableNoContactButton(!allFieldsFilled);
  };

  useEffect(() => {
    updateButtonStates();
  }, [render]);

  const buttons = [
    <Button
      key="save"
      style={{
        backgroundColor: '#5cb85c', // Verde
        color: 'white',
        border: 'none',
        opacity: isLoading ? 0.3 : 1,
      }}
      loading={isLoading}
      onClick={() => handleActionUpdate('save')}
    >
      Salvar
    </Button>,
  ];

  if (shouldShowConfirmButton) {
    buttons.push(
      <Button
        key="confirm"
        style={{
          backgroundColor: '#5cb85c', // Verde
          color: 'white',
          border: 'none',
          opacity: isLoading || shouldDisableConfirmButton ? 0.3 : 1,
        }}
        loading={isLoading}
        onClick={() => {
          setConfirmationModalConfirmAction('confirm');
          setIsConfirmationModalOpen(true);
        }}
        disabled={shouldDisableConfirmButton}
      >
        Confirmar
      </Button>
    );
  }

  if (shouldShowDenyButton) {
    buttons.push(
      <Button
        key="deny"
        style={{
          backgroundColor: '#d9534f', // Vermelho
          color: 'white',
          border: 'none',
          opacity: isLoading ? 0.3 : 1,
        }}
        loading={isLoading}
        onClick={() => handleActionUpdate('deny')}
      >
        Negar
      </Button>
    );
  }

  if (shouldShowNoContactButton) {
    buttons.push(
      <Button
        key="no-contact"
        style={{
          backgroundColor: '#fff',
          color: '#333',
          border: 'none',
          opacity: isLoading || shouldDisableNoContactButton ? 0.3 : 1,
          boxShadow: '0px 4px 4px #00000040',
        }}
        loading={isLoading}
        onClick={() => {
          setConfirmationModalConfirmAction('noClientContact');
          setIsConfirmationModalOpen(true);
        }}
        disabled={shouldDisableNoContactButton}
      >
        Sem contato com cliente
      </Button>
    );
  }

  buttons.push(
    <Button
      key="cancel"
      style={{
        backgroundColor: '#fff',
        color: '#333',
        border: 'none',
        opacity: isLoading ? 0.3 : 1,
      }}
      loading={isLoading}
      onClick={() => setIsEditing(false)}
    >
      Cancelar
    </Button>
  );

  return buttons;
}

export default TaskDrawerFooterComponent;
