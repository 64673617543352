/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import Utils from '../Assets/Scripts/Utils';

const apiNoToken = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
  withCredentials: true,
});

const api = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
  withCredentials: true,
});

// Método para atualiza o token
const refreshAccessToken = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const userData = JSON.parse(localStorage.getItem('conecta__userData'));
    const refreshTokenCookie = Utils.getCookie('conecta__refreshToken');

    const { data: newToken } = await apiNoToken.post(
      `/Authentication/RefreshToken?refreshToken=${refreshTokenCookie}&userId=${userData?.id}`
    );

    // Preenche o Storage e a instância do axios com o novo token
    localStorage.setItem('conecta__token', newToken);
    api.defaults.headers.common.Authorization = `Bearer ${newToken}`;

    return newToken;
  } catch (error) {
    throw error;
  }
};

// Método para tratar o erro de atualização do token
const handleRefreshTokenError = () => {
  // Limpa o Storage e redireciona para tela de Login
  localStorage.removeItem('conecta__token');
  localStorage.removeItem('conecta__permissions');
  localStorage.removeItem('conecta__userData');
  localStorage.removeItem('conecta__scheduleData');
  localStorage.removeItem('conecta__currentView');
  localStorage.removeItem('conecta__menuPreference');
  window.location.href = '/Login';
};

api.interceptors.request.use((config) => {
  // Exibe o spinner
  document.body.classList.add('loading-indicator');

  return config;
});

api.interceptors.response.use(
  (response) => {
    // Esconde o spinner
    document.body.classList.remove('loading-indicator');

    return response;
  },
  async (error) => {
    // Esconde o spinner
    document.body.classList.remove('loading-indicator');

    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const hasCookies = Utils.hasCookies();

      if (!hasCookies) {
        handleRefreshTokenError();
        return Promise.reject(error);
      }

      try {
        // Tenta atualizar o token
        const newToken = await refreshAccessToken();

        // Atualiza o token no request e realiza novamente
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        return api(originalRequest);
      } catch (error) {
        handleRefreshTokenError();
      }
    }

    throw error.response.data;
  }
);

export { api, apiNoToken };
