/* eslint-disable react/prop-types */
import React from 'react';
import { Badge } from 'antd';

import { ShareAltOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';

export default function DraggableTask({ itemData }) {
  return (
    <div
      id="draggable-task"
      style={{ backgroundColor: Utils.hexToRgbA(itemData.stage.color, 0.5) }}
    >
      <div className="left-bar" style={{ backgroundColor: itemData.stage.color }} />
      <h5 className="class-id">{itemData.cardTitle}</h5>
      <p className="card-text">{itemData.text}</p>
      <p className="card-text">{itemData.dealClientCommercialName}</p>
      <p className="card-text">Alunos: {itemData.qtyStudentsPerClass}</p>
      <p className="card-text">{itemData.trainingType}</p>
      <p className="card-text">{itemData.stage.name}</p>
      {itemData.quoteOrDealUpdated && (
        <Badge className="updated-badge" status="default" text="Atualizado" />
      )}
      <div className="right-bar">{itemData.sharedTraining && <ShareAltOutlined />}</div>
    </div>
  );
}
