/* eslint-disable react/prop-types */
import React from 'react';
import { Col, message, Tooltip } from 'antd';

import { CopyOutlined } from '@ant-design/icons';

function Placeholder({ label, name, searchText }) {
  const shouldDisplay = label.toLowerCase().includes(searchText.toLowerCase());

  if (!shouldDisplay) return null;

  return (
    <Col span={12}>
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Tooltip title="Copiar">
          <CopyOutlined
            onClick={() => {
              navigator.clipboard
                .writeText(name)
                .then(() => {
                  message.info('Copiado para área de transferência!');
                })
                .catch((err) => {
                  console.error(err);
                  message.error('Oops. Algo deu errado ao copiar o campo!');
                });
            }}
          />
        </Tooltip>
        <strong>{label}</strong>
        <span>{'=>'}</span>
        <i>{name}</i>
      </div>
    </Col>
  );
}

export default Placeholder;
