/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  TimePicker,
} from 'antd';

import Utils from '../../../../Assets/Scripts/Utils';
import CustomInstructorSelect from '../../../../Components/Schedule/CustomInstructorSelect';
import StageTagComponent from '../../../TaskList/Components/StageTagComponent';
import UploadFileField from '../UploadFileField';

function FormGeneralFieldsComponent({
  fieldsPermissions,
  form,
  task,
  stages,
  combinedInstructorList,
  trainingsOptions,
  handleTaskUpdate,
  setUploadFileType,
  setUploadFileModalOpen,
  isTaskReadOnly,
  isEditing,
}) {
  return (
    <Col span={24}>
      <Card title="Geral" bordered={false} className="task-form-section-card">
        {/* Campo Produto */}
        {fieldsPermissions?.Product.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Produto" name={['product', 'name']}>
              <Input disabled placeholder="o campo está vazio" />
            </Form.Item>
          </Col>
        )}

        {/* Complemento */}
        {fieldsPermissions?.Product.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Complemento" name={['product', 'complement']}>
              <Input disabled placeholder="o campo está vazio" />
            </Form.Item>
          </Col>
        )}

        {/* Produto - Complemento */}
        {fieldsPermissions?.Product.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Produto - Complemento" name="productWithComplement">
              <Input disabled placeholder="o campo está vazio" />
            </Form.Item>
          </Col>
        )}

        {/* Estágio */}
        {fieldsPermissions?.Stage.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Estágio"
              name={['stage', 'id']}
              rules={[
                {
                  required: fieldsPermissions?.Stage.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(_, object) => object}
            >
              <Select
                options={stages.map((stage) => ({
                  label: stage.name,
                  value: stage.id,
                }))}
                allowClear
                placeholder="o campo está vazio"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                disabled
                style={{ display: 'none' }}
              />
              <StageTagComponent stage={task.stage} />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.Date.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Data"
              name="startDate"
              rules={[
                {
                  required: fieldsPermissions?.Date.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{
                  borderRadius: 16,
                }}
                disabled
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Horário de Início */}
        {fieldsPermissions?.StartHour.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Horário Início"
              name="startHour"
              rules={[
                {
                  required: fieldsPermissions?.StartHour.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <TimePicker
                format="HH:mm"
                style={{ borderRadius: 16 }}
                disabled={fieldsPermissions?.StartHour.access !== 2 || isTaskReadOnly || !isEditing}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.EndHour.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Horário Fim"
              name="endHour"
              rules={[
                {
                  required: fieldsPermissions?.EndHour.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <TimePicker
                format="HH:mm"
                style={{ borderRadius: 16 }}
                disabled={fieldsPermissions?.EndHour.access !== 2 || isTaskReadOnly || !isEditing}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Recurso */}
        {fieldsPermissions?.Resource.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Recurso" name="resource">
              <Input disabled placeholder="o campo está vazio" />
            </Form.Item>
          </Col>
        )}

        {/* Campo Instrutor */}
        {fieldsPermissions?.MainInstructor.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Instrutor"
              name="mainInstructor"
              rules={[
                {
                  required: fieldsPermissions?.MainInstructor.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(value) => {
                if (!value) return value;

                const selectedInstructor = combinedInstructorList.find((item) => item.id === value);

                // 1 = Estágio Aguardando
                const waitingStage = stages.find(({ id }) => id === 1);

                return {
                  ...selectedInstructor,
                  userId: selectedInstructor.id,
                  userName: selectedInstructor.name,
                  stage: waitingStage,
                };
              }}
            >
              <CustomInstructorSelect
                type="mainInstructor"
                taskData={task}
                options={combinedInstructorList}
                fieldsPermissions={fieldsPermissions}
                isTaskReadOnly={isTaskReadOnly}
                isEditing={isEditing}
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Instrutor Auxiliar */}
        {fieldsPermissions?.AssistantInstructor.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Instrutor Auxiliar"
              name="assistantInstructor"
              rules={[
                {
                  required: fieldsPermissions?.AssistantInstructor.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(value) => {
                if (!value) return value;

                const selectedInstructor = combinedInstructorList.find((item) => item.id === value);

                // 1 = Estágio Aguardando
                const waitingStage = stages.find(({ id }) => id === 1);

                return {
                  ...selectedInstructor,
                  userId: selectedInstructor.id,
                  userName: selectedInstructor.name,
                  stage: waitingStage,
                };
              }}
            >
              <CustomInstructorSelect
                type="assistantInstructor"
                taskData={task}
                options={combinedInstructorList}
                fieldsPermissions={fieldsPermissions}
                isTaskReadOnly={isTaskReadOnly}
                isEditing={isEditing}
              />
            </Form.Item>
          </Col>
        )}

        {/* Valores dos Instrutores */}
        {fieldsPermissions?.InstructorValue.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Valor Instrutor"
              name="instructorValue"
              rules={[
                {
                  required: fieldsPermissions?.InstructorValue.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <InputNumber
                min={0}
                step={0.0001}
                formatter={Utils.priceFormatter}
                parser={Utils.priceParser}
                style={{ width: '100%' }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.AssistantInstructorValue.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Valor Instrutor Auxiliar"
              name="assistantInstructorValue"
              rules={[
                {
                  required: fieldsPermissions?.AssistantInstructorValue.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <InputNumber
                min={0}
                step={0.0001}
                formatter={Utils.priceFormatter}
                parser={Utils.priceParser}
                style={{ width: '100%' }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo de Treinamentos */}
        {fieldsPermissions?.Group.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Treinamentos"
              name={['group', 'id']}
              rules={[
                {
                  required: fieldsPermissions?.Group.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(_, object) => object}
            >
              <Select
                options={trainingsOptions.map((trainingsOptions) => ({
                  label: trainingsOptions.name,
                  value: trainingsOptions.id,
                }))}
                allowClear
                placeholder="o campo está vazio"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                disabled={fieldsPermissions?.Group.access !== 2 || isTaskReadOnly || !isEditing}
              />
            </Form.Item>
          </Col>
        )}

        {/* Campos de Texto */}
        {fieldsPermissions?.QuoteTitle.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Nome do Negócio"
              name="quoteTitle"
              rules={[
                {
                  required: fieldsPermissions?.QuoteTitle.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.QuoteTitle.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.TrainingTransportation.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Transporte Oficial"
              name="trainingTransportation"
              rules={[
                {
                  required: fieldsPermissions?.TrainingTransportation.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.TrainingTransportation.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.QuoteResponsible.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Responsável pelo Negócio"
              name="quoteResponsible"
              rules={[
                {
                  required: fieldsPermissions?.QuoteResponsible.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.QuoteResponsible.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealOportunity.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Valor da Oportunidade"
              name="dealOportunity"
              rules={[
                {
                  required: fieldsPermissions?.DealOportunity.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealOportunity.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealId.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Número Negócio"
              name="dealId"
              rules={[
                {
                  required: fieldsPermissions?.DealId.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={fieldsPermissions?.DealId.access !== 2 || isTaskReadOnly || !isEditing}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealTitle.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Nome do Negócio"
              name="dealTitle"
              rules={[
                {
                  required: fieldsPermissions?.DealTitle.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={fieldsPermissions?.DealTitle.access !== 2 || isTaskReadOnly || !isEditing}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealClient.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Cliente"
              name="dealClient"
              rules={[
                {
                  required: fieldsPermissions?.DealClient.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealClient.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealContractedCompany.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Empresa Contratada"
              name="dealContractedCompany"
              rules={[
                {
                  required: fieldsPermissions?.DealContractedCompany.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealContractedCompany.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealTrainingCenterAdress.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Endereço do CT para Treinamento"
              name="dealTrainingCenterAddress"
              rules={[
                {
                  required: fieldsPermissions?.DealTrainingCenterAdress.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealTrainingCenterAdress.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealPaymentCondition.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Condição de Pagamento"
              name="dealPaymentCondition"
              rules={[
                {
                  required: fieldsPermissions?.DealPaymentCondition.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealPaymentCondition.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DealPaymentForm.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Forma de Pagamento"
              name="dealPaymentForm"
              rules={[
                {
                  required: fieldsPermissions?.DealPaymentForm.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealPaymentForm.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Ordem de Compra Obrigatória */}
        {fieldsPermissions?.ObrigatoryBuyOrder.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Ordem de Compra Obrigatória"
              name="obrigatoryBuyOrder"
              rules={[
                {
                  required: fieldsPermissions?.ObrigatoryBuyOrder.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ObrigatoryBuyOrder.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Data Limite para Faturamento */}
        {fieldsPermissions?.DealEndDateInvoice.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Data Limite para Faturamento Oficial"
              name="dealEndDateInvoice"
              rules={[
                {
                  required: fieldsPermissions?.DealEndDateInvoice.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealEndDateInvoice.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Retenção Tributária */}
        {fieldsPermissions?.DealTributaryRetention.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Retenção Tributária"
              name="dealTributaryRetention"
              rules={[
                {
                  required: fieldsPermissions?.DealTributaryRetention.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealTributaryRetention.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Cliente Prioritário */}
        {fieldsPermissions?.DealPrioritaryClient.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Cliente Prioritário"
              name="dealPrioritaryClient"
              rules={[
                {
                  required: fieldsPermissions?.DealPrioritaryClient.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealPrioritaryClient.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Observação Específica */}
        {fieldsPermissions?.DealClientObservation.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Observação Específica"
              name="dealClientObservation"
              rules={[
                {
                  required: fieldsPermissions?.DealClientObservation.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input.TextArea
                disabled={
                  fieldsPermissions?.DealClientObservation.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Forma de Pagamento Negociada */}
        {fieldsPermissions?.DealPaymentFormNegotiated.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Forma de Pagamento Negociada"
              name="dealPaymentFormNegotiated"
              rules={[
                {
                  required: fieldsPermissions?.DealPaymentFormNegotiated.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealPaymentFormNegotiated.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Condição de Pagamento Negociada */}
        {fieldsPermissions?.DealPaymentConditionNegotiated.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Condição de Pagamento Negociada"
              name="dealPaymentConditionNegotiated"
              rules={[
                {
                  required: fieldsPermissions?.DealPaymentConditionNegotiated.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DealPaymentConditionNegotiated.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Grupo de Treinamento */}
        {fieldsPermissions?.Group.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Grupo de Treinamento"
              name="group.name"
              rules={[
                {
                  required: fieldsPermissions?.Group.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={fieldsPermissions?.Group.access !== 2 || isTaskReadOnly || !isEditing}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Quantidade de Turmas */}
        {fieldsPermissions?.QtyClass.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Quantidade de Turmas"
              name="qtyClass"
              rules={[
                {
                  required: fieldsPermissions?.QtyClass.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={fieldsPermissions?.QtyClass.access !== 2 || isTaskReadOnly || !isEditing}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Alunos por Turma */}
        {fieldsPermissions?.QtyStudentsPerClass.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Alunos por Turma"
              name="qtyStudentsPerClass"
              rules={[
                {
                  required: fieldsPermissions?.QtyStudentsPerClass.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.QtyStudentsPerClass.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Alunos Treinados */}
        {fieldsPermissions?.StudentsTrained.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Alunos Treinados"
              name="qtyStudentsTrained"
              rules={[
                {
                  required: fieldsPermissions?.StudentsTrained.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.StudentsTrained.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Alunos Transportados */}
        {fieldsPermissions?.StudentsTransported.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Alunos Transportados"
              name="qtyStudentsTransported"
              rules={[
                {
                  required: fieldsPermissions?.StudentsTransported.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.StudentsTransported.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Centro de Treinamento */}
        {fieldsPermissions?.TrainingCenter.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Centro de Treinamento"
              name="trainingCenter"
              rules={[
                {
                  required: fieldsPermissions?.TrainingCenter.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.TrainingCenter.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Data do Agendamento */}
        {fieldsPermissions?.DateScheduling.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Data do Agendamento"
              name="dateScheduling"
              rules={[
                {
                  required: fieldsPermissions?.DateScheduling.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{
                  borderRadius: 16,
                }}
                disabled={
                  fieldsPermissions?.DateScheduling.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Lista de Presença Inicial */}
        {fieldsPermissions?.InitialAttendanceList?.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Lista de Presença Inicial" name="initialAttendanceDocList">
              <UploadFileField
                handleTaskUpdate={handleTaskUpdate}
                form={form}
                task={task}
                fieldName="initialAttendanceDocList"
                fieldPermission={fieldsPermissions?.InitialAttendanceList}
                isTaskReadOnly={isTaskReadOnly}
                isEditing={isEditing}
                setUploadFileType={setUploadFileType}
                setUploadFileModalOpen={setUploadFileModalOpen}
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Lista de Presença Final  */}
        {fieldsPermissions?.FinalAttendanceList.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Lista de Presença Final" name="finalAttendanceDocList">
              <UploadFileField
                handleTaskUpdate={handleTaskUpdate}
                form={form}
                task={task}
                fieldName="finalAttendanceDocList"
                fieldPermission={fieldsPermissions?.FinalAttendanceList}
                isTaskReadOnly={isTaskReadOnly}
                isEditing={isEditing}
                setUploadFileType={setUploadFileType}
                setUploadFileModalOpen={setUploadFileModalOpen}
              />
            </Form.Item>
          </Col>
        )}

        {/* Formulário do Instrutor  */}
        {fieldsPermissions?.InstructorForm.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Formulário do Instrutor" name="instructorForm">
              <UploadFileField
                handleTaskUpdate={handleTaskUpdate}
                form={form}
                task={task}
                fieldName="instructorForm"
                fieldPermission={fieldsPermissions?.InstructorForm}
                isTaskReadOnly={isTaskReadOnly}
                isEditing={isEditing}
                setUploadFileType={setUploadFileType}
                setUploadFileModalOpen={setUploadFileModalOpen}
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Pedido de Compra */}
        {fieldsPermissions?.DealBuyOrder.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Pedido de Compra">
              {form.getFieldValue('downloadDealBuyOrder') ? (
                <a
                  href={form.getFieldValue('downloadDealBuyOrder')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Abrir
                </a>
              ) : (
                <p style={{ color: 'orange' }}>Pendente</p>
              )}
            </Form.Item>
          </Col>
        )}

        {/* Campo Termo de Aceite */}
        {fieldsPermissions?.AcceptTerm1.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Termo de Aceite">
              {form.getFieldValue('downloadAcceptTerm1') ? (
                <a
                  href={form.getFieldValue('downloadAcceptTerm1')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Abrir
                </a>
              ) : (
                <p style={{ color: 'orange' }}>Pendente</p>
              )}
            </Form.Item>
          </Col>
        )}

        {/* Termo de Aceite Assinado */}
        {fieldsPermissions?.AcceptTerm2.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Termo de Aceite Assinado">
              {form.getFieldValue('downloadAcceptTerm2') ? (
                <a
                  href={form.getFieldValue('downloadAcceptTerm2')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Abrir
                </a>
              ) : (
                <p style={{ color: 'orange' }}>Pendente</p>
              )}
            </Form.Item>
          </Col>
        )}

        {/* Data de Recebimento do Termo Assinado */}
        {fieldsPermissions?.DateUploadAcceptTerm2.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Data de Recebimento do Termo Assinado"
              name="dateUploadAcceptTerm2"
              rules={[
                {
                  required: fieldsPermissions?.DateUploadAcceptTerm2.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{
                  borderRadius: 16,
                }}
                disabled={
                  fieldsPermissions?.DateUploadAcceptTerm2.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Documentação Enviada */}
        {fieldsPermissions?.HasDocumentation.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Documentação Enviada" name="hasDocumentation" valuePropName="checked">
              <Checkbox
                disabled={
                  fieldsPermissions?.HasDocumentation.access !== 2 ||
                  !form.getFieldValue('finalAttendanceDocList') ||
                  form.getFieldValue('finalAttendanceDocList')?.length === 0 ||
                  isTaskReadOnly ||
                  !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {/* Aceita Encaixe */}
        {fieldsPermissions?.AcceptSharedTraining.access !== 0 && (
          <Col span={24}>
            <Form.Item label="Aceita Encaixe" name="acceptSharedTraining" valuePropName="checked">
              <Checkbox
                disabled={
                  fieldsPermissions?.AcceptSharedTraining.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {/* Lista de Presença Verificada */}
        {fieldsPermissions?.AttendanceListChecked.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Lista de Presença Verificada"
              name="attendanceListChecked"
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  fieldsPermissions?.AttendanceListChecked.access !== 2 ||
                  !form.getFieldValue('finalAttendanceDocList') ||
                  isTaskReadOnly ||
                  !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {/* Produtos do Negócio */}
        {fieldsPermissions?.QuoteProducts.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Produtos do Negócio"
              name="quoteProducts"
              rules={[
                {
                  required: fieldsPermissions?.QuoteProducts.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input.TextArea
                disabled={
                  fieldsPermissions?.QuoteProducts.access !== 2 || isTaskReadOnly || !isEditing
                }
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Observação Negócio */}
        {fieldsPermissions?.DealNotes.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Observação Negócio"
              name="dealNotes"
              rules={[
                {
                  required: fieldsPermissions?.DealNotes.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input.TextArea
                disabled={fieldsPermissions?.DealNotes.access !== 2 || isTaskReadOnly || !isEditing}
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Observação Comercial */}
        {fieldsPermissions?.CommercialNotes.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Observação Comercial"
              name="commercialNotes"
              rules={[
                {
                  required: fieldsPermissions?.CommercialNotes.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input.TextArea
                disabled={
                  fieldsPermissions?.CommercialNotes.access !== 2 || isTaskReadOnly || !isEditing
                }
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Observação Logística */}
        {fieldsPermissions?.LogisticsNotes.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Observação Logística"
              name="logisticsNotes"
              rules={[
                {
                  required: fieldsPermissions?.LogisticsNotes.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input.TextArea
                disabled={
                  fieldsPermissions?.LogisticsNotes.access !== 2 || isTaskReadOnly || !isEditing
                }
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Observação CT */}
        {fieldsPermissions?.CtNotes.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Observação CT"
              name="ctNotes"
              rules={[
                {
                  required: fieldsPermissions?.CtNotes.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input.TextArea
                disabled={fieldsPermissions?.CtNotes.access !== 2 || isTaskReadOnly || !isEditing}
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Campo Observação Instrutor */}
        {fieldsPermissions?.InstructorNotes.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Observação Instrutor"
              name="instructorNotes"
              rules={[
                {
                  required: fieldsPermissions?.InstructorNotes.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input.TextArea
                disabled={
                  fieldsPermissions?.InstructorNotes.access !== 2 || isTaskReadOnly || !isEditing
                }
                autoSize={{ maxRows: 6 }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}
      </Card>
    </Col>
  );
}

export default FormGeneralFieldsComponent;
