/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';

import { FileExcelOutlined } from '@ant-design/icons';

import ModalUploadExcel from '../../../Components/Instructor/ModalUploadExcel';
import TableAntd from '../../../Components/TableAntd/TableAntd';

export default function ProductList() {
  const [isModalOpen, setIsModalOpen] = useState();
  const [canHandle, setCanHandle] = useState(false);

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'level',
        title: 'Nível',
        width: '15%',
      },
      {
        dataIndex: 'workload',
        title: 'Carga Horária',
        width: '10%',
      },
    ];
    return columns;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const renderExtraToolbar = () => {
    if (!canHandle) return null;

    return (
      <div style={{ marginLeft: '10px' }}>
        <Tooltip title="Importar Registro">
          <Button
            onClick={openModal}
            shape="circle"
            icon={<FileExcelOutlined />}
            style={{
              width: '100%',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          />
        </Tooltip>
      </div>
    );
  };

  useLayoutEffect(() => {
    const vehicleResource = JSON.parse(
      localStorage.getItem('conecta__permissions')
    )?.resources.find(({ name }) => name === 'Instructor');

    setCanHandle(vehicleResource.canHandle);
  }, []);

  return (
    <>
      <TableAntd
        columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
        routePermissions="Product"
        requestName="Product"
        route="/Produtos"
        cdPage="id"
        notDelete={true}
        notInactivate={true}
        extraToolbar={renderExtraToolbar()}
      />
      <ModalUploadExcel setIsOpen={setIsModalOpen} isOpen={isModalOpen} screenType="product" />
    </>
  );
}
