/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
} from 'antd';
import { Popup } from 'devextreme-react';
import moment from 'moment';

import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import SchedulingFunctions from '../../Pages/ScheduleConecta/API/SchedulingFunctions';
import { api } from '../../Services/axiosService';

import './NewSlotPopup.scss';

const { TextArea } = Input;

export default function NewSlotPopup({
  newSlotPopupRef,
  slots,
  setSlots,
  getCurrentDate,
  trainingCenter,
  bitrixProducts,
  callSlotGeneration,
}) {
  const [form] = Form.useForm();
  const [formList] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [resourceOptions, setResourceOptions] = useState([]);
  const [maxSlots, setMaxSlots] = useState(0);

  const ctId = parseInt(
    JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId,
    10
  );
  const showFooterButtons =
    JSON.parse(localStorage.getItem('conecta__userData'))?.isApprover && slots.length > 0;

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      align: 'center',
      width: 0,
      render: (date) => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      align: 'center',
      width: 110,
      render: (type) => (type === 'Service' ? 'Serviço' : type),
    },
    {
      title: 'Aprovado',
      dataIndex: 'approved',
      align: 'center',
      width: 0,
      render: (approved) =>
        approved ? <CheckCircleOutlined style={{ color: 'green', fontSize: 20 }} /> : '',
    },
    {
      title: 'Produto',
      dataIndex: 'product',
      width: '30%',
    },
    {
      title: 'Observação',
      dataIndex: 'notes',
      width: '60%',
    },
  ];

  const submit = async (values) => {
    setLoading(true);

    try {
      const data = {
        ...values,
        date: moment(new Date(moment(getCurrentDate())).setHours(12, 59, 59, 999)),
        approved: false,
        trainingCenterId: ctId,
      };

      const { data: newSlots } = await api.post('/Slot', data);
      await SchedulingFunctions.sendNotificationToApprovers();
      const newSlotsWithKeys = newSlots.map((item) => ({ ...item, key: item.id }));
      setSlots([...slots, ...newSlotsWithKeys]);

      message.success('Solicitação de slots enviada!');
      form.resetFields();
    } catch (error) {
      Utils.logError(error);
      message.error('Falha ao solicitar slots!');
    } finally {
      setLoading(false);
    }
  };

  const approveSlots = async () => {
    setLoading(true);

    const idsToApprove = selectedItems.filter(({ approved }) => !approved).map(({ id }) => id);

    await api
      .put(`/Slot?ids=${idsToApprove.toString()}`)
      .then(() => {
        slots.forEach((item) => {
          if (idsToApprove.includes(item.id)) {
            item.approved = true;
          }
        });

        const slotsApproved = slots?.map((item) => {
          item.approved = true;
          return item;
        });
        setSlots([...slotsApproved]);
        callSlotGeneration(trainingCenter, null, null, null, slotsApproved);

        formList.resetFields();
        setSelectedItems([]);
        message.success('Slots aprovados!');
        setLoading(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Falha ao aprovar slots!');
        setLoading(false);
      });
    setLoading(false);
  };

  const deleteSlots = async () => {
    setLoading(true);

    const idsToDelete = selectedItems.map(({ id }) => id);

    await api
      .delete(`/Slot?ids=${idsToDelete.toString()}`)
      .then(() => {
        slots.forEach(({ id }, index) => {
          if (idsToDelete.includes(id)) {
            slots.splice(index, 1);
          }
        });

        const updatedSlots = slots.filter(({ id }) => !idsToDelete.includes(id));
        setSlots([...updatedSlots]);
        callSlotGeneration(trainingCenter, null, null, null, updatedSlots);

        formList.resetFields();
        setSelectedItems([]);
        message.success('Slots removidos!');
        setLoading(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Falha ao remover slots!');
        setLoading(false);
      });
    setLoading(false);
  };

  const calculateMaxSlots = () => {
    const type = form.getFieldValue('type');

    const slotsCt =
      slots?.filter(
        ({ type, date }) => type === 'CT' && moment(date).format('YYYY-MM-DD') === getCurrentDate()
      )?.length ?? 0;
    const slotsInCompany =
      slots?.filter(
        ({ type, date }) =>
          type === 'In Company' && moment(date).format('YYYY-MM-DD') === getCurrentDate()
      )?.length ?? 0;
    const slotsEad =
      slots?.filter(
        ({ type, date }) => type === 'EAD' && moment(date).format('YYYY-MM-DD') === getCurrentDate()
      )?.length ?? 0;
    const slotsService =
      slots?.filter(
        ({ type, date }) =>
          type === 'Service' && moment(date).format('YYYY-MM-DD') === getCurrentDate()
      )?.length ?? 0;

    switch (type) {
      case 'CT':
        setMaxSlots(
          (trainingCenter?.ctQtyColumns ?? 0) * 5 - (trainingCenter?.ctQtySlots ?? 0) - slotsCt
        );
        break;
      case 'In Company':
        setMaxSlots(
          (trainingCenter?.inCompanyQtyColumns ?? 0) * 5 -
            (trainingCenter?.inCompanyQtySlots ?? 0) -
            slotsInCompany
        );
        break;
      case 'EAD':
        setMaxSlots(
          (trainingCenter?.eadQtyColumns ?? 0) * 5 - (trainingCenter?.eadQtySlots ?? 0) - slotsEad
        );
        break;
      case 'Service':
        setMaxSlots(
          (trainingCenter?.serviceQtyColumns ?? 0) * 5 -
            (trainingCenter?.serviceQtySlots ?? 0) -
            slotsService
        );
        break;
      default:
        setMaxSlots(0);
        break;
    }
  };

  useEffect(() => {
    if (trainingCenter?.ctQtyColumns > 0) {
      resourceOptions.push({
        value: 'CT',
        label: 'CT',
      });
    }
    if (trainingCenter?.inCompanyQtyColumns > 0) {
      resourceOptions.push({
        value: 'In Company',
        label: 'In Company',
      });
    }
    if (trainingCenter?.eadQtyColumns > 0) {
      resourceOptions.push({
        value: 'EAD',
        label: 'EAD',
      });
    }
    if (trainingCenter?.serviceQtyColumns > 0) {
      resourceOptions.push({
        value: 'Service',
        label: 'Serviço',
      });
    }
    setResourceOptions([...resourceOptions]);
  }, []);

  return (
    <Popup
      ref={newSlotPopupRef}
      className="new-slot-popup"
      title={`Adicionar Slot - (${moment(getCurrentDate()).format('DD/MM/YYYY')})`}
      hideOnOutsideClick={false}
      onHidden={() => form.resetFields()}
      copyRootClassesToWrapper
    >
      <Form
        form={form}
        disabled={loading}
        name="newSlotPopup"
        layout="vertical"
        autoComplete="off"
        onFinish={submit}
      >
        <Row gutter={[24]}>
          <Col span={8}>
            <Form.Item
              label="Recurso"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Radio.Group
                options={resourceOptions}
                optionType="button"
                buttonStyle="solid"
                disabled={loading}
                onChange={calculateMaxSlots}
              />
            </Form.Item>
            <Form.Item
              label="Qtd. Slots"
              name="qtySlots"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <InputNumber min={1} max={maxSlots} />
            </Form.Item>
            <Form.Item
              label="Produto"
              name="product"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Select
                placeholder="Selecione"
                optionFilterProp="label"
                dropdownStyle={{ borderRadius: 16, zIndex: 9999 }}
                options={bitrixProducts}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="Observação" name="notes">
              <TextArea rows={9} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button loading={loading} block type="primary" htmlType="submit">
              Salvar
            </Button>
          </Col>
        </Row>

        <Divider className="configuration-divider" orientation="left" />

        <div className="new-slot-footer" style={{ display: showFooterButtons ? 'block' : 'none' }}>
          <Button
            className="approve-button"
            type="default"
            icon={<CheckCircleOutlined />}
            onClick={approveSlots}
            disabled={selectedItems?.filter(({ approved }) => !approved)?.length === 0}
          >
            Aprovar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja deletar estes slots?"
            onConfirm={deleteSlots}
            okText="Sim"
            cancelText="Não"
            overlayStyle={{
              zIndex: 9999,
            }}
            disabled={selectedItems.length === 0}
          >
            <Button
              className="delete-button"
              type="default"
              danger
              style={{ marginLeft: 10 }}
              icon={<DeleteOutlined />}
              disabled={selectedItems.length === 0}
            >
              Deletar
            </Button>
          </Popconfirm>
        </div>

        <Table
          rowSelection={{
            type: 'checkbox',
            selectedItems,
            onChange: (_id, object) => {
              setSelectedItems(object);
            },
          }}
          id="id"
          columns={columns}
          dataSource={slots}
          pagination={false}
          style={{
            marginTop: 10,
          }}
        />
      </Form>
    </Popup>
  );
}
