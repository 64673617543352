import React, { useLayoutEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Spin, Tooltip } from 'antd';
import { List } from 'devextreme-react';
import moment from 'moment';

import { LoadingOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './Billing.scss';

export default function Billing() {
  const [tasksMapped, setTasksMapped] = useState();
  const [tasksFiltered, setTasksFiltered] = useState();
  const [stageOptions, setStageOptions] = useState();
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [permissions, setPermissions] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const tasksSelected = [];

  const getBilling = async (periodStart, periodEnd) => {
    setLoading(true);
    const tasks = await api
      .get(
        `/Task?filters[0].Condition=DATE.>=&filters[0].Field=StartDate&filters[0].Value=${periodStart}&filters[1].Condition=DATE.<=&filters[1].Field=StartDate&filters[1].Value=${periodEnd}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const quotes = await api
      .get('/Quote')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const stage = await api
      .get(`/Stage`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const trainings = await api
      .get('/Trainings')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    setTasksMapped(
      tasks
        .filter(({ stage }) => stage.id !== 10)
        .map((task) => {
          const quote = quotes?.find(({ dealId }) => dealId === task.dealId);

          const taskMapped = {
            ...quote,
            ...task,
            text: task.product.name,
            startDateFormatted: task.startDate ? moment(task.startDate).format('DD/MM/YYYY') : null,
          };

          return taskMapped;
        })
    );

    setStageOptions(stage?.map(({ id, name }) => ({ label: name, value: id })));
    setTrainingsOptions(trainings?.map(({ id, name }) => ({ label: name, value: id })));
    setLoading(false);
  };

  const filterTasksLists = ({ global, stages, trainings }) => {
    const searchTerm = global
      ?.normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toUpperCase(); // Limpa e Padroniza a string

    const stagesToFilter = stages;
    const trainingsToFilter = trainings;

    const taskFiltered = tasksMapped?.filter(
      // eslint-disable-next-line array-callback-return, consistent-return
      (task) => {
        let taskTextConcat = '';
        taskTextConcat += task.classId;
        taskTextConcat += ` ${task.cardTitle}`;
        taskTextConcat += ` ${task.text}`;
        taskTextConcat += ` Alunos: ${task.qtyStudentsPerClass}`;
        taskTextConcat += ` ${task.stage.name}`;
        taskTextConcat += ` ${task.startDateFormatted ?? ''}`;
        taskTextConcat += ` ${task.dealId}`;
        taskTextConcat += ` ${task.quoteName}`;
        taskTextConcat += ` ${task.dealTitle}`;
        taskTextConcat += ` ${task.dealClient}`;
        taskTextConcat += ` ${task.dealClientCNPJ}`;
        taskTextConcat = taskTextConcat
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toUpperCase(); // Limpa e Padroniza a string

        let validTaskText = taskTextConcat.includes(searchTerm);
        let validTaskStage = stagesToFilter?.some((stage) => stage === task.stage.id);
        let validTaskTraining = trainingsToFilter?.some((training) => training === task.group.id);

        if (!global || searchTerm === '') {
          validTaskText = true;
        }

        if (!stagesToFilter || stagesToFilter.length === 0) {
          validTaskStage = true;
        }

        if (!trainingsToFilter || trainingsToFilter.length === 0) {
          validTaskTraining = true;
        }

        if (validTaskText && validTaskStage && validTaskTraining) {
          return task;
        }
      }
    );

    setTasksFiltered(taskFiltered);
  };

  const filterDateRange = () => {
    const datesToFilter = form.getFieldValue('dates');
    if (datesToFilter) {
      const startDateToFilter = moment
        .utc(new Date(datesToFilter[0]))
        ?.format('YYYY-MM-DDTHH:mm:ss');
      const endDateToFilter = moment.utc(new Date(datesToFilter[1]))?.format('YYYY-MM-DDTHH:mm:ss');
      getBilling(startDateToFilter, endDateToFilter);
    } else {
      getBilling(
        moment.utc(new Date(moment().startOf('isoWeek'))).format('YYYY-MM-DDTHH:mm:ss'),
        moment.utc(new Date(moment().endOf('isoWeek'))).format('YYYY-MM-DDTHH:mm:ss')
      );
    }
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, canView, canHandle, selfView }) => {
        permissions[name] = { canView, canHandle, selfView };
      }
    );
    setPermissions(permissions);
    getBilling(
      moment.utc(new Date(moment().startOf('isoWeek'))).format('YYYY-MM-DDTHH:mm:ss'),
      moment.utc(new Date(moment().endOf('isoWeek'))).format('YYYY-MM-DDTHH:mm:ss')
    );
  }, []);

  return (
    <>
      <Form
        form={form}
        name="Billing"
        onFinish={filterTasksLists}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={[24]}>
          <Col span={5}>
            <Form.Item label="Global" className="tooltip-globalfilter">
              <Tooltip
                placement="right"
                title="É possível filtrar por: Turma, Treinamento, Qtd. Alunos, Estágio, Data (DD/MM/AAAA), Número do Negócio, Nome do Negócio, Cliente e CNPJ."
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Form.Item>
            <Form.Item name="global">
              <Input placeholder="Filtrar Negócio" prefix={<SearchOutlined />} allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Estágios" name="stages">
              <Select
                options={stageOptions}
                allowClear
                placeholder="Selecione"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                removeIcon={false}
                mode="multiple"
                maxTagCount={1}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Treinamentos" name="trainings">
              <Select
                options={trainingsOptions}
                allowClear
                placeholder="Selecione"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                removeIcon={false}
                mode="multiple"
                maxTagCount={1}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Periodo" name="dates">
              <RangePicker
                format="DD/MM/YYYY"
                popupStyle={{ borderRadius: 16 }}
                onChange={filterDateRange}
                ranges={{
                  Hoje: [moment(), moment()],
                  'Este Mês': [moment().startOf('month'), moment().endOf('month')],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Button type="primary" htmlType="submit">
              <SearchOutlined />
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              style={{ marginTop: 20, display: permissions?.Billing?.canHandle ? '' : 'none' }}
              disabled={!permissions?.Billing?.canHandle}
            >
              Faturar
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? (
        <Row gutter={[24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '72vh',
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 56,
                    textAlign: 'center',
                  }}
                  spin
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <List
          displayExpr={(item) =>
            `${item.cardTitle} - ${item.product.name} - Alunos: ${item.qtyStudentsPerClass} - ${item.stage.name} - ${item.startDateFormatted}`
          }
          dataSource={tasksFiltered ?? tasksMapped}
          onItemClick={({ itemData }) => {
            const exist = tasksSelected?.includes(itemData);
            if (exist) {
              const index = tasksSelected?.indexOf(itemData);
              tasksSelected?.splice(index, 1);
            } else {
              tasksSelected.push(itemData);
            }
          }}
          id="billing-list"
          showSelectionControls={true}
          selectionMode="multiple"
          selectAllMode="allPages"
        />
      )}
    </>
  );
}
