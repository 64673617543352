/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Table } from 'antd';

import './ModalStyle.scss';

function ConfirmationModal({ setIsVisible, onConfirm, actionType, taskList, selectedRowKeys }) {
  const selectedTasks = taskList.filter((task) => selectedRowKeys.includes(task.id));

  const columns = [
    {
      title: 'Id Negócio',
      dataIndex: 'dealId',
      key: 'dealId',
      width: '110px',
    },
    {
      title: 'Id Turma',
      dataIndex: 'slotClassId',
      key: 'slotClassId',
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'dealClient',
      key: 'dealClient',
      width: '55%',
    },
  ];

  // Configuração do botão com base na ação
  const buttonConfig = {
    confirm: {
      text: 'Confirmado',
      textColor: '#5cb85c',
      okButtonStyle: { backgroundColor: '#5cb85c', color: 'white', border: 'none' },
    },
    deny: {
      text: 'Negado',
      textColor: '#d9534f',
      okButtonStyle: { backgroundColor: '#d9534f', color: 'white', border: 'none' },
    },
    noClientContact: {
      text: 'Sem Contato com Cliente',
      textColor: '#333',
      okButtonStyle: { backgroundColor: '#fff', color: '#333', border: 'none' },
    },
  };

  return (
    <Modal
      title="Confirmação de Ação"
      open={true}
      onOk={() => onConfirm(actionType)}
      onCancel={() => setIsVisible(false)}
      okText="Confirmar Ação"
      cancelText="Cancelar"
      width="fit-content"
      className="training-list-confirmation-modal"
      okButtonProps={{
        style: buttonConfig[actionType].okButtonStyle,
      }}
    >
      <p>
        Você está prestes a alterar os registros selecionados para{' '}
        <strong style={{ color: buttonConfig[actionType].textColor }}>
          {buttonConfig[actionType].text}
        </strong>
        . Por favor, revise os detalhes abaixo e confirme se deseja prosseguir ou cancelar a
        operação.
      </p>
      <Table columns={columns} dataSource={selectedTasks} pagination={false} rowKey="id" />
    </Modal>
  );
}

export default ConfirmationModal;
