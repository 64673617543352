/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from 'antd';

import { CarOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';

import './ListItem.scss';

export default function ListVehicleItemComponent({ data, deleteFunc }) {
  return (
    <div className="transport-list-item-wrapper">
      <Tooltip title="Nome" placement="right">
        <h3>{data.licensePlate}</h3>
      </Tooltip>

      <Tooltip title="Tipo de Veículo" placement="right">
        <p>
          <CarOutlined style={{ marginRight: 5 }} /> {data.type.name}
        </p>
      </Tooltip>

      <Tooltip title="Capacidade" placement="right">
        <p>
          <UserOutlined style={{ marginRight: 5 }} />
          {data.capacity} Pessoas
        </p>
      </Tooltip>

      <Tooltip title="Remover" placement="left" className="transport-list-item-delete-btn">
        <DeleteOutlined onClick={deleteFunc} />
      </Tooltip>
    </div>
  );
}
