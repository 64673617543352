/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import { Carousel, Col, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';

import logo from '../../../Assets/Images/logo-inline.png';

import appStoreImg from './download-app-store.png';
import playStoreImg from './download-google-play.png';

import './AppDownload.scss';

function AppDownloadPage() {
  const contentStyle = {
    color: '#fff',
    textAlign: 'center',
    background: '#05005B',
    height: 32,
    borderRadius: 8,
  };

  return (
    <Row className="wrapper-login">
      <Col className="right-panel" offset={6} span={12}>
        <Row justify="center" className="wrapper-app-download">
          <Header className="download-app-header">
            <img src={logo} title="Conecta Treine" alt="Logo Conecta Treine" />
            <h1>Aplicativo Operações Conecta Treinamentos</h1>
          </Header>

          <p className="download-app-alert-text">
            O aplicativo possibilita aos Instrutores e Motoristas poderem acompanhar e realizar os
            Treinamentos.
          </p>

          <Carousel autoplay style={{ width: 'calc(30vw + 30px)' }}>
            <div>
              <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/appPics%2FSimulator%20Screenshot%20-%20iPhone%2014%20Pro%20Max%20(6%2C7)%20-%202024-07-17%20at%2013.25.22.png?alt=media&token=930c3de6-d67b-41d5-ab68-a697aa5a1a52"
                  alt="Foto exibindo os meses com seus treinamentos"
                  style={{ width: '10vw', borderRadius: 16 }}
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/appPics%2FSimulator%20Screenshot%20-%20iPhone%2014%20Pro%20Max%20(6%2C7)%20-%202024-07-17%20at%2013.25.59.png?alt=media&token=d6e54bc1-987a-46ea-93ca-57d4ba76f470"
                  alt="Foto exibindo os treinamentos em cada dia do mês selecionado"
                  style={{ width: '10vw', borderRadius: 16 }}
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/appPics%2FSimulator%20Screenshot%20-%20iPhone%2014%20Pro%20Max%20(6%2C7)%20-%202024-07-17%20at%2013.27.45.png?alt=media&token=287a3fa2-8f18-4091-89a5-c36648d2fa78"
                  alt="Foto exibindo a lista de treinamentos, com possibilidade de aceitar e negar"
                  style={{ width: '10vw', borderRadius: 16 }}
                />
              </div>
              <h3 style={contentStyle}>1</h3>
            </div>
            <div>
              <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/appPics%2FSimulator%20Screenshot%20-%20iPhone%2014%20Pro%20Max%20(6%2C7)%20-%202024-07-17%20at%2013.49.41.png?alt=media&token=26022b77-d570-4d4a-b47e-5b9afa0ec94d"
                  alt="Foto exibindo a tela principal, com a lista de treinamentos confirmados e concluídos"
                  style={{ width: '10vw', borderRadius: 16 }}
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/appPics%2FSimulator%20Screenshot%20-%20iPhone%2014%20Pro%20Max%20(6%2C7)%20-%202024-07-17%20at%2013.50.56.png?alt=media&token=ee12a48a-ccfa-49df-9c5b-66c2b5eedae6"
                  alt="Foto exibindo a lista de notificações do usuário"
                  style={{ width: '10vw', borderRadius: 16 }}
                />
              </div>
              <h3 style={contentStyle}>2</h3>
            </div>
          </Carousel>

          <div className="download-buttons">
            <h2>Abrir aplicativo na loja</h2>
            <div className="buttons-wrapper">
              <img
                src={playStoreImg}
                title="Abrir aplicativo na Play Store"
                alt="Imagem com link para abrir aplicativo na Play Store"
                className="btn-app"
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.crmmobileconectatreine',
                    '_blank'
                  );
                }}
              />
              <img
                src={appStoreImg}
                title="Abrir aplicativo na App Store"
                alt="Imagem com link para abrir aplicativo na App Store"
                className="btn-app"
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/app/opera%C3%A7%C3%B5es-conecta/id6476204439',
                    '_blank'
                  );
                }}
              />
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default AppDownloadPage;
