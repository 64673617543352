import React from 'react';

import TableAntd from '../../../Components/TableAntd/TableAntd';

export default function InstructorPreRegisterList() {
  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome',
        width: '25%',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'cpf',
        title: 'CPF',
        ...getColumnSearchProps('cpf', 'CPF'),
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'email',
        title: 'E-mail',
        ...getColumnSearchProps('email', 'E-mail'),
      },
      {
        dataIndex: 'phone2',
        title: 'Celular',
        ...getColumnSearchProps('phone2', 'Celular'),
      },
    ];
    return columns;
  };

  return (
    <TableAntd
      columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
      routePermissions="Instructor"
      requestName="PreInstructor"
      route="/PreCadastroInstrutor"
      cdPage="id"
    />
  );
}
