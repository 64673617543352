/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Popup } from 'devextreme-react';
import moment from 'moment';

import './OverviewPopup.scss';

export default function OverviewPopup({
  overviewPopupRef,
  overviewList,
  totalStudents,
  getCurrentDate,
  schedulerRef,
  currentScreen,
  fetchResumeOverview,
}) {
  const [appointmentList, setAppointmentList] = useState(overviewList || []);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Produto',
      dataIndex: 'product',
    },
    {
      title: 'Turmas Agendadas',
      dataIndex: 'classes',
      width: '13%',
    },
    {
      title: 'Alunos CT',
      dataIndex: 'ctStudents',
      width: '13%',
    },
    {
      title: 'Alunos In Company',
      dataIndex: 'inCompanyStudents',
      width: '13%',
    },
    {
      title: 'Alunos EAD',
      dataIndex: 'eadStudents',
      width: '13%',
    },
  ];

  if (currentScreen === 'scheduleOverView') {
    columns.unshift({
      title: 'Data',
      dataIndex: 'startDate',
      render: (text) => moment(text).format('DD/MM/YYYY'),
    });
  }

  const genereteTitle = () => {
    if (getCurrentDate) {
      return `Overview - (${moment(getCurrentDate()).format('DD/MM/YYYY')})`;
    }

    if (schedulerRef?.current?.instance) {
      const currentStartDate = schedulerRef.current.instance.getStartViewDate();
      const currentEndDate = schedulerRef.current.instance.getEndViewDate();

      return `Overview - (${moment(currentStartDate).format('DD/MM/YYYY')} até ${moment(
        currentEndDate
      ).format('DD/MM/YYYY')})`;
    }
    return 'Overview';
  };

  const renderSummary = () => {
    const calculateColor = (value) => {
      if (value <= 0.5) {
        return 'green'; // 0% até 50%
      }
      if (value <= 0.75) {
        return '#dfc70e'; // 50% até 75%
      }
      return 'red'; // 75% até 100%
    };

    let firstColSpan = 0;
    if (currentScreen === 'scheduleOverView') {
      firstColSpan += 1;
    }

    return (
      <Table.Summary fixed>
        <Table.Summary.Row className="overview-summary">
          <Table.Summary.Cell index={0} colSpan={1 + firstColSpan} align="right">
            Total:
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <span>{appointmentList.reduce((sum, item) => sum + item.classes, 0)}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            {currentScreen === 'scheduleOverView' ? (
              <span>{appointmentList.reduce((sum, item) => sum + item.ctStudents, 0)}</span>
            ) : (
              <span
                style={{
                  color: calculateColor(
                    appointmentList.reduce((sum, item) => sum + item.ctStudents, 0) / totalStudents
                  ),
                }}
              >
                ({appointmentList.reduce((sum, item) => sum + item.ctStudents, 0)} / {totalStudents}
                )
              </span>
            )}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <span>{appointmentList.reduce((sum, item) => sum + item.inCompanyStudents, 0)}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <span>{appointmentList.reduce((sum, item) => sum + item.eadStudents, 0)}</span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  useEffect(() => {
    setAppointmentList(overviewList || []);
  }, [overviewList]);

  return (
    <Popup
      ref={overviewPopupRef}
      title={genereteTitle()}
      className="overview-popup"
      hideOnOutsideClick={false}
      copyRootClassesToWrapper
      onShowing={async () => {
        if (currentScreen === 'scheduleOverView') {
          setLoading(true);
          const { overviewList } = await fetchResumeOverview();
          setAppointmentList(overviewList);
          setLoading(false);
        }
      }}
    >
      <Table
        columns={columns}
        dataSource={appointmentList}
        pagination={false}
        summary={renderSummary}
        loading={loading}
      />
    </Popup>
  );
}
