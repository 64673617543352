import React from 'react';

import TableAntd from '../../../Components/TableAntd/TableAntd';

export default function UserList() {
  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome',
        width: '25%',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'description',
        title: 'Descrição',
        ...getColumnSearchProps('description', 'Descrição'),
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  return (
    <TableAntd
      columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
      routePermissions="Role"
      requestName="Role"
      route="/Cargo"
      cdPage="id"
      btnNew
    />
  );
}
