/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

export default function DataCell({ itemData, instructorBookingList, appointmentList }) {
  let cssClasses = 'open-cell';

  const instructorBooking = instructorBookingList?.find(
    ({ date }) => moment(date).format('DD/MM/YYYY') === itemData.startDate.toLocaleDateString()
  );

  if (instructorBooking != null) {
    cssClasses += ' selected-cell';
  }

  const appointment = appointmentList?.find(
    ({ startDate }) =>
      moment(startDate).format('DD/MM/YYYY') === itemData.startDate.toLocaleDateString()
  );
  if (appointment != null) {
    return (
      <div className={`${cssClasses}`} data-id={appointment.id}>
        {itemData.text}
      </div>
    );
  }

  cssClasses += ' every-cell';
  return (
    <div key="cell" className={cssClasses}>
      {itemData.text}
    </div>
  );
}
