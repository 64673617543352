import React from 'react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import { loadMessages, locale } from 'devextreme/localization';

import ptMessages from './Assets/pt.json';
import ErrorBoundary from './Assets/Scripts/ErrorBoundary';
import NavigationRoutes from './routes';

import './Assets/antd.less';

import 'devextreme/dist/css/dx.light.css';
import './Assets/global.scss';

function App() {
  loadMessages(ptMessages);
  locale(navigator.language);

  return (
    <ErrorBoundary>
      <ConfigProvider locale={ptBR}>
        <NavigationRoutes />
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
