/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Tabs,
  TimePicker,
  Upload,
} from 'antd';
import moment from 'moment';

import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import CompanyFunctions from '../../../Assets/Scripts/CompanyFunctions';
import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

const workDaysOptions = [
  { label: 'Selecionar todos', value: 'selectAll' },
  { label: 'Segunda', value: '1' },
  { label: 'Terça', value: '2' },
  { label: 'Quarta', value: '3' },
  { label: 'Quinta', value: '4' },
  { label: 'Sexta', value: '5' },
  { label: 'Sábado', value: '6' },
  { label: 'Domingo', value: '0' },
];

export default function DriverRegisterDrawerComponent({
  isOpen,
  setIsOpen,
  driverOptions,
  setDriverOptions,
  docIds,
}) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);

  const clearForm = () => {
    form.resetFields();

    const newValue = form.getFieldsValue();
    newValue.documents = undefined;

    form.setFieldsValue(newValue);
  };

  const submit = async (values) => {
    setLoading(true);
    const data = { ...values };

    if (values.businessHour && values.businessHour !== undefined) {
      data.startBusinessHour = moment.utc(values.businessHour[0]).format('YYYY-MM-DDTHH:mm:ss');
      data.endBusinessHour = moment.utc(values.businessHour[1]).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (values.breakTime && values.breakTime !== undefined) {
      data.breakStart = moment.utc(values.breakTime[0]).format('YYYY-MM-DDTHH:mm:ss');
      data.breakFinish = moment.utc(values.breakTime[1]).format('YYYY-MM-DDTHH:mm:ss');
    }

    data.workDays = data.workDays?.toString();

    await CompanyFunctions.saveDocuments(data, documentTypeOptions, 'driver');

    await api
      .post('/Driver', data)
      .then(async ({ data: resData }) => {
        message.success('Dados salvos!');

        const filteredDriver = CompanyFunctions.registersWithValidDocs([resData], docIds);

        if (filteredDriver?.length > 0) {
          const filter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${resData.id}`;
          const [driverUser] = await api.get(filter).then((res) => res.data);

          driverOptions.push({
            ...resData,
            ...driverUser,
            value: driverUser.id,
            label: resData.name,
          });
          setDriverOptions([...driverOptions]);
        }

        clearForm();

        setLoading(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Erro ao enviar os dados do motorista!');
        setLoading(false);
      });
  };

  const getDocumentTypes = async () => {
    const documentTypes = await api
      .get('/DocumentType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os tipos de documento!');
      });

    const mappedDocumentTypes = documentTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setDocumentTypeOptions(mappedDocumentTypes);
    return mappedDocumentTypes;
  };

  const getRequiredDocuments = async () => {
    const docTypes = await getDocumentTypes();

    const reqDocs = docTypes
      ?.filter(({ value }) => docIds.includes(value))
      ?.map(({ label }) => label);

    setRequiredDocuments(reqDocs);
  };

  useEffect(() => {
    getRequiredDocuments();
  }, []);

  return (
    <Drawer
      title="Novo Motorista"
      width="30vw"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <Button onClick={() => clearForm()}>Limpar</Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            style={{ marginRight: '8px', background: '#5cb85c', borderColor: '#5cb85c' }}
          >
            Salvar
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        disabled={loading}
        name="driverForm"
        layout="vertical"
        onFinish={submit}
        autoComplete="off"
      >
        <Tabs>
          <Tabs.TabPane tab="Motorista" key="driver">
            <Row gutter={[24]}>
              <Col span={24}>
                <Form.Item
                  label="Nome"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Nome Fantasia"
                  name="commercialName"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input placeholder="exemplo@email.com" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Telefone 1"
                  name="phone1"
                  rules={[
                    {
                      required: false,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input id="phone1" placeholder="(00) 0000-0000" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Telefone 2"
                  name="phone2"
                  rules={[
                    {
                      required: false,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input id="phone2" placeholder="(00) 0000-0000" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Dias de trabalho"
                  name="workDays"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Select
                    options={workDaysOptions}
                    mode="multiple"
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    maxTagCount="responsive"
                    onChange={(values) =>
                      Utils.handleSelectAllChange(values, workDaysOptions, 'workDays', form)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Horário de trabalho"
                  name="businessHour"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <TimePicker.RangePicker
                    format="HH:mm"
                    style={{ borderRadius: 16 }}
                    autoComplete="new"
                    ranges={{
                      '08h às 18h': [
                        moment().startOf('day').add(8, 'hours'),
                        moment().startOf('day').add(18, 'hours'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Horário de intervalo"
                  name="breakTime"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <TimePicker.RangePicker
                    format="HH:mm"
                    style={{ borderRadius: 16 }}
                    ranges={{
                      '12h às 13h': [
                        moment().startOf('day').add(12, 'hours'),
                        moment().startOf('day').add(13, 'hours'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Documentos" key="documentsData" forceRender>
            <Form.List name="documents">
              {(fields, { add, remove }) => (
                <>
                  {requiredDocuments?.length > 0 && (
                    <div>
                      <h3>Documentos Obrigatórios</h3>
                      <p style={{ margin: 0 }}>{requiredDocuments?.join(' / ')}</p>
                    </div>
                  )}

                  <Button
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                      width: 200,
                    }}
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Documento
                  </Button>
                  <Row gutter={[24]}>
                    {fields.map(({ key, name, ...restField }) => (
                      <Col key={key} span={24}>
                        <Card
                          extra={
                            <Popconfirm
                              title="Deseja mesmo excluir?"
                              onConfirm={() => {
                                if (form.getFieldValue('documents')[name]?.downloadUrls) {
                                  CompanyFunctions.deleteDocumentFromStorage(form, 'driver', name);
                                  remove(name);
                                  submit(form.getFieldsValue());
                                } else {
                                  remove(name);
                                }
                              }}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <CloseOutlined />
                            </Popconfirm>
                          }
                          style={{
                            backgroundColor: '#ffffff00',
                            borderColor: '#b7b7b7',
                            borderRadius: 16,
                            marginBottom: 10,
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'verified']}
                              valuePropName="checked"
                              initialValue={true}
                            >
                              <Checkbox>Verificado</Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              label="Tipo de documento"
                              rules={[
                                {
                                  required: true,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Selecione"
                                optionFilterProp="label"
                                showSearch
                                options={documentTypeOptions}
                                dropdownStyle={{ borderRadius: 16 }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'number']}
                              label="Nº documento"
                              rules={[
                                {
                                  required: true,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'validity']}
                              label="Validade"
                              rules={[
                                {
                                  required: false,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <DatePicker
                                format="DD-MM-YYYY"
                                style={{
                                  borderRadius: 16,
                                }}
                              />
                            </Form.Item>
                          </Col>

                          {form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'downloadUrls']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <List
                                  size="small"
                                  header={<div>Arquivos</div>}
                                  bordered
                                  dataSource={form.getFieldValue('documents')[name]?.downloadUrls}
                                  renderItem={({ label, url }) => (
                                    <List.Item>
                                      <a
                                        href={url}
                                        target="blank"
                                        style={{ wordBreak: 'break-word' }}
                                      >
                                        {label}
                                      </a>
                                    </List.Item>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          )}

                          {!form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'uploadFiles']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Upload multiple beforeUpload={() => false}>
                                  <Button icon={<UploadOutlined />}>Upload de arquivos</Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          )}
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Form.List>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Drawer>
  );
}
