/* eslint-disable react/prop-types */
import React from 'react';

import DataCell from './DataCell';

export default function DataCellMonth({ itemData, instructorBookingList, appointmentList }) {
  const day = itemData.startDate.getDate();

  return (
    <DataCell
      itemData={itemData}
      instructorBookingList={instructorBookingList}
      appointmentList={appointmentList}
    >
      {day}
    </DataCell>
  );
}
