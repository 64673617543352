import React from 'react';
import { Breadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';

import './CustomBreadcrumb.scss';

function CustomBreadcrumb() {
  const { pathname } = useLocation();
  const urlSplitted = pathname.split('/');

  return (
    <Breadcrumb className="custom-breadcrumb" separator=">">
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      {urlSplitted.map((item) => {
        if (item !== '' && item !== 'Home' && !/\d/.test(item)) {
          return <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>;
        }
        return null;
      })}
    </Breadcrumb>
  );
}

export default CustomBreadcrumb;
