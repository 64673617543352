/* eslint-disable no-param-reassign */
import Utils from '../../Assets/Scripts/Utils';

export default class QuoteListFunctions {
  static calculateQuoteListTotal(dataSource) {
    dataSource?.forEach((quote) => {
      const groupedClassGUID = Utils.groupBy(quote.tasks, 'classGUID');

      let quoteTrainingsScheduled = 0;
      let quoteTotalTrainings = 0;

      // Percorre os produtos
      Object.keys(groupedClassGUID).forEach((guid) => {
        const guidTasksByClass = Utils.groupBy(groupedClassGUID[guid], 'classId');

        // Traz os valores indicando quantos dias estão Agendados e quantos dias tem no Total
        const daysIndicator = Object.keys(guidTasksByClass).map((classId) => {
          // Verifica quantos dias de uma Turma estão Agendados
          const practicalDaysScheduledSum = guidTasksByClass[classId]
            .map(({ stage }) => stage.id)
            .reduce((a, v) => (v !== 1 && v !== 6 && v !== 8 && v !== 11 ? a + 1 : a), 0);

          // Adiciona nos Treinamentos os "(Dias Agendados/Total de Dias)"
          guidTasksByClass[classId].forEach((task) => {
            task.counterPracticalDays = `(${practicalDaysScheduledSum}/${guidTasksByClass[classId].length})`;
          });

          return { scheduled: practicalDaysScheduledSum, total: guidTasksByClass[classId].length };
        });

        // Soma os valores de Treinamentos totalmente Agendados e Total de Treinamentos
        quoteTotalTrainings += daysIndicator.length;
        daysIndicator.forEach(({ scheduled, total }) => {
          if (scheduled === total) {
            quoteTrainingsScheduled += 1;
          }
        });
      });

      // Adiciona no Negócio os "(Treinamentos Totalmente Agendados/Total de Treinamentos)"
      quote.trainingsIndicator = `(${quoteTrainingsScheduled}/${quoteTotalTrainings})`;
    });
  }
}
