/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Form, InputNumber, message, Modal, Select, Switch, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons';

import { uploadFiles } from '../../Services/firebaseService';
import TaskCommentFunctions from '../Schedule/TaskCommentFunctions';

export default function ModalTrainingConclusion({
  openModalTrainingConclusion,
  onCancel,
  currentOpenTask,
  onOk,
  loading,
  setOpenModalTrainingConclusion,
  eventSource,
  reasonsOptions,
  finishMargin,
}) {
  const [form] = Form.useForm();
  const [loadingModal, setLoadingModal] = useState(false);
  const [hasAttendanceList, setHasAttendanceList] = useState(true);
  const [showFinishMarginReason, setShowFinishMarginReason] = useState(false);

  const submit = async (values) => {
    const data = currentOpenTask;
    const documents = values.attendanceList?.fileList;
    const urls = [];

    if (!documents && hasAttendanceList) {
      message.error('A lista de presença é obrigatória!');
      return;
    }

    if (
      moment(new Date(data.endDate)).diff(new Date(), 'seconds') > finishMargin * 60 &&
      !values.reasonFinishMargin
    ) {
      setShowFinishMarginReason(true);
      message.error(
        `Você está concluindo o Treinamento antes do prazo aceitável (${moment(
          new Date(moment(new Date(data.endDate)).subtract(finishMargin, 'minutes'))
        ).format('HH:mm:ss')}min), preencha o motivo!`
      );
      return;
    }

    if (showFinishMarginReason && values.reasonFinishMargin) {
      const reasonFinishMargin = reasonsOptions.find(
        ({ value }) => value === values.reasonFinishMargin
      );
      await TaskCommentFunctions.addTaskComment(
        data,
        'TaskFinishedBeforeMargin',
        eventSource,
        form.getFieldValue('noteFinishMargin'),
        reasonFinishMargin.value,
        reasonFinishMargin.label
      );
    }

    setShowFinishMarginReason(false);
    setLoadingModal(true);

    if (documents) {
      for (let i = 0; i < documents.length; i += 1) {
        const res = await uploadFiles(documents[i].originFileObj, 'attendanceList', data.id);
        const docName = `${data.classId}_${i + 1}`;
        urls.push({
          name: docName,
          url: res,
        });
      }
    }

    // Gera Atividade no Treinamento
    const reason = reasonsOptions.find(({ value }) => value === values.reason);
    await TaskCommentFunctions.addTaskComment(
      currentOpenTask,
      'TaskFinished',
      eventSource,
      !hasAttendanceList
        ? ` ${form.getFieldValue('note') ?? ''}
      \nObs: Sem Lista de presença
      `
        : form.getFieldValue('note'),
      !hasAttendanceList ? reason.value : null,
      !hasAttendanceList ? reason.label : null
    );

    data.attendanceList = urls;
    data.qtyStudentsTrained = values.qtyStudentsTrained;

    await onOk(data);
    setOpenModalTrainingConclusion(false);
    setHasAttendanceList(true);
    form.resetFields();
    setLoadingModal(false);
  };

  return (
    <Modal
      className="modal-Training-Complet"
      title="Concluir Treinamento"
      open={openModalTrainingConclusion}
      onCancel={onCancel}
      cancelText="Fechar"
      destroyOnClose
      footer={[
        <Button onClick={() => setOpenModalTrainingConclusion(false)}>Fechar</Button>,
        <Button
          form="modalConclusion"
          type="primary"
          key="submit"
          htmlType="submit"
          loading={loading || loadingModal}
        >
          Concluir
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="modalConclusion"
        layout="vertical"
        autoComplete="off"
        onFinish={submit}
        preserve={false}
      >
        <Form.Item label="Possui lista de presença? " name="attendanceList" initialValue={true}>
          <Switch
            checkedChildren="Sim"
            unCheckedChildren="Não"
            defaultChecked
            onClick={() => setHasAttendanceList(!hasAttendanceList)}
            style={{ float: 'left' }}
          />
        </Form.Item>
        <Form.Item
          name="attendanceList"
          rules={[
            {
              required: hasAttendanceList,
              message: 'Campo obrigatório!',
            },
          ]}
          style={{ display: !hasAttendanceList ? 'none' : '' }}
        >
          <Upload beforeUpload={() => false} multiple disabled={!hasAttendanceList || loading}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Motivo"
          name="reason"
          style={{ display: hasAttendanceList ? 'none' : '' }}
          rules={[
            {
              required: !hasAttendanceList,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Select
            disabled={hasAttendanceList || loading}
            placeholder="Selecione"
            optionFilterProp="label"
            dropdownStyle={{ borderRadius: 16 }}
            options={reasonsOptions}
          />
        </Form.Item>
        <Form.Item label="Observações" name="note">
          <TextArea rows={6} />
        </Form.Item>
        {eventSource !== 'Driver' && (
          <Form.Item
            label="Quantidade Alunos Treinados"
            name="qtyStudentsTrained"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <InputNumber placeholder="0" min={0} max={currentOpenTask?.qtyStudentsPerClass} />
          </Form.Item>
        )}

        {eventSource === 'Driver' && (
          <Form.Item
            label="Quantidade Alunos Transportados"
            name="qtyStudentsTransported"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <InputNumber placeholder="0" min={0} max={currentOpenTask?.qtyStudentsPerClass} />
          </Form.Item>
        )}

        {showFinishMarginReason && (
          <>
            <h3 style={{ marginTop: 48 }}>Motivo conclusão antecipada</h3>
            <Form.Item
              label="Motivo"
              name="reasonFinishMargin"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Select
                disabled={loading}
                placeholder="Selecione"
                optionFilterProp="label"
                dropdownStyle={{ borderRadius: 16 }}
                options={reasonsOptions}
              />
            </Form.Item>
            <Form.Item label="Observações" name="noteFinishMargin">
              <TextArea rows={6} disabled={loading} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
