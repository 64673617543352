/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Select } from 'antd';

import Utils from '../../Assets/Scripts/Utils';
import { fetchComments } from '../../Pages/ScheduleConecta/API/ScheduleSchedulingAPI';
import { api } from '../../Services/axiosService';

const { Option } = Select;

function OccurrencesPopup({ isVisible, onClose, currentOpenTask, setComments, task }) {
  const [form] = Form.useForm();
  const [occurrenceTypes, setOccurrenceTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(0);
  const [loading, setLoading] = useState(false);

  // Função para buscar os tipos de ocorrência do banco de dados
  const fetchOccurrenceTypes = async () => {
    setLoading(true);
    try {
      const response = await api.get('/Occurrence');
      setOccurrenceTypes(response.data);
    } catch (error) {
      message.error('Oops. Algo deu errado ao tentar buscar os Tipos de Ocorrência!');
      Utils.logError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveTaskComment = async (occurrenceData, eventSource) => {
    try {
      const userData = JSON.parse(localStorage.getItem('conecta__userData'));
      const title = `Ocorrência ${occurrenceData.occurrenceType}`;
      const description = `${occurrenceData.occurrenceType} - ${occurrenceData.observations ?? ''}`;

      const data = {
        taskId: currentOpenTask.id,
        userName: userData.name,
        userAvatar: userData.profilePic,
        title,
        description,
        sourceId: parseInt(userData?.id, 10),
        sourceType: eventSource,
        activyType: 1,
      };

      await api.post('/TaskComment', data).then((res) => res);
      message.success('Ocorrência adicionada!');
    } catch (error) {
      message.error('Oops. Algo deu errado ao tentar adicionar uma Ocorrência!');
      Utils.logError(error);
    }
  };

  useEffect(() => {
    fetchOccurrenceTypes();
  }, []);

  const handleSave = async () => {
    form.validateFields().then(async (values) => {
      const { occurrenceType } = values;
      const eventSource =
        occurrenceType === 'Instrutor' ? 'InstructorNewOccurrence' : 'TransportNewOccurrence';
      const occurrence = occurrenceTypes.find((type) => type.name === values.occurrence);
      const event = `Occurrence${occurrence?.id}`;

      const occurrenceData = {
        ...values,
        id: currentOpenTask.id,
        occurrenceType,
        event,
      };
      await saveTaskComment(occurrenceData, eventSource, event);
      form.resetFields();

      const commentsCombined = await fetchComments(task);

      setComments(commentsCombined);

      onClose();
    });
  };

  return (
    <Modal
      visible={isVisible}
      title="Registrar Ocorrência"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="occurrenceType"
          label="Tipo de Ocorrência"
          rules={[{ required: true, message: 'Por favor, selecione o tipo de ocorrência!' }]}
        >
          <Select
            placeholder="Selecione o tipo"
            loading={loading}
            style={{ borderRadius: '10px' }}
            dropdownStyle={{ borderRadius: '10px' }}
            onChange={(val) => {
              if (val === 'Instrutor') {
                setSelectedType(1);
              } else {
                setSelectedType(2);
              }
            }}
          >
            <Option value="Instrutor">Instrutor</Option>
            <Option value="Transporte">Transporte</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="occurrence"
          label="Ocorrência"
          rules={[{ required: true, message: 'Por favor, selecione uma ocorrência!' }]}
        >
          <Select
            placeholder="Selecione uma ocorrência"
            loading={loading}
            style={{ borderRadius: '10px' }}
            dropdownStyle={{ borderRadius: '10px' }}
          >
            {occurrenceTypes
              ?.filter(({ type }) => type === selectedType)
              ?.map((item) => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="observations" label="Observações">
          <Input.TextArea
            rows={4}
            placeholder="Adicione observações"
            style={{ borderRadius: '10px' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default OccurrencesPopup;
