/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select } from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../../Services/axiosService';

import '../../TaskList/Components/ModalStyle.scss';

export default function CertificateSettingsModal({
  form,
  setIsOpen,
  certificateModelOptions,
  productOptions,
  taskList,
  selectedRowKeys,
  isSchedulingScreen,
  handleTaskUpdate,
}) {
  const [formSettings] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));
  const [firstTask] = selectedTaskList;

  const isSameProduct = selectedTaskList.every(
    (task) => task.product.id === selectedTaskList[0].product.id
  );

  if (isSameProduct) {
    const product = productOptions.find(
      ({ idBitrixProduct }) => idBitrixProduct === firstTask.product.id
    );

    if (product) {
      formSettings.setFieldsValue({
        certificateWorkload: product.workload,
        certificateLevel: product.level,
        certificateValidity: product.validity,
        certificateSayings: product.sayings,
        certificateSayingsCompany: product.sayingsCompany,
        certificateSayingsAttestation: product.sayingsAttestation,
        certificateSayingsCard: product.sayingsCard,
        certificateProgramContent: product.programContent,
        certificateTechnicalInstruction: product.technicalInstruction,
      });
    }
  }

  const submit = async ({
    certificateModel,
    certificateSayings,
    certificateSayingsCompany,
    certificateSayingsAttestation,
    certificateSayingsCard,
    certificateProgramContent,
    certificateTechnicalInstruction,
    certificateWorkload,
    certificateLevel,
    certificateValidity,
  }) => {
    try {
      if (selectedRowKeys.length > 0) {
        setIsLoading(true);

        const model = certificateModelOptions.find(({ url }) => url === certificateModel);

        for (let index = 0; index < selectedTaskList.length; index += 1) {
          const newTask = selectedTaskList[index];

          newTask.certificateModel = model;

          if (certificateSayings && certificateSayings !== '') {
            newTask.certificateSayings = certificateSayings;
          }
          if (certificateSayingsCompany && certificateSayingsCompany !== '') {
            newTask.certificateSayingsCompany = certificateSayingsCompany;
          }
          if (certificateSayingsAttestation && certificateSayingsAttestation !== '') {
            newTask.certificateSayingsAttestation = certificateSayingsAttestation;
          }
          if (certificateSayingsCard && certificateSayingsCard !== '') {
            newTask.certificateSayingsCard = certificateSayingsCard;
          }
          if (certificateProgramContent && certificateProgramContent?.length > 0) {
            newTask.certificateProgramContent = certificateProgramContent;
          }
          if (certificateTechnicalInstruction && certificateTechnicalInstruction?.length > 0) {
            newTask.certificateTechnicalInstruction = certificateTechnicalInstruction;
          }
          if (certificateWorkload) {
            newTask.certificateWorkload = certificateWorkload;
          }
          if (certificateLevel) {
            newTask.certificateLevel = certificateLevel;
          }
          if (certificateValidity) {
            newTask.certificateValidity = certificateValidity;
          }

          // Atualiza Treinamento
          if (isSchedulingScreen) {
            form.setFieldValue('certificateModel', model);

            if (certificateSayings && certificateSayings !== '') {
              newTask.certificateSayings = certificateSayings;
              form.setFieldValue('certificateSayings', certificateSayings);
            }
            if (certificateSayingsCompany && certificateSayingsCompany !== '') {
              newTask.certificateSayingsCompany = certificateSayingsCompany;
              form.setFieldValue('certificateSayingsCompany', certificateSayingsCompany);
            }
            if (certificateSayingsAttestation && certificateSayingsAttestation !== '') {
              newTask.certificateSayingsAttestation = certificateSayingsAttestation;
              form.setFieldValue('certificateSayingsAttestation', certificateSayingsAttestation);
            }
            if (certificateSayingsCard && certificateSayingsCard !== '') {
              newTask.certificateSayingsCard = certificateSayingsCard;
              form.setFieldValue('certificateSayingsCard', certificateSayingsCard);
            }
            if (certificateProgramContent && certificateProgramContent?.length > 0) {
              newTask.certificateProgramContent = certificateProgramContent;
              form.setFieldValue('certificateProgramContent', certificateProgramContent);
            }
            if (certificateTechnicalInstruction && certificateTechnicalInstruction?.length > 0) {
              newTask.certificateTechnicalInstruction = certificateTechnicalInstruction;
              form.setFieldValue(
                'certificateTechnicalInstruction',
                certificateTechnicalInstruction
              );
            }
            if (certificateWorkload) {
              newTask.certificateWorkload = certificateWorkload;
              form.setFieldValue('certificateWorkload', certificateWorkload);
            }
            if (certificateLevel) {
              newTask.certificateLevel = certificateLevel;
              form.setFieldValue('certificateLevel', certificateLevel);
            }
            if (certificateValidity) {
              newTask.certificateValidity = certificateValidity;
              form.setFieldValue('certificateValidity', certificateValidity);
            }

            await handleTaskUpdate('save');
          } else {
            await api.put('/Task/UpdateTaskScheduling', newTask);

            await new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 3000); // 3 Segundos
            });
          }

          // Gera Atividade de Upload de Arquivo
          await TaskCommentFunctions.addTaskCommentUploadFile(
            newTask,
            'CertificateModelUploaded',
            'Certificates'
          );
        }
      }
      message.success('Tarefas atualizadas com sucesso!');
      window.location.reload();
    } catch (error) {
      Utils.error('Erro ao atualizar tarefas:', error);
      message.error('Erro ao atualizar tarefas.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      name="certificateSettings"
      width="80vw"
      title="Configuração de Certificado no Treinamento"
      open={true}
      onOk={() => formSettings.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Salvar"
      cancelText="Cancelar"
      className="certificate-settings-modal"
      okButtonProps={{
        style: { backgroundColor: '#5cb85c', color: 'white', border: 'none' },
      }}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      {isSameProduct && (
        <p>
          Os Treinamentos selecionados <strong>possuem</strong> o mesmo serviço,
          <strong> {firstTask.product.name}</strong>, por isso os campos já vieram preenchidos. Por
          favor, selecione um modelo e revise as informações antes de salvar.
        </p>
      )}

      {!isSameProduct && (
        <p>
          Os Treinamentos selecionados <strong>não possuem</strong> o mesmo serviço, por isso os
          campos não vieram preenchidos. Por favor, selecione um modelo, preencha os campos se
          necessário e revise as informações antes de salvar.
        </p>
      )}

      <Form
        form={formSettings}
        layout="vertical"
        onFinish={submit}
        style={{ maxWidth: '100%', margin: '0 auto' }}
      >
        {isSameProduct && (
          <Form.Item label="Produto" name="certificateProduct">
            <p>{firstTask.product.name}</p>
          </Form.Item>
        )}

        <Row gutter={[24]}>
          <Col span={6}>
            <Form.Item
              label="Modelo Certificado"
              name="certificateModel"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
              fieldId="name"
            >
              <Select
                placeholder="Selecione"
                optionFilterProp="label"
                dropdownStyle={{ borderRadius: 16 }}
                options={certificateModelOptions.map((option) => ({
                  label: option.label,
                  value: option.url,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Nível" name="certificateLevel">
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Carga Horária" name="certificateWorkload">
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Validade" name="certificateValidity">
              <InputNumber min={0} max={999} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Dizeres" name="certificateSayings">
              <Input.TextArea
                rows={6}
                placeholder="Digite aqui..."
                style={{ borderRadius: '10px' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Dizeres Empresa" name="certificateSayingsCompany">
              <Input.TextArea
                rows={6}
                placeholder="Digite aqui..."
                style={{ borderRadius: '10px' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Dizeres Atestado" name="certificateSayingsAttestation">
              <Input.TextArea
                rows={6}
                placeholder="Digite aqui..."
                style={{ borderRadius: '10px' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Dizeres Carteirinha" name="certificateSayingsCard">
              <Input.TextArea
                rows={6}
                placeholder="Digite aqui..."
                style={{ borderRadius: '10px' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Conteúdo Programático" name="certificateProgramContent">
              <Input.TextArea
                rows={6}
                placeholder="Digite aqui..."
                style={{ borderRadius: '10px' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Instrução Técnica" name="certificateTechnicalInstruction">
              <Input.TextArea
                rows={6}
                placeholder="Conforme tabela B.2 da..."
                style={{ borderRadius: '10px' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
