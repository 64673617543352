/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Divider, Modal } from 'antd';
import moment from 'moment';

function ConfirmationModal({ setIsOpen, onConfirm, formData }) {
  const [isLoading, setIsLoading] = useState(false);

  const formatTime = (time) => (time ? moment(time).format('HH:mm') : 'Não Preenchido');
  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : 'Não Preenchido');
  const formatCurrency = (value) =>
    value
      ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(value)
      : 'Não Preenchido';

  const fieldsToConfirm = {
    comercial: [
      {
        key: 'dealClientCommercialName',
        label: 'Nome do Cliente',
        value: formData.dealClientCommercialName || 'Não Preenchido',
      },
      {
        key: 'startDate',
        label: 'Data do Treinamento',
        value: formatDate(formData.startDate),
      },
      {
        key: 'product',
        label: 'Produto + Complemento',
        value:
          formData.product && typeof formData.product === 'object'
            ? `${formData.product.name} ${formData.product.complement || ''}`.trim()
            : formData.product || 'Não Preenchido',
      },
      {
        key: 'note',
        label: 'Observação Oficial do Negócio',
        value: formData.note || 'Não Preenchido',
      },
      {
        key: 'commercialNotes',
        label: 'Observação Comercial',
        value: formData.commercialNotes || 'Não Preenchido',
      },
    ],
    logistica: [
      {
        key: 'mainInstructor',
        label: 'Instrutor',
        value: formData.mainInstructor?.userName || 'Não Preenchido',
      },
      {
        key: 'instructorValue',
        label: 'Valor Instrutor',
        value: formatCurrency(formData.instructorValue),
      },
      {
        key: 'startHour',
        label: 'Horário de Início',
        value: formatTime(formData.startHour),
      },
      {
        key: 'responsiblePersonNameLogistic',
        label: 'Nome Responsável',
        value: formData.responsiblePersonNameLogistic || 'Não Preenchido',
      },
      {
        key: 'responsiblePersonPhoneLogistic',
        label: 'Telefone Responsável',
        value: formData.responsiblePersonPhoneLogistic || 'Não Preenchido',
      },
      {
        key: 'responsiblePersonEmailLogistic',
        label: 'E-mail Responsável',
        value: formData.responsiblePersonEmailLogistic || 'Não Preenchido',
      },
      {
        key: 'nameResponsibleConfirmation',
        label: 'Nome Responsável Confirmação',
        value: formData.nameResponsibleConfirmation || 'Não Preenchido',
      },
      {
        key: 'emailResponsibleConfirmation',
        label: 'E-mail Responsável Confirmação',
        value: formData.emailResponsibleConfirmation || 'Não Preenchido',
      },
      {
        key: 'phoneResponsibleConfirmation',
        label: 'Telefone Responsável Confirmação',
        value: formData.phoneResponsibleConfirmation || 'Não Preenchido',
      },
      {
        key: 'transportCompany',
        label: 'Empresa de Transporte',
        value: formData.transportCompany?.name || 'Não Preenchido',
      },
      {
        key: 'vehicleList',
        label: 'Veículos',
        value: Array.isArray(formData.vehicleList)
          ? formData.vehicleList
              .map((item) => `${item.licensePlate} - ${item.type.name} (${item.capacity} pessoas)`)
              .join(', ')
          : 'Não Preenchido',
      },
      {
        key: 'driverList',
        label: 'Motoristas',
        value: Array.isArray(formData.driverList)
          ? formData.driverList.map((item) => item.userName).join(', ')
          : 'Não Preenchido',
      },
      {
        key: 'transportValue',
        label: 'Valor Transporte',
        value: formatCurrency(formData.transportValue),
      },
      {
        key: 'boardingHour',
        label: 'Horário de Embarque',
        value: formatTime(formData.boardingHour),
      },
      {
        key: 'postalCodeLogistic',
        label: 'CEP',
        value: formData.postalCodeLogistic || 'Não Preenchido',
      },
      {
        key: 'streetLogistic',
        label: 'Rua',
        value: formData.streetLogistic || 'Não Preenchido',
      },
      {
        key: 'numberLogistic',
        label: 'Número',
        value: formData.numberLogistic || 'Não Preenchido',
      },
      {
        key: 'districtLogistic',
        label: 'Bairro',
        value: formData.districtLogistic || 'Não Preenchido',
      },
      {
        key: 'cityLogistic',
        label: 'Cidade',
        value: formData.cityLogistic || 'Não Preenchido',
      },
      {
        key: 'stateLogistic',
        label: 'Estado',
        value: formData.stateLogistic || 'Não Preenchido',
      },
    ],
  };

  return (
    <Modal
      open={true}
      title="Confirmação dos Dados do Treinamento"
      onOk={async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
        setIsOpen(false);
      }}
      onCancel={() => setIsOpen(false)}
      okText="Confirmar"
      cancelText="Cancelar"
      okButtonProps={{
        style: { backgroundColor: '#5cb85c', color: 'white', border: 'none' },
      }}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      <div style={{ fontSize: '14px', lineHeight: '1.5' }}>
        <Divider className="configuration-divider" orientation="left">
          Comercial
        </Divider>
        {fieldsToConfirm.comercial.map(({ label, value }, index) => (
          <div key={`comercial-${index}`} style={{ marginBottom: '4px' }}>
            <strong>{label}:</strong> {value}
          </div>
        ))}

        <Divider className="configuration-divider" orientation="left" style={{ marginTop: 40 }}>
          Logística
        </Divider>

        {fieldsToConfirm.logistica.map(({ label, value }, index) => (
          <div key={`logistica-${index}`} style={{ marginBottom: '4px' }}>
            <strong>{label}:</strong> {value}
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
