/* eslint-disable react/prop-types */
import React from 'react';
import { Tag } from 'antd';

export default function StageTagComponent({ stage }) {
  if (!stage) {
    return '-';
  }

  let textColor = '#121212';
  switch (stage.id) {
    case 12: // Reservado
      textColor = '#fff';
      break;
    case 14: // Aguardando Documentação
      textColor = '#fff';
      break;
    default:
      textColor = '#121212';
      break;
  }

  return (
    <Tag
      color={stage.color}
      style={{ color: textColor, fontSize: 12, fontWeight: 600, borderRadius: 8, padding: 4 }}
    >
      {stage.name}
    </Tag>
  );
}
