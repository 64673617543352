/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import { Col, message, Row, Spin, Switch } from 'antd';
import { List, Scheduler } from 'devextreme-react';
import moment from 'moment';

import { CalendarOutlined, LoadingOutlined, UnorderedListOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import CustomDropdown from '../../Components/CustomDropdown/CustomDropdown';
import ModalIntercurrence from '../../Components/ModalIntercurrence/ModalIntercurrence';
import ModalTrainingConclusion from '../../Components/ModalTrainingConclusion/ModalTrainingConclusion';
import ReasonModal from '../../Components/ReasonModal/ReasonModal';
import Appointment from '../../Components/Schedule/Appointment';
import DataCell from '../../Components/Schedule/DataCell';
import DataCellMonth from '../../Components/Schedule/DataCellMonth';
import DateCell from '../../Components/Schedule/DateCell';
import ListItem from '../../Components/Schedule/ListItem';
import ScheduleUtils from '../../Components/Schedule/ScheduleUtils';
import TaskCommentFunctions from '../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../Services/axiosService';

import '../../Assets/Style/ScheduleOperator.scss';
import '../../Components/Schedule/ListItem.scss';
import '../../Components/Schedule/TasksList.scss';

let currentOpenTask = null;
let event = '';
let onModalOk = null;
let finishMargin = null;
let timeout = null;
let currentListItem = null;

const views = [
  {
    name: 'Dia',
    type: 'day',
    cellDuration: 60,
  },
  {
    name: 'Semana',
    type: 'week',
    cellDuration: 60,
  },
  {
    name: 'Mês',
    type: 'month',
  },
];

function ScheduleDriverComponent() {
  const schedulerRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [currentView, setCurrentView] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [driver, setDriver] = useState();
  const [blockedDates, setBlockedDates] = useState([]);
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [stages, setStages] = useState();
  const [changeVisibility, setChangeVisibility] = useState(false);
  const [openModalIntercurrence, setOpenModalIntercurrence] = useState(false);
  const [openModalTrainingConclusion, setOpenModalTrainingConclusion] = useState(false);
  const [reasonsOptions, setReasonsOptions] = useState();
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);

  const confirmTask = async (task) => {
    setLoadingSchedule(true);
    const data = JSON.parse(JSON.stringify(task)); // Deep Copy da task
    const confirmStage = stages?.find(({ id }) => id === 3);
    const driverUserId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.id, 10);
    let driverIndex = -1;
    const driverData = data.driverList?.find(({ userId }, index) => {
      if (userId === driverUserId) {
        driverIndex = index;
        return true;
      }
      return false;
    });

    // Preenche o Estágio e Data Limite de Aceite
    if (driverIndex !== -1) {
      data.driverList[driverIndex].stage = confirmStage;
      data.driverList[driverIndex].acceptanceDeadline = null;
    }

    // Atualiza a task
    await api
      .put('/Task/UpdateTaskStage', data)
      .then(() => {
        message.success('Treinamento Aceito!');
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao Aceitar o Treinamento!');
      });

    // Adiciona Atividade no Treinamento
    await TaskCommentFunctions.addTaskComment(data, 'TaskAccepted', 'Driver', '');

    // Envia Notificação para Logística, informando o Aceite do Treinamento
    await ScheduleUtils.sendNotificationToLogistics(data, data.scheduleType, driverData, true);

    task.driver.stage = confirmStage; // Altera o valor no objeto original
    setLoadingSchedule(false);
  };

  const denyTask = async (task) => {
    const data = JSON.parse(JSON.stringify(task)); // Deep Copy da task
    const driverUserId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.id, 10);
    let driverIndex = -1;
    data.driverList?.find(({ userId }, index) => {
      if (userId === driverUserId) {
        driverIndex = index;
        return true;
      }
      return false;
    });

    // Preenche o Estágio e Data Limite de Aceite
    if (driverIndex !== -1) {
      data.driverList.splice(driverIndex, 1);
      data.driverUserIdList.splice(driverIndex, 1);
    }

    onModalOk = async () => {
      setLoadingSchedule(true);

      await api
        .put('/Task/UpdateTaskStage', data)
        .then(() => {
          message.success('Treinamento Negado!');
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo deu errado ao Negar o Treinamento!');
        });

      appointments.splice(task.index, 1); // Remove da lista
      setAppointments([...appointments.map((appointment, index) => ({ ...appointment, index }))]);
      setLoadingSchedule(false);
    };

    event = 'DriverTaskDenied';
    currentOpenTask = task;
    setReasonModalOpen(true);
  };

  // const startTask = async (task) => {
  //   setLoadingSchedule(true);
  //   const data = JSON.parse(JSON.stringify(task)); // Deep Copy da task
  //   const onGoingStage = stages?.find(({ id }) => id === 7);
  //   const startDate = new Date();
  //   data.driver.stage = onGoingStage;
  //   data.driver.startDate = startDate;

  //   await api
  //     .put('/Task/UpdateTaskStage', data)
  //     .then(() => {
  //       message.success('Treinamento Iniciado!');
  //     })
  //     .catch((error) => {
  //       Utils.logError(error);
  //       message.error('Oops. Algo deu errado!');
  //     });

  //   await TaskCommentFunctions.addTaskComment(data, 'TaskStarted', 'Driver', '');

  //   // Altera o valor no objeto original
  //   task.driver.stage = onGoingStage;
  //   task.driver.startDate = startDate;
  //   setLoadingSchedule(false);
  // };

  const editTask = async (task, newValues) => {
    const data = JSON.parse(JSON.stringify(task)); // Deep Copy da task
    const completedLateStage = stages?.find(({ id }) => id === 9);
    data[data.scheduleType].stage = completedLateStage;
    data[data.scheduleType].acceptanceDeadline = null;
    data[data.scheduleType].startDate = new Date(newValues.newStartDate);
    data[data.scheduleType].endDate = new Date(newValues.newEndDate);
    data.qtyStudentsTransported = parseInt(newValues.newQtyStudentsTransported, 10);
    data.startDate = new Date(data.startDate);
    data.endDate = new Date(data.endDate);

    onModalOk = async () => {
      setLoadingSchedule(true);

      await api
        .put('/Task/UpdateTaskScheduling', data)
        .then(() => {
          message.success('Treinamento Editado!');
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo deu errado!');
        });

      setLoadingSchedule(false);
    };

    event = 'TaskEditedDelayed';
    currentOpenTask = data;
    setReasonModalOpen(true);
  };

  const finishTask = async (task) => {
    setLoadingSchedule(true);
    const data = JSON.parse(JSON.stringify(task)); // Deep Copy da task
    const finishedTask = stages?.find(({ id }) => id === 5);
    const endDate = new Date();
    const totalTime = moment(endDate).diff(moment(new Date(data.driver.startDate)), 'minutes');

    // Converte para Date Time para o banco aceitar de volta
    data.startDate = new Date(data.startDate);
    data.endDate = new Date(data.endDate);
    data.driver.startDate = new Date(data.driver.startDate);

    data.driver.stage = finishedTask;
    data.driver.endDate = endDate;
    data.driver.totalTrainingTime = totalTime;

    await api
      .put('/Task/UpdateTaskScheduling', data)
      .then(() => {
        message.success('Treinamento Concluído!');
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    // Altera o valor no objeto original
    task.driver.stage = finishedTask;
    task.driver.endDate = endDate;
    task.driver.totalTrainingTime = totalTime;
    setLoadingSchedule(false);
  };

  const onAppointmentUpdating = (e) => {
    e.cancel = true;
  };

  const onAppointmentFormOpening = (e) => {
    const { form, popup } = e;
    currentOpenTask = e.appointmentData;
    // let startTraining;
    let editingTask;
    const driverUserId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.id, 10);
    let driverIndex = -1;
    currentOpenTask.driverList?.find(({ userId }, index) => {
      if (userId === driverUserId) {
        driverIndex = index;
        return true;
      }
      return false;
    });
    const driverStageId = driverIndex !== -1 ? currentOpenTask.driverList[driverIndex].stage.id : 0;

    // const dateNow = new Date();
    // const endDate = new Date(currentOpenTask.endDate);
    // const startDate = new Date(currentOpenTask.startDate);

    // if (dateNow >= startDate && dateNow <= endDate) {
    //   startTraining = false;
    // } else {
    //   startTraining = true;
    // }

    // Titulo Popup
    popup.option('showTitle', true);
    popup.option('title', e.appointmentData.cardTitle);
    popup.option('hideOnOutsideClick', true);
    popup.option('showCloseButton', true);

    // Botões Popup
    let toolbar = popup.option('toolbarItems');

    // 1 = Aguardando
    if (driverStageId === 1) {
      toolbar[0].options.text = 'Aceitar';
      toolbar[0].location = 'center';
      toolbar[0].onClick = () => {
        confirmTask(e.appointmentData);
      };

      toolbar[1].options = {};
      toolbar[1].options.text = 'Negar';
      toolbar[1].location = 'center';
      toolbar[1].onClick = () => {
        denyTask(e.appointmentData);
      };
    } else {
      toolbar = [];
    }

    // if (currentOpenTask.driver.stage.id === 3) {
    //   toolbar = [
    //     {
    //       location: 'center',
    //       onClick: () => startTask(e.appointmentData),
    //       options: {
    //         disabled: startTraining,
    //         text: 'Iniciar Treinamento',
    //       },
    //       shortcut: 'cancel',
    //     },
    //   ];
    // }

    if (currentOpenTask.stage?.id === 3 && (driverStageId === 3 || driverStageId === 9)) {
      toolbar = [
        {
          location: 'center',
          onClick: () => setOpenModalTrainingConclusion(true),
          options: {
            text: 'Concluir Treinamento',
          },
          shortcut: 'cancel',
        },
        {
          location: 'center',
          onClick: () => setOpenModalIntercurrence(true),
          options: {
            text: 'Intercorrência',
          },
          shortcut: 'cancel',
        },
      ];
    }

    if (driverStageId === 9) {
      editingTask = true;
      toolbar = [
        {
          location: 'center',
          onClick: () => {
            const newValues = {
              newStartDate: form
                .getEditor(`${currentOpenTask.scheduleType}.startDate`)
                .option('value'),
              newEndDate: form.getEditor(`${currentOpenTask.scheduleType}.endDate`).option('value'),
              newQtyStudentsTransported: form.getEditor('qtyStudentsTransported').option('value'),
            };
            editTask(e.appointmentData, newValues);
          },
          options: {
            text: 'Salvar',
          },
          shortcut: 'cancel',
        },
      ];
    }

    if (driverStageId === 5) {
      toolbar = [];
    }

    popup.option('toolbarItems', toolbar);

    const itens = ScheduleUtils.generatePopupFieldsDriver(
      form.itemOption('mainGroup').items,
      fieldsPermissions,
      stages,
      trainingsOptions,
      editingTask
    );

    form.itemOption('mainGroup', 'items', itens);
  };

  const onListItemClick = (e) => {
    const data = e.itemData;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
      }, 300);
    } else if (data.id === currentListItem) {
      schedulerRef.current.instance.showAppointmentPopup(data);
    }
    currentListItem = data.id;
  };

  const onCurrentViewChange = (value) => {
    localStorage.setItem('conecta__currentView', value);
    setCurrentView(value);
  };

  const onCurrentDateChange = (value) => {
    localStorage.setItem('conecta__currentDate', value);
    setCurrentDate(value);
  };

  const renderDataCell = (itemData) => {
    const CellTemplate = currentView === 'month' ? DataCellMonth : DataCell;
    return (
      <CellTemplate
        itemData={itemData}
        workDays={driver?.workDays}
        breakTime={{
          breakStart: driver?.breakStart,
          breakFinish: driver?.breakFinish,
        }}
        blockedDates={blockedDates}
      />
    );
  };

  const renderDateCell = (itemData) => (
    <DateCell
      itemData={itemData}
      workDays={driver?.workDays}
      breakTime={{
        breakStart: driver?.breakStart,
        breakFinish: driver?.breakFinish,
      }}
      blockedDates={blockedDates}
    />
  );

  const appointmentRender = (itemData) => <Appointment itemData={itemData} type="driver" />;

  const renderItem = (itemData) => (
    <ListItem
      itemData={itemData}
      confirmTask={confirmTask}
      denyTask={denyTask}
      loading={loadingSchedule}
    />
  );

  const getDriver = async () => {
    try {
      setLoading(true);
      const driverUserId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.id, 10);

      const user = await api
        .get(`/User?id=${driverUserId}`)
        .then((res) => res.data)
        .catch((error) => error);

      const driver = await api
        .get(`/Driver?id=${user.company.id}`)
        .then((res) => res.data)
        .catch((error) => error);

      const tasks = await api
        .get(
          `/Task?filters[0].Field=DriverUserIdList&filters[0].Condition=NUMBER.ARRAY_CONTAIN&filters[0].Value=${driverUserId}`
        )
        .then((res) => res.data)
        .catch((error) => error);

      const mappedTasks = tasks?.map((task, index) => {
        // Calcula a Duração do Treinamento
        const durationMinutes = moment(new Date(task.endDate)).diff(
          new Date(task.startDate),
          'minutes'
        );
        const durationHours = Math.ceil(durationMinutes / 60);

        const driverData = task.driverList?.find(({ userId }) => userId === driverUserId);

        return {
          ...task,
          driver: driverData,
          text: task.product.name,
          scheduleType: 'driver',
          index,
          durationHours,
          durationMinutes,
        };
      });
      setDriver(driver);
      setAppointments(mappedTasks);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utils.logError(error);
      message.error('Oops. Algo deu errado!');
    }
  };

  const getBlockedDays = () =>
    api
      .get(
        `/BlockedDay?filters[0].Field=DriverId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${parseInt(
          JSON.parse(localStorage.getItem('conecta__userData'))?.id,
          10
        )}`
      )
      .then((res) => setBlockedDates(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const getTrainings = () =>
    api
      .get('/Trainings')
      .then((res) => setTrainingsOptions(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const getStages = () =>
    api
      .get(`/Stage`)
      .then((res) => setStages(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const getReasons = async () => {
    const reasons = await api
      .get(`/DenyTaskReason?filters[0].Field=Type&filters[0].Condition=IN&filters[0].Value=Driver`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Motivos!');
      });

    setReasonsOptions(
      reasons?.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
    );
  };

  const getFinishMargin = () =>
    api
      .get(`/Settings?id=1`)
      .then((res) => {
        finishMargin = res.data.conclusionMargin;
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'ScheduleDriver') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
    getDriver();
    getBlockedDays();
    getTrainings();
    getStages();
    getReasons();
    getFinishMargin();
  }, []);

  if (loading) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!loading) {
    return (
      <Row gutter={[12]}>
        <Col span={24}>
          <div className="changeVisibility">
            <Switch
              checkedChildren={<CalendarOutlined />}
              unCheckedChildren={<UnorderedListOutlined />}
              defaultChecked
              onClick={() => {
                setChangeVisibility(!changeVisibility);
              }}
            />
          </div>
          <List
            id="tasks-list"
            style={{ marginTop: 35 }}
            visible={changeVisibility}
            dataSource={appointments?.filter(
              ({
                driver: {
                  stage: { id },
                },
              }) => id !== 10
            )}
            height="calc(100vh - 90px)"
            searchExpr={['text', 'classId', 'quoteTitle', 'stage.driver.name', 'startDate']}
            searchEnabled={true}
            itemRender={renderItem}
            onItemClick={onListItemClick}
          />
          <CustomDropdown
            type="Driver"
            sourceId={parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.id, 10)}
            dataSource={appointments}
            updateBlockedDays={getBlockedDays}
            workDays={driver?.workDays}
            breakTime={{
              breakStart: driver?.breakStart,
              breakFinish: driver?.breakFinish,
            }}
            blockedDates={blockedDates}
            visible={changeVisibility}
          />
          <Scheduler
            style={
              !changeVisibility
                ? null
                : {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: 0,
                    width: 0,
                    visibility: 'hidden',
                  }
            }
            ref={schedulerRef}
            disabled={loadingSchedule}
            id="scheduleOperator"
            views={views}
            dataSource={appointments}
            defaultCurrentView={
              localStorage.getItem('conecta__currentView')
                ? localStorage.getItem('conecta__currentView')
                : currentView
            }
            defaultCurrentDate={
              localStorage.getItem('conecta__currentDate')
                ? new Date(localStorage.getItem('conecta__currentDate'))
                : currentDate
            }
            startDayHour={
              driver?.startBusinessHour ? new Date(driver?.startBusinessHour).getHours() : 8
            }
            endDayHour={driver?.endBusinessHour ? new Date(driver?.endBusinessHour).getHours() : 17}
            showAllDayPanel={false}
            firstDayOfWeek={1}
            maxAppointmentsPerCell={1}
            height="calc(100vh - 98px)"
            dataCellRender={renderDataCell}
            dateCellRender={renderDateCell}
            onCurrentViewChange={onCurrentViewChange}
            onCurrentDateChange={onCurrentDateChange}
            onAppointmentFormOpening={onAppointmentFormOpening}
            onAppointmentUpdating={onAppointmentUpdating}
            appointmentComponent={appointmentRender}
            crossScrollingEnabled
            editing={{
              allowResizing: false,
              allowDeleting: false,
            }}
            onCellClick={(e) => {
              e.cancel = true;
            }}
            useDropDownViewSwitcher
          />
        </Col>
        <ModalIntercurrence
          openModalIntercurrence={openModalIntercurrence}
          onCancel={() => setOpenModalIntercurrence(!openModalIntercurrence)}
          currentOpenTask={currentOpenTask}
          eventSource="Driver"
        />
        <ModalTrainingConclusion
          openModalTrainingConclusion={openModalTrainingConclusion}
          onCancel={() => setOpenModalTrainingConclusion(!openModalTrainingConclusion)}
          currentOpenTask={currentOpenTask}
          onOk={finishTask}
          loading={loadingSchedule}
          setOpenModalTrainingConclusion={setOpenModalTrainingConclusion}
          eventSource="Driver"
          reasonsOptions={reasonsOptions}
          finishMargin={finishMargin}
        />
        <ReasonModal
          isModalOpen={isReasonModalOpen}
          setModalOpen={setReasonModalOpen}
          reasonsOptions={reasonsOptions}
          currentOpenTask={currentOpenTask}
          event={event}
          eventSource="Driver"
          onOk={onModalOk}
        />
      </Row>
    );
  }
}

const ScheduleDriver = memo(ScheduleDriverComponent);
export default ScheduleDriver;
