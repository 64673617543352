/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Row,
  Select,
  Skeleton,
  Tooltip,
} from 'antd';
import { Accordion, ScrollView } from 'devextreme-react';
import moment from 'moment';

import {
  CalendarOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import {
  addSchedulingScheduleFilter,
  fetchSchedulingScheduleFilter,
  fetchTasks,
  updateSchedulingScheduleFilter,
} from '../../Pages/ScheduleConecta/API/ScheduleSchedulingAPI';

import QuoteListFunctions from './QuoteListFunctions';
import Task from './Task';

import './QuoteList.scss';

let timeoutFilter = null;

const QuoteList = forwardRef(
  (
    {
      loading,
      isLogistic,
      stages: stagesParam,
      trainings: trainingsParam,
      schedulerRef,
      dealId,
      bitrixUsers,
      quoteList,
      setQuoteList,
      trainingCenterOptions,
      fetchInstructors,
      fetchSlots,
      callSlotGeneration,
      handleFetchTasks,
      trainingCenter,
      setTrainingCenter,
      taskRescheduling,
      exitReschedulingMode,
      onAppointmentDblClick,
      setIsTaskReadOnly,
      schedulingScheduleFilter,
      setSchedulingScheduleFilter,
      fieldsPermissions,
      getCurrentDate,
      instructorBookingPopupRef,
      resumePopupRef,
      overviewPopupRef,
      newSlotPopupRef,
    },
    ref
  ) => {
    const [form] = Form.useForm();

    const [stageOptions, setStageOptions] = useState();
    const [trainingsOptions, setTrainingsOptions] = useState();
    const [loader, setLoader] = useState(false);

    const userData = JSON.parse(localStorage.getItem('conecta__userData'));
    const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));
    const initialCtId = scheduleData ? scheduleData.trainingCenterId : undefined;

    const handleFilterClear = async () => {
      const submitData = {};
      submitData.filters = {
        currentDate: null,
        trainingCenterId: initialCtId,
        global: '',
        stages: stagesParam?.filter(({ id }) => ![3, 12].includes(id))?.map(({ id }) => id),
        responsibles: userData?.bitrixId ? [userData.bitrixId] : [],
        trainings: [],
      };

      submitData.id = schedulingScheduleFilter.id;
      await updateSchedulingScheduleFilter(submitData);

      message.success('Filtros limpos!');
      window.location.reload();
    };

    const handleFilterSave = async () => {
      if (timeoutFilter) {
        clearTimeout(timeoutFilter);
      }

      timeoutFilter = setTimeout(async () => {
        if (!schedulerRef?.current?.instance) {
          handleFilterSave();
          return;
        }

        const scheduleInstance = schedulerRef.current.instance;
        const date = moment(scheduleInstance.option('currentDate')).format('YYYY-MM-DD');
        const formValues = form.getFieldsValue();

        const submitData = {};
        submitData.filters = {
          currentDate: date,
          trainingCenterId: formValues.trainingCenterId,
          global: formValues.global,
          stages: formValues.stages,
          responsibles: formValues.responsibles,
          trainings: formValues.trainings,
        };

        if (schedulingScheduleFilter) {
          submitData.id = schedulingScheduleFilter.id;

          updateSchedulingScheduleFilter(submitData);
          setSchedulingScheduleFilter(submitData);
        } else {
          addSchedulingScheduleFilter(submitData);

          fetchSchedulingScheduleFilter().then((data) => {
            setSchedulingScheduleFilter(data);
          });
        }
      }, 1500); // 1,5s
    };

    const filterQuotesList = (global, stages, responsibles, trainings, pQuoteList) => {
      const searchTerm = global
        ?.normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toUpperCase(); // Limpa e Padroniza a string

      const quotesFiltered = [];

      const stagesToFilter = stages;
      const responsiblesToFilter = responsibles;
      const trainingsToFilter = trainings;

      pQuoteList.forEach((quote) => {
        const tasksFiltered = quote.tasks?.filter(
          // eslint-disable-next-line array-callback-return, consistent-return
          (task) => {
            let taskTextConcat = '';
            taskTextConcat += task.classId;
            taskTextConcat += ` ${task.cardTitle}`;
            taskTextConcat += ` ${task.text}`;
            taskTextConcat += ` Alunos: ${task.qtyStudentsPerClass}`;
            taskTextConcat += ` ${task.stage.name}`;
            taskTextConcat += ` ${task.startDateFormatted ?? ''}`;
            taskTextConcat += ` ${task.dealTitle}`;
            taskTextConcat += ` ${task.dealClient}`;
            taskTextConcat += ` ${task.dealClientCNPJ}`;
            taskTextConcat += ` ${task.trainingType}`;
            taskTextConcat += ` ${task.dealClientCommercialName}`;
            taskTextConcat += ` ${task.dealId}`;
            taskTextConcat = taskTextConcat
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
              .toUpperCase(); // Limpa e Padroniza a string

            let validTaskText = taskTextConcat.includes(searchTerm);
            let validTaskStage = stagesToFilter?.some((stage) => stage === task.stage.id);
            let validTaskResponsible = responsiblesToFilter?.some(
              (responsible) => responsible === task.responsible
            );
            let validTaskTraining = trainingsToFilter?.some(
              (training) => training === task.group.id
            );

            if (!global || searchTerm === '') {
              validTaskText = true;
            }

            if (!stagesToFilter || stagesToFilter.length === 0) {
              validTaskStage = true;
            }

            if (!trainingsToFilter || trainingsToFilter.length === 0) {
              validTaskTraining = true;
            }

            if (!responsiblesToFilter || responsiblesToFilter.length === 0) {
              validTaskResponsible = true;
            }

            if (validTaskText && validTaskStage && validTaskTraining && validTaskResponsible) {
              return task;
            }
          }
        );

        if (tasksFiltered.length > 0) {
          quotesFiltered.push({ ...quote, tasks: tasksFiltered });
        }
      });

      setQuoteList(quotesFiltered);
      setLoader(false);
    };

    const fetchQuoteList = async ({ global, stages, responsibles, trainings }) => {
      try {
        if (isLogistic) {
          return [];
        }

        setLoader(true);

        // Filtros
        const globalF = schedulingScheduleFilter?.filters?.global ?? global;
        const stagesF = schedulingScheduleFilter?.filters?.stages ?? stages;
        const responsiblesF = schedulingScheduleFilter?.filters?.responsibles ?? responsibles;
        const trainingsF = schedulingScheduleFilter?.filters?.trainings ?? trainings ?? [];

        const taskFilters = {
          responsibles,
          stages,
          trainings,
        };

        const tasks = await fetchTasks(taskFilters);

        const taskMap = new Map();
        tasks.forEach((task) => {
          if (!taskMap.has(task.dealId)) {
            taskMap.set(task.dealId, {
              id: task.dealId,
              name: task.quoteTitle,
              tasks: [],
            });
          }

          taskMap.get(task.dealId).tasks.push(task);
        });

        // Step 2: Convert the Map to an array of task objects
        const quoteList = Array.from(taskMap.values());

        filterQuotesList(globalF, stagesF, responsiblesF, trainingsF, quoteList);
        return quoteList;
      } catch (error) {
        setLoader(false);
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar a Lista de Treinamentos!');
        return [];
      }
    };

    const handleChange = async (value) => {
      try {
        const trainingCenter = trainingCenterOptions.find(({ id }) => id === value);
        if (!trainingCenter) {
          message.error('Centro de treinamento não encontrado');
          return;
        }
        const ctData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

        const newCtData = {
          trainingCenterId: trainingCenter.id,
          trainingCenter: trainingCenter.commercialName,
          date: ctData.date,
          viewType: ctData.viewType,
          maxCapacityPerDay: trainingCenter.maxCapacityPerDay,
        };

        localStorage.setItem('conecta__scheduleData', JSON.stringify(newCtData));

        setTrainingCenter(trainingCenter);
        await fetchInstructors();
        const slotsToFunction = await fetchSlots(trainingCenter);
        callSlotGeneration(trainingCenter, null, null, null, slotsToFunction);

        await handleFetchTasks(
          trainingCenter,
          schedulerRef.current.instance.getStartViewDate(),
          schedulerRef.current.instance.getEndViewDate()
        );
        form.submit();
      } catch (error) {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao filtrar o Centro de Treinamento!');
      } finally {
        handleFilterSave();
      }
    };

    const renderQuote = (itemData) => (
      <Task
        itemData={itemData}
        taskRescheduling={taskRescheduling}
        onAppointmentDblClick={onAppointmentDblClick}
        setIsTaskReadOnly={setIsTaskReadOnly}
      />
    );

    const moreMenu = () => {
      const currentDate = new Date(getCurrentDate());
      const canAddNewSlot =
        fieldsPermissions?.NewSlot?.access >= 1 &&
        trainingCenter?.workDays?.includes(currentDate?.getDay()) &&
        currentDate >= new Date().setHours(0, 0, 0, 0);

      const canBookInstructor = fieldsPermissions?.BookInstructorsButton?.access >= 1;

      return (
        <Menu>
          {fieldsPermissions?.BookInstructorsButton?.access >= 1 && (
            <Menu.Item
              key="1"
              onClick={() => {
                if (canBookInstructor) {
                  instructorBookingPopupRef.current.instance.show();
                }
              }}
              disabled={!canBookInstructor}
              icon={<CalendarOutlined />}
            >
              Reserva Instrutores
            </Menu.Item>
          )}

          <Menu.Item
            key="2"
            onClick={() => resumePopupRef.current.instance.show()}
            icon={<UnorderedListOutlined />}
          >
            Resumo
          </Menu.Item>

          <Menu.Item
            key="3"
            onClick={() => overviewPopupRef.current.instance.show()}
            icon={<UnorderedListOutlined />}
          >
            Overview
          </Menu.Item>

          {fieldsPermissions?.NewSlot?.access >= 1 && (
            <Menu.Item
              key="4"
              onClick={() => {
                if (canAddNewSlot) {
                  newSlotPopupRef.current.instance.show();
                }
              }}
              disabled={!canAddNewSlot}
              icon={<PlusCircleOutlined />}
            >
              Novo Slot
            </Menu.Item>
          )}
        </Menu>
      );
    };

    useImperativeHandle(ref, () => ({
      handleFilterSave,
    }));

    useEffect(() => {
      const stages = stagesParam?.map(({ id, name }) => ({ label: name, value: id }));
      stages?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
      setStageOptions(stages);

      const trainings = trainingsParam?.map(({ id, name }) => ({ label: name, value: id }));
      trainings?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
      setTrainingsOptions(trainings);
    }, []);

    useEffect(() => {
      if (dealId) {
        form.setFieldValue('global', dealId);
      }

      form.submit();
    }, []);

    useEffect(() => {
      QuoteListFunctions.calculateQuoteListTotal(quoteList);
    }, [quoteList]);

    return (
      <>
        <Form
          form={form}
          disabled={loading || loader}
          name="QuotesFilter"
          onFinish={fetchQuoteList}
          layout="vertical"
          autoComplete="off"
          style={
            isLogistic
              ? null
              : {
                  position: 'absolute',
                  width: '475%',
                  zIndex: 3,
                }
          }
          initialValues={
            schedulingScheduleFilter
              ? schedulingScheduleFilter.filters
              : {
                  responsibles: userData?.bitrixId ? [userData.bitrixId] : [],
                  stages: stagesParam
                    ?.filter(({ id }) => ![3, 12].includes(id))
                    ?.map(({ id }) => id),
                  trainingCenterId: initialCtId,
                }
          }
        >
          <Row gutter={[24]}>
            <Col span={5}>
              <Form.Item label="CT" name="trainingCenterId">
                <Select
                  options={trainingCenterOptions.map((tc) => ({
                    label: tc.commercialName,
                    value: tc.id,
                  }))}
                  loading={loading}
                  onChange={handleChange}
                  placeholder="Selecione um CT"
                  optionFilterProp="label"
                  dropdownStyle={{ borderRadius: 16 }}
                />
              </Form.Item>
            </Col>

            {taskRescheduling && (
              <Col span={19}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'rgba(177, 156, 217, 0.2)',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '10px 16px 16px 10px',
                    marginTop: '22px',
                  }}
                >
                  <span style={{ flex: 2, textAlign: 'center' }}>Modo de Reagendamento</span>
                  <Button danger type="text" disabled={loading} onClick={exitReschedulingMode}>
                    Cancelar
                  </Button>
                </div>
              </Col>
            )}

            {!taskRescheduling && (
              <>
                {!isLogistic && (
                  <Col span={5}>
                    <Form.Item label="Global" className="tooltip-globalfilter">
                      <Tooltip
                        placement="right"
                        title="É possível filtrar por: Turma, Treinamento, Qtd. Alunos, Tipo de Treinamento, Estágio, Data (DD/MM/AAAA), Número do Negócio, Nome do Negócio, Nome do Cliente e CNPJ."
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Form.Item>
                    <Form.Item name="global">
                      <Input
                        placeholder="Filtro"
                        prefix={<SearchOutlined />}
                        allowClear
                        onChange={handleFilterSave}
                      />
                    </Form.Item>
                  </Col>
                )}

                {!isLogistic && (
                  <Col span={4}>
                    <Form.Item label="Responsável" name="responsibles">
                      <Select
                        options={bitrixUsers}
                        allowClear
                        placeholder="Selecione"
                        optionFilterProp="label"
                        showSearch
                        dropdownStyle={{ borderRadius: 16 }}
                        removeIcon={false}
                        mode="multiple"
                        maxTagCount="responsive"
                        onChange={(values) => {
                          Utils.handleSelectAllChange(values, bitrixUsers, 'responsibles', form);
                          handleFilterSave();
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}

                {!isLogistic && (
                  <Col span={4}>
                    <Form.Item label="Estágios" name="stages">
                      <Select
                        options={stageOptions}
                        allowClear
                        placeholder="Selecione"
                        optionFilterProp="label"
                        showSearch
                        dropdownStyle={{ borderRadius: 16 }}
                        removeIcon={false}
                        mode="multiple"
                        maxTagCount="responsive"
                        onChange={(values) => {
                          Utils.handleSelectAllChange(values, stageOptions, 'stages', form);
                          handleFilterSave();
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}

                {!isLogistic && (
                  <Col span={4}>
                    <Form.Item label="Treinamentos" name="trainings">
                      <Select
                        options={trainingsOptions}
                        allowClear
                        placeholder="Selecione"
                        optionFilterProp="label"
                        showSearch
                        dropdownStyle={{ borderRadius: 16 }}
                        removeIcon={false}
                        mode="multiple"
                        maxTagCount="responsive"
                        onChange={(values) => {
                          Utils.handleSelectAllChange(values, trainingsOptions, 'trainings', form);
                          handleFilterSave();
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col span={2}>
                  <Form.Item label=" ">
                    <div style={{ display: 'flex', gap: 12 }}>
                      {!isLogistic && (
                        <Tooltip title="Pesquisar">
                          <Button type="primary" icon={<SearchOutlined />} htmlType="submit" />
                        </Tooltip>
                      )}

                      <Tooltip title="Limpar Filtros">
                        <Button
                          icon={<i className="dx-icon-clearformat dx-icon-custom-style" />}
                          onClick={handleFilterClear}
                          disabled={!schedulingScheduleFilter || loading || loader}
                        />
                      </Tooltip>

                      <Dropdown overlay={moreMenu} trigger={['hover']}>
                        <Button icon={<MoreOutlined />} />
                      </Dropdown>
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>

        {(loading || loader) && !isLogistic && (
          <div className="quote-list-skeleton">
            <Skeleton.Input active block size="large" className="quote-list-skeleton-input" />
            <Skeleton.Input active block size="large" className="quote-list-skeleton-input" />
            <Skeleton.Input active block size="large" className="quote-list-skeleton-input" />
          </div>
        )}

        {!loading && !loader && !isLogistic && (
          <ScrollView id="scrollQuotes">
            <Accordion
              id="quotesList"
              disabled={loading}
              dataSource={taskRescheduling ?? quoteList}
              collapsible
              multiple
              itemTitleRender={(quote) => (
                <h2>
                  {quote.trainingsIndicator} {quote.id} - {quote.name}
                </h2>
              )}
              itemRender={renderQuote}
            />
          </ScrollView>
        )}
      </>
    );
  }
);

export default React.memo(QuoteList);
