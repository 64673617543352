/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import Utils from '../../../Assets/Scripts/Utils';
import TableAntd from '../../../Components/TableAntd/TableAntd';
import { api } from '../../../Services/axiosService';

export default function TrainingCenterList() {
  const [requiredDocuments, setRequiredDocuments] = useState();

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'commercialName',
        title: 'Nome',
        ...getColumnSearchProps('commercialName', 'Nome'),
        sorter: (a, b) => a.commercialName?.localeCompare(b.commercialName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'cnpj',
        title: 'CNPJ',
        width: '15%',
        ...getColumnSearchProps('cnpj', 'CNPJ'),
      },
      {
        dataIndex: 'email',
        title: 'E-mail',
        width: '25%',
        ...getColumnSearchProps('email', 'E-mail'),
        sorter: (a, b) => a.email?.localeCompare(b.email),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'city',
        title: 'Cidade',
        ...getColumnSearchProps('city', 'Cidade'),
        sorter: (a, b) => a.city?.localeCompare(b.city),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  const fetchSettings = async () => {
    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => Utils.decryptSettings(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });
    const reqDocs = res.requiredDocumentsCT?.split(',')?.map((item) => parseInt(item, 10)) ?? [];
    setRequiredDocuments(reqDocs);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  if (requiredDocuments) {
    return (
      <TableAntd
        columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
        routePermissions="TrainingCenter"
        requestName="TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento"
        route="/CentroTreinamento"
        toggleStatusRoute="/Company"
        cdPage="id"
        btnNew
        requiredDocuments={requiredDocuments}
      />
    );
  }
}
