import React from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

import TableAntd from '../../Components/TableAntd/TableAntd';
import { api } from '../../Services/axiosService';

export default function InstructorManagementPage() {
  const navigate = useNavigate();

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Nome Instrutor',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'acceptedTasksCount',
        title: 'Qtd. Dias Agendados',
        width: '25%',
        ...getColumnSearchProps('acceptedTasksCount', 'Qtd. Dias Agendados'),
        sorter: (a, b) => a.acceptedTasksCount.localeCompare(b.acceptedTasksCount),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'score',
        title: 'Score',
        width: '25%',
        ...getColumnSearchProps('score', 'Score'),
        sorter: (a, b) => a.score.localeCompare(b.score),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  const actionMenu = (keyItem, record) => (
    <Menu.Item
      onClick={async () => {
        const userId = await api
          .get(
            `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${record.id}`
          )
          .then((res) => res.data[0].id)
          .catch((error) => error);

        navigate(`/GestaoInstrutores/Agenda/Instrutor/${userId}`);
      }}
      key="2"
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
          }}
        >
          Vizualizar Agenda
        </button>
      </div>
    </Menu.Item>
  );

  return (
    <TableAntd
      columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
      routePermissions="InstructorManagement"
      requestName="Instructor?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Instrutor"
      route="/GestaoInstrutores"
      cdPage="id"
      notDelete={true}
      notEdit={true}
      notInactivate={true}
      menuItems={actionMenu}
    />
  );
}
