/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Drawer, Form, message } from 'antd';
import moment from 'moment';

import Utils from '../../../Assets/Scripts/Utils';
import ReasonModal from '../../../Components/ReasonModal/ReasonModal';
import ModalUploadFile from '../../../Components/Schedule/ModalUploadFile';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../../Services/axiosService';
import { fetchComments, fetchTasks } from '../API/ScheduleSchedulingAPI';
import SchedulingFunctions from '../API/SchedulingFunctions';
import FormLoader from '../Assets/FormLoader';

import ConfirmationModal from './ConfirmationModal';
import TaskDrawerExtra from './TaskDrawerExtra';
import TaskDrawerFooter from './TaskDrawerFooter';
import TaskForm from './TaskForm';

import './TaskDrawer.scss';

function TaskDrawer({
  taskId,
  setIsTaskDrawerVisible,
  fieldsPermissions,
  stages,
  trainingsOptions,
  ctResources,
  instructorOptions,
  transportOptions,
  vehicleOptions,
  driverOptions,
  reasonNoBillingOptions,
  trainingCenterOptions,
  productOptions,
  certificateModelOptions,
  quoteList,
  appointments,
  isReasonModalOpen,
  setReasonModalOpen,
  reasonsOptions,
  event,
  onModalOk,
  taskAcceptanceDeadline,
  ctResourcesSlots,
  setAppointments,
  setQuoteList,
  isTaskReadOnly,
  setIsTaskReadOnly,
  page,
}) {
  const [taskData, setTaskData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [availableInstructors, setAvailableInstructors] = useState([]);
  const [uploadFileType, setUploadFileType] = useState(null);
  const [isUploadModalOpen, setUploadFileModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalConfirmAction, setConfirmationModalConfirmAction] = useState('confirm');
  const [isEditing, setIsEditing] = useState(false);
  const [render, setRender] = useState(false);

  const [form] = Form.useForm();

  const notifySameInstructors = () => {
    message.warning('O "Instrutor" e o "Instrutor Auxiliar" não podem ser o mesmo!');
  };

  // Função para remover a marcação "Atualizado"
  const removeQuoteOrDealUpdatedBadge = (taskToUpdate) => {
    api
      .put(`/Task/RemoveQuoteOrDealUpdatedBadge?taskId=${taskToUpdate.id}`)
      .then(() => {})
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });
  };

  // Função para buscar os dados da tarefa
  const fetchTaskData = async () => {
    try {
      // Busca a tarefa pelo ID
      const [newTask] = await fetchTasks({ TaskIdList: [taskId] });
      const openTask = JSON.parse(JSON.stringify(newTask)); // Deep Copy da task

      SchedulingFunctions.mapTimeFields(openTask);
      SchedulingFunctions.mapFormData(openTask);
      setTaskData(openTask);

      // Remove a marcação "Atualizado" se necessário
      if (newTask.quoteOrDealUpdated) {
        removeQuoteOrDealUpdatedBadge(newTask);
      }

      const commentsCombined = await fetchComments(newTask);

      setComments(commentsCombined);

      // Busca disponibilidade dos instrutores
      const availableInstructorsData = await SchedulingFunctions.fetchInstructorsAvailability(
        instructorOptions,
        openTask.startDate,
        openTask.endDate
      );

      let combinedInstructorList = [];
      if (availableInstructorsData?.length > 0) {
        combinedInstructorList = instructorOptions?.map((instructor) => {
          const availability = availableInstructorsData.find((avail) => avail.id === instructor.id);
          const trainingGroupsList = instructor?.trainingKind?.map(({ id }) => id);

          return {
            label: instructor.name,
            value: instructor.id,
            hasTrainings: availability?.hasTrainings ?? false,
            isBlocked: availability?.isBlocked ?? false,
            disabled: availability?.isBlocked ?? false,
            trainingGroupsList,
          };
        });
      }

      setAvailableInstructors(combinedInstructorList);
      return newTask;
    } catch (error) {
      console.error('Erro ao buscar dados da tarefa:', error);
      message.error('Oops. Algo deu errado ao tentar buscar os dados do Treinamento!');
      return null;
    }
  };

  // Função chamada quando o modal é fechado
  const handleClose = () => {
    // Remove a marcação "Atualizado" na tela
    if (taskData && taskData.quoteOrDealUpdated) {
      const taskQuote = quoteList
        ?.find(({ id }) => id === taskData.dealId)
        ?.tasks?.find(({ id }) => id === taskData.id);
      const taskAppointment = appointments?.find(({ id }) => id === taskData.id);
      if (taskQuote) {
        taskQuote.quoteOrDealUpdated = false;
      }
      if (taskAppointment) {
        taskAppointment.quoteOrDealUpdated = false;
      }
    }

    setIsTaskDrawerVisible(false);
  };

  const waitForTaskUpdate = async (beforePutDate) => {
    let taskUpdateComplete = false;
    const MAX_RETRIES = 5;
    let attempts = 0;

    while (!taskUpdateComplete && attempts < MAX_RETRIES) {
      const fetchTaskRes = await fetchTaskData();
      const afterPutDate = moment(fetchTaskRes.updateDate);

      taskUpdateComplete = afterPutDate.isSameOrAfter(beforePutDate);

      if (!taskUpdateComplete) {
        attempts += 1;

        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000); // 1 Segundo
        });
      }
    }

    return taskUpdateComplete;
  };

  // Função para salvar os dados do formulário
  const handleActionUpdate = async (type) => {
    try {
      setIsLoading(true);

      const values = await form.validateFields();

      const userData = JSON.parse(localStorage.getItem('conecta__userData'));
      let newStageId = null;
      let otherDataChange = null;

      switch (type) {
        case 'confirm':
          newStageId = 3; // 3 = Estágio "Confirmado"
          otherDataChange = {
            confirmedBy: userData.name,
            idConfirmedBy: parseInt(userData.id, 10),
            confirmationDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          };
          break;
        case 'deny':
          newStageId = 4; // 4 = Estágio "Negado"
          break;
        case 'noClientContact':
          newStageId = 13; // 13 = Estágio "Reservado Sem Confirmação"
          break;
        default:
          newStageId = values?.stage?.id;
          break;
      }

      if (values?.stage?.id === 14) {
        newStageId = 14;
      }

      if (values.hasDocumentation) {
        newStageId = 5;
      }

      const newStage = stages.find(({ id }) => id === newStageId);

      let data = taskData;
      const oldTaskData = JSON.parse(JSON.stringify(data));

      // Atualiza o objeto antes de enviar
      data = { ...data, ...values, ...otherDataChange, stage: newStage };

      const shouldCancel = SchedulingFunctions.formatAppointmmentInfo(
        data,
        ctResources,
        taskAcceptanceDeadline,
        ctResourcesSlots
      );
      if (shouldCancel) {
        return;
      }

      if (
        data.mainInstructor &&
        data.assistantInstructor &&
        data.mainInstructor === data.assistantInstructor
      ) {
        notifySameInstructors();
        return;
      }

      const beforePutDate = moment().subtract(1, 'second');

      // Enviar para atualizar
      await api.put('/Task/UpdateTaskScheduling', data);

      // Eventos secundárias
      await TaskCommentFunctions.addTaskCommentActivities(data, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentHistory(data, oldTaskData, 'Scheduling');

      await TaskCommentFunctions.addTaskCommentDriver(data, oldTaskData, driverOptions);
      await TaskCommentFunctions.addTaskCommentVehicle(data, oldTaskData, vehicleOptions);

      const quote = quoteList?.find(({ id }) => id === data.dealId);
      const task = quote?.tasks?.find(({ id }) => id === data.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = data.stage;
        quote.tasks.splice(indexTask, 1, { ...task, ...data });
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }

      const appointment = appointments.find(({ id }) => id === data.id);
      const indexAppointment = appointments.indexOf(appointment);
      appointments.splice(indexAppointment, 1, data);
      setAppointments([...appointments]);

      setTaskData();
      setComments();

      await waitForTaskUpdate(beforePutDate);

      message.success('Atualização dos Treinamentos concluída!');
    } catch (error) {
      if (error?.errorFields?.length > 0) {
        const [firstField] = error.errorFields;
        form.scrollToField(firstField.name);

        message.warn(
          'Alguns campos estão com preenchimento incorreto, realize os ajustes necessários e tente novamente!',
          7
        );
      } else {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar atualizar os Treinamentos selecionados!');
      }
    } finally {
      setIsEditing(false);
      setIsLoading(false);
    }
  };

  const handlefetchTaskData = async () => {
    setIsLoading(true);
    await fetchTaskData();
    setIsLoading(false);
  };

  useEffect(() => {
    handlefetchTaskData();

    return () => {
      if (setIsTaskReadOnly) {
        setIsTaskReadOnly(false);
      }
    };
  }, []);

  return (
    <Drawer
      title={taskData?.cardTitle || 'Detalhes da Tarefa'}
      placement="right"
      width="90vw"
      className="training-card-drawer"
      onClose={handleClose}
      maskClosable={false}
      closable={!isLoading}
      open={true}
      footer={
        taskData && !isTaskReadOnly && isEditing ? (
          <TaskDrawerFooter
            fieldsPermissions={fieldsPermissions}
            task={taskData}
            isLoading={isLoading}
            handleActionUpdate={handleActionUpdate}
            setIsEditing={setIsEditing}
            form={form}
            render={render}
            setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            setConfirmationModalConfirmAction={setConfirmationModalConfirmAction}
          />
        ) : null
      }
      footerStyle={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
      }}
      extra={
        taskData ? (
          <TaskDrawerExtra
            isLoading={isLoading}
            fieldsPermissions={fieldsPermissions}
            form={form}
            task={taskData}
            isTaskReadOnly={isTaskReadOnly}
            setIsEditing={setIsEditing}
            certificateModelOptions={certificateModelOptions}
            productOptions={productOptions}
            handleTaskUpdate={handleActionUpdate}
            setComments={setComments}
            page={page}
          />
        ) : null
      }
    >
      {(!taskData || !comments || isLoading) && <FormLoader />}

      {taskData && comments && !isLoading && (
        <TaskForm
          form={form}
          task={taskData}
          isLoading={isLoading}
          fieldsPermissions={fieldsPermissions}
          stages={stages}
          trainingsOptions={trainingsOptions}
          ctResources={ctResources}
          transportOptions={transportOptions}
          reasonNoBillingOptions={reasonNoBillingOptions}
          trainingCenterOptions={trainingCenterOptions}
          vehicleOptions={vehicleOptions}
          driverOptions={driverOptions}
          productOptions={productOptions}
          certificateModelOptions={certificateModelOptions}
          comments={comments}
          availableInstructors={availableInstructors}
          instructorOptions={instructorOptions}
          handleTaskUpdate={handleActionUpdate}
          setUploadFileType={setUploadFileType}
          setUploadFileModalOpen={setUploadFileModalOpen}
          setComments={setComments}
          isTaskReadOnly={isTaskReadOnly}
          isEditing={isEditing}
          render={render}
          setRender={setRender}
        />
      )}

      <ReasonModal
        isModalOpen={isReasonModalOpen}
        setModalOpen={setReasonModalOpen}
        reasonsOptions={reasonsOptions}
        currentOpenTask={taskData}
        event={event}
        eventSource="Scheduling"
        onOk={onModalOk}
      />

      {isUploadModalOpen && (
        <ModalUploadFile
          setIsOpen={setUploadFileModalOpen}
          loading={isLoading}
          currentOpenTask={taskData}
          handleTaskUpdate={handleActionUpdate}
          type={uploadFileType}
          formTaskDrawer={form}
        />
      )}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          setIsOpen={setIsConfirmationModalOpen}
          onConfirm={() => {
            handleActionUpdate(confirmationModalConfirmAction);
          }}
          formData={form.getFieldsValue()}
        />
      )}
    </Drawer>
  );
}

export default TaskDrawer;
