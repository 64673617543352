import React from 'react';

function FormLoader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100% 1285"
      preserveAspectRatio="xMinYMin meet"
      style={{ width: '100%', height: 1070 }}
      fill="none"
    >
      <svg height="1070px">
        <defs>
          <g id="block-1">
            <rect x="40" width="44%" height="58" fill="#FDFDFD" stroke="#EDEEF0" />
          </g>
        </defs>

        <rect width="100%" height="1070" fill="#EEF2F4" />

        <rect x="20" y="0" width="46%" height="194" rx="10" fill="#F5F8F9" />
        <rect x="20" y="209" width="46%" height="247" rx="10" fill="#F5F8F9" />
        <rect x="20" y="471" width="46%" height="103" rx="10" fill="#F5F8F9" />
        <rect x="20" y="589" width="46%" height="103" rx="10" fill="#F5F8F9" />
        <rect x="20" y="707" width="46%" height="76" rx="10" fill="#F5F8F9" />
        <rect x="20" y="798" width="46%" height="57" rx="10" fill="#F5F8F9" />
        <rect x="20" y="870" width="46%" height="57" rx="10" fill="#F5F8F9" />

        <rect
          x="46%"
          y="-1"
          width="54%"
          height="94"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="144"
          width="54%"
          height="128"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="287"
          width="54%"
          height="80"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="382"
          width="54%"
          height="58"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="455"
          width="54%"
          height="58"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="564"
          width="54%"
          height="128"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="707"
          width="54%"
          height="80"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="802"
          width="54%"
          height="58"
          rx="10"
          fill="white"
          transform="translate(28)"
        />
        <rect
          x="46%"
          y="875"
          width="54%"
          height="58"
          rx="10"
          fill="white"
          transform="translate(28)"
        />

        <use xlinkHref="#block-1" y="277" />
        <use xlinkHref="#block-1" y="377" />
        <rect x="46%" y="277" width="1" height="58" fill="#EDEEF0" transform="translate(-56)" />
        <rect x="46%" y="377" width="1" height="58" fill="#EDEEF0" transform="translate(-56)" />
        <rect x="46%" y="209" width="20" height="247" fill="#F5F8F9" transform="translate(-55)" />

        <rect x="46%" y="49" width="54%" height="1" fill="#e2e3e5" transform="translate(28)" />
      </svg>

      <svg height="100%">
        <defs>
          <g id="upper-right-corner">
            <path d="M9.99997 10V0H0C5.52285 0 9.99997 4.47715 9.99997 10Z" fill="#eef2f4" />
          </g>
          <g id="lower-right-corner">
            <path d="M9.99997 0V10H0C5.52285 10 9.99997 5.52285 9.99997 0Z" fill="#eef2f4" />
          </g>
        </defs>

        <rect width="63" height="100%" fill="#EEF2F4" x="46%" y="0" transform="translate(-35)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="0" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="184" transform="translate(-45)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="209" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="446" transform="translate(-45)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="471" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="564" transform="translate(-45)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="589" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="682" transform="translate(-45)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="707" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="773" transform="translate(-45)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="798" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="845" transform="translate(-45)" />
        <use xlinkHref="#upper-right-corner" x="46%" y="870" transform="translate(-45)" />
        <use xlinkHref="#lower-right-corner" x="46%" y="917" transform="translate(-45)" />

        <rect width="20" height="100%" fill="#EEF2F4" x="100%" y="-1" transform="translate(-20)" />
        <use xlinkHref="#upper-right-corner" x="100%" y="-1" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="83" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="144" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="262" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="287" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="357" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="382" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="430" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="455" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="503" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="564" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="682" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="707" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="777" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="802" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="850" transform="translate(-30)" />

        <use xlinkHref="#upper-right-corner" x="100%" y="875" transform="translate(-30)" />
        <use xlinkHref="#lower-right-corner" x="100%" y="923" transform="translate(-30)" />
      </svg>

      <svg height="1070px" id="animated-elements">
        <defs>
          <g id="block-stage--animat">
            <rect x="0" y="-112" width="22%" height="32" rx="2" fill="#dee3e6" />
            <svg x="22%" y="-112">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-136 3C-136 1.34315 -134.657 0 -133 0H3.90631C5.23847 0 6.41107 0.878484 6.78542 2.15697L11 16.5505L6.81322 29.8979C6.42085 31.1488 5.26171 32 3.95075 32H-133C-134.657 32 -136 30.6569 -136 29V3Z"
                fill="#dee3e6"
                fillOpacity="1"
              />
            </svg>
          </g>

          <g id="block-item-1--animat">
            <rect x="0" y="18" width="64" height="10" rx="2" fill="#e4e8ea" />
          </g>

          <g id="block-item-2--animat">
            <rect x="60" y="300" width="12%" height="10" rx="2" fill="#e4e8ea" />
          </g>

          <g id="block-item-3--animat">
            <rect x="40" y="489" width="64" height="10" rx="2" fill="#e4e8ea" />
            <rect x="40" y="514" width="19%" height="10" rx="2" fill="#e4e8ea" />
            <rect x="40" y="536" width="19%" height="10" rx="2" fill="#e4e8ea" />
          </g>

          <g id="block-item-4--animat">
            <rect x="40" y="750" width="19%" height="10" rx="2" fill="#e4e8ea" />
          </g>

          <g id="block-item-5--animat">
            <rect x="0" y="21" width="73" height="10" rx="2" fill="#ecedee" />
          </g>

          <g id="block-item-6--animat">
            <rect x="0" y="161" width="29%" height="8" rx="2" fill="#ecedee" />
          </g>

          <g id="block-item-7--animat">
            <rect x="0" y="179" width="22%" height="8" rx="2" fill="#ecedee" />
            <rect x="0" y="196" width="10%" height="8" rx="2" fill="#ecedee" />
          </g>

          <g id="block-item-8--animat">
            <rect x="0" y="224" width="36%" height="8" rx="2" fill="#ecedee" />
            <rect x="0" y="241" width="22%" height="8" rx="2" fill="#ecedee" />
          </g>
        </defs>

        <use xlinkHref="#block-item-1--animat" x="40" />
        <use xlinkHref="#block-item-1--animat" x="46%" transform="translate(-117)" />
        <use xlinkHref="#block-item-1--animat" x="40" y="49" />
        <rect x="40" y="86" width="16%" height="20" rx="2" fill="#e4e8ea" />
        <rect x="40" y="134" width="10%" height="10" rx="2" fill="#e4e8ea" />
        <rect x="40" y="153" width="139" height="13" rx="2" fill="#e4e8ea" />
        <use xlinkHref="#block-item-1--animat" x="40" y="-7" />
        <use xlinkHref="#block-item-1--animat" x="40" y="23" />
        <use xlinkHref="#block-item-1--animat" x="40" y="122" />
        <use xlinkHref="#block-item-1--animat" x="40" y="492" />
        <use xlinkHref="#block-item-1--animat" x="46%" y="-7" transform="translate(-117)" />
        <use xlinkHref="#block-item-1--animat" x="46%" y="-7" transform="translate(-198)" />
        <use xlinkHref="#block-item-1--animat" x="46%" y="122" transform="translate(-117)" />
        <use xlinkHref="#block-item-1--animat" x="46%" y="122" transform="translate(-198)" />
        <use xlinkHref="#block-item-2--animat" />
        <use xlinkHref="#block-item-2--animat" y="100" />

        <use xlinkHref="#block-item-3--animat" />
        <use xlinkHref="#block-item-3--animat" y="118" />

        <use xlinkHref="#block-item-4--animat" />
        <use xlinkHref="#block-item-4--animat" y="72" />
        <use xlinkHref="#block-item-4--animat" y="144" />

        <use xlinkHref="#block-item-5--animat" x="46%" transform="translate(48)" />
        <use xlinkHref="#block-item-5--animat" x="46%" transform="translate(136)" />
        <use xlinkHref="#block-item-5--animat" x="46%" transform="translate(225)" />
        <use xlinkHref="#block-item-5--animat" x="46%" transform="translate(315)" />
        <rect
          x="100%"
          y="21"
          width="39"
          height="10"
          rx="2"
          fill="#ecedee"
          transform="translate(-79)"
        />
        <rect
          x="46%"
          y="66"
          width="13%"
          height="10"
          rx="2"
          fill="#ecedee"
          transform="translate(48)"
        />

        <use xlinkHref="#block-item-6--animat" x="46%" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" transform="translate(48)" />
        <use xlinkHref="#block-item-8--animat" x="46%" transform="translate(48)" />

        <use xlinkHref="#block-item-6--animat" x="46%" y="142" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" y="142" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" y="217" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" y="292" transform="translate(48)" />

        <use xlinkHref="#block-item-7--animat" x="46%" y="420" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" y="562" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" y="640" transform="translate(48)" />
        <use xlinkHref="#block-item-7--animat" x="46%" y="712" transform="translate(48)" />
        <use xlinkHref="#block-item-6--animat" x="46%" y="420" transform="translate(48)" />
        <use xlinkHref="#block-item-6--animat" x="46%" y="562" transform="translate(48)" />
        <use xlinkHref="#block-item-8--animat" x="46%" y="420" transform="translate(48)" />

        <rect
          x="73%"
          y="108"
          width="124"
          height="21"
          rx="10.5"
          fill="#dee3e5"
          transform="translate(-62)"
        />
        <rect
          x="73%"
          y="528"
          width="124"
          height="21"
          rx="10.5"
          fill="#dee3e5"
          transform="translate(-62)"
        />
      </svg>

      <rect id="msk1" width="100%" fill="url(#msk_gd)" mask="url(#headerMsk)" height="1055" />

      <mask id="headerMsk" fill="white">
        <use xlinkHref="#animated-elements" />
      </mask>

      <linearGradient x1="66.6%" y1="50%" x2="33.3%" y2="50%" id="msk_gd">
        <stop stopColor="#000000" stopOpacity="0" offset="0%" />
        <stop stopColor="#000000" stopOpacity="0.07" offset="50%" />
        <stop stopColor="#000000" stopOpacity="0" offset="100%" />
      </linearGradient>

      <animate
        xlinkHref="#msk1"
        attributeName="x"
        from="-100%"
        to="100%"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />

      <svg height="1070px">
        <defs>
          <g id="line">
            <rect
              x="46%"
              y="0"
              width="14"
              height="1"
              rx="0.5"
              fill="#dfe3e6"
              transform="translate(13)"
            />
          </g>

          <g id="circle">
            <circle cx="46%" cy="0" r="15.5" fill="#dfe3e6" transform="translate(-2)" />
          </g>
        </defs>

        <use xlinkHref="#line" y="19" />
        <use xlinkHref="#line" y="159" />
        <use xlinkHref="#line" y="308" />
        <use xlinkHref="#line" y="412" />
        <use xlinkHref="#line" y="477" />
        <use xlinkHref="#line" y="587" />
        <use xlinkHref="#line" y="732" />
        <use xlinkHref="#line" y="825" />
        <use xlinkHref="#line" y="895" />

        <use xlinkHref="#circle" y="19.5" />
        <use xlinkHref="#circle" y="159.5" />
        <use xlinkHref="#circle" y="308.5" />
        <use xlinkHref="#circle" y="412.5" />
        <use xlinkHref="#circle" y="477.5" />
        <use xlinkHref="#circle" y="587.5" />
        <use xlinkHref="#circle" y="732.5" />
        <use xlinkHref="#circle" y="825.5" />
        <use xlinkHref="#circle" y="895.5" />

        <rect x="46%" y="118" width="54%" height="1" fill="#dfe3e6" transform="translate(-2)" />
        <rect x="46%" y="538" width="54%" height="1" fill="#dfe3e6" transform="translate(-2)" />
        <rect x="46%" y="19" width="1" height="878" fill="#dfe3e6" transform="translate(-2)" />
      </svg>
    </svg>
  );
}

export default FormLoader;
