/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Checkbox, Col, Form } from 'antd';

function FormCertificateFieldsComponent({ fieldsPermissions, isTaskReadOnly, isEditing }) {
  return (
    <Col span={24}>
      <Card title="Certificado" bordered={false} className="task-form-section-card">
        {/* Não Gera Certificado */}
        {fieldsPermissions?.NotHaveCertificate.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Não Possui Certificado"
              name="notHaveCertificate"
              valuePropName="checked"
            >
              <Checkbox
                disabled={
                  fieldsPermissions?.NotHaveCertificate.access !== 2 || isTaskReadOnly || !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}
      </Card>
    </Col>
  );
}

export default FormCertificateFieldsComponent;
