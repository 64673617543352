/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Select, TimePicker } from 'antd';

function FormTransportFieldsComponent({
  fieldsPermissions,
  transportOptions,
  driverOptions,
  vehicleOptions,
  isTaskReadOnly,
  stages,
  task,
  isEditing,
}) {
  const [driverListOptions, setDriverListOptions] = useState([]);
  const [vehicleListOptions, setVehicleListOptions] = useState([]);

  const handleTransportCompanyChange = (transportCompanyId) => {
    const transportCompanyIdNumber = Number(transportCompanyId);

    const selectedTransportCompany = transportOptions.find(
      (company) => company.id === transportCompanyIdNumber
    );

    if (selectedTransportCompany) {
      if (
        selectedTransportCompany.driverList &&
        Array.isArray(selectedTransportCompany.driverList)
      ) {
        const driverOptionsIds = driverOptions.map(({ userId }) => userId);

        const transportDriverOptions = selectedTransportCompany.driverList
          .filter(({ id }) => driverOptionsIds.includes(id))
          .map((item) => {
            const driverObj = driverOptions.find(({ id }) => id === item.id);

            return {
              ...driverObj,
              label: driverObj.name,
              value: driverObj.id,
            };
          });

        setDriverListOptions(transportDriverOptions);
      } else {
        setDriverListOptions([]);
      }

      if (
        selectedTransportCompany.vehicleList &&
        Array.isArray(selectedTransportCompany.vehicleList)
      ) {
        const vehicleOptionsIds = vehicleOptions.map(({ userId }) => userId);
        const transportVehicleOptions = selectedTransportCompany.vehicleList
          .filter(({ userId }) => vehicleOptionsIds.includes(userId))
          .map((item) => ({
            ...item,
            label: `${item.licensePlate} - ${item.type.name} - ${item.capacity} pessoas`,
            value: item.id,
          }));

        setVehicleListOptions(transportVehicleOptions);
      } else {
        setVehicleListOptions([]);
      }
    } else {
      setDriverListOptions([]);
      setVehicleListOptions([]);
    }
  };

  useEffect(() => {
    if (task.transportCompany) {
      handleTransportCompanyChange(task.transportCompany.id);
    }
  }, []);

  return (
    <Col span={24}>
      <Card title="Transporte" bordered={false} className="task-form-section-card">
        {fieldsPermissions?.TransportCompany.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Empresa Transporte"
              name="transportCompany"
              rules={[
                {
                  required: fieldsPermissions?.TransportCompany.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(_, object) => object}
            >
              <Select
                options={transportOptions}
                onChange={(value) => handleTransportCompanyChange(value)}
                allowClear
                placeholder="o campo está vazio"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                disabled={
                  fieldsPermissions?.TransportCompany.access !== 2 || isTaskReadOnly || !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.VehicleList.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Veículos"
              name="vehicleList"
              rules={[
                {
                  required: fieldsPermissions?.VehicleList.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(_, objectList) => objectList}
            >
              <Select
                mode="multiple"
                options={vehicleListOptions}
                allowClear
                placeholder="o campo está vazio"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                disabled={
                  fieldsPermissions?.VehicleList.access !== 2 || isTaskReadOnly || !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.DriverList.access !== 0 && (
          <Col span={24}>
            <Form.Item
              label="Motoristas"
              name="driverList"
              rules={[
                {
                  required: fieldsPermissions?.DriverList.isRequired,
                  message: 'Campo obrigatório!',
                },
              ]}
              getValueFromEvent={(_, objectList) => {
                // 1 = Estágio Aguardando
                const waitingStage = stages.find(({ id }) => id === 1);
                return objectList.map((obj) => ({ ...obj, stage: waitingStage }));
              }}
            >
              <Select
                mode="multiple"
                options={driverListOptions}
                allowClear
                placeholder="o campo está vazio"
                optionFilterProp="label"
                showSearch
                dropdownStyle={{ borderRadius: 16 }}
                disabled={
                  fieldsPermissions?.DriverList.access !== 2 || isTaskReadOnly || !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {fieldsPermissions?.BoardingHour.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="boardingHour"
              label="Horário de Embarque"
              rules={[
                {
                  required: fieldsPermissions?.BoardingHour.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <TimePicker
                format="HH:mm"
                style={{ borderRadius: 16 }}
                disabled={
                  fieldsPermissions?.BoardingHour.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}
      </Card>
    </Col>
  );
}

export default FormTransportFieldsComponent;
