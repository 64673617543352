/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import { Dropdown, message, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { createRoot } from 'react-dom/client';

import { CommentOutlined, CopyOutlined, SendOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import { api } from '../../../Services/axiosService';

class SchedulingFunctions {
  static notifyWrongTrainingType(trainingType) {
    message.warning(`Este recurso não aceita este Tipo de Treinamento (${trainingType})!`);
  }

  static generateResourceSlots(pResources, pCtResourcesSlots) {
    const dateKeys = Object.keys(pResources);

    dateKeys.forEach((dateKey) => {
      const ctResourceList = [];
      pResources[dateKey]?.forEach(
        ({ id, validSlots, additionalSlots, trainingType, isLastColumn }) => {
          const workTime = 10;
          const slotDuration = 2;
          const totalSlots = workTime / slotDuration;

          const resourceSlots = [];
          let startHour = new Date(2023, 7, 24, 8, 0, 0, 0);
          for (let i = 0; i < totalSlots; i += 1) {
            startHour = new Date(moment(startHour).add(1, 'seconds'));
            const endHour = new Date(
              moment(startHour).subtract(2, 'seconds').add(slotDuration, 'hours')
            );

            resourceSlots.push({
              type: trainingType,
              slotStart: startHour,
              slotEnd: endHour,
              isValid: validSlots > 0 || additionalSlots >= 0,
              isLastColumn,
              isAdditional: validSlots === 0 && additionalSlots >= 0,
            });
            startHour = new Date(moment(endHour).add(1, 'seconds'));

            if (validSlots > 0) {
              validSlots -= 1;
            }
            if (validSlots === 0 && additionalSlots >= 0) {
              additionalSlots -= 1;
            }
          }

          ctResourceList[id] = resourceSlots;
        }
      );

      pCtResourcesSlots[dateKey] = ctResourceList;
    });
  }

  static calculateResourceSlots(pResources, pTrainingCenter, pSlots, pStartDate, pEndDate) {
    const {
      ctQtyColumns,
      ctQtySlots,
      inCompanyQtyColumns,
      inCompanyQtySlots,
      eadQtyColumns,
      eadQtySlots,
      serviceQtyColumns,
      serviceQtySlots,
    } = pTrainingCenter;
    const diff = moment(pEndDate).diff(pStartDate, 'days');
    const dateKeys = [];

    if (diff > 0) {
      for (let i = 0; i < 7; i += 1) {
        dateKeys.push(moment(pStartDate).add(i, 'days').format('YYYY-MM-DD'));
      }
    } else {
      dateKeys.push(moment(pStartDate).format('YYYY-MM-DD'));
    }

    dateKeys.forEach((dateKey) => {
      const resourceList = [];
      let ctQtySlotsCopy = ctQtySlots;
      let inCompanyQtySlotsCopy = inCompanyQtySlots;
      let eadQtySlotsCopy = eadQtySlots;
      let serviceQtySlotsCopy = serviceQtySlots;

      for (let i = 1; i <= ctQtyColumns; i += 1) {
        resourceList.push({
          id: resourceList.length + 1,
          trainingTypeId: 1,
          trainingType: 'CT',
          slotType: 'CT',
          validSlots: 0,
          additionalSlots: 0,
          isLastColumn: i % ctQtyColumns === 0,
        });
      }

      for (let i = 1; i <= inCompanyQtyColumns; i += 1) {
        resourceList.push({
          id: resourceList.length + 1,
          trainingTypeId: 2,
          trainingType: 'In Company',
          slotType: 'In Company',
          validSlots: 0,
          additionalSlots: 0,
          isLastColumn: i % inCompanyQtyColumns === 0,
        });
      }

      for (let i = 1; i <= eadQtyColumns; i += 1) {
        resourceList.push({
          id: resourceList.length + 1,
          trainingTypeId: 3,
          trainingType: 'EAD',
          slotType: 'EAD',
          validSlots: 0,
          additionalSlots: 0,
          isLastColumn: i % eadQtyColumns === 0,
        });
      }

      for (let i = 1; i <= serviceQtyColumns; i += 1) {
        resourceList.push({
          id: resourceList.length + 1,
          trainingTypeId: 1,
          trainingType: 'CT',
          slotType: 'Service',
          validSlots: 0,
          additionalSlots: 0,
          isLastColumn: i % serviceQtyColumns === 0,
        });
      }

      const additionalSlots = pSlots?.filter(
        ({ approved, date }) =>
          approved && moment(date).add(4, 'hours').format('YYYY-MM-DD') === dateKey
      );

      let additionalCtSlots = additionalSlots?.filter(({ type }) => type === 'CT')?.length ?? 0;
      let additionalInCompanySlots =
        additionalSlots?.filter(({ type }) => type === 'In Company')?.length ?? 0;
      let additionalEadSlots = additionalSlots?.filter(({ type }) => type === 'EAD')?.length ?? 0;
      let additionalServiceSlots =
        additionalSlots?.filter(({ type }) => type === 'Service')?.length ?? 0;

      while (ctQtySlotsCopy > 0 || inCompanyQtySlotsCopy > 0 || eadQtySlotsCopy > 0) {
        // eslint-disable-next-line no-loop-func
        resourceList?.forEach((item) => {
          switch (item.slotType) {
            case 'CT':
              if (ctQtySlotsCopy > 0) {
                item.validSlots += 1;
                ctQtySlotsCopy -= 1;
              }
              if (item.validSlots + item.additionalSlots < 5) {
                if (additionalCtSlots > 0) {
                  item.additionalSlots += 1;
                  additionalCtSlots -= 1;
                }
              }
              break;
            case 'In Company':
              if (inCompanyQtySlotsCopy > 0) {
                item.validSlots += 1;
                inCompanyQtySlotsCopy -= 1;
              }
              if (item.validSlots + item.additionalSlots < 5) {
                if (additionalInCompanySlots > 0) {
                  item.additionalSlots += 1;
                  additionalInCompanySlots -= 1;
                }
              }
              break;
            case 'EAD':
              if (eadQtySlotsCopy > 0) {
                item.validSlots += 1;
                eadQtySlotsCopy -= 1;
              }
              if (item.validSlots + item.additionalSlots < 5) {
                if (additionalEadSlots > 0) {
                  item.additionalSlots += 1;
                  additionalEadSlots -= 1;
                }
              }
              break;
            case 'Service':
              if (serviceQtySlotsCopy > 0) {
                item.validSlots += 1;
                serviceQtySlotsCopy -= 1;
              }
              if (item.validSlots + item.additionalSlots < 5) {
                if (additionalServiceSlots > 0) {
                  item.additionalSlots += 1;
                  additionalServiceSlots -= 1;
                }
              }
              break;
            default:
              break;
          }
        });
      }

      pResources[dateKey] = resourceList;
    });
  }

  static async fetchBitrixProducts(pBitrixUrl, pSetBitrixProducts) {
    try {
      const productOptions = [];
      const { data: firstResponse } = await axios.get(
        `${pBitrixUrl}crm.product.list?filter[ACTIVE]=Y&start=0&select[]=*`
      );

      const totalCount = firstResponse.total;
      const batchCmds = {};
      const batchCount = Math.ceil(totalCount / 50);

      for (let i = 0; i < batchCount; i += 1) {
        const start = i * 50;
        const cmdKey = `cmd${start}`;
        batchCmds[cmdKey] = `crm.product.list?filter[ACTIVE]=Y&start=${start}&select[]=*`;
      }

      const response = await axios.post(`${pBitrixUrl}batch`, { cmd: batchCmds });

      if (response && response?.data) {
        Object.values(response.data?.result?.result).forEach((result) => {
          result.forEach((product) => {
            productOptions.push({
              label: product.NAME,
              value: product.NAME,
              id: product.ID,
              measure: product.MEASURE,
              measurePrice: product.PROPERTY_432?.value,
              minQuantity: product.PROPERTY_392?.value,
              description: product.DESCRIPTION,
              practicalDaysPerClass: product.PROPERTY_538?.value,
              practicalTimePerClass: product.PROPERTY_542?.value,
              theoreticalDaysPerClass: product.PROPERTY_564?.value,
              theoreticalTimePerClass: product.PROPERTY_562?.value,
              inCompanyDaysPerClass: product.PROPERTY_568?.value,
              inCompanyTimePerClass: product.PROPERTY_566?.value,
              eadDays: product.PROPERTY_594?.value,
              resourceGroup: product.PROPERTY_546?.value,
              disabled: product.ID === '19890',
            });
          });
        });

        const concatOptions = productOptions.flat();
        pSetBitrixProducts(concatOptions);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao buscar os produtos do Bitrix!');
    }
  }

  static getOverviewData(pAppointments, pSetOverview, pStartDate, pEndDate) {
    const tasksOverview = pAppointments.filter(({ startDate }) => {
      const dateFilter = moment(new Date(startDate)).format('YYYY-MM-DDTHH:mm:ss');
      return dateFilter > pStartDate && dateFilter <= pEndDate;
    });

    const tasksByProduct = Utils.groupBy(tasksOverview, 'text');
    const scheduledTasks = tasksOverview?.map(({ id }) => id);

    const overviewList = [];
    Object.keys(tasksByProduct).forEach((product) => {
      const scheduledClasses = tasksByProduct[product].filter(({ id }) =>
        scheduledTasks?.includes(id)
      );

      // Determina a data de início mais precoce para o produto atual
      const startDates = scheduledClasses.map(({ startDate }) => startDate);
      const startDate = startDates.reduce((a, b) => (a < b ? a : b), startDates[0]);

      // Agrupa Turmas por Start Date
      let totalClassesSlot = 0;
      const scheduledClassesByDate = Utils.groupBy(scheduledClasses, 'startDate');
      Object.keys(scheduledClassesByDate).forEach((key) => {
        const resourceIdList = scheduledClassesByDate[key].map(({ resourceId }) => resourceId);
        const uniqueResourceIdList = resourceIdList.filter(
          (value, index, self) => self.indexOf(value) === index
        );
        totalClassesSlot += uniqueResourceIdList.length;
      });

      const customProductList = ScheduleUtils.getCustomProductList();
      const ctStudents = scheduledClasses
        .filter(
          ({ resource, product }) => resource === 'CT' && !customProductList.includes(product.id)
        )
        .map(({ qtyStudentsPerClass }) => qtyStudentsPerClass)
        .reduce((startVal, nextVal) => startVal + nextVal, 0);

      const inCompanyStudents = scheduledClasses
        .filter(
          ({ resource, product }) =>
            resource === 'In Company' && !customProductList.includes(product.id)
        )
        .map(({ qtyStudentsPerClass }) => qtyStudentsPerClass)
        .reduce((startVal, nextVal) => startVal + nextVal, 0);

      const eadStudents = scheduledClasses
        .filter(
          ({ resource, product }) => resource === 'EAD' && !customProductList.includes(product.id)
        )
        .map(({ qtyStudentsPerClass }) => qtyStudentsPerClass)
        .reduce((startVal, nextVal) => startVal + nextVal, 0);

      overviewList.push({
        product,
        classes: totalClassesSlot,
        ctStudents,
        inCompanyStudents,
        eadStudents,
        startDate, // Adiciona a data de início mais precoce ao objeto
      });
    });
    pSetOverview(overviewList);
  }

  static getResumeData(pAppointments, pSetResume, pStartDate, pEndDate) {
    const tasksOverview = pAppointments.filter(({ startDate }) => {
      const dateFilter = moment(new Date(startDate)).format('YYYY-MM-DDTHH:mm:ss');
      return dateFilter > pStartDate && dateFilter <= pEndDate;
    });

    const resumeList = tasksOverview.map((item) => ({
      ...item,
      key: item.id,
      company: item.dealClient,
      productName: item.product.name,
      stageName: item.stage.name,
      students: item.qtyStudentsPerClass,
      type: item.resource,
      isShared: item.sharedTraining,
      stageColor: item.stage.color,
    }));
    pSetResume(resumeList);
  }

  static async sendNotificationToApprovers() {
    try {
      const receiversList = await api
        .get(
          '/User?filters[0].Field=IsApprover&filters[0].Condition=BOOL.EQUAL&filters[0].Value=true'
        )
        .then((res) => res.data);

      for (let i = 0; i < receiversList.length; i += 1) {
        const userData = JSON.parse(localStorage.getItem('conecta__userData') ?? '{}');
        const ctData = JSON.parse(localStorage.getItem('conecta__scheduleData') ?? '{}');

        const data = {
          sourceId: userData?.id,
          sourceEvent: 'SlotApproval',
          notification: {
            userId: receiversList[i].id,
            title: 'Solicitação de slots',
            body: `${userData?.name} solicitou novos slots no CT ${ctData?.trainingCenter}. Você pode clicar no botão de redirecionamento para ir até a tela de Agendamento, podendo Aprovar ou Negar as solicitações.`,
            redirectUrlPathWeb: `/Agenda/Agendamento/OpenNewSlots/${ctData?.maxCapacityPerDay}/${ctData?.trainingCenter}/${ctData?.trainingCenterId}`,
          },
        };

        await api.post('/Notification', data).then((res) => res);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao notificar os Aprovadores!');
    }
  }

  static async sendNotificationToInstructor(date, instructorUserId) {
    try {
      const userData = JSON.parse(localStorage.getItem('conecta__userData') ?? '{}');

      const data = {
        sourceId: userData?.id,
        sourceEvent: 'InstructorBooking',
        notification: {
          userId: instructorUserId,
          title: 'Reserva de Agenda',
          body: `Novo reserva recebida para o dia (${date}). Você pode clicar no botão de redirecionamento para ir até a agenda!`,
          redirectUrlPathWeb: `/Agenda/Instrutor`,
          redirectUrlPathMobile: `Agenda`,
        },
      };

      await api.post('/Notification', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao notificar o Instrutor sobre a reserva!');
    }
  }

  static async sendDeletionNotificationToInstructor(date, instructorUserId) {
    try {
      const userData = JSON.parse(localStorage.getItem('conecta__userData') ?? '{}');

      const data = {
        sourceId: userData?.id,
        sourceEvent: 'InstructorBookingDeletion',
        notification: {
          userId: instructorUserId,
          title: 'Reserva de Agenda Removida',
          body: `Uma reserva para o dia (${date}) foi removida. Você pode clicar no botão de redirecionamento para verificar a agenda!`,
          redirectUrlPathWeb: `/Agenda/Instrutor`,
          redirectUrlPathMobile: `Agenda`,
        },
      };

      await api.post('/Notification', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Ocorreu um erro ao notificar o Instrutor sobre a remoção da reserva!');
    }
  }

  static async sendNotificationToOperators(newTaskData, oldTaskData) {
    try {
      const stagesToNotify = [
        3, // 3 = Confirmado
        10, // 10 = Pré-Reservado
        12, // 12 = Reservado
        13, // 13 = Reservado Sem Confirmação
      ];

      if (stagesToNotify.includes(newTaskData.stage.id)) {
        // Notifica Instrutor
        if (!oldTaskData?.oldMainInstructorId && newTaskData?.mainInstructor?.userId) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskReceived',
            'mainInstructor',
            newTaskData.mainInstructor.userId
          );
        }

        if (
          (oldTaskData?.oldMainInstructorId && !newTaskData?.mainInstructor?.userId) ||
          (oldTaskData?.oldMainInstructorId &&
            oldTaskData?.oldMainInstructorId !== newTaskData?.mainInstructor?.userId)
        ) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskRemoved',
            'mainInstructor',
            oldTaskData.oldMainInstructorId
          );

          if (newTaskData?.mainInstructor?.userId) {
            await ScheduleUtils.sendPushNotification(
              newTaskData,
              'TaskReceived',
              'mainInstructor',
              newTaskData.mainInstructor.userId
            );
          }
        }

        // Notifica Instrutor Auxiliar
        if (!oldTaskData?.oldAssistantInstructorId && newTaskData?.assistantInstructor?.userId) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskReceived',
            'assistantInstructor',
            newTaskData.assistantInstructor.userId
          );
        }

        if (
          (oldTaskData?.oldAssistantInstructorId && !newTaskData?.assistantInstructor?.userId) ||
          (oldTaskData?.oldAssistantInstructorId &&
            oldTaskData?.oldAssistantInstructorId !== newTaskData?.assistantInstructor?.userId)
        ) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskRemoved',
            'assistantInstructor',
            oldTaskData.oldAssistantInstructorId
          );

          if (newTaskData?.assistantInstructor?.userId) {
            await ScheduleUtils.sendPushNotification(
              newTaskData,
              'TaskReceived',
              'assistantInstructor',
              newTaskData.assistantInstructor.userId
            );
          }
        }

        // Notifica Motoristas
        const oldDriverUserIdList = oldTaskData.driverUserIdList;
        const newDriverUserIdList = newTaskData.driverUserIdList;
        const removedDriverIds = [];
        const newDriverIds = [];

        // Monta lista de Motoristas Removidos
        oldDriverUserIdList?.forEach((newId) => {
          const driver = newDriverUserIdList?.find((oldId) => oldId === newId);

          if (!driver) {
            removedDriverIds.push(newId);
          }
        });

        // Monta lista de Novos Motoristas
        newDriverUserIdList?.forEach((newId) => {
          const driver = oldDriverUserIdList?.find((oldId) => oldId === newId);

          if (!driver) {
            newDriverIds.push(newId);
          }
        });

        for (let index = 0; index < newDriverIds?.length; index += 1) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskReceived',
            'driver',
            newDriverIds[index]
          );
        }

        for (let index = 0; index < removedDriverIds?.length; index += 1) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskRemoved',
            'driver',
            removedDriverIds[index]
          );
        }
      }

      newTaskData.oldMainInstructorId = newTaskData?.mainInstructor?.userId;
      newTaskData.oldAssistantInstructorId = newTaskData?.assistantInstructor?.userId;
      newTaskData.oldDriverId = newTaskData?.driver?.userId;
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar notificar os operadores!');
    }
  }

  /**
   * Busca a disponibilidade dos instrutores de um centro de treinamento específico.
   * @param {Array} instructorOptions - Lista de instrutores disponíveis.
   * @param {Date} startDate - Data de início para verificar disponibilidade.
   * @param {Date} endDate - Data de fim para verificar disponibilidade.
   * @returns {Promise<Array>} - Promessa com a lista de disponibilidade dos instrutores.
   */
  static async fetchInstructorsAvailability(instructorOptions, startDate, endDate) {
    try {
      const operatorIdList = instructorOptions?.map((instructor) => ({
        UserId: instructor.id,
        CompanyId: instructor.company.id,
      }));

      let response = [];
      if (operatorIdList?.length > 0) {
        const availabilityModel = {
          Start: moment(new Date(startDate).setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss'),
          Finish: moment(new Date(endDate).setHours(23, 59, 59, 99)).format('YYYY-MM-DDTHH:mm:ss'),
          OperatorIdList: operatorIdList,
        };

        response = await api
          .post('/Task/InstructorsAvailability', availabilityModel)
          .then((res) => res.data);
      }

      return response;
    } catch (error) {
      message.error('Oops. Algo deu errado ao buscar a Disponibilidade dos Instrutores!');
      throw error;
    }
  }

  static addSlotClassId(task, foundAppointments) {
    if (foundAppointments.length > 0) {
      const [appointment] = foundAppointments;
      task.slotClassId = appointment.slotClassId;
    } else {
      task.slotClassId = Utils.generateUUID();
    }
  }

  static addOccurrencesBtn(toolbar, openPopup, fieldsPermissions, isReadOnly) {
    if (fieldsPermissions?.OccurrenceButton?.access >= 1) {
      toolbar.push({
        location: 'before',
        widget: 'dxButton',
        options: {
          elementAttr: { class: 'my-icon-button' },
          onClick: () => openPopup(true),
          hint: 'Adicionar Ocorrência',
          template: (data, itemElement) => {
            const container = document.createElement('div');
            itemElement.append(container);

            const root = createRoot(container);
            root.render(
              <Tooltip title="Adicionar Ocorrência">
                <CommentOutlined />
              </Tooltip>
            );
            return container;
          },
          disabled: isReadOnly,
        },
      });
    }
  }

  static addEmailConfirmDropdown(toolbar, e, openPopup, fieldsPermissions, isReadOnly) {
    const stageId = e.appointmentData.stage?.id;
    const isInCompany = e.appointmentData.trainingType === 'In Company';
    const hasInstructor = e.appointmentData.mainInstructor?.userId;
    const hasTransport = e.appointmentData.trainingTransportation === 'Incluso';
    const hasDriverVehicleTransport =
      e.appointmentData.transportCompany?.id &&
      e.appointmentData.driverList &&
      e.appointmentData.vehicleList;
    const isDriverVehicleSameSize =
      e.appointmentData.driverList?.length === e.appointmentData.vehicleList?.length;
    const invalidStages = [1, 6, 10, 12]; // 1 = Aguardando, 6 = Reagendar, 10 = Pré-Reservado, 12 = Reservado
    const isValidStage = !invalidStages.includes(stageId);

    const items = [
      {
        key: '1',
        label: (
          <p
            onClick={() => {
              if (fieldsPermissions?.EmailConfirmTrainingButton?.access > 1 && isValidStage) {
                openPopup(1);
              }
            }}
          >
            Enviar E-mail Confirmação Treinamento
          </p>
        ), // 1 = Treinamento
        disabled: fieldsPermissions?.EmailConfirmTrainingButton?.access < 1 || !isValidStage,
      },
      {
        key: '2',
        label: (
          <p
            onClick={() => {
              if (
                fieldsPermissions?.EmailConfirmTransportButton?.access > 1 &&
                isValidStage &&
                hasDriverVehicleTransport &&
                hasTransport
              ) {
                openPopup(2);
              }
            }}
          >
            Enviar E-mail Confirmação Transporte
          </p>
        ), // 2 = Transporte
        disabled:
          fieldsPermissions?.EmailConfirmTransportButton?.access < 1 ||
          !isValidStage ||
          !hasDriverVehicleTransport ||
          !hasTransport,
      },
      {
        key: '3',
        label: (
          <p
            onClick={() => {
              if (
                fieldsPermissions?.EmailConfirmInstructorButton?.access > 1 &&
                isValidStage &&
                isInCompany &&
                hasInstructor
              ) {
                openPopup(3);
              }
            }}
          >
            Enviar E-mail Confirmação Instrutor
          </p>
        ), // 3 = Instrutor
        disabled:
          fieldsPermissions?.EmailConfirmInstructorButton?.access < 1 ||
          !isValidStage ||
          !isInCompany ||
          !hasInstructor,
      },
      {
        key: '4',
        label: (
          <p
            onClick={() => {
              if (
                fieldsPermissions?.EmailConfirmDriverButton?.access > 1 &&
                isValidStage &&
                hasDriverVehicleTransport &&
                isDriverVehicleSameSize &&
                hasTransport
              ) {
                openPopup(4);
              }
            }}
          >
            Enviar E-mail Confirmação Motorista
          </p>
        ), // 4 = Motorista
        disabled:
          fieldsPermissions?.EmailConfirmDriverButton?.access < 1 ||
          !isValidStage ||
          !hasDriverVehicleTransport ||
          !isDriverVehicleSameSize ||
          !hasTransport,
      },
    ];

    toolbar.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        elementAttr: { class: 'my-icon-button' },
        hint: 'Enviar e-mail',
        template: (data, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <Dropdown menu={{ items }} disabled={isReadOnly}>
              <SendOutlined />
            </Dropdown>
          );
          return container;
        },
      },
    });
  }

  static addInfoBtn(toolbar, e) {
    toolbar.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        elementAttr: { class: 'my-icon-button' },
        onClick: () => {
          const task = e.appointmentData;

          // Criando a string com as informações do treinamento
          const text =
            `Id: ${task.id}\n` +
            `Id Negócio: ${task.dealId}\n` +
            `Id Turma: ${task.slotClassId ?? ''}\n` +
            `Titulo: ${task.cardTitle}\n` +
            `Negócio: ${task.dealTitle}\n` +
            `Cliente: ${task.dealClient}\n` +
            `Responsável: ${task.quoteResponsible}\n` +
            `Produto: ${task.text}\n` +
            `Tipo: ${task.trainingType}\n` +
            `CT: ${task.trainingCenter ?? ''}`;

          // Copiando o texto para a área de transferência
          navigator.clipboard
            .writeText(text)
            .then(() => {
              message.info('Detalhes do Treinamento copiadas para área de transferência!');
            })
            .catch((err) => {
              Utils.logError(err);
              message.error('Oops. Algo deu errado ao copiar os detalhes do treinamento!');
            });
        },
        hint: 'Copiar Detalhes do Treinamento',
        template: (data, itemElement) => {
          const container = document.createElement('div');
          itemElement.append(container);

          const root = createRoot(container);
          root.render(
            <Tooltip title="Copiar Detalhes do Treinamento">
              <CopyOutlined />
            </Tooltip>
          );
          return container;
        },
      },
    });
  }

  static formatAppointmmentInfo(data, ctResources, taskAcceptanceDeadline, ctResourcesSlots) {
    const resource = ctResources?.find(({ id }) => id === data.resourceId);

    data.resource = resource.trainingType;
    data.resourceId = resource.id;
    data.startDate = new Date(moment(data.startDate).add(1, 'seconds'));
    data.acceptanceDeadline = new Date(moment(data.startDate).add(taskAcceptanceDeadline, 'hours'));

    const dateKey = moment(data.startDate).format('YYYY-MM-DD');

    // eslint-disable-next-line array-callback-return, consistent-return
    const resourceSlot = ctResourcesSlots[dateKey][resource?.id]?.find((resource) => {
      const startDt = new Date(data.startDate);
      const endDt = new Date(data.endDate);
      const slotStartDt = new Date(resource.slotStart);
      const slotEndDt = new Date(resource.slotEnd);

      const slotStartHour = Utils.createSafeDate(startDt, slotStartDt);
      const slotEndHour = Utils.createSafeDate(endDt, slotEndDt);

      if (startDt >= slotStartHour && startDt <= slotEndHour) {
        return resource;
      }
    });

    if (!resourceSlot?.isValid) {
      const errMessage = 'Oops! Slot inválido, contate um administrador do sistema.';
      Utils.logError(errMessage);
      message.error(errMessage);
      return true;
    }

    const slotStart = moment(resourceSlot.slotStart);
    const slotStartDate = slotStart
      .set('year', data.startDate.getFullYear())
      .set('month', data.startDate.getMonth())
      .set('date', data.startDate.getDate());

    const slotStartFormatted = slotStartDate.toDate();

    const slotTotalTime = 2;
    const slotEndFormatted = slotStartDate
      .clone()
      .add(slotTotalTime, 'hours')
      .subtract(2, 'seconds')
      .toDate();

    if (resource.trainingTypeId !== data.trainingTypeId) {
      this.notifyWrongTrainingType(data.trainingType);
      return true;
    }

    data.startDate = slotStartFormatted;
    data.endDate = slotEndFormatted;
    return false;
  }

  static mapTimeFields(task) {
    const timeFields = [
      'startDate',
      'startHour',
      'endHour',
      'boardingHour',
      'date',
      'dateScheduling',
      'dateUploadAcceptTerm2',
      'confirmationDate',
      'billingDeadlineDate',
      'invoiceEmissionDate',
    ];

    timeFields.forEach((field) => {
      task[field] = task[field] ? moment(task[field]) : null;
    });
  }

  static mapFormData(task) {
    if (task.mainInstructor) {
      task.mainInstructor = {
        ...task.mainInstructor,
        label: task.mainInstructor.userName,
        value: task.mainInstructor.userId,
      };
    }

    if (task.assistantInstructor) {
      task.assistantInstructor = {
        ...task.assistantInstructor,
        label: task.assistantInstructor.userName,
        value: task.assistantInstructor.userId,
      };
    }

    if (task.transportCompany) {
      task.transportCompany = {
        ...task.transportCompany,
        label: task.transportCompany.commercialName,
        value: task.transportCompany.id,
      };
    }

    if (task.vehicleList) {
      task.vehicleList = task.vehicleList.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.licensePlate} - ${item.type.name} (${item.capacity} pessoas)`,
      }));
    }

    if (!task.vehicleList) {
      task.vehicleList = [];
    }

    if (task.driverList) {
      task.driverList = task.driverList.map((item) => ({
        ...item,
        value: item.userId,
        label: item.userName,
      }));
    }

    if (!task.driverList) {
      task.driverList = [];
    }

    if (task.invoiceIssuer) {
      task.invoiceIssuer = {
        ...task.invoiceIssuer,
        label: task.invoiceIssuer.name,
        value: task.invoiceIssuer.id,
      };
    }
  }

  static hasAccess(fieldsPermissions, permissionNames) {
    return permissionNames.some((name) => fieldsPermissions?.[name]?.access !== 0);
  }
}

export default SchedulingFunctions;
