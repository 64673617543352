import { message } from 'antd';
import moment from 'moment';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

/* eslint-disable no-param-reassign */
export const fetchOverviewScheduleFilter = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;
    const filterType = 'overviewSchedule';

    const response = await api.get(
      `/ScreenFilter?filters[0].Field=UserId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${userId}&filters[1].Field=Type&filters[1].Condition=EQUAL&filters[1].Value=${filterType}`
    );

    if (response.data?.length > 0) {
      const [filter] = response.data;
      filter.filters = JSON.parse(filter.filters);

      if (filter.filters.period) {
        const periodStart = filter.filters?.period[0];
        const periodEnd = filter.filters?.period[1];
        filter.filters.period = [moment(periodStart), moment(periodEnd)];
      }

      return filter;
    }

    return null;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os filtros!');
    return [];
  }
};

export const addOverviewScheduleFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'overviewSchedule';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.post('/ScreenFilter', submitData);
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar salvar os filtros!');
    return [];
  }
};

export const updateOverviewScheduleFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'overviewSchedule';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.put('/ScreenFilter', submitData);
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar os filtros!');
    return [];
  }
};
