/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Form, message, Modal, Select, Table, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { updateTask, uploadFilesToFirebase } from '../API/TaskListApi';

import './ModalStyle.scss';

const columns = [
  {
    title: 'Id Negócio',
    dataIndex: 'dealId',
    key: 'dealId',
    width: '110px',
  },
  {
    title: 'Id Turma',
    dataIndex: 'slotClassId',
    key: 'slotClassId',
  },
  {
    title: 'Nome Cliente',
    dataIndex: 'dealClient',
    key: 'dealClient',
    width: '55%',
  },
];

function AttachFilesModal({
  setIsVisible,
  taskList,
  selectedRowKeys,
  driverOptions,
  vehicleOptions,
  stageOptions,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((fileProps) => ({ ...fileProps, status: 'done' }));
    setFileList(newFileList);
  };

  const handleImport = async (file) => {
    if (file.status !== 'removed') {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    }
  };

  const selectedTasks = taskList.filter((task) => selectedRowKeys.includes(task.id));

  const submit = async () => {
    if (fileList.length > 0 && selectedTasks.length > 0) {
      setIsLoading(true);

      const { field } = form.getFieldsValue();

      for (let index = 0; index < selectedTasks.length; index += 1) {
        const newTask = selectedTasks[index];
        const oldTask = JSON.parse(JSON.stringify(taskList.find(({ id }) => id === newTask.id)));

        if (field === 'finalAttendanceDocList') {
          newTask.stage.id = 14; // 14 = Aguardando Documentação
        }

        if (newTask.stage?.id) {
          const stageObject = stageOptions.find(({ id }) => id === newTask.stage.id);
          newTask.stage = stageObject;
        }

        // Realiza Upload dos Arquivos para o Firebase
        const uploadedFiles = await uploadFilesToFirebase(fileList, field, newTask.id);

        if (!newTask[field]) {
          newTask[field] = [];
        }
        newTask[field].push(...uploadedFiles);

        // Atualiza Treinamento
        await updateTask(newTask, oldTask, driverOptions, vehicleOptions);

        let eventName = '';
        switch (field) {
          case 'nfTransportation':
            eventName = 'NFTransportationUploaded';
            break;
          case 'nfTraining':
            eventName = 'NFTrainingUploaded';
            break;
          case 'initialAttendanceDocList':
            eventName = 'InitialAttendanceListUploaded';
            break;
          case 'finalAttendanceDocList':
            eventName = 'FinalAttendanceListUploaded';
            break;
          case 'instructorForm':
            eventName = 'InstructorFormUploaded';
            break;
          default:
            break;
        }

        // Gera Atividade de Upload de Arquivo
        await TaskCommentFunctions.addTaskCommentUploadFile(newTask, eventName, 'TaskList');
      }

      message.success('Atualização dos Treinamentos concluída!');
      window.location.reload(); // Recarrega a página
    }
  };

  return (
    <Modal
      title="Anexar Arquivos de Treinamento"
      open={true}
      onOk={() => submit()}
      onCancel={() => setIsVisible(false)}
      okText="Enviar"
      cancelText="Cancelar"
      width="fit-content"
      className="training-list-attach-files-modal"
      okButtonProps={{
        style: { backgroundColor: '#5cb85c', color: 'white', border: 'none' },
      }}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      <p>
        Você está prestes a anexar arquivos aos registros selecionados. Por favor, revise os
        detalhes abaixo e confirme se deseja prosseguir ou cancelar a operação.
      </p>

      <Form
        form={form}
        name="attachFilesModalForm"
        layout="vertical"
        autoComplete="off"
        preserve={false}
        disabled={isLoading}
      >
        <Form.Item
          name="field"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Select
            options={[
              { label: 'Lista de Presença Inicial', value: 'initialAttendanceDocList' },
              { label: 'Lista de Presença Final', value: 'finalAttendanceDocList' },
              { label: 'Formulário Instrutor', value: 'instructorForm' },
              { label: 'NF Transporte', value: 'nfTransportation' },
              { label: 'NF Treinamento', value: 'nfTraining' },
            ]}
            allowClear
            placeholder="Tipo de Anexo"
            optionFilterProp="label"
            showSearch
            dropdownStyle={{ borderRadius: 16 }}
            maxTagCount="responsive"
          />
        </Form.Item>

        <Form.Item
          name="attachmentList"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Upload
            multiple
            beforeUpload={() => {
              setFileList([]);
            }}
            onChange={handleChange}
            customRequest={({ file }) => handleImport(file)}
            disabled={isLoading}
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
          </Upload>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={selectedTasks} pagination={false} rowKey="id" />
    </Modal>
  );
}

export default AttachFilesModal;
