/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';
import { Badge } from 'antd';
import moment from 'moment';

import ScheduleUtils from './ScheduleUtils';

export default function DataCell({
  itemData,
  workDays,
  blockedDates,
  ctResourcesSlots,
  appointments,
}) {
  let slotClasses = '';
  let cssClasses = 'open-cell';
  let isLastColumnCell = false;
  let placeBadge = false;
  let totalStudents = 0;

  if (ctResourcesSlots) {
    const startFormatted = moment(itemData.startDate).add(1, 'seconds').format('HH:mm:ss');
    const endFormatted = moment(itemData.endDate).subtract(1, 'seconds').format('HH:mm:ss');
    const dateKey = moment(itemData.startDate).format('YYYY-MM-DD');

    if (ctResourcesSlots[dateKey]) {
      ctResourcesSlots[dateKey][itemData.groups.resourceId]?.forEach((slot) => {
        const slotStart = moment(slot.slotStart).format('HH:mm:ss');
        const slotEnd = moment(slot.slotEnd).format('HH:mm:ss');

        if (startFormatted === slotStart || endFormatted === slotEnd) {
          if (slot.isLastColumn) {
            isLastColumnCell = true;
          }

          if (slot.isAdditional) {
            slotClasses = 'additional-slot ';
          }
        }

        if (startFormatted === slotStart) {
          if (!slot.isValid) {
            slotClasses = 'invisible-cell';
          } else {
            slotClasses += 'top-slot-cell';
          }
        }
        if (endFormatted === slotEnd) {
          if (!slot.isValid) {
            slotClasses = 'invisible-cell';
          } else {
            slotClasses += 'bottom-slot-cell';
          }
        }
      });
    }

    if (appointments) {
      const foundApppointments = appointments
        .map(({ startDate, endDate, resourceId, id }) => ({
          id,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          resourceId,
        }))
        .filter(
          // eslint-disable-next-line array-callback-return, consistent-return
          (appointment) => {
            const startFormatted = new Date(moment(itemData.startDate).add(1, 'seconds'));
            if (
              startFormatted >= appointment.startDate &&
              startFormatted <= appointment.endDate &&
              appointment.resourceId === itemData.groups.resourceId
            ) {
              cssClasses = 'filled-cell';
              return appointment;
            }
          }
        );

      if (foundApppointments?.length > 1) {
        const foundApppointmentsIds = foundApppointments.map(({ id }) => id);
        const customProductList = ScheduleUtils.getCustomProductList();

        placeBadge = true;
        totalStudents = appointments
          .filter(({ id, product, group }) => {
            const isCustomProduct = customProductList.includes(product.id) || group?.id === 24; // 24 = Personalizado
            return foundApppointmentsIds.includes(id) && !isCustomProduct;
          })
          .reduce((sum, item) => sum + item.qtyStudentsPerClass, 0);
      }
    }
  }
  let isWorkDay = false;
  if (workDays) {
    isWorkDay = ScheduleUtils.isDisableDate(itemData.startDate, workDays);
  }

  const isBlockedDay = ScheduleUtils.isBlockedDay(itemData.startDate, blockedDates);
  if (
    (cssClasses === 'open-cell' || cssClasses === 'filled-cell') &&
    (isWorkDay || isBlockedDay) &&
    slotClasses !== 'invisible-cell'
  ) {
    cssClasses = 'disable-date';
  }

  if (isBlockedDay) {
    const blockedDay = blockedDates.find(
      ({ blockedDay }) => blockedDay === itemData.startDate.toLocaleDateString()
    );
    return (
      <div className={`${cssClasses} ${slotClasses}`} data-id={blockedDay.id}>
        {itemData.text}
      </div>
    );
  }

  if (slotClasses?.includes('bottom-slot-cell') && placeBadge) {
    return (
      <Badge.Ribbon text={totalStudents}>
        <div key="cell" className={`${cssClasses} ${slotClasses} every-cell`}>
          {isLastColumnCell && <hr className="last-column-cell-line" />}
          {itemData.text}
        </div>
      </Badge.Ribbon>
    );
  }

  return (
    <div key="cell" className={`${cssClasses} ${slotClasses} every-cell`}>
      {isLastColumnCell && <hr className="last-column-cell-line" />}
      {itemData.text}
    </div>
  );
}
