import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, message, Tooltip } from 'antd';

import { FileExcelOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import ModalUploadExcel from '../../../Components/Instructor/ModalUploadExcel';
import TableAntd from '../../../Components/TableAntd/TableAntd';
import { api } from '../../../Services/axiosService';

export default function VehicleList() {
  const [requiredDocuments, setRequiredDocuments] = useState();
  const [isModalOpen, setIsModalOpen] = useState();
  const [canHandle, setCanHandle] = useState(false);

  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: ['type', 'name'],
        title: 'Tipo',
        ...getColumnSearchProps(['type', 'name'], 'Tipo'),
        sorter: (a, b) => a.type.name.localeCompare(b.type.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'capacity',
        title: 'Capacidade',
        ...getColumnSearchProps('capacity', 'Capacidade'),
        render: (text) => `${text} pessoas`,
      },
      {
        dataIndex: 'licensePlate',
        title: 'Placa',
        ...getColumnSearchProps('licensePlate', 'Nome'),
        sorter: (a, b) => a.licensePlate.localeCompare(b.licensePlate),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  const fetchSettings = async () => {
    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => Utils.decryptSettings(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });

    const reqDocs =
      res.requiredDocumentsVehicle?.split(',')?.map((item) => parseInt(item, 10)) ?? [];
    setRequiredDocuments(reqDocs);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const renderExtraToolbar = () => {
    if (!canHandle) return null;

    return (
      <div style={{ marginLeft: '10px' }}>
        <Tooltip title="Importar Registro">
          <Button
            onClick={openModal}
            shape="circle"
            icon={<FileExcelOutlined />}
            style={{
              width: '100%',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          />
        </Tooltip>
      </div>
    );
  };

  useLayoutEffect(() => {
    const vehicleResource = JSON.parse(
      localStorage.getItem('conecta__permissions')
    )?.resources.find(({ name }) => name === 'Vehicle');

    setCanHandle(vehicleResource.canHandle);
  }, []);

  useEffect(() => {
    fetchSettings();
  }, []);

  if (requiredDocuments) {
    return (
      <>
        <TableAntd
          columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
          routePermissions="Vehicle"
          requestName="Vehicle"
          route="/Veiculo"
          toggleStatusRoute="/Vehicle"
          cdPage="id"
          btnNew
          requiredDocuments={requiredDocuments}
          extraToolbar={renderExtraToolbar()}
        />
        <ModalUploadExcel setIsOpen={setIsModalOpen} isOpen={isModalOpen} screenType="vehicle" />
      </>
    );
  }
}
