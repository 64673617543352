import { initializeApp } from 'firebase/app';
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage';

import Utils from '../Assets/Scripts/Utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const fbStorage = getStorage(app);

export const uploadFiles = async (file, origin, originId, replaceName) => {
  const fileName = `${originId}__${Utils.generateUUID()}`;
  const storageRef = ref(fbStorage, `documents/${origin}/${originId}/${replaceName || fileName}`);
  return uploadBytes(storageRef, file).then(() => getDownloadURL(storageRef));
};

export const uploadProfilePic = async (file) => {
  const storageRef = ref(fbStorage, `profilePics/${Utils.generateUUID()}`);
  return uploadBytes(storageRef, file).then(() => getDownloadURL(storageRef));
};

export const deleteFileDocuments = async (docName, origin, originId) => {
  const deleteRef = ref(fbStorage, `documents/${origin}/${originId}/${docName}`);

  return deleteObject(deleteRef)
    .then(() => {})
    .catch((error) => Utils.logError(error));
};

export const getStorageList = async (reference) => {
  const storageRef = ref(fbStorage, `${reference}`);
  return listAll(storageRef).then((response) => response);
};

export const getFile = async (reference) => {
  const storageRef = ref(fbStorage, `${reference}`);
  const metaData = await getMetadata(storageRef).then((response) => response);
  const url = await getDownloadURL(storageRef).then((response) => response);
  return { metaData, url };
};

// export const createFolder = async (reference) => {
//   const newDir = ref(fbStorage, `knowledgeBase/${reference}`);
//   const ghostFile = ref(newDir, '.ghostfile');
//   await uploadString(ghostFile, '');
// };

export const deleteFileKnowledgeBase = async (reference) => {
  const deleteRef = ref(fbStorage, `knowledgeBase/${reference}`);
  return deleteObject(deleteRef)
    .then(() => {})
    .catch((error) => Utils.logError(error));
};

export const uploadFileKnowledgeBase = async (file, origin, name) => {
  const storageRef = ref(fbStorage, `knowledgeBase/${origin}/${name}__${Utils.generateUUID()}`);
  return uploadBytes(storageRef, file).then(() => getDownloadURL(storageRef));
};
