import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['https://conectatreinamentos.eastus.cloudapp.azure.com'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysOnErrorSampleRate: 0, // Captura 100% das sessões com erro para replays
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
