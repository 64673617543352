/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';
import moment from 'moment';

import './ListItem.scss';

export default function ListItem({ loading, itemData, denyTask }) {
  const stageName = itemData.scheduleType
    ? itemData[itemData.scheduleType].stage.name
    : itemData.stage.name;
  const stageId = itemData.scheduleType
    ? itemData[itemData.scheduleType].stage.id
    : itemData.stage.id;

  return (
    <div className="custom-list-item">
      <span>
        {itemData.cardTitle} - {itemData.quoteTitle ?? itemData.quoteName} - {itemData.text} -{' '}
        {stageName} - {`${moment(new Date(itemData.startDate)).format('DD/MM/YYYY')}`}
      </span>

      {/* 1 = Aguardando */}
      {stageId === 1 && (
        <div className="buttons">
          <Button
            danger
            disabled={loading}
            className="deny-button"
            onClick={() => {
              denyTask(itemData);
            }}
          >
            Negar
          </Button>
        </div>
      )}
    </div>
  );
}
