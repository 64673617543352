/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';

import logo from '../../../Assets/Images/logo-inline.png';

import './AppSupportInfo.scss';

function AppSupportInfoPage() {
  return (
    <Row>
      <Col offset={6} span={12}>
        <Row justify="center" align="middle" className="wrapper-privacy-policy">
          <Header className="download-app-header">
            <img
              src={logo}
              style={{ width: 300, textAlign: 'start' }}
              title="Conecta Treine"
              alt="Logo Conecta Treine"
            />
            <h1>Suporte do Portal e Aplicativo</h1>
          </Header>
          <div className="privacy-policy-body">
            <p>
              Se você estiver enfrentando algum problema ou tiver dúvidas, entre em contato através
              dos canais abaixo para auxiliarmos e encontrarmos uma solução.
            </p>
            <strong>Suporte Técnico:</strong>
            <p>
              ● E-mail:&nbsp;
              <strong>
                <a href="mailto:fabio@conectatreinamentos.com.br">
                  fabio@conectatreinamentos.com.br
                </a>
              </strong>
              &nbsp; ou &nbsp;
              <strong>
                <a href="mailto:mario@42dev.com.br">mario@42dev.com.br</a>
              </strong>
            </p>
            <p>
              ● Telefone:&nbsp;
              <strong>
                <a href="https://wa.me/5547991136655?text=Olá meu nome é [SEU NOME], estou tendo problemas com [DESCREVER PROBLEMA], consegue me ajudar?">
                  (47) 99113-6655
                </a>
              </strong>
            </p>
            <p>
              ● Horário de Atendimento:&nbsp; Segunda a Sexta, das 8h às 17h (Horário de Brasília)
            </p>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default AppSupportInfoPage;
