/* eslint-disable import/prefer-default-export */
import { message } from 'antd';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

export const getDocumentTypes = async () => {
  try {
    const documentTypes = await api
      .get('/DocumentType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os tipos de documento!');
      });

    const mappedDocumentTypes = documentTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));
    return mappedDocumentTypes;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Documentos!');
    return [];
  }
};

export const getSettings = async () => {
  try {
    const [response] = await api
      .get('/Settings')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });
    const decryptedResponse = Utils.decryptSettings(response);

    decryptedResponse.requiredDocumentsCT =
      decryptedResponse.requiredDocumentsCT?.split(',')?.map((item) => parseInt(item, 10)) ?? [];
    decryptedResponse.requiredDocumentsInstructor =
      decryptedResponse.requiredDocumentsInstructor
        ?.split(',')
        ?.map((item) => parseInt(item, 10)) ?? [];
    decryptedResponse.requiredDocumentsDriver =
      decryptedResponse.requiredDocumentsDriver?.split(',')?.map((item) => parseInt(item, 10)) ??
      [];
    decryptedResponse.requiredDocumentsTransport =
      decryptedResponse.requiredDocumentsTransport?.split(',')?.map((item) => parseInt(item, 10)) ??
      [];
    decryptedResponse.requiredDocumentsVehicle =
      decryptedResponse.requiredDocumentsVehicle?.split(',')?.map((item) => parseInt(item, 10)) ??
      [];

    return decryptedResponse;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar as Configurações!');
    return [];
  }
};
