/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form, Input, InputNumber, message, Modal, Select, Table } from 'antd';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

import './ModalStyle.scss';

function DeletionModal({ taskList, setLoading, setIsVisible, reasonsOptions, selectedRowKeys }) {
  const [form] = Form.useForm();
  const [render, setRender] = useState(false);

  const selectedTasks = taskList.filter((task) => selectedRowKeys.includes(task.id));

  const columns = [
    {
      title: 'Id Negócio',
      dataIndex: 'dealId',
      key: 'dealId',
      width: '110px',
    },
    {
      title: 'Id Turma',
      dataIndex: 'slotClassId',
      key: 'slotClassId',
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'dealClient',
      key: 'dealClient',
      width: '55%',
    },
  ];

  const handleOk = async () => {
    form
      .validateFields()
      .then(async () => {
        const invalidStages = [];
        const values = await form.validateFields();
        const { reason, percentageToBeCharged = 0 } = values; // Recupera o motivo da deleção do formulário

        if (invalidStages.length > 0) {
          message.warning(
            `Os Treinamentos nos estágios ${invalidStages.join(', ')} não podem ser removidos.`
          );
          return;
        }

        setLoading(true);
        try {
          const taskIds = selectedRowKeys.join(',');
          const stageId =
            form.getFieldValue('reason') === 1 || form.getFieldValue('reason') === 2 ? 11 : 6;

          await api.delete(
            `/Task/RemoveTaskList?taskId=${taskIds}&stageId=${stageId}&reasonId=${reason}&percentageToBeCharged=${percentageToBeCharged}`
          );

          message.success('Remoção dos Treinamentos concluída!');
          window.location.reload(); // Recarrega a página
        } catch (error) {
          Utils.logError(error);
          message.error('Oops. Algo deu errado ao tentar remover os Treinamentos selecionados!');
          setLoading(false);
        }
      })
      .catch(() => {
        Utils.error('Oops. Algo deu errado na deleção!');
      });
  };

  return (
    <Modal
      title="Confirmar Remoção"
      open={true}
      onOk={handleOk}
      onCancel={() => setIsVisible(false)}
      okText="Remover"
      okButtonProps={{
        style: { backgroundColor: '#d9534f', color: 'white', border: 'none' },
      }}
      cancelText="Cancelar"
      width="fit-content"
      className="training-list-deletion-modal"
    >
      <p>
        Você está prestes a <strong style={{ color: '#d9534f' }}>Remover</strong> os itens
        selecionados. Por favor, revise os detalhes abaixo e confirme se deseja prosseguir ou
        cancelar a operação.
      </p>

      <Form form={form} layout="vertical">
        <Form.Item
          name="reason"
          label="Motivo"
          rules={[{ required: true, message: 'Por favor, selecione um motivo!' }]}
        >
          <Select
            placeholder="Selecione um motivo"
            optionFilterProp="label"
            dropdownStyle={{ borderRadius: 16 }}
            style={{ 'text-align': 'start' }}
            onChange={() => setRender(!render)}
          >
            {reasonsOptions
              .filter(({ type }) => type === 'Scheduling')
              .map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {(form.getFieldValue('reason') === 1 || form.getFieldValue('reason') === 3) && (
          <Form.Item
            label="Percentual a ser cobrado"
            name="percentageToBeCharged"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
        )}

        <Form.Item name="note" label="Observações">
          <Input.TextArea rows={3} placeholder="Adicione qualquer observação aqui..." />
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={selectedTasks} pagination={false} rowKey="id" />
    </Modal>
  );
}

export default DeletionModal;
