/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { FaRegBuilding } from 'react-icons/fa';

import { EnvironmentOutlined, ScheduleOutlined } from '@ant-design/icons';

export default function ModalConfirmBody({ itemData: { startDate, dealClient } }) {
  const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <p style={{ marginBottom: 5 }}>
        <EnvironmentOutlined style={{ marginRight: 5 }} />
        {scheduleData.trainingCenter}
      </p>
      <p style={{ marginBottom: 5 }}>
        <FaRegBuilding style={{ marginRight: 5 }} />
        {dealClient}
      </p>
      <p style={{ marginBottom: 5 }}>
        <ScheduleOutlined style={{ marginRight: 5 }} />
        {moment(startDate).format('DD/MM/YYYY')}
      </p>
    </div>
  );
}
