/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { Scheduler } from 'devextreme-react';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';
import DataCellMonth from '../Schedule/DataCellMonth';

import './ScheduleModal.scss';

const data = {
  BlockedDaysList: [],
};

const notifyWorkDay = () => {
  message.warning('Não é possível habilitar/desabilitar um Dia de Trabalho!');
};

const notifyDayHasTask = () => {
  message.warning('Não é possível bloquear um dia com Treinamentos!');
};

const notifyNothintSelected = () => {
  message.warning('Nenhuma data selecionada!');
};

export default function BlockedDaysModal({
  open,
  onCancel,
  type,
  sourceId,
  updateBlockedDays,
  workDays,
  breakTime,
  blockedDates,
  dataSource,
}) {
  const [loadingSchedule, setLoadingSchedule] = useState(false);

  const onCellClick = ({ cellElement, cellData: { startDate, endDate } }) => {
    const foundAppointments = dataSource.filter(
      ({ startDate: sourceStart, endDate: sourceEnd }) =>
        (new Date(sourceStart) >= new Date(startDate) &&
          new Date(sourceStart) <= new Date(endDate)) ||
        (new Date(sourceEnd) >= new Date(startDate) && new Date(sourceEnd) <= new Date(endDate))
    );

    if (foundAppointments?.length > 0) {
      notifyDayHasTask();
      return;
    }

    const exists = data.BlockedDaysList.find(
      ({ BlockedDay }) => BlockedDay === startDate.toLocaleDateString()
    );

    if (exists) {
      cellElement.style.background = '#fff';
      cellElement.style.boxShadow = 'none';
      const index = data.BlockedDaysList.map(({ BlockedDay }) => BlockedDay).indexOf(
        startDate.toLocaleDateString()
      );
      data.BlockedDaysList.splice(index, 1);
    } else {
      const isWorkDay = Array.from(cellElement.firstChild?.classList).find(
        (className) => className === 'disable-date'
      );
      const blockedDayId = cellElement.firstChild?.dataset.id;

      if (isWorkDay && !blockedDayId) {
        notifyWorkDay();
        return;
      }

      cellElement.style.background = '#05005B26';
      cellElement.style.boxShadow = 'inset 0 0 0 1px #05005B';

      const blockedDay = {
        [`${type}Id`]: sourceId,
        BlockedDay: startDate.toLocaleDateString(),
      };

      if (blockedDayId) {
        blockedDay.id = blockedDayId;
      }

      data.BlockedDaysList.push(blockedDay);
    }
  };

  const submitBlockedDays = async (type) => {
    if (data.BlockedDaysList.length === 0) {
      notifyNothintSelected();
      return;
    }

    data.BlockedDaysList = data.BlockedDaysList.map((blockedDay) => ({
      ...blockedDay,
      ActionType: type,
    }));

    setLoadingSchedule(true);
    await api
      .post('/BlockedDay', data)
      .then(() => {
        message.success('Dias bloqueados!!');
        updateBlockedDays();
        data.BlockedDaysList = [];
        setLoadingSchedule(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao bloquear os dias!');
        setLoadingSchedule(false);
      });
  };

  const renderDataCell = (itemData) => (
    <DataCellMonth
      itemData={itemData}
      workDays={workDays}
      breakTime={breakTime}
      blockedDates={blockedDates}
    />
  );
  const appointmentRender = () => <div id="appointments-blocked-days" />;

  return (
    <Modal
      className="schedule-modal"
      open={open}
      width="70%"
      style={{ top: 10, padding: 0 }}
      confirmLoading={loadingSchedule}
      closable={false}
      maskClosable={false}
      footer={[
        <Button loading={loadingSchedule} onClick={onCancel}>
          Fechar
        </Button>,
        <Button type="primary" loading={loadingSchedule} onClick={() => submitBlockedDays('add')}>
          Bloquear
        </Button>,
        <Button
          type="primary"
          loading={loadingSchedule}
          onClick={() => submitBlockedDays('remove')}
        >
          Desbloquear
        </Button>,
      ]}
    >
      <Scheduler
        disabled={loadingSchedule}
        id="schedule-disable-dates"
        views={[]}
        dataSource={dataSource}
        defaultCurrentView="month"
        defaultCurrentDate={new Date()}
        showAllDayPanel={false}
        firstDayOfWeek={1}
        height="calc(100vh - 125px)"
        crossScrollingEnabled
        editing={false}
        dataCellRender={renderDataCell}
        onCellClick={onCellClick}
        appointmentComponent={appointmentRender}
      />
    </Modal>
  );
}
