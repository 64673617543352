import React, { useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './Home.scss';

function HomePage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const submit = async (values) => {
    setLoading(true);
    const data = values;
    data.id = JSON.parse(localStorage.getItem('conecta__userData'))?.id;

    // Requisição
    await api
      .put('/User/UpdatePassword', data)
      .then((res) => {
        const date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
        date.toUTCString();

        const userData = JSON.parse(localStorage.getItem('conecta__userData'));
        userData.firstAccess = res.data.firstAccess;
        localStorage.setItem('conecta__userData', JSON.stringify(userData));

        if (Utils.getCookie('conecta__keepConnected') !== '') {
          document.cookie = `conecta__login=${userData.login}; expires=${date}; path=/; SameSite=none; secure;`;
          document.cookie = `conecta__password=${res.data.password}; expires=${date}; path=/; SameSite=none; secure;`;
          document.cookie = `conecta__keepConnected=true; expires=${date}; path=/; SameSite=none; secure;`;
        }
        document.body.style.overflow = 'auto';
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Utils.logError(error);
        message.error('Erro ao alterar senha!');
      });
  };

  const updatePasswordCriteria = (value) => {
    const criteria = {
      minLength: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*]/.test(value),
    };

    setPasswordCriteria(criteria);
  };

  useLayoutEffect(() => {
    if (JSON.parse(localStorage.getItem('conecta__userData'))?.firstAccess) {
      document.body.style.overflow = 'hidden';
    }
  }, []);

  return (
    <>
      {JSON.parse(localStorage.getItem('conecta__userData'))?.firstAccess && (
        <Row justify="center" align="middle" className="wrapper-form screen-overlay">
          <Col span={8}>
            <Form
              form={form}
              disabled={loading}
              name="passwordChange"
              layout="vertical"
              onFinish={submit}
              autoComplete="off"
            >
              <h2>Alteração de senha</h2>

              <Form.Item
                label="Nova Senha"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Preencha com a senha!',
                  },
                ]}
              >
                <Input.Password onChange={(e) => updatePasswordCriteria(e.target.value)} />
              </Form.Item>
              <div style={{ marginBottom: '24px' }}>
                <p style={{ color: passwordCriteria.minLength ? 'green' : 'red' }}>
                  No mínimo 8 caracteres.
                </p>
                <p
                  style={{
                    color:
                      passwordCriteria.uppercase && passwordCriteria.lowercase ? 'green' : 'red',
                  }}
                >
                  Ao menos uma letra maiúscula e uma minúscula.
                </p>
                <p style={{ color: passwordCriteria.number ? 'green' : 'red' }}>
                  Ao menos um número.
                </p>
                <p style={{ color: passwordCriteria.specialChar ? 'green' : 'red' }}>
                  Ao menos um caractere especial.
                </p>
              </div>

              <Form.Item
                label="Repita a senha"
                name="confirmPassword"
                rules={[
                  {
                    validator: (rule, value) => {
                      if (form.getFieldValue('password') !== value) {
                        return Promise.reject(new Error(`As senhas precisam ser iguais`));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button loading={loading} block type="primary" htmlType="submit">
                  Confirmar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      <h3>Home</h3>
    </>
  );
}

export default HomePage;
