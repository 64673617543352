/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, message, Popconfirm, Row, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

import './RoleRegister.scss';

let fieldsValue = {};
const userData = JSON.parse(localStorage.getItem('conecta__userData'));

function RoleRegisterComponent() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [resourceOptions, setResourceOptions] = useState();
  const [fieldsForm, setFieldsForm] = useState();
  const [update, setUpdate] = useState(false);
  const [screenModified, setScreenModified] = useState(false);

  // Monta a lista de Permissões dos Recursos para enviar no request
  const mountResourcesToRequest = () =>
    Object.values(fieldsValue).map((resource) => {
      const canView = resource.CanView;
      const canHandle = resource.CanHandle;
      const name = resource.ResourceName;
      const selfView = resource.SelfView;
      const id = resource.ResourceId;

      delete resource.CanHandle;
      delete resource.CanView;
      delete resource.ResourceName;
      delete resource.ResourceId;
      delete resource.SelfView;

      const keys = Object.keys(resource);
      const fields = Object.values(resource).map((field, index) => ({
        Name: keys[index],
        Access: field.access,
        IsRequired: field.isRequired,
        IsADM: field.isADM,
      }));

      return {
        CanView: canView,
        CanHandle: canHandle,
        SelfView: selfView,
        Id: id,
        Name: name,
        Fields: fields,
      };
    });

  // Pega as informações do Cargo para Editar
  const getRoleEdit = async (id) => {
    setLoading(true);
    const response = await api
      .get(`/Role?id=${id}`)
      .then((res) => res)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    // Preenche o objeto com as Permissões
    const fields = {};
    response.data.resources.forEach((resource) => {
      fields[resource.id] = {
        ResourceName: resource.name,
        CanView: resource.canView,
        CanHandle: resource.canHandle,
        ResourceId: resource.id,
        SelfView: resource.selfView,
      };

      resource.fields.forEach((field) => {
        fields[resource.id][field.name] = {
          access: field.access,
          isRequired: field.isRequired,
          isADM: field.isADM,
        };
      });
    });
    fieldsValue = fields;

    // Preenche os campos do Form
    form.setFieldsValue({
      name: response.data.name,
      description: response.data.description,
    });

    setLoading(false);
  };

  // Monta as Permissões dos Recursos para manipular em tela
  const mountResourcesPermissions = async () => {
    const fields = {};

    const resources = await api
      .get('/Resource')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const resourcesToShow = [];

    resources.forEach((resource) => {
      fields[resource.id] = {
        ResourceName: resource.model,
        CanView: true,
        CanHandle: true,
        SelfView: false,
        ResourceId: resource.id,
      };

      resource.fields.forEach((field) => {
        fields[resource.id][field.name] = {
          access: field.isADM ? 1 : 2,
          isRequired: field.isRequired,
          isADM: field.isADM,
        };
      });

      if (resource.showMenu) {
        resourcesToShow.push({
          label: resource.name,
          value: resource.id,
          fields: resource.fields,
          showFields: resource.showFields,
        });
      }
    });

    setResourceOptions(resourcesToShow);

    if (!editId) {
      fieldsValue = fields;
    }
  };

  const submit = async (values) => {
    // setLoading(true);
    const formResources = mountResourcesToRequest();

    if (editId) {
      const data = {
        Id: parseInt(editId, 10),
        Name: values.name,
        Description: values.description,
        Resources: formResources,
      };

      await api
        .put('/Role', data)
        .then(() => {
          form.resetFields();
          setLoading(false);
          getRoleEdit(editId);
          message.success('Dados atualizados!');
          setScreenModified(false);
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Erro ao editar!');
          setLoading(false);
        });
    } else {
      const data = {
        Name: values.name,
        Description: values.description,
        Resources: formResources,
      };

      await api
        .post('/Role', data)
        .then(() => {
          form.resetFields();
          mountResourcesPermissions();
          message.success('Registro criado!');
          setLoading(false);
          setScreenModified(false);
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Erro ao cadastrar!');
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    mountResourcesPermissions();

    if (editId) {
      getRoleEdit(editId);
    }
  }, []);

  return (
    <Form
      form={form}
      disabled={loading}
      name="rolesForm"
      layout="vertical"
      initialValues={{
        view: true,
        handle: true,
        selfView: false,
      }}
      onFinish={submit}
      autoComplete="off"
      onChange={() => setScreenModified(true)}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Button loading={loading} block type="primary" htmlType="submit">
          Salvar
        </Button>
      </div>
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Recurso" name="resource">
            <Select
              onChange={(value, object) => {
                form.setFieldsValue({
                  view: fieldsValue[value].CanView,
                  handle: fieldsValue[value].CanHandle,
                  selfView: fieldsValue[value].SelfView,
                });

                if (object.showFields) {
                  setFieldsForm({ Id: value, Fields: object.fields });
                } else {
                  setFieldsForm();
                }
                setUpdate(!update);
              }}
              options={resourceOptions}
              allowClear
              placeholder="Selecione"
              optionFilterProp="label"
              showSearch
              dropdownStyle={{ borderRadius: 16 }}
            />
          </Form.Item>
        </Col>
        {form.getFieldValue('resource') && (
          <Col span={2}>
            <Form.Item name="view" valuePropName="checked" className="form-checkbox">
              <Checkbox
                onChange={() => {
                  const resourceId = form.getFieldValue('resource');
                  const newValue = !fieldsValue[resourceId].CanView;
                  fieldsValue[resourceId].CanView = newValue;
                }}
                disabled={form.getFieldValue('handle') + form.getFieldValue('selfView')}
              >
                Visualizar
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {form.getFieldValue('resource') && (
          <Col span={2}>
            <Form.Item name="handle" valuePropName="checked" className="form-checkbox">
              <Checkbox
                onChange={() => {
                  const resourceId = form.getFieldValue('resource');
                  const newValue = !fieldsValue[resourceId].CanHandle;
                  fieldsValue[resourceId].CanHandle = newValue;
                  form.setFieldValue('view', true);
                  setUpdate(!update);
                }}
                disabled={form.getFieldValue('selfView')}
              >
                Manipular
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {form.getFieldValue('resource') && (
          <Col span={2}>
            <Form.Item name="selfView" valuePropName="checked" className="form-checkbox">
              <Checkbox
                onChange={({ target }) => {
                  const resourceId = form.getFieldValue('resource');
                  const newValue = !fieldsValue[resourceId].SelfView;
                  fieldsValue[resourceId].SelfView = newValue;
                  fieldsValue[resourceId].CanHandle = false;
                  fieldsValue[resourceId].CanView = false;
                  form.setFieldValue('handle', false);
                  form.setFieldValue('view', false);
                  setUpdate(!update);
                  if (target.checked === false) {
                    form.setFieldValue('handle', true);
                    form.setFieldValue('view', true);
                    fieldsValue[resourceId].CanHandle = true;
                    fieldsValue[resourceId].CanView = true;
                  }
                }}
              >
                Auto Visualizar
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>

      {form.getFieldValue('resource') && fieldsForm && (
        <Card title="Permissões" className="resources">
          <Row gutter={[32, 0]} className="fields-permissions">
            <Col offset={9} span={3}>
              <div className="checkbox-canView">
                <Form.Item>
                  <Checkbox
                    defaultChecked={!editId}
                    onChange={({ target }) => {
                      const resourceId = form.getFieldValue('resource');

                      Object.keys(fieldsValue[resourceId]).forEach((field) => {
                        if (
                          field !== 'ResourceId' &&
                          field !== 'ResourceName' &&
                          field !== 'CanView' &&
                          field !== 'CanHandle'
                        ) {
                          if (fieldsValue[resourceId][field].isADM === false && userData.id !== 1) {
                            const canView = target.checked ? 1 : 0;
                            const canHandle = form.getFieldValue(`${field}-canHandle`);
                            fieldsValue[resourceId][field].access = canHandle ? 2 : canView;
                            form.setFieldsValue({
                              [`${field}-canView`]: canHandle ? true : canView,
                            });
                          } else if (userData.id === 1) {
                            const canView = target.checked ? 1 : 0;
                            const canHandle = form.getFieldValue(`${field}-canHandle`);
                            fieldsValue[resourceId][field].access = canHandle ? 2 : canView;
                            form.setFieldsValue({
                              [`${field}-canView`]: canHandle ? true : canView,
                            });
                          }
                        }
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={3}>
              <div className="checkbox-canHandle">
                <Form.Item>
                  <Checkbox
                    defaultChecked={!editId}
                    onChange={({ target }) => {
                      const resourceId = form.getFieldValue('resource');

                      Object.keys(fieldsValue[resourceId]).forEach((field) => {
                        if (
                          field !== 'ResourceId' &&
                          field !== 'ResourceName' &&
                          field !== 'CanView' &&
                          field !== 'CanHandle'
                        ) {
                          if (fieldsValue[resourceId][field].isADM === false && userData.id !== 1) {
                            const canView = form.getFieldValue(`${field}-canView`) ? 1 : 0;
                            if (target.checked) {
                              fieldsValue[resourceId][field].access = 2;
                              form.setFieldsValue({
                                [`${field}-canView`]: true,
                                [`${field}-canHandle`]: true,
                              });
                              setUpdate(!update);
                            } else {
                              fieldsValue[resourceId][field].access = canView;
                              form.setFieldsValue({
                                [`${field}-canView`]: true,
                                [`${field}-canHandle`]: false,
                              });
                              setUpdate(!update);
                            }
                          } else if (userData.id === 1) {
                            const canView = form.getFieldValue(`${field}-canView`) ? 1 : 0;
                            if (target.checked) {
                              fieldsValue[resourceId][field].access = 2;
                              form.setFieldsValue({
                                [`${field}-canView`]: true,
                                [`${field}-canHandle`]: true,
                              });
                              setUpdate(!update);
                            } else {
                              fieldsValue[resourceId][field].access = canView;
                              form.setFieldsValue({
                                [`${field}-canView`]: true,
                                [`${field}-canHandle`]: false,
                              });
                              setUpdate(!update);
                            }
                          }
                        }
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={3}>
              <div className="checkbox-required">
                <Form.Item>
                  <Checkbox
                    defaultChecked={!editId}
                    onChange={({ target }) => {
                      const resourceId = form.getFieldValue('resource');

                      Object.keys(fieldsValue[resourceId]).forEach((field) => {
                        if (
                          field !== 'ResourceId' &&
                          field !== 'ResourceName' &&
                          field !== 'CanView' &&
                          field !== 'CanHandle' &&
                          field !== 'IsRequired'
                        ) {
                          if (fieldsValue[resourceId][field].isADM === false && userData.id !== 1) {
                            if (target.checked) {
                              fieldsValue[resourceId][field].access = 2;
                              fieldsValue[resourceId][field].isRequired = true;
                              form.setFieldsValue({
                                [`${field}-required`]: true,
                                [`${field}-canHandle`]: true,
                                [`${field}-canView`]: true,
                              });
                              setUpdate(!update);
                            } else {
                              fieldsValue[resourceId][field].access = 2;
                              fieldsValue[resourceId][field].isRequired = false;
                              form.setFieldsValue({
                                [`${field}-required`]: false,
                                [`${field}-canHandle`]: false,
                                [`${field}-canView`]: false,
                              });
                              setUpdate(!update);
                            }
                          } else if (userData.id === 1) {
                            if (target.checked) {
                              fieldsValue[resourceId][field].access = 2;
                              fieldsValue[resourceId][field].isRequired = true;
                              form.setFieldsValue({
                                [`${field}-required`]: true,
                                [`${field}-canHandle`]: true,
                                [`${field}-canView`]: true,
                              });
                              setUpdate(!update);
                            } else {
                              fieldsValue[resourceId][field].access = 2;
                              fieldsValue[resourceId][field].isRequired = false;
                              form.setFieldsValue({
                                [`${field}-required`]: false,
                                [`${field}-canHandle`]: false,
                                [`${field}-canView`]: false,
                              });
                              setUpdate(!update);
                            }
                          }
                        }
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          {/* eslint-disable-next-line array-callback-return, consistent-return */}
          {fieldsForm?.Fields?.map(({ label, name, isADM }) => {
            const id = fieldsForm.Id;
            const fieldAccess = fieldsValue[id][name]?.access;
            const fieldIsRequired = fieldsValue[id][name]?.isRequired;

            form.setFieldsValue({
              [`${name}-canView`]: !!(fieldAccess === 1 || fieldAccess === 2),
              [`${name}-canHandle`]: fieldAccess === 2,
              [`${name}-required`]: fieldIsRequired === true,
            });

            if (userData.id !== 1) {
              if (isADM === false) {
                return (
                  <Row key={name} gutter={[32, 0]} className="fields-permissions">
                    <Col span={9} className="label-checkbox">
                      {label} :{' '}
                    </Col>
                    <Col span={3}>
                      <Form.Item valuePropName="checked" name={`${name}-canView`}>
                        <Checkbox
                          onChange={() => {
                            let value = 0;

                            if (form.getFieldValue(`${name}-canHandle`)) {
                              value = 2;
                            } else if (form.getFieldValue(`${name}-canView`)) {
                              value = 1;
                            }

                            fieldsValue[id][name].access = value;
                          }}
                          disabled={
                            form.getFieldValue(`${name}-canHandle`) ||
                            form.getFieldValue(`${name}-required`)
                          }
                        >
                          Visualizar
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item valuePropName="checked" name={`${name}-canHandle`}>
                        <Checkbox
                          onChange={() => {
                            let value = 0;

                            if (form.getFieldValue(`${name}-canView`)) {
                              value = 1;
                            }

                            if (form.getFieldValue(`${name}-canHandle`)) {
                              value = 2;
                            }

                            fieldsValue[id][name].access = value;
                            setUpdate(!update);
                          }}
                          disabled={form.getFieldValue(`${name}-required`)}
                        >
                          Manipular
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item valuePropName="checked" name={`${name}-required`}>
                        <Checkbox
                          onChange={() => {
                            const field = {};
                            field[`${name}-canView`] = true;
                            field[`${name}-canHandle`] = true;
                            form.setFieldsValue({ ...form.getFieldsValue(), ...field });
                            fieldsValue[id][name].access = 2;
                            fieldsValue[id][name].isRequired = form.getFieldValue(
                              `${name}-required`
                            );
                            setUpdate(!update);
                          }}
                        >
                          Obrigatório
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }
            }
            if (userData.id === 1) {
              return (
                <Row key={name} gutter={[32, 0]} className="fields-resource">
                  <Col span={9} className="label-checkbox">
                    {label} :{' '}
                  </Col>
                  <Col span={3}>
                    <Form.Item valuePropName="checked" name={`${name}-canView`}>
                      <Checkbox
                        onChange={() => {
                          let value = 0;

                          if (form.getFieldValue(`${name}-canHandle`)) {
                            value = 2;
                          } else if (form.getFieldValue(`${name}-canView`)) {
                            value = 1;
                          }

                          fieldsValue[id][name].access = value;
                        }}
                        disabled={
                          form.getFieldValue(`${name}-canHandle`) ||
                          form.getFieldValue(`${name}-required`)
                        }
                      >
                        Visualizar
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item valuePropName="checked" name={`${name}-canHandle`}>
                      <Checkbox
                        onChange={() => {
                          let value = 0;

                          if (form.getFieldValue(`${name}-canView`)) {
                            value = 1;
                          }

                          if (form.getFieldValue(`${name}-canHandle`)) {
                            value = 2;
                          }

                          fieldsValue[id][name].access = value;
                          setUpdate(!update);
                        }}
                        disabled={form.getFieldValue(`${name}-required`)}
                      >
                        Manipular
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item valuePropName="checked" name={`${name}-required`}>
                      <Checkbox
                        onChange={() => {
                          const field = {};
                          field[`${name}-canView`] = true;
                          field[`${name}-canHandle`] = true;
                          form.setFieldsValue({ ...form.getFieldsValue(), ...field });
                          fieldsValue[id][name].access = 2;
                          fieldsValue[id][name].isRequired = form.getFieldValue(`${name}-required`);
                          setUpdate(!update);
                        }}
                      >
                        Obrigatório
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              );
            }
          })}
        </Card>
      )}
    </Form>
  );
}

const RoleRegister = memo(RoleRegisterComponent);
export default RoleRegister;
