/* eslint-disable react/prop-types */
import React from 'react';

import ScheduleUtils from './ScheduleUtils';

export default function DateCell({ itemData, workDays, blockedDates }) {
  const { date, text } = itemData;

  return (
    <div
      className={
        ScheduleUtils.isDisableDate(date, workDays) ||
        ScheduleUtils.isBlockedDay(date, blockedDates)
          ? 'disable-date'
          : 'default-cell'
      }
    >
      <div>{text}</div>
    </div>
  );
}
