/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Tabs,
  TimePicker,
} from 'antd';
import Upload from 'antd/lib/upload/Upload';
import Inputmask from 'inputmask';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import CompanyFunctions from '../../../Assets/Scripts/CompanyFunctions';
import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

const { Search } = Input;

const workDaysOptions = [
  { label: 'Selecionar todos', value: 'selectAll' },
  { label: 'Segunda', value: '1' },
  { label: 'Terça', value: '2' },
  { label: 'Quarta', value: '3' },
  { label: 'Quinta', value: '4' },
  { label: 'Sexta', value: '5' },
  { label: 'Sábado', value: '6' },
  { label: 'Domingo', value: '0' },
];

let canHandleDriver = false;

function DriverRegisterPage() {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [documentTypeOptions, setDocumentTypeOptions] = useState();
  const [screenModified, setScreenModified] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState();

  const getDriverById = async (editId) => {
    setLoading(true);

    const response = await api
      .get(`/Driver?id=${editId}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados do motorista!');
      });

    response.workDays = response.workDays?.split(',');
    response.businessHour = [moment(response.startBusinessHour), moment(response.endBusinessHour)];
    response.breakTime = [moment(response.breakStart), moment(response.breakFinish)];
    response.documents = response?.documents?.map(
      ({ type: { id: type }, number, validity, downloadUrls, verified }) => ({
        type,
        number,
        validity: moment(validity),
        downloadUrls,
        verified,
      })
    );

    form.setFieldsValue(response);
    setLoading(false);
  };

  const submit = async (values) => {
    setLoading(true);
    const data = { ...values };

    if (values.businessHour && values.businessHour !== undefined) {
      data.startBusinessHour = moment.utc(values.businessHour[0]).format('YYYY-MM-DDTHH:mm:ss');
      data.endBusinessHour = moment.utc(values.businessHour[1]).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (values.breakTime && values.breakTime !== undefined) {
      data.breakStart = moment.utc(values.breakTime[0]).format('YYYY-MM-DDTHH:mm:ss');
      data.breakFinish = moment.utc(values.breakTime[1]).format('YYYY-MM-DDTHH:mm:ss');
    }

    data.workDays = data.workDays?.toString();

    await CompanyFunctions.saveDocuments(data, documentTypeOptions, 'driver');

    const httpMethod = editId ? 'put' : 'post';
    await api[httpMethod]('/Driver', data)
      .then(() => {
        message.success(`Dados ${editId ? 'atualizados' : 'salvos'}!`);
        form.resetFields();

        if (editId) {
          getDriverById(editId);
        }

        setLoading(false);
        setScreenModified(false);
      })
      .catch((error) => {
        Utils.logError(error);
        let errorMessage = 'Erro ao enviar os dados do Motorista!';

        if (error?.errors?.length > 0 && error.errors[0]?.message.includes('Email')) {
          errorMessage = error.errors[0].message;
        } else if (error?.message?.includes('Email')) {
          errorMessage = error.message;
        }

        message.error(`Oops. ${errorMessage}`);
        setLoading(false);
      });
  };

  const getDocumentTypes = async () => {
    const documentTypes = await api
      .get('/DocumentType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os tipos de documento!');
      });

    const mappedDocumentTypes = documentTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setDocumentTypeOptions(mappedDocumentTypes);
    return mappedDocumentTypes;
  };

  const fetchSettings = async () => {
    const docTypes = await getDocumentTypes();

    const res = await api
      .get(`/Settings?id=1`)
      .then(async (res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os documentos obrigatórios!');
      });

    const decryptedSettings = Utils.decryptSettings(res);
    const reqDocsIds =
      decryptedSettings.requiredDocumentsDriver?.split(',')?.map((item) => parseInt(item, 10)) ??
      [];

    const reqDocs = docTypes
      ?.filter(({ value }) => reqDocsIds.includes(value))
      ?.map(({ label }) => label);

    setRequiredDocuments(reqDocs);
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields, canHandle }) => {
        if (name === 'Driver') {
          canHandleDriver = canHandle;
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  useEffect(() => {
    fetchSettings();

    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone2'));
    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone1'));
    Inputmask({ mask: '99.999.999/9999-99' }).mask(document.getElementById('cnpj'));

    if (editId) {
      getDriverById(editId);
    }
  }, []);

  return (
    <Form
      form={form}
      disabled={loading}
      name="driverForm"
      layout="vertical"
      onFinish={submit}
      autoComplete="off"
      onChange={() => setScreenModified(true)}
    >
      <div className="form-toolbar">
        {screenModified && (
          <Popconfirm
            title="Deseja mesmo voltar?"
            onConfirm={() => navigate(-1)}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Voltar</Button>
          </Popconfirm>
        )}
        {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}

        <Button loading={loading} block type="primary" htmlType="submit ">
          Salvar
        </Button>
      </div>

      <Tabs>
        <Tabs.TabPane tab="Dados Gerais" key="generalData">
          {/* Campos invisiveis */}
          <Form.Item className="input-hidden" label="Id" name="id">
            <Input />
          </Form.Item>

          <Row gutter={[24]}>
            {fieldsPermissions?.Cnpj.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="CNPJ"
                  name="cnpj"
                  rules={[
                    {
                      required: fieldsPermissions?.Cnpj.isRequired,
                      validator: (rule, value) => {
                        const isValid = Utils.validateCNPJ(value);
                        if (!isValid && fieldsPermissions?.Cnpj.isRequired) {
                          return Promise.reject(new Error(`CNPJ Inválido`));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Search
                    id="cnpj"
                    placeholder="00.000.000/0000-00"
                    disabled={fieldsPermissions?.Cnpj.access !== 2}
                    onSearch={(val) => CompanyFunctions.fetchCnpj(val, form)}
                  />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Name.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="Nome"
                  name="name"
                  rules={[
                    {
                      required: fieldsPermissions?.Name.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input disabled={fieldsPermissions?.Name.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.CommercialName.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="Nome Fantasia"
                  name="commercialName"
                  rules={[
                    {
                      required: fieldsPermissions?.CommercialName.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input disabled={fieldsPermissions?.CommercialName.access !== 2} />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Email.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: fieldsPermissions?.Email.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input
                    placeholder="exemplo@email.com"
                    disabled={fieldsPermissions?.Email.access !== 2}
                  />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Phone1.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="Telefone 1"
                  name="phone1"
                  rules={[
                    {
                      required: fieldsPermissions?.Phone1.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input
                    id="phone1"
                    placeholder="(00) 0000-0000"
                    disabled={fieldsPermissions?.Phone1.access !== 2}
                  />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.Phone2.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="Telefone 2"
                  name="phone2"
                  rules={[
                    {
                      required: fieldsPermissions?.Phone2.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input
                    id="phone2"
                    placeholder="(00) 0000-0000"
                    disabled={fieldsPermissions?.Phone2.access !== 2}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Dados Complementares" key="additionalData" forceRender>
          <Row gutter={[24]}>
            {fieldsPermissions?.WorkDays.access !== 0 && (
              <Col span={8}>
                <Form.Item
                  label="Dias de trabalho"
                  name="workDays"
                  rules={[
                    {
                      required: fieldsPermissions?.WorkDays.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Select
                    options={workDaysOptions}
                    mode="multiple"
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    maxTagCount="responsive"
                    disabled={fieldsPermissions?.WorkDays.access !== 2}
                    onChange={(values) =>
                      Utils.handleSelectAllChange(values, workDaysOptions, 'workDays', form)
                    }
                  />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.BusinessHour.access !== 0 && (
              <Col span={4}>
                <Form.Item
                  label="Horário de trabalho"
                  name="businessHour"
                  rules={[
                    {
                      required: fieldsPermissions?.BusinessHour.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <TimePicker.RangePicker
                    format="HH:mm"
                    style={{ borderRadius: 16 }}
                    disabled={fieldsPermissions?.BusinessHour.access !== 2}
                    autoComplete="new"
                    ranges={{
                      '08h às 18h': [
                        moment().startOf('day').add(8, 'hours'),
                        moment().startOf('day').add(18, 'hours'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            )}

            {fieldsPermissions?.BreakTime.access !== 0 && (
              <Col span={4}>
                <Form.Item
                  label="Horário de intervalo"
                  name="breakTime"
                  rules={[
                    {
                      required: fieldsPermissions?.BreakTime.isRequired,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <TimePicker.RangePicker
                    format="HH:mm"
                    style={{ borderRadius: 16 }}
                    disabled={fieldsPermissions?.BreakTime.access !== 2}
                    ranges={{
                      '12h às 13h': [
                        moment().startOf('day').add(12, 'hours'),
                        moment().startOf('day').add(13, 'hours'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Documentos" key="documentsData" forceRender>
          <Form.List name="documents">
            {(fields, { add, remove }) => (
              <>
                {requiredDocuments?.length > 0 && (
                  <div>
                    <h3>Documentos Obrigatórios</h3>
                    <p style={{ margin: 0 }}>{requiredDocuments?.join(' / ')}</p>
                  </div>
                )}

                <Button
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                    width: 200,
                  }}
                  type="dashed"
                  onClick={() => {
                    add();
                    setScreenModified(true);
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Adicionar Documento
                </Button>
                <Row gutter={[24]}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Col key={key} span={8}>
                      <Card
                        extra={
                          <Popconfirm
                            title="Deseja mesmo excluir?"
                            onConfirm={() => {
                              if (form.getFieldValue('documents')[name]?.downloadUrls) {
                                CompanyFunctions.deleteDocumentFromStorage(form, 'driver', name);
                                remove(name);
                                submit(form.getFieldsValue());
                                setScreenModified(false);
                              } else {
                                remove(name);
                                setScreenModified(true);
                              }
                            }}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <CloseOutlined />
                          </Popconfirm>
                        }
                        style={{
                          backgroundColor: '#ffffff00',
                          borderColor: '#b7b7b7',
                          borderRadius: 16,
                          marginBottom: 10,
                        }}
                      >
                        {canHandleDriver && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'verified']}
                              valuePropName="checked"
                              initialValue={true}
                            >
                              <Checkbox>Verificado</Checkbox>
                            </Form.Item>
                          </Col>
                        )}
                        {fieldsPermissions?.DocumentType.access !== 0 && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              label="Tipo de documento"
                              rules={[
                                {
                                  required: fieldsPermissions?.DocumentType.isRequired,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Selecione"
                                optionFilterProp="label"
                                showSearch
                                options={documentTypeOptions}
                                dropdownStyle={{ borderRadius: 16 }}
                                disabled={fieldsPermissions?.DocumentType.access !== 2}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        {fieldsPermissions?.DocumentNumber.access !== 0 && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'number']}
                              label="Nº documento"
                              rules={[
                                {
                                  required: fieldsPermissions?.DocumentNumber.isRequired,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Input disabled={fieldsPermissions?.DocumentNumber.access !== 2} />
                            </Form.Item>
                          </Col>
                        )}
                        {fieldsPermissions?.DocumentValidity.access !== 0 && (
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'validity']}
                              label="Validade"
                              rules={[
                                {
                                  required: fieldsPermissions?.DocumentValidity.isRequired,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <DatePicker
                                format="DD-MM-YYYY"
                                style={{
                                  borderRadius: 16,
                                }}
                                disabled={fieldsPermissions?.DocumentValidity.access !== 2}
                              />
                            </Form.Item>
                          </Col>
                        )}

                        {fieldsPermissions?.UploadFiles.access !== 0 &&
                          form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'downloadUrls']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                                rules={[
                                  {
                                    required: fieldsPermissions?.UploadFiles.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <List
                                  size="small"
                                  header={<div>Arquivos</div>}
                                  bordered
                                  dataSource={form.getFieldValue('documents')[name]?.downloadUrls}
                                  renderItem={({ label, url }) => (
                                    <List.Item>
                                      <a
                                        href={url}
                                        target="blank"
                                        style={{ wordBreak: 'break-word' }}
                                      >
                                        {label}
                                      </a>
                                    </List.Item>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          )}

                        {fieldsPermissions?.UploadFiles.access !== 0 &&
                          !form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'uploadFiles']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                                rules={[
                                  {
                                    required: fieldsPermissions?.UploadFiles.isRequired,
                                    message: 'Campo obrigatório!',
                                  },
                                ]}
                              >
                                <Upload
                                  multiple
                                  beforeUpload={() => false}
                                  disabled={fieldsPermissions?.UploadFiles.access !== 2}
                                >
                                  <Button icon={<UploadOutlined />}>Upload de arquivos</Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          )}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </Form.List>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
}

const DriverRegister = memo(DriverRegisterPage);
export default DriverRegister;
