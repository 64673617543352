/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, DatePicker, Divider, Drawer, Form, message, Radio, Row } from 'antd';
import moment from 'moment';

import { DownloadOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';

const { RangePicker } = DatePicker;

function ExportDataDrawer({ isOpen, setIsOpen }) {
  const [taskReportForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [filterAll, setFilterAll] = useState(false);

  const downloadReport = async (values) => {
    setLoading(true);
    let startDateFormatted = null;
    let endDateFormatted = null;
    let fileName = '';

    if (!filterAll) {
      const [startDate, endDate] = values.dateRangeTaskReport;
      startDateFormatted = moment(startDate).format('YYYY-MM-DDT00:00:01');
      endDateFormatted = moment(endDate).format('YYYY-MM-DDT23:59:59');
      fileName = `Relatorio_Treinamentos_${moment(startDate).format('DD-MM-YYYY')}_ate_${moment(
        endDate
      ).format('DD-MM-YYYY')}.xlsx`;
    } else {
      fileName = `Relatorio_Treinamentos_Completo_${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;
    }

    const data = { startDate: startDateFormatted, endDate: endDateFormatted };

    await fetch(`${process.env.REACT_APP_HOST}/ExportData`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
        'Content-Type': 'application/json; charset=UTF-8',
        'Cache-Control': 'max-age=31536000',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      body: JSON.stringify(data),
      responseType: 'blob',
      withCredentials: true,
    })
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw Error('Oops. Algo deu errado ao tentar baixar o relatório!');
        }

        return res.blob();
      })
      .then((res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        link.click();
        message.success('Download do relatório realizado com sucesso!');
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar baixar o relatório!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Drawer
      title="Exportação de Dados"
      placement="right"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      width="40%"
    >
      <Form
        form={taskReportForm}
        disabled={loading}
        name="taskReportForm"
        layout="vertical"
        onFinish={downloadReport}
        autoComplete="off"
        initialValues={{ filterAll }}
      >
        <Row gutter={[24]}>
          <Col span={24}>
            <Divider className="configuration-divider" orientation="left">
              Relatório de Treinamentos
            </Divider>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Filtrar Tudo?"
              name="filterAll"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Radio.Group
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
                optionType="button"
                onChange={(val) => {
                  if (val?.target?.value === true) {
                    setFilterAll(true);
                    taskReportForm.setFieldValue('dateRangeTaskReport', undefined);
                  } else {
                    setFilterAll(false);
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Período"
              name="dateRangeTaskReport"
              rules={[
                {
                  required: !filterAll,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                style={{ borderRadius: 16 }}
                popupStyle={{ borderRadius: 16, zIndex: 99999999 }}
                disabled={filterAll}
              />
            </Form.Item>
          </Col>

          <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              icon={<DownloadOutlined />}
            />
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default ExportDataDrawer;
