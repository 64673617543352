/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Drawer, List, message, Skeleton } from 'antd';
import moment from 'moment';

import { LinkOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './NotificationDrawer.scss';

let startAt = null;
let lastLength = null;

function NotificationDrawer({ isOpen, setIsOpen, notifications, queryLimit }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);

  const onLoadMore = async () => {
    setLoading(true);
    setList(
      data.concat(
        [...new Array(3)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        }))
      )
    );

    const userId = JSON.parse(localStorage.getItem('conecta__userData'))?.id;

    await api
      .get(`/Notification/UserNotifications?id=${userId}&startAt=${startAt}&limit=${queryLimit}`)
      .then((res) => {
        const result = res.data.notifications;
        const newData = data.concat(result);
        const lastItem = result.at(-1)?.id ?? 1;
        startAt = lastItem - 1;
        lastLength = result?.length;

        setData(newData);
        setList(newData);
        setLoading(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as notificações!');
      });
  };

  useEffect(() => {
    setData(notifications);
    setList(notifications);
    startAt = (notifications.at(-1)?.id ?? 1) - 1;
    lastLength = notifications?.length;
  }, [notifications]);

  const loadMore =
    lastLength >= 10 && startAt > 0 && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore}>Carregar mais</Button>
      </div>
    ) : null;

  return (
    <Drawer
      className="notifications-drawer"
      title="Notificações"
      placement="right"
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={list}
        renderItem={({ id, title, body, createDate, loading, redirectUrlPathWeb }) => {
          let notificationDate = null;
          const daysDiff = moment(new Date()).diff(new Date(moment(createDate)), 'days');

          if (daysDiff === 0) {
            notificationDate = `Hoje • ${moment(createDate).format('HH:mm')}`;
          } else if (daysDiff === 1) {
            notificationDate = `Ontem • ${moment(createDate).format('HH:mm')}`;
          } else if (daysDiff < 7) {
            notificationDate = `${Utils.getWeekDayLong(createDate)} • ${moment(createDate).format(
              'HH:mm'
            )}`;
          } else {
            notificationDate = `${new Date(createDate).getDate()} ${Utils.getMonthShort(
              createDate
            )}`;
          }

          return (
            <List.Item>
              <Skeleton title={false} loading={loading} active>
                <div key={id} className="notification-wrapper">
                  <h2>{title}</h2>
                  <p className="notification-body" dangerouslySetInnerHTML={{ __html: body }} />
                  <p className="notification-date">{notificationDate}</p>

                  {redirectUrlPathWeb && (
                    <Button
                      style={{ marginTop: 5 }}
                      icon={<LinkOutlined />}
                      onClick={() => {
                        window.location.href = redirectUrlPathWeb;
                      }}
                    >
                      Abrir
                    </Button>
                  )}
                </div>
              </Skeleton>
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
}

export default NotificationDrawer;
