/* eslint-disable react/prop-types */
import React, { useLayoutEffect, useState } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';

import { api } from '../../Services/axiosService';
import TaskComment from '../Schedule/TaskComment';
import TaskCommentFunctions from '../Schedule/TaskCommentFunctions';

export default function ModalIntercurrence({
  openModalIntercurrence,
  onCancel,
  currentOpenTask,
  eventSource,
}) {
  const [form] = Form.useForm();
  const [comentsTasks, setTaskComments] = useState();
  const [loading, setLoading] = useState(false);
  const eventSourceFormatted = eventSource
    ? eventSource.charAt(0).toUpperCase() + eventSource.slice(1)
    : '';

  const getComentsTasks = async () => {
    setLoading(true);
    await api
      .get(
        `/TaskComment?filters[0].Field=TaskId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${currentOpenTask?.id}&filters[1].Field=SourceType&filters[1].Condition=EQUAL&filters[1].Value=${eventSourceFormatted}TaskIntercurrence`
      )
      .then((res) => setTaskComments(res.data))
      .catch((error) => error);
    setLoading(false);
  };

  const addComentsTask = async () => {
    setLoading(true);

    await TaskCommentFunctions.addTaskComment(
      currentOpenTask,
      'TaskIntercurrence',
      eventSourceFormatted,
      form.getFieldValue('intercurrence')
    );

    message.success('Intercorrência adicionada!');
    getComentsTasks();
    form.resetFields();
    setLoading(false);
  };

  useLayoutEffect(() => {
    if (currentOpenTask) {
      getComentsTasks();
    }
  }, [currentOpenTask]);

  return (
    <Modal
      title={`Intercorrências - (${currentOpenTask?.classId})`}
      open={openModalIntercurrence}
      onCancel={onCancel}
      cancelText="Fechar"
      okButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
    >
      <Form
        form={form}
        name="modalIntercurrence"
        layout="vertical"
        autoComplete="off"
        onFinish={addComentsTask}
        preserve={false}
      >
        <Form.Item
          label="Intercorrência"
          name="intercurrence"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Button htmlType="submit" loading={loading}>
          Adicionar
        </Button>
        {!loading && <TaskComment comments={comentsTasks} />}
      </Form>
    </Modal>
  );
}
