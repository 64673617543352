import React, { useLayoutEffect, useState } from 'react';
import { Avatar, Badge, Button, Dropdown, Layout, Menu, message, Spin, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  BellOutlined,
  FileExcelOutlined,
  LoginOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';
import CustomBreadcrumb from '../CustomBreadcrumb/CustomBreadcrumb';
import ExportDataDrawer from '../ExportDataDrawer/ExportDataDrawer';
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import VersionModal from '../VersionModal/VersionModal';

import './BaseLayout.scss';

let showNotificationsCount = false;

function BaseLayout() {
  const navigate = useNavigate();

  const [openDataExport, setOpenDataExport] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userNotification, setUserNotification] = useState();
  const [showNewVersion, setShowNewVersion] = useState(
    JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.showNewWebVersion ?? false
  );
  const [dropdownItems, setDropdownItems] = useState([
    {
      label: 'Sair',
      key: '/Sair',
      icon: <LoginOutlined />,
    },
  ]);
  const queryLimit = 10;

  const resetNotificationCount = () =>
    api
      .put(
        `/User/UpdateNotificationCount?id=${
          JSON.parse(localStorage.getItem('conecta__userData'))?.id
        }&reset=true`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao atualizar as Notificações!');
      });

  const getNotifications = async () => {
    const userId = JSON.parse(localStorage.getItem('conecta__userData'))?.id;
    dropdownItems.splice(0, dropdownItems.length); // Limpa os itens
    dropdownItems.push({
      label: 'Sair',
      key: '/Sair',
      icon: <LoginOutlined />,
    });

    const response = await api
      .get(`/Notification/UserNotifications?id=${userId}&limit=${queryLimit}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as Notificações!');
      });

    if (response?.newNotificationCount > 0) {
      showNotificationsCount = true;
    } else {
      showNotificationsCount = false;
    }

    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, canView, canHandle }) => {
        permissions[name] = { canView, canHandle };
      }
    );

    if (permissions.Configuration?.canView) {
      dropdownItems.unshift({
        label: 'Configurações',
        key: '/Configuracao',
        icon: <SettingOutlined />,
      });
    }

    if (permissions.Exporter?.canView || permissions.Exporter?.canHandle) {
      dropdownItems.unshift({
        label: 'Exportador',
        onClick: () => {
          setOpenDataExport(true);
        },
        key: 'exporter',
        icon: <FileExcelOutlined />,
      });
    }

    dropdownItems.unshift({
      label: 'Notificações',
      key: 'notification',
      icon: (
        <Badge
          count={showNotificationsCount ? response?.newNotificationCount : 0}
          overflowCount={99}
          className="notification-badge"
        >
          <BellOutlined />
        </Badge>
      ),
      onClick: () => {
        const itemIndex = dropdownItems.findIndex(({ key }) => key === 'notification');
        dropdownItems[itemIndex].icon = (
          <Badge count={0} overflowCount={99} className="notification-badge">
            <BellOutlined />
          </Badge>
        );
        setDropdownItems(dropdownItems);

        if (showNotificationsCount) {
          resetNotificationCount();
        }

        showNotificationsCount = false;
        setIsDrawerOpen(true);
      },
    });

    if (permissions.Profile?.canView) {
      dropdownItems.unshift({
        label: 'Perfil',
        key: '/Perfil',
        icon: <UserOutlined />,
      });
    }

    setDropdownItems([...dropdownItems]);
    setUserNotification(response);
  };

  useLayoutEffect(() => {
    getNotifications();
  }, []);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Spin tip="Carregando..." className="global-spinner" />
      <SidebarMenu />
      <ExportDataDrawer isOpen={openDataExport} setIsOpen={setOpenDataExport} />
      <Content className="site-layout-background">
        <CustomBreadcrumb />
        <Tooltip placement="left" title='Clique para ver "Novidades e ajustes"'>
          <Button
            type="primary"
            icon={<QuestionOutlined />}
            onClick={() => setShowNewVersion(true)}
            className="btn-new-version"
          />
        </Tooltip>
        {dropdownItems && (
          <Dropdown
            overlay={
              <Menu
                onClick={({ key }) => {
                  if (key === '/Sair') {
                    Utils.delCookies();
                  } else if (key !== 'notification' && key !== 'exporter') {
                    navigate(key);
                  }
                }}
                theme="light"
                items={dropdownItems}
              />
            }
            className="user-dropdown"
            placement="bottomRight"
            arrow
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
          >
            <Badge dot={showNotificationsCount && userNotification?.newNotificationCount > 0}>
              <Avatar
                className="avatar"
                src={JSON.parse(localStorage.getItem('conecta__userData'))?.profilePic}
              />
            </Badge>
          </Dropdown>
        )}
        <Outlet />
      </Content>
      {userNotification && (
        <NotificationDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          notifications={userNotification?.notifications}
          queryLimit={queryLimit}
        />
      )}

      {showNewVersion && (
        <VersionModal showNewVersion={showNewVersion} setShowNewVersion={setShowNewVersion} />
      )}
    </Layout>
  );
}

export default BaseLayout;
