/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Upload,
} from 'antd';
import axios from 'axios';
import Inputmask from 'inputmask';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import icon from '../../../Assets/Images/logo-inline.png';
import CompanyFunctions from '../../../Assets/Scripts/CompanyFunctions';
import Utils from '../../../Assets/Scripts/Utils';
import { api } from '../../../Services/axiosService';

import './InstructorPreRegister.scss';

function InstructorPreRegisterComponent({ mobile }) {
  const { id: editId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const { Header } = Layout;

  const [loading, setLoading] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState();
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [viewElements, setviewElements] = useState('');

  const submit = async (values) => {
    setLoading(true);
    const data = values;

    data.birthDate = moment(data.birthDate);
    data.workDays = data.workDays.toString();

    data.trainingKind = data.trainingKind?.map((type) => {
      const [training] = trainingsOptions?.filter(({ value: id }) => id === type);

      return {
        id: training.value,
        name: training.label,
      };
    });

    await CompanyFunctions.saveDocuments(data, documentTypeOptions, 'instructorPreRegister');

    await api
      .post('/PreInstructor', data)
      .then(() => {
        message.success('Cadastro realizado!');
        form.resetFields();
        setLoading(false);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar realizar o cadastro!');
        setLoading(false);
      });
  };

  const getPreInstructorView = async () => {
    setLoading(true);
    const preRegistrations = await api
      .get(`/PreInstructor`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const mappedPreRegistrations = preRegistrations?.map((records) => {
      const data = records;
      data.birthDate = moment(data.birthDate);
      data.workDays = data.workDays?.split(',');
      data.trainingKind = data?.trainingKind?.map(({ id }) => id);
      data.documents = data?.documents?.map(
        ({ type: { id: type }, number, validity, downloadUrls }) => ({
          type,
          number,
          validity: moment(validity),
          downloadUrls,
        })
      );

      return {
        label: data.name,
        value: data.id,
        fields: data,
      };
    });

    if (editId) {
      const [viewPreRegistrations] = mappedPreRegistrations?.filter(
        (company) => company.value === parseInt(editId, 10)
      );

      Object.defineProperty(
        viewPreRegistrations.fields,
        'company',
        Object.getOwnPropertyDescriptor(viewPreRegistrations.fields, 'id')
      );
      delete viewPreRegistrations.fields.id;

      viewPreRegistrations.fields.id = parseInt(editId, 10);
      form.setFieldsValue(viewPreRegistrations.fields);
    }
    setLoading(false);
  };

  const getAdressByCEP = async (cep, type) => {
    if (cep.replace(/[-_]/g, '')?.length === 8) {
      const address = await axios
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.data)
        .catch((error) => {
          Utils.logError(error);
          message.error('Erro ao buscar CEP!');
        });

      const data = {};
      if (type === 'Personal') {
        data.personalStreet = address.logradouro;
        data.personalDistrict = address.bairro;
        data.personalComplement = address.complemento;
        data.personalCity = address.localidade;
        data.personalState = address.uf;
      } else {
        data.street = address.logradouro;
        data.district = address.bairro;
        data.complement = address.complemento;
        data.city = address.localidade;
        data.state = address.uf;
      }

      form.setFieldsValue({ ...form.getFieldsValue(), ...data });
    }
  };

  const getDocumentTypes = async () => {
    const documentTypes = await api
      .get('/DocumentType')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const mappedDocumentTypes = documentTypes?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setDocumentTypeOptions(mappedDocumentTypes);
  };

  const getTrainings = async () => {
    const trainings = await api
      .get('/Trainings')
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const mappedTrainings = trainings?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setTrainingsOptions(mappedTrainings);
  };

  useEffect(() => {
    if (editId) {
      setviewElements('none');
      getPreInstructorView();
    }

    getDocumentTypes();
    getTrainings();

    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone2'));
    Inputmask({ mask: '(99) 9{1,9}' }).mask(document.getElementById('phone1'));
    Inputmask({ mask: '999.999.999-99' }).mask(document.getElementById('cpf'));
    Inputmask({ mask: '99999-999' }).mask(document.getElementById('personalCep'));
  }, []);

  return (
    <Layout className={`layout-wrapper ${mobile ? 'wrapper-mobile' : ''}`}>
      <Header style={{ display: mobile ? 'none' : viewElements }}>
        <img src={icon} style={{ width: 155 }} title="Conecta Treine" alt="Logo Conecta Treine" />
      </Header>
      <h1 style={{ display: viewElements }}>Pré-Cadastro de Instrutor</h1>
      <Card className="instructor-preregister-wrapper">
        <div className="form-toolbar">
          <Button onClick={() => navigate(-1)}>Voltar</Button>
        </div>
        <Form
          form={form}
          disabled={loading}
          name="InstructorPreRegister"
          onFinish={submit}
          layout="vertical"
          autoComplete="off"
        >
          <Card type="inner" title="Dados" className="instructor-preregister">
            <Row gutter={[24]}>
              <Col span={mobile ? 24 : 8}>
                <Form.Item
                  label="Nome"
                  name="name"
                  rules={[
                    {
                      required: false,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={mobile ? 24 : 8}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      required: false,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input placeholder="exemplo@email.com" />
                </Form.Item>
              </Col>
              <Col span={mobile ? 12 : 8}>
                <Form.Item
                  label="RG"
                  name="rg"
                  rules={[
                    {
                      required: false,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={mobile ? 12 : 8}>
                <Form.Item
                  label="CPF"
                  name="cpf"
                  rules={[
                    {
                      required: false,
                      validator: (rule, value) => {
                        const isValid = Utils.validateCPF(value);
                        if (!isValid) {
                          return Promise.reject(new Error(`CPF Inválido`));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input id="cpf" placeholder="000.000.000-00" />
                </Form.Item>
              </Col>
              <Col span={mobile ? 12 : 8}>
                <Form.Item label="Telefone" name="phone1">
                  <Input id="phone1" placeholder="(00) 0000-0000" />
                </Form.Item>
              </Col>
              <Col span={mobile ? 12 : 8}>
                <Form.Item label="Celular" name="phone2">
                  <Input id="phone2" placeholder="(00) 0000-0000" />
                </Form.Item>
              </Col>
              <Col span={mobile ? 12 : 4}>
                <Form.Item label="Data de nascimento" name="birthDate">
                  <DatePicker
                    placeholder="00/00/0000"
                    format="DD-MM-YYYY"
                    style={{ borderRadius: 16 }}
                  />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 9}>
                <Form.Item label="Dias de trabalho" name="workDays">
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    maxTagCount="responsive"
                  >
                    <Option value="1">Segunda</Option>
                    <Option value="2">Terça</Option>
                    <Option value="3">Quarta</Option>
                    <Option value="4">Quinta</Option>
                    <Option value="5">Sexta</Option>
                    <Option value="6">Sábado</Option>
                    <Option value="7">Domingo</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={mobile ? 24 : 11}>
                <Form.Item label="Tipos de treinamento" name="trainingKind">
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    options={trainingsOptions}
                    dropdownStyle={{ borderRadius: 16 }}
                    maxTagCount="responsive"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Formação profissional" name="qualification">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item label="Modelo do veículo" name="carModel">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 4}>
                <Form.Item label="Cor" name="carColor">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 4}>
                <Form.Item label="Placa" name="carLicensePlate">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item
                  label={mobile ? 'Guia RPA' : 'Inscrição municipal para guia RPA'}
                  name="municipalRegistration"
                >
                  <Input type="number" />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item label="Banco" name="bank">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 4}>
                <Form.Item label="Agência" name="agency">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 4}>
                <Form.Item label="Conta corrente" name="transactionAccount">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item label={mobile ? 'Chave Pix' : 'Chave recebimento - Pix'} name="pix">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            type="inner"
            title="Endereço Pessoal"
            style={{
              marginTop: 25,
            }}
          >
            <Row gutter={[24]}>
              <Col span={mobile ? 12 : 8}>
                <Form.Item name="personalCep" label="CEP">
                  <Input
                    id="personalCep"
                    maxLength={9}
                    placeholder="00000-000"
                    onChange={(e) => getAdressByCEP(e.target.value, 'Personal')}
                  />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item name="personalState" label="Estado">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item name="personalCity" label="Cidade">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item name="personalDistrict" label="Bairro">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={mobile ? 12 : 8}>
                <Form.Item name="personalStreet" label="Rua">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={mobile ? 12 : 8}>
                <Form.Item name="personalComplement" label="Complemento">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card
            type="inner"
            title="Documentos"
            style={{
              marginTop: 25,
            }}
          >
            <Form.List name="documents">
              {(fields, { add, remove }) => (
                <>
                  <Button
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                      width: 200,
                      left: mobile ? '20%' : 0,
                    }}
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Documento
                  </Button>
                  <Row gutter={[24]}>
                    {fields.map(({ key, name, ...restField }) => (
                      <Col key={key} span={mobile ? 24 : 8}>
                        <Card
                          extra={
                            <Popconfirm
                              title="Deseja mesmo excluir?"
                              onConfirm={() => {
                                if (form.getFieldValue('documents')[name]?.downloadUrls) {
                                  CompanyFunctions.deleteDocumentFromStorage(
                                    form,
                                    'instructorPreRegister',
                                    name
                                  );
                                  remove(name);
                                  form.submit();
                                } else {
                                  remove(name);
                                }
                              }}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <CloseOutlined />
                            </Popconfirm>
                          }
                          style={{
                            backgroundColor: '#ffffff00',
                            borderColor: '#b7b7b7',
                            borderRadius: 16,
                            marginBottom: 10,
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              label="Tipo de documento"
                              rules={[
                                {
                                  required: true,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Selecione"
                                optionFilterProp="label"
                                showSearch
                                options={documentTypeOptions}
                                dropdownStyle={{ borderRadius: 16 }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'number']}
                              label="Nº documento"
                              rules={[
                                {
                                  required: true,
                                  message: 'Campo obrigatório!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item {...restField} name={[name, 'validity']} label="Validade">
                              <DatePicker
                                format="DD-MM-YYYY"
                                style={{
                                  borderRadius: 16,
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'downloadUrls']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <List
                                  size="small"
                                  header={<div>Arquivos</div>}
                                  bordered
                                  dataSource={form.getFieldValue('documents')[name]?.downloadUrls}
                                  renderItem={({ label, url }) => (
                                    <List.Item>
                                      <a
                                        href={url}
                                        target="blank"
                                        style={{ wordBreak: 'break-word' }}
                                      >
                                        {label}
                                      </a>
                                    </List.Item>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {!form.getFieldValue('documents')[name]?.downloadUrls && (
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'uploadFiles']}
                                label
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <Upload multiple beforeUpload={() => false}>
                                  <Button icon={<UploadOutlined />}>Upload de arquivos</Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          )}
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Form.List>
          </Card>
          <div
            className={mobile ? '' : 'form-toolbar'}
            style={{ justifyContent: 'center', paddingTop: 25, display: viewElements }}
          >
            <Button loading={loading} block type="primary" htmlType="submit">
              Salvar
            </Button>
          </div>
        </Form>
      </Card>
    </Layout>
  );
}

const InstructorPreRegister = memo(InstructorPreRegisterComponent);
export default InstructorPreRegister;
