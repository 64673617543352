/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';

import logo from '../../../Assets/Images/logo-inline.png';

import './AppPrivacyPolicy.scss';

function AppPrivacyPolicyPage() {
  return (
    <Row>
      <Col offset={6} span={12}>
        <Row justify="center" align="middle" className="wrapper-privacy-policy">
          <Header className="download-app-header">
            <img
              src={logo}
              style={{ width: 300, textAlign: 'start' }}
              title="Conecta Treine"
              alt="Logo Conecta Treine"
            />
            <h1>Política de Privacidade da Conecta Treinamentos LTDA</h1>
          </Header>
          <div className="privacy-policy-body">
            <strong>Data de Entrada em Vigor:</strong> 14/05/2024
            <p>
              Esta Política de Privacidade descreve como a Conecta Treinamentos LTDA, inscrita no
              CNPJ sob o nº 42.809.741/0001-68, coleta, utiliza e compartilha suas informações
              pessoais ao usar nosso aplicativo. A segurança dos seus dados é uma prioridade para
              nós, e estamos comprometidos em proteger sua privacidade e tratar suas informações com
              o maior cuidado e responsabilidade.
            </p>
            <strong>Dados Coletados e Permissões do Aplicativo:</strong>
            <p>
              Acesso à armazenamento externo: Para upload e gerenciamento de arquivos e fotos.
              <br />
              Acesso à localização (grossa e precisa, incluindo plano de fundo): Para acompanhamento
              de trajetos e serviços baseados em localização.
              <br />
              Acesso à câmera: Para tirar fotos necessárias às operações internas.
            </p>
            <strong>iOS:</strong>
            <p>
              Modos de operação em plano de fundo: Para atualizações e notificações remotas.
              <br />
              Acesso à câmera: Para tirar fotos necessárias às operações internas.
              <br />
              Localização quando em uso e sempre: Para acompanhamento de trajetos e serviços
              baseados em localização.
            </p>
            <strong>Uso dos Dados:</strong>
            <p>
              Utilizamos as informações coletadas para:
              <br />
              ● Upload de arquivos e fotos dentro do aplicativo.
              <br />● Coleta de localização do motorista para acompanhamento de trajeto.
              <br />● Envio de notificações aos usuários.
              <br />● Controle de acesso com coleta de nome, e-mail, telefone e senha para
              autenticação e dados básicos de cada usuário.
              <br />● Melhoria dos sistemas e fluxo de trabalho.
              <br />● Mensuração de estatísticas dos serviços oferecidos e prestados.
              <br />● Tomadas de decisão estratégicas internas.
            </p>
            <strong>Compartilhamento de Dados:</strong>
            <p>
              Os dados coletados são utilizados exclusivamente internamente e não são compartilhados
              com terceiros, exceto conforme necessário para as operações normais do aplicativo e
              conforme acordado pelos usuários como parte das operações da empresa.
            </p>
            <strong>Segurança dos Dados:</strong>
            <p>
              Adotamos medidas de segurança técnicas e organizacionais para proteger seus dados
              pessoais contra acesso não autorizado, alteração, divulgação ou destruição.
            </p>
            <strong>Seus Direitos:</strong>
            <p>
              Você tem direito de acessar, corrigir ou excluir suas informações pessoais. Para
              exercer esses direitos, por favor, entre em contato conosco através dos nossos canais
              de suporte.
            </p>
            <strong>Mudanças na Política de Privacidade:</strong>
            <p>
              Podemos atualizar nossa Política de Privacidade periodicamente. Qualquer mudança será
              comunicada através de nosso aplicativo ou outros meios de comunicação.
            </p>
            <strong>Contato:</strong>
            <p>
              Para questões relacionadas a esta política, entre em contato conosco através do
              e-mail&nbsp;
              <strong>
                <a href="mailto:contato@conectatreinamentos.com.br">
                  contato@conectatreinamentos.com.br
                </a>
              </strong>
            </p>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default AppPrivacyPolicyPage;
