/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Form, InputNumber, message, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Utils from '../../Assets/Scripts/Utils';
import ScheduleUtils from '../Schedule/ScheduleUtils';
import TaskCommentFunctions from '../Schedule/TaskCommentFunctions';

export default function ReasonModal({
  isModalOpen,
  setModalOpen,
  reasonsOptions,
  currentOpenTask,
  event,
  eventSource,
  onOk,
  onCancel,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [render, setRender] = useState(false);
  let titleModal = '';
  let typeFilter = '';

  switch (event) {
    case 'TaskAccepted':
      titleModal = 'Aceitar';
      break;
    case 'TaskDenied':
      titleModal = 'Negar';
      typeFilter = 'CT';
      break;
    case 'InstructorTaskDenied':
      titleModal = 'Negar';
      typeFilter = 'Instructor';
      break;
    case 'DriverTaskDenied':
      titleModal = 'Negar';
      typeFilter = 'Driver';
      break;
    case 'TaskRemoved':
      titleModal = 'Remover';
      typeFilter = 'Scheduling';
      break;
    case 'TaskStarted':
      titleModal = 'Iniciar';
      break;
    case 'TaskFinished':
      titleModal = 'Concluir';
      break;
    case 'TaskEditedDelayed':
      titleModal = 'Editar';
      break;
    case 'LogisticsDeny':
      titleModal = 'Negar';
      typeFilter = 'Logistics';
      break;
    default:
      break;
  }

  const submit = async () => {
    try {
      setLoading(true);

      // Gera Atividade no Treinamento
      const reason = reasonsOptions.find(({ value }) => value === form.getFieldValue('reason'));
      await TaskCommentFunctions.addTaskComment(
        currentOpenTask,
        event,
        eventSource,
        form.getFieldValue('note'),
        reason.value,
        reason.label,
        form.getFieldValue('percentageToBeCharged')
      );

      // Envia Notificação para o CT, informando Negativa do Operador
      if (
        event === 'TaskDenied' &&
        eventSource !== 'CT' &&
        eventSource !== 'Instructor' &&
        eventSource !== 'Driver'
      ) {
        await ScheduleUtils.sendPushNotification(
          currentOpenTask,
          event,
          eventSource,
          currentOpenTask[eventSource]?.userId
        );
      }

      // Envia Notificação para a Logística, informando Negativa do Instrutor
      if (event === 'InstructorTaskDenied' && eventSource === 'Instructor') {
        await ScheduleUtils.sendNotificationToLogistics(currentOpenTask, eventSource, null, true);
      }

      // Envia Notificação para a Logística, informando Negativa do Motorista
      if (event === 'DriverTaskDenied' && eventSource === 'Driver') {
        const driverUserId = parseInt(
          JSON.parse(localStorage.getItem('conecta__userData'))?.id,
          10
        );
        const driverData = currentOpenTask.driverList?.find(({ userId }) => {
          if (userId === driverUserId) {
            return true;
          }
          return false;
        });

        await ScheduleUtils.sendNotificationToLogistics(
          currentOpenTask,
          eventSource,
          driverData,
          true
        );
      }

      // Envia Notificação para o Responsável do Negócio, informando Negativa do CT
      if (event === 'TaskDenied' && eventSource === 'CT') {
        await ScheduleUtils.sendPushNotificationToResponsible(currentOpenTask);
      }

      const stageId =
        form.getFieldValue('reason') === 1 || form.getFieldValue('reason') === 2 ? 11 : 6;

      if (onOk) {
        await onOk(stageId);
      }

      setModalOpen(false);
      form.resetFields();
      setLoading(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado!');
    }
  };

  return (
    <Modal
      name="reasonModal"
      className="schedule-modal"
      open={isModalOpen}
      confirmLoading={loading}
      closable={false}
      maskClosable={false}
      footer={[
        <Button
          onClick={async () => {
            if (onCancel) {
              await onCancel();
            }
            setModalOpen(false);
          }}
        >
          Cancelar
        </Button>,
        <Button form="note" type="primary" key="submit" htmlType="submit" loading={loading}>
          Confirmar
        </Button>,
      ]}
    >
      <Form
        form={form}
        disabled={loading}
        name="note"
        layout="vertical"
        autoComplete="off"
        onFinish={submit}
      >
        <h2>{titleModal} Treinamento</h2>

        <Form.Item
          label="Motivo"
          name="reason"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Select
            placeholder="Selecione"
            optionFilterProp="label"
            dropdownStyle={{ borderRadius: 16 }}
            options={reasonsOptions?.filter(({ type }) => type === typeFilter)}
            onChange={() => setRender(!render)}
          />
        </Form.Item>
        {(form.getFieldValue('reason') === 1 || form.getFieldValue('reason') === 3) && (
          <Form.Item
            label="Percentual a ser cobrado"
            name="percentageToBeCharged"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
        )}
        <Form.Item label="Observações" name="note">
          <TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
