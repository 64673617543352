/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';

function UploadFileFieldComponent({
  handleTaskUpdate,
  form,
  task,
  fieldName,
  fieldPermission,
  isTaskReadOnly,
  isEditing,
  setUploadFileType,
  setUploadFileModalOpen,
}) {
  const taskData = { ...task, ...form.getFieldsValue() };

  const [files, setFiles] = useState(taskData[fieldName] || []);

  const handleDelete = async (file, index) => {
    try {
      await form.validateFields();

      await ScheduleUtils.deleteAttachmentFromTask(file.name, taskData.id);

      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);

      const updatedData = { ...taskData, [fieldName]: updatedFiles };

      form.setFieldValue(fieldName, updatedFiles);

      let eventName = '';
      switch (fieldName) {
        case 'nfTransportation':
          eventName = 'NFTransportationDelete';
          break;
        case 'nfTraining':
          eventName = 'NFTrainingDelete';
          break;
        case 'initialAttendanceDocList':
          eventName = 'InitialAttendanceListDelete';
          break;
        case 'finalAttendanceDocList':
          eventName = 'FinalAttendanceListDelete';
          break;
        case 'instructorForm':
          eventName = 'InstructorFormDelete';
          break;
        default:
          break;
      }

      await TaskCommentFunctions.addTaskCommentUploadFile(updatedData, eventName, 'Scheduling');
      await handleTaskUpdate('save');
    } catch (error) {
      message.warn(
        'Oops! Parece que alguns campos não foram preenchidos ou estão inválidos, verifique o formulário e tente novamente.',
        7
      );
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      {/* Upload Button */}
      {fieldPermission.access === 2 && !isTaskReadOnly && isEditing && (
        <Button
          type="default"
          icon={<UploadOutlined />}
          style={{ width: 'fit-content' }}
          onClick={() => {
            setUploadFileType(fieldName);
            setUploadFileModalOpen(true);
          }}
        >
          Adicionar Arquivo
        </Button>
      )}

      {/* List of Files */}
      {files.map((file, index) => (
        <div key={index} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <a href={file.url} target="_blank" className="custom-a" rel="noreferrer">
            {file.label}
          </a>

          {/* Delete Option */}
          {fieldPermission.access === 2 && !isTaskReadOnly && isEditing && (
            <Popconfirm
              placement="topLeft"
              title="Tem certeza que deseja deletar o arquivo?"
              onConfirm={() => handleDelete(file, index)}
              okText="Sim"
              okType="danger"
              cancelText="Cancelar"
            >
              <DeleteOutlined style={{ color: '#fc5d20', cursor: 'pointer' }} />
            </Popconfirm>
          )}
        </div>
      ))}

      {files.length === 0 && !isEditing && (
        <span className="upload-file-field-no-data">o campo está vazio</span>
      )}
    </div>
  );
}

export default UploadFileFieldComponent;
