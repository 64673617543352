/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useLayoutEffect, useState } from 'react';
import { Button, Layout, Menu, message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  CalendarOutlined,
  CarOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  FundProjectionScreenOutlined,
  GroupOutlined,
  IdcardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProductOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';

import logo from '../../Assets/Images/logo-inline.png';
import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import './SidebarMenu.scss';

const { Sider } = Layout;

export default function SidebarMenu() {
  const [permissions, setPermissions] = useState({});
  const [companyId, setCompanyId] = useState({});
  const [collapsed, setCollapsed] = useState(
    JSON.parse(localStorage.getItem('conecta__menuPreference'))
  );
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    localStorage.setItem('conecta__menuPreference', !collapsed);
    setCollapsed(!collapsed);
  };

  const getResources = () =>
    api
      .get('/Resource')
      .then((res) => {
        const permissions = {};

        JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
          ({ name, canView, canHandle, selfView }) => {
            permissions[name] = { canView, canHandle, selfView };
          }
        );

        res.data?.forEach(({ model, showMenu }) => {
          if (permissions[model]) {
            permissions[model].ShowMenu = showMenu;
          }
        });
        setPermissions(permissions);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  useLayoutEffect(() => {
    const userCompanyId = JSON.parse(localStorage.getItem('conecta__userData'))?.companyId;
    setCompanyId(userCompanyId);
    getResources();
  }, []);

  return (
    <div className="siderBar-menu">
      <Button type="primary" onClick={toggleCollapsed}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <img
        src={logo}
        title="Conecta Treinamentos"
        alt="Logo Conecta Treinamentos"
        onClick={() => navigate('/')}
      />
      <div className="background-menu">
        <Sider theme="light" collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Skeleton round active size="small" loading={Object.keys(permissions).length === 0}>
            {Object.keys(permissions).length > 0 && (
              <Menu
                onClick={({ key }) => {
                  navigate(key);
                }}
                theme="light"
                mode="inline"
              >
                {permissions.ScheduleOverview.canView && permissions.ScheduleOverview.ShowMenu && (
                  <Menu.Item key="/Agenda/VisaoGeral" icon={<CalendarOutlined />}>
                    Agenda
                  </Menu.Item>
                )}

                {permissions.ScheduleTrainingCenter.canView &&
                  permissions.ScheduleTrainingCenter.ShowMenu && (
                    <Menu.Item key="/Agenda/CentroTreinamento" icon={<CalendarOutlined />}>
                      Agenda CT
                    </Menu.Item>
                  )}

                {permissions.ScheduleInstructor.canView &&
                  permissions.ScheduleInstructor.ShowMenu && (
                    <Menu.Item key="/Agenda/Instrutor" icon={<CalendarOutlined />}>
                      Agenda Instrutor
                    </Menu.Item>
                  )}

                {permissions.ScheduleDriver.canView && permissions.ScheduleDriver.ShowMenu && (
                  <Menu.Item key="/Agenda/Motorista" icon={<CalendarOutlined />}>
                    Agenda Motorista
                  </Menu.Item>
                )}

                {permissions.Role.canView && permissions.Role.ShowMenu && (
                  <Menu.Item key="/Cargo/Listar" icon={<SolutionOutlined />}>
                    Cargo
                  </Menu.Item>
                )}

                {permissions.TrainingCenter.canView && permissions.TrainingCenter.ShowMenu && (
                  <Menu.Item key="/CentroTreinamento/Listar" icon={<EnvironmentOutlined />}>
                    Centro De Treinamento
                  </Menu.Item>
                )}

                {permissions.TrainingCenter?.selfView && permissions.TrainingCenter.ShowMenu && (
                  <Menu.Item
                    key={`/CentroTreinamento/Editar/${companyId}`}
                    icon={<EnvironmentOutlined />}
                  >
                    Centro De Treinamento
                  </Menu.Item>
                )}

                {permissions.Certificates.canView && permissions.Certificates.ShowMenu && (
                  <Menu.Item key="/Certificados/Listar" icon={<FileProtectOutlined />}>
                    Certificados
                  </Menu.Item>
                )}

                {permissions.Company.canView && permissions.Company.ShowMenu && (
                  <Menu.Item key="/Empresas/Listar" icon={<GroupOutlined />}>
                    Empresas
                  </Menu.Item>
                )}

                {permissions.Billing.canView && permissions.Billing.ShowMenu && (
                  <Menu.Item key="/Faturamento" icon={<DollarOutlined />}>
                    Faturamento
                  </Menu.Item>
                )}

                {permissions.InstructorManagement.canView &&
                  permissions.InstructorManagement.ShowMenu && (
                    <Menu.Item key="/GestaoInstrutores" icon={<FundProjectionScreenOutlined />}>
                      Gestão Instrutores
                    </Menu.Item>
                  )}

                {permissions.Instructor.canView && permissions.Instructor.ShowMenu && (
                  <Menu.Item key="/Instrutor/Listar" icon={<IdcardOutlined />}>
                    Instrutor
                  </Menu.Item>
                )}

                {permissions.Instructor?.selfView && permissions.Instructor.ShowMenu && (
                  <Menu.Item key={`/Instrutor/Editar/${companyId}`} icon={<IdcardOutlined />}>
                    Instrutor
                  </Menu.Item>
                )}

                {permissions.TrainingList.canView && permissions.TrainingList.ShowMenu && (
                  <Menu.Item key="/Treinamentos/Listar" icon={<UnorderedListOutlined />}>
                    Lista de Treinamentos
                  </Menu.Item>
                )}

                {permissions.Driver.canView && permissions.Driver.ShowMenu && (
                  <Menu.Item key="/Motorista/Listar" icon={<CarOutlined />}>
                    Motorista
                  </Menu.Item>
                )}

                {permissions.Driver?.selfView && permissions.Driver.ShowMenu && (
                  <Menu.Item key={`/Motorista/Editar/${companyId}`} icon={<CarOutlined />}>
                    Motorista
                  </Menu.Item>
                )}

                {permissions.PreInstructor.canView && permissions.PreInstructor.ShowMenu && (
                  <Menu.Item key="/PreCadastroInstrutor" icon={<IdcardOutlined />}>
                    Pré-Registro de Instrutor
                  </Menu.Item>
                )}

                {permissions.Product.canView && permissions.Product.ShowMenu && (
                  <Menu.Item key="/Produtos/Listar" icon={<ProductOutlined />}>
                    Produtos
                  </Menu.Item>
                )}

                {permissions.Transport.canView && permissions.Transport.ShowMenu && (
                  <Menu.Item key="/Transporte/Listar" icon={<CarOutlined />}>
                    Transporte
                  </Menu.Item>
                )}

                {permissions.Transport?.selfView && permissions.Transport.ShowMenu && (
                  <Menu.Item key={`/Transporte/Editar/${companyId}`} icon={<CarOutlined />}>
                    Transporte
                  </Menu.Item>
                )}

                {permissions.User.canView && permissions.User.ShowMenu && (
                  <Menu.Item key="/Usuario/Listar" icon={<UserOutlined />}>
                    Usuário
                  </Menu.Item>
                )}

                {permissions.Vehicle.canView && permissions.Vehicle.ShowMenu && (
                  <Menu.Item key="/Veiculo/Listar" icon={<CarOutlined />}>
                    Veículo
                  </Menu.Item>
                )}
              </Menu>
            )}
          </Skeleton>
        </Sider>
      </div>
    </div>
  );
}
