/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Popconfirm } from 'antd';

import {
  DeleteOutlined,
  FilePdfOutlined,
  SettingOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';

export default function CertificateActionButtonsComponent({
  selectedRowKeys,
  subTableSelectedRowKeys,
  fieldsPermissions,
  shouldDisableCertificateGeneration,
  setIsUploadStudentsModalOpen,
  setIsCertificateSettingsModalOpen,
  setIsGenerateCertificateModalOpen,
  handleDeleteStudents,
}) {
  return (
    <div style={{ display: 'flex', gap: 8 }}>
      {/* Importar Lista de Alunos */}
      {fieldsPermissions?.ImportStudentListBtn?.access >= 1 && (
        <Button
          disabled={selectedRowKeys.length === 0}
          onClick={() => {
            setIsUploadStudentsModalOpen(true);
          }}
          type="default"
          icon={<SnippetsOutlined />}
        >
          Importar Lista de Alunos
        </Button>
      )}

      {/* Configurar Certificado */}
      {fieldsPermissions?.CertificateTrainingSettingsBtn?.access >= 1 && (
        <Button
          disabled={selectedRowKeys.length === 0}
          onClick={() => {
            setIsCertificateSettingsModalOpen(true);
          }}
          type="default"
          icon={<SettingOutlined />}
        >
          Configurar Certificado
        </Button>
      )}

      {/* Gerar Certificado */}
      {fieldsPermissions?.GenerateCertificateBtn?.access >= 1 && (
        <Button
          disabled={shouldDisableCertificateGeneration()}
          onClick={() => {
            setIsGenerateCertificateModalOpen(true);
          }}
          type="default"
          icon={<FilePdfOutlined />}
        >
          Gerar Certificado
        </Button>
      )}

      {/* Remover Alunos */}
      {fieldsPermissions?.DeleteStudentBtn?.access >= 1 && (
        <Popconfirm
          title="Tem certeza que deseja remover os alunos selecionados?"
          onConfirm={() => {
            if (selectedRowKeys.length > 0 && subTableSelectedRowKeys.length > 0) {
              handleDeleteStudents();
            }
          }}
          okText="Sim"
          cancelText="Não"
          overlayStyle={{
            zIndex: 9999,
          }}
          disabled={selectedRowKeys.length === 0 || subTableSelectedRowKeys.length === 0}
        >
          <Button
            style={{
              color: '#121212',
              border: 'none',
              boxShadow: 'none',
              opacity:
                selectedRowKeys.length === 0 || subTableSelectedRowKeys.length === 0 ? 0.3 : 1,
            }}
            type="link"
            icon={<DeleteOutlined />}
          >
            Remover Alunos
          </Button>
        </Popconfirm>
      )}
    </div>
  );
}
