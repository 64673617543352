/* eslint-disable import/order */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/named */
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Col, message, Row, Spin } from 'antd';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import FileManager, { Notifications, Permissions } from 'devextreme-react/file-manager';
import { Popup } from 'devextreme-react/popup';
import { useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api, apiNoToken } from '../../Services/axiosService';
import {
  deleteFileKnowledgeBase,
  getFile,
  getStorageList,
  uploadFileKnowledgeBase,
} from '../../Services/firebaseService';

import './KnowledgeBase.scss';

export default function KnowledgeBase() {
  const { authToken, currentPath } = useParams();
  const [imageItemToDisplay, setImageItemToDisplay] = useState();
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [folderName, setFolderName] = useState();
  const [instructor, setInstructor] = useState();
  const imagePopupRef = useRef(null);

  const getAllFiles = async (directory) => {
    const rootPath = directory;
    const { items, prefixes } = await getStorageList(rootPath);

    const currentItems = [];
    for (let index = 0; index < items.length; index += 1) {
      currentItems.push(
        await getFile(items[index]).then(({ metaData: { name, fullPath, size }, url }) => ({
          name,
          path: fullPath,
          size,
          url,
          isDirectory: false,
        }))
      );
    }

    const currentItemsFilter = currentItems.filter((file) => file?.name !== '.ghostfile');

    const currentFolders = [];
    prefixes.forEach(({ _location: { path_ }, name }) => {
      if (
        directory === 'knowledgeBase' &&
        folderName?.includes(name) &&
        instructor?.companyType === 'Instrutor'
      ) {
        currentFolders.push({
          name,
          path: path_,
          isDirectory: true,
        });
      }

      if (directory !== 'knowledgeBase' || instructor?.companyType !== 'Instrutor') {
        currentFolders.push({
          name,
          path: path_,
          isDirectory: true,
        });
      }
    });

    return [...currentFolders, ...currentItemsFilter];
  };

  const getInstructorById = async (editId) => {
    const options = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
        'Cache-Control': 'max-age=31536000',
      },
    };

    const response = await api
      .get(`/Instructor?id=${editId}`, authToken ? options : null)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    setInstructor(response);
    setFolderName(response.trainingKind?.map(({ name }) => name).toString());
  };

  // Abre o popup para exibição de imagens
  const onSelectedFileOpened = (e) => {
    imagePopupRef.current.instance.show();
    setImageItemToDisplay({ name: e.file.name, url: e.file.dataItem.url });
  };

  // Função para criar pastas
  // const onDirectoryCreated = async (e) => {
  //   await createFolder(`${e?.parentDirectory.path}/${e?.name}`);
  // };

  // Função para deletar arquivos
  const onItemDeleted = async (e) => {
    await deleteFileKnowledgeBase(e?.item.path);
  };

  // Função para fazer upload arquivos
  const onFileUploaded = async (e) => {
    await uploadFileKnowledgeBase(e.fileData, e.parentDirectory.path, e.parentDirectory.name);
  };

  // Função para fazer  download dos arquivos
  const onItemDownloading = async (e) => {
    if (authToken) {
      window.ReactNativeWebView.postMessage(e.item.dataItem.url);
    } else {
      fetch(e.item.dataItem.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = e.item.dataItem.name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => Utils.logError(error));
    }
  };

  const validateToken = async (authToken) => {
    const userData = await apiNoToken
      .post(`/Authentication/ValidateToken?token=${authToken}`)
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Acesso negado!');
      });

    await getInstructorById(userData.company.id);
  };

  useLayoutEffect(() => {
    if (authToken) {
      validateToken(authToken);
    } else {
      JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
        ({ name, canHandle, canView }) => {
          if (name === 'KnowledgeBase') {
            setFieldsPermissions({ canHandle, canView });
          }
        }
      );
      const instructorData = JSON.parse(localStorage.getItem('conecta__userData'));

      getInstructorById(instructorData.companyId);
    }
  }, []);

  if (!instructor) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (instructor) {
    return (
      <>
        {currentPath ? (
          <FileManager
            height={authToken ? '100vh' : 'calc(100vh - 60px)'}
            className={authToken ? 'mobile-file-manager' : 'default-file-manager'}
            currentPath={currentPath}
            fileSystemProvider={
              new CustomFileSystemProvider({
                getItems: (e) => {
                  const data = e?.dataItem;
                  return getAllFiles(data?.path ?? 'knowledgeBase');
                },
              })
            }
            onSelectedFileOpened={onSelectedFileOpened}
            // onDirectoryCreated={onDirectoryCreated}
            onItemDeleting={onItemDeleted}
            onFileUploaded={onFileUploaded}
            onItemDownloading={onItemDownloading}
            rootFolderName="Base de Conhecimento"
          >
            <Permissions
              delete={fieldsPermissions?.canHandle}
              upload={fieldsPermissions?.canHandle}
              download={true}
            />
            <Notifications showPanel={false} showPopup={false} />
          </FileManager>
        ) : (
          <FileManager
            height={authToken ? '100vh' : 'calc(100vh - 60px)'}
            className={authToken ? 'mobile-file-manager' : 'default-file-manager'}
            fileSystemProvider={
              new CustomFileSystemProvider({
                getItems: (e) => {
                  const data = e?.dataItem;
                  return getAllFiles(data?.path ?? 'knowledgeBase');
                },
              })
            }
            onSelectedFileOpened={onSelectedFileOpened}
            // onDirectoryCreated={onDirectoryCreated}
            onItemDeleting={onItemDeleted}
            onFileUploaded={onFileUploaded}
            onItemDownloading={onItemDownloading}
            rootFolderName="Base de Conhecimento"
          >
            <Permissions
              delete={fieldsPermissions?.canHandle}
              upload={fieldsPermissions?.canHandle}
              download={true}
            />
            <Notifications showPanel={false} showPopup={false} />
          </FileManager>
        )}

        <Popup
          maxHeight={600}
          hideOnOutsideClick={true}
          title={imageItemToDisplay?.name}
          ref={imagePopupRef}
        >
          <img
            src={imageItemToDisplay?.url}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Popup>
      </>
    );
  }
}
