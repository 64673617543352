/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import TableAntd from '../../../Components/TableAntd/TableAntd';
import { api } from '../../../Services/axiosService';

export default function CompanyList() {
  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'commercialName',
        title: 'Nome',
        ...getColumnSearchProps('commercialName', 'Nome'),
        sorter: (a, b) => a.commercialName?.localeCompare(b.commercialName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'cnpj',
        title: 'CNPJ',
        width: '15%',
        ...getColumnSearchProps('cnpj', 'CNPJ'),
      },
      {
        dataIndex: 'email',
        title: 'E-mail',
        width: '25%',
        ...getColumnSearchProps('email', 'E-mail'),
        sorter: (a, b) => a.email?.localeCompare(b.email),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'city',
        title: 'Cidade',
        ...getColumnSearchProps('city', 'Cidade'),
        sorter: (a, b) => a.city?.localeCompare(b.city),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return columns;
  };

  const fetchCompany = async () => {
    const products = await api.get('/Company');
    return products.data;
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <TableAntd
      columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
      routePermissions="Company"
      requestName="Company"
      route="/Empresas"
      cdPage="id"
      btnNew={true}
    />
  );
}
